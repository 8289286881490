import { Button, FlexDiv, Header2, Input, LoadingDots, Modal, useToast } from '@/src/components/Components';
import { useUserSettings } from '@/src/components/ContextAndHooks';
import { ApiRequests } from '@/src/requests/ApiRequests';
import { updateAuto } from '@/src/utils/Helpers';
import React, { useState } from 'react';
export var CreateNewTeamModal = function (props) {
    var _a = useUserSettings(), loggedInUser = _a[0], setLoggedInUser = _a[1], trigger = _a[2], fetchComplete = _a[3];
    var _b = useState(false), addingTeamMember = _b[0], setAddingTeamMember = _b[1];
    var _c = useState(), newTeam = _c[0], setNewTeam = _c[1];
    var addToast = useToast();
    var addTeamMember = function () {
        setAddingTeamMember(true);
        ApiRequests.User.postTeamToUser(loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.pk, newTeam, function (response) {
            if (response.status == '200') {
                addToast('New team  added', 'success');
                setLoggedInUser(function (prev) { return updateAuto(prev, { teamAccess: { $push: [{ pk: null, teamSettings: response.data }] } }); });
                props.setShowModal(false);
                if (props.onSuccessCreation)
                    props.onSuccessCreation();
            }
            else {
                addToast('Error adding team member', 'danger');
            }
            setAddingTeamMember(false);
        });
    };
    return (React.createElement(Modal, { style: { maxWidth: '600px' }, show: props.showModal, onHide: function () { return props.setShowModal(false); } },
        React.createElement(Header2, null, "Add new Team"),
        props.extraExplanation,
        React.createElement(Input, { style: { marginTop: '10px' }, label: 'Name of your team', placeHolder: 'Enter a name of your new team', value: newTeam === null || newTeam === void 0 ? void 0 : newTeam.name, onChange: function (v) { return setNewTeam(function (prev) { return updateAuto(prev, { name: { $set: v } }); }); } }),
        React.createElement(FlexDiv, { style: { marginTop: '20px' } },
            React.createElement(Button, { type: 'blue', size: 'medium', onClick: addTeamMember, disabled: addingTeamMember }, addingTeamMember ? React.createElement(LoadingDots, null) : 'Save'),
            React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: function () { setNewTeam(null); props.setShowModal(false); }, disabled: addingTeamMember }, "Cancel"))));
};
