import React, { useEffect, useState } from 'react';
import { FlexDiv, Header1, useToast } from '../components/Components';
import { Navigate } from 'react-router-dom';
import { ApiRequests } from '../requests/ApiRequests';
import { undefinedOrEmpty } from '../utils/Helpers';
import { useUserSettings } from '../components/ContextAndHooks';
export var Logout = function () {
    var _a = useState(false), logginOut = _a[0], setLoggingOut = _a[1];
    var _b = useUserSettings(), loggedInUser = _b[0], setLoggedInUser = _b[1];
    var addToast = useToast();
    useEffect(function () {
        logout();
    }, []);
    var logout = function () {
        setLoggingOut(true);
        ApiRequests.Login.logout(function (response) {
            if (response.status == '200') {
                setLoggedInUser(null);
                addToast('Logged out', 'success');
            }
            setLoggingOut(false);
        });
    };
    return (React.createElement(React.Fragment, null,
        undefinedOrEmpty(loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.pk) &&
            React.createElement(Navigate, { to: "/login" }),
        React.createElement(FlexDiv, { style: { width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'center' } },
            React.createElement(Header1, { black: true }, "Logging out..."))));
};
