import React, { useEffect, useMemo, useState, createContext, useContext } from 'react';
import { ApiRequests } from '../requests/ApiRequests';
import { CenterItems, LoadingDots, ShowIf, useToast } from './Components';
import { notUndefinedOrEmpty, updateAuto } from '../utils/Helpers';
var UserContext = createContext(null);
export var UserContextProvider = function (props) {
    var _a = useState(false), fetchingData = _a[0], setFetchingData = _a[1];
    var _b = useState(), loggedInUser = _b[0], setLoggedInUser = _b[1];
    var _c = useState(false), fetchComplete = _c[0], setFetchComplete = _c[1];
    var addTost = useToast();
    useEffect(function () {
        getLoggedIn();
    }, []);
    var getLoggedIn = function () {
        setFetchingData(true);
        ApiRequests.User.getLoggedInUser(function (response) {
            if (response.status == '200') {
                setLoggedInUser(response.data);
                setFetchComplete(true);
            }
            else {
                addTost('Error getting logged in user', 'danger');
            }
            setFetchingData(false);
        });
    };
    var triggerFetchAgain = function () {
        getLoggedIn();
    };
    return (React.createElement(UserContext.Provider, { value: [loggedInUser, setLoggedInUser, triggerFetchAgain, fetchComplete] },
        React.createElement(ShowIf, { show: fetchingData },
            React.createElement(CenterItems, null,
                React.createElement(LoadingDots, null))),
        React.createElement(ShowIf, { show: !fetchingData }, props.children)));
};
export var useUserSettings = function () {
    return React.useContext(UserContext);
};
var TeamStatisticsContext = createContext(null);
export var TeamStatisticsContextProvider = function (props) {
    var _a;
    var _b = useState(false), fetchingData = _b[0], setFetchingData = _b[1];
    var _c = useState([]), teamSettings = _c[0], setTeamSettings = _c[1];
    var _d = useState([]), activeSubscrtiptions = _d[0], setActiveSubscrtiptions = _d[1];
    var _e = useState(true), fetchComplete = _e[0], setFetchComplete = _e[1];
    var _f = useUserSettings(), userSettings = _f[0], setUserSettings = _f[1];
    var addTost = useToast();
    useEffect(function () {
        getAllStatistics();
    }, [(_a = userSettings === null || userSettings === void 0 ? void 0 : userSettings.teamAccess) === null || _a === void 0 ? void 0 : _a.length]);
    var getAllStatistics = function () {
        getTeamStatistics();
        getSubscriptions();
    };
    var getSubscriptions = function () {
        var _a;
        setFetchingData(true);
        (_a = userSettings === null || userSettings === void 0 ? void 0 : userSettings.teamAccess) === null || _a === void 0 ? void 0 : _a.forEach(function (team, i) {
            var _a;
            ApiRequests.Team.Subscriptions.getActive((_a = team === null || team === void 0 ? void 0 : team.teamSettings) === null || _a === void 0 ? void 0 : _a.pk, function (response) {
                if (response.status == '200') {
                    setActiveSubscrtiptions(function (prev) {
                        var index = prev === null || prev === void 0 ? void 0 : prev.findIndex(function (k) { var _a; return (k === null || k === void 0 ? void 0 : k.teamPk) == ((_a = team === null || team === void 0 ? void 0 : team.teamSettings) === null || _a === void 0 ? void 0 : _a.pk); });
                        if (index > -1) {
                            prev[index] = response.data;
                            return prev;
                        }
                        else {
                            return prev === null || prev === void 0 ? void 0 : prev.concat(response.data);
                        }
                    });
                    setFetchComplete(true);
                }
                else {
                    addTost('Error getting team subscriptions', 'danger');
                }
                setFetchingData(false);
            });
        });
    };
    var getTeamStatistics = function () {
        var _a;
        setFetchingData(true);
        (_a = userSettings === null || userSettings === void 0 ? void 0 : userSettings.teamAccess) === null || _a === void 0 ? void 0 : _a.forEach(function (team, i) {
            var _a;
            ApiRequests.Team.get((_a = team === null || team === void 0 ? void 0 : team.teamSettings) === null || _a === void 0 ? void 0 : _a.pk, function (response) {
                if (response.status == '200') {
                    setTeamSettings(function (prev) {
                        var index = prev === null || prev === void 0 ? void 0 : prev.findIndex(function (k) { var _a; return (k === null || k === void 0 ? void 0 : k.pk) == ((_a = team === null || team === void 0 ? void 0 : team.teamSettings) === null || _a === void 0 ? void 0 : _a.pk); });
                        if (index > -1) {
                            prev[index] = response.data;
                            return prev;
                        }
                        else {
                            return prev === null || prev === void 0 ? void 0 : prev.concat(response.data);
                        }
                    });
                    setFetchComplete(true);
                }
                else {
                    addTost('Error getting team statistics', 'danger');
                }
                setFetchingData(false);
            });
        });
    };
    var triggerFetchAgain = function () {
        getAllStatistics();
    };
    var updateStatistics = function (arg) {
        setTeamSettings(function (prev) {
            var index = prev === null || prev === void 0 ? void 0 : prev.findIndex(function (k) { return (k === null || k === void 0 ? void 0 : k.pk) == (arg === null || arg === void 0 ? void 0 : arg.teamPk); });
            console.log("update stats");
            if (index > -1) {
                prev[index] = updateAuto(prev[index], { statistics: { numberOfKeys: { $apply: function (p) { return p + arg.numberOfKeysIncrement; } } } });
                prev[index] = updateAuto(prev[index], { statistics: { numberOfStrings: { $apply: function (p) { return p + arg.numBerOfWordsIncrement; } } } });
                return prev;
            }
            else {
                return prev;
            }
        });
    };
    return (React.createElement(TeamStatisticsContext.Provider, { value: { teamStatistics: teamSettings === null || teamSettings === void 0 ? void 0 : teamSettings.map(function (d) { return d === null || d === void 0 ? void 0 : d.statistics; }), updateTeamStatistics: updateStatistics, activeSubscriptions: activeSubscrtiptions, forceUpdate: triggerFetchAgain, fetchComplete: fetchComplete } },
        React.createElement(ShowIf, { show: false && fetchingData },
            React.createElement(CenterItems, null,
                React.createElement(LoadingDots, null))),
        React.createElement(ShowIf, { show: true || !fetchingData }, props.children)));
};
export var useTeamStatistics = function () {
    return React.useContext(TeamStatisticsContext);
};
var WrapEnableDarkOverlayContext = createContext(null);
export function useDarkoverContext() {
    var state = useContext(WrapEnableDarkOverlayContext);
    var t = useMemo(function () { return state; }, [state]);
    return t;
}
export var WrapEnableDarkOverlay = function (props) {
    var _a = useState(''), elementToShowDarkContextFor = _a[0], setElementToShowDarkContextFor = _a[1];
    //	const [elementsToNeverShowContextFor, setElementsToNeverShowContextFor] = useState<Array<string>>([]);
    var _b = useState([]), elementsToNeverShowContextFor = _b[0], setElementsToNeverShowContextFor = _b[1];
    var disableElementToBeShownAgain = function (element) {
        if (notUndefinedOrEmpty(element))
            setElementsToNeverShowContextFor(function (prev) { return updateAuto(prev, { $push: [element] }); });
    };
    var elementToShowDarkContextForFiltered = useMemo(function () {
        var disable = elementsToNeverShowContextFor === null || elementsToNeverShowContextFor === void 0 ? void 0 : elementsToNeverShowContextFor.includes(elementToShowDarkContextFor);
        return disable ? '' : elementToShowDarkContextFor;
    }, [elementToShowDarkContextFor, elementsToNeverShowContextFor]);
    return (React.createElement(React.Fragment, null,
        React.createElement(WrapEnableDarkOverlayContext.Provider, { value: { disableElementToBeShownAgain: disableElementToBeShownAgain, elementToShowDarkContextFor: elementToShowDarkContextForFiltered, setElementToShowDarkContextFor: setElementToShowDarkContextFor } }, props.children)));
};
