import { Button, FlexDiv, Header1, Header3, Indicator, Line, LoadingDots, ShowIf, useToast } from '@/src/components/Components';
import { ApiRequests } from '@/src/requests/ApiRequests';
import React, { useEffect, useMemo, useState } from 'react';
import { FullPricingTable } from '@/src/website/Pricing';
export var getSubscriptionNameFromSubscription = function (s) {
    if ((s === null || s === void 0 ? void 0 : s.type) == 'trial') {
        return 'Trial';
    }
    else if ((s === null || s === void 0 ? void 0 : s.type) == 'start') {
        return 'Start';
    }
    else if ((s === null || s === void 0 ? void 0 : s.type) == 'premium') {
        return 'Premium';
    }
    else if ((s === null || s === void 0 ? void 0 : s.type) == 'plus') {
        return 'Plus';
    }
    else if ((s === null || s === void 0 ? void 0 : s.type) == 'free') {
        return 'Free';
    }
    else if ((s === null || s === void 0 ? void 0 : s.type) == 'beta') {
        return 'Beta';
    }
    else {
        return React.createElement(LoadingDots, null);
    }
};
export var BillingOverview = function (props) {
    var _a;
    var addToast = useToast();
    var _b = useState(), paymentSettings = _b[0], setPaymentSettings = _b[1];
    var _c = useState(), activeSubscription = _c[0], setActiveSubscription = _c[1];
    var _d = useState(false), doingAction = _d[0], setDoingAction = _d[1];
    var _e = useState(), newPlan = _e[0], setNewPlan = _e[1];
    var onClickManageMyCurrentPlan = function () {
        ApiRequests.Team.Subscriptions.getLinkUrlToManagePlans(props.teamSettings.pk, function (response) {
            if (response.status == '200') {
                window.open(response.data.url, '_blank');
            }
            else {
                addToast(response.errors, 'danger');
            }
        });
    };
    useEffect(function () {
        getActiveSubscription();
    }, [(_a = props.teamSettings) === null || _a === void 0 ? void 0 : _a.pk]);
    var getActiveSubscription = function () {
        setDoingAction(true);
        ApiRequests.Team.Subscriptions.getActive(props.teamSettings.pk, function (response) {
            if (response.status == '200') {
                setActiveSubscription(response.data);
            }
            else {
                addToast(response.errors, 'danger');
            }
            setDoingAction(false);
        });
    };
    var onClickBasic = function () {
        onClickPremium();
    };
    var onClickPlus = function () {
        onClickPremium();
    };
    var onClickPremium = function () {
        setDoingAction(true);
        ApiRequests.Team.Subscriptions.createSubscription(props.teamSettings.pk, { invoicePeriod: 'monthly', type: 'premium' }, function (response) {
            if (response.status == '200') {
                window.open(response.data.url, '_blank');
            }
            else {
                addToast(response.errors, 'danger');
            }
            setDoingAction(false);
        });
    };
    var isBeta = useMemo(function () {
        return (activeSubscription === null || activeSubscription === void 0 ? void 0 : activeSubscription.type) == 'beta';
    }, [activeSubscription === null || activeSubscription === void 0 ? void 0 : activeSubscription.type]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Header3, { black: true }, "Subscription"),
        React.createElement(Line, { noMargin: true }),
        React.createElement("div", { style: { marginTop: '20px' } },
            React.createElement(FlexDiv, null,
                React.createElement(Header1, { black: true, style: { textTransform: 'uppercase' } },
                    React.createElement(Indicator, { type: 'blue' }, getSubscriptionNameFromSubscription(activeSubscription)))),
            React.createElement(ShowIf, { show: (activeSubscription === null || activeSubscription === void 0 ? void 0 : activeSubscription.type) != 'premium' },
                React.createElement(Button, { style: { marginTop: '10px' }, size: 'small', type: 'white', disabled: doingAction, onClick: onClickManageMyCurrentPlan },
                    React.createElement(ShowIf, { show: doingAction },
                        React.createElement(LoadingDots, null)),
                    React.createElement(ShowIf, { show: !doingAction }, " Manage my current plan")))),
        React.createElement(Header3, { black: true, style: { marginTop: '20px' } }, "Select plan"),
        React.createElement(ShowIf, { show: (activeSubscription === null || activeSubscription === void 0 ? void 0 : activeSubscription.type) != 'premium' },
            React.createElement(React.Fragment, null,
                React.createElement(FullPricingTable, { doingAction: doingAction, onClick: { basic: onClickBasic, plus: onClickPlus, premium: onClickPremium }, disableBenefits: true })),
            React.createElement("a", { target: '_blank', href: "http://localhost:3000/#/web/pricing" },
                React.createElement(Button, { type: 'underline', style: { marginTop: '20px' } }, "Show full comparison ")))));
};
