import { fetchAPI } from "../utils/http/ajax";
export var ApiRequests = {
    Project: {
        post: function (data, callback) {
            fetchAPI('POST', '/projects', data, function (response) {
                callback(response);
            });
        },
        get: function (pk, callback) {
            fetchAPI('GET', '/projects/' + pk, '', function (response) {
                callback(response);
            });
        },
        getAll: function (callback) {
            fetchAPI('GET', '/projects/', '', function (response) {
                callback(response);
            });
        },
        put: function (data, callback) {
            fetchAPI('PUT', '/projects/' + data.pk, data, function (response) {
                callback(response);
            });
        },
        delele: function (pk, callback) {
            fetchAPI('DELETE', '/projects/' + pk, '', function (response) {
                callback(response);
            });
        },
        MachineTranslations: {
            machineTranslate: function (projectPk, data, callback) {
                fetchAPI('POST', '/projects/' + projectPk + '/machinetranslate', data, function (response) {
                    callback(response);
                });
            },
            statistics: function (projectPk, callback) {
                fetchAPI('GET', '/projects/' + projectPk + '/statistics', '', function (response) {
                    callback(response);
                });
            }
        },
        FileUpload: {
            uploadFile: function (projectPk, data, callback) {
                fetchAPI('POST', '/projects/' + projectPk + '/upload', data, function (response) {
                    callback(response);
                });
            }
        },
        CDN: {
            getLatestCdnStatus: function (projectPk, callback) {
                fetchAPI('GET', '/projects/' + projectPk + '/cdn', '', function (response) {
                    callback(response);
                });
            }
            /*	publishToCdn: (projectPk: string,data:Types.PublishToCdnRequest, callback: (arg0: FetchResponseType<Types.PublishToCdnResponse>) => void) => {
                    fetchAPI('POST', '/projects/' + projectPk + '/cdn', data, (response) => {
                        callback(response);
                    });
                }
                ,
                unpublishCdn: (projectPk: string, callback: (arg0: FetchResponseType) => void) => {
                    fetchAPI('DELETE', '/projects/' + projectPk + '/cdn', '', (response) => {
                        callback(response);
                    });
                }*/
        }
    },
    Codes: {
        post: function (projectPk, data, callback) {
            fetchAPI('POST', getCodeLink(projectPk), data, function (response) {
                callback(response);
            });
        },
        get: function (projectPk, codePk, callback) {
            fetchAPI('GET', getCodeLink(projectPk) + codePk, '', function (response) {
                callback(response);
            });
        },
        put: function (data, callback) {
            fetchAPI('PUT', getCodeLink(data.projectPk) + data.pk, data, function (response) {
                callback(response);
            });
        },
        delele: function (projectPk, pk, callback) {
            fetchAPI('DELETE', getCodeLink(projectPk) + pk, '', function (response) {
                callback(response);
            });
        }
    },
    Tags: {
        post: function (projectPk, data, callback) {
            fetchAPI('POST', getTagLink(projectPk), data, function (response) {
                callback(response);
            });
        },
        getAll: function (projectPk, callback) {
            fetchAPI('GET', getTagLink(projectPk), '', function (response) {
                callback(response);
            });
        },
        delele: function (projectPk, pk, callback) {
            fetchAPI('DELETE', getTagLink(projectPk) + pk, '', function (response) {
                callback(response);
            });
        }
    },
    Translations: {
        /*post: (codepk: string, data: Types.Translation, callback: (arg0: FetchResponseType<Types.WrapStatisticsChange<Types.Translation>>) => void) => {
            fetchAPI('POST', getTranslationLink({ codePk: codePk, translationPk: '' }), data, (response) => {
                callback(response);
            });
        },*/
        put: function (data, callback) {
            fetchAPI('PUT', getTranslationLink({ projectPk: data.projectPk, codePk: data.codePk, locale: data.full_locale }), data, function (response) {
                callback(response);
            });
        },
        delete: function (data, callback) {
            fetchAPI('DELETE', getTranslationLink({ projectPk: data.projectPk, codePk: data.codePk, locale: data.full_locale }), '', function (response) {
                callback(response);
            });
        },
        machineTranslate: function (data, callback) {
            fetchAPI('POST', getTranslationLink({ projectPk: data === null || data === void 0 ? void 0 : data.translation.projectPk, codePk: data.translation.codePk, locale: data.translation.full_locale }) + '/machinetranslate', data, function (response) {
                callback(response);
            });
        }
    },
    Languages: {
        post: function (projectPk, data, callback) {
            fetchAPI('POST', getLanguageLink(projectPk), data, function (response) {
                callback(response);
            });
        },
        getAll: function (projectPk, callback) {
            fetchAPI('GET', getLanguageLink(projectPk), '', function (response) {
                callback(response);
            });
        },
        get: function (projectPk, locale, callback) {
            fetchAPI('GET', getLanguageLink(projectPk) + locale, '', function (response) {
                callback(response);
            });
        },
        put: function (data, callback) {
            fetchAPI('PUT', getLanguageLink(data.project_pk) + data.language_code, data, function (response) {
                callback(response);
            });
        },
        delele: function (projectPk, locale, callback) {
            fetchAPI('DELETE', getLanguageLink(projectPk) + locale, '', function (response) {
                callback(response);
            });
        }
    },
    Contributors: {
        post: function (projectPk, data, callback) {
            fetchAPI('POST', getContributorLink(projectPk), data, function (response) {
                callback(response);
            });
        },
        getAll: function (projectPk, callback) {
            fetchAPI('GET', getContributorLink(projectPk), '', function (response) {
                callback(response);
            });
        },
        get: function (projectPk, pk, callback) {
            fetchAPI('GET', getContributorLink(projectPk) + pk, '', function (response) {
                callback(response);
            });
        },
        put: function (data, callback) {
            fetchAPI('PUT', getContributorLink(data.projectPk) + data.pk, data, function (response) {
                callback(response);
            });
        },
        delele: function (projectPk, pk, callback) {
            fetchAPI('DELETE', getContributorLink(projectPk) + pk, '', function (response) {
                callback(response);
            });
        }
    },
    ApiKeys: {
        post: function (projectPk, data, callback) {
            fetchAPI('POST', getProjectApiLink(projectPk), data, function (response) {
                callback(response);
            });
        },
        getAll: function (projectPk, callback) {
            fetchAPI('GET', getProjectApiLink(projectPk), '', function (response) {
                callback(response);
            });
        },
        get: function (projectPk, pk, callback) {
            fetchAPI('GET', getProjectApiLink(projectPk) + pk, '', function (response) {
                callback(response);
            });
        },
        put: function (data, callback) {
            fetchAPI('PUT', getProjectApiLink(data.projectPk) + data.pk, data, function (response) {
                callback(response);
            });
        },
        delele: function (projectPk, pk, callback) {
            fetchAPI('DELETE', getProjectApiLink(projectPk) + pk, '', function (response) {
                callback(response);
            });
        }
    },
    User: {
        getLoggedInUser: function (callback) {
            fetchAPI('GET', getUsersLink(), '', function (response) {
                callback(response);
            });
        },
        put: function (data, callback) {
            fetchAPI('PUT', getUsersLink() + data.pk, data, function (response) {
                callback(response);
            });
        },
        postTeamToUser: function (userPk, data, callback) {
            fetchAPI('POST', getUsersLink() + userPk + '/teams', data, function (response) {
                callback(response);
            });
        },
        delete: function (userPk, callback) {
            fetchAPI('DELETE', getUsersLink() + userPk, '', function (response) {
                callback(response);
            });
        }
        /*	,
            changePassword: (userpk: string, data: { newPasswordMd5: string, oldPasswordMd5: string }, callback: (arg0: FetchResponseType) => void) => {
                fetchAPI('PUT', getUsersLink() + userPk + '/changepassword', data, (response) => {
                    callback(response);
                });
            } */
    },
    TeamAccess: {
    /*setAsNotificationVerfied: (pk: string, callback: (arg0: FetchResponseType) => void) => {
        fetchAPI('GET', getTeamAccessLink() + pk + '/verified', '', (response) => {
            callback(response);
        });
    },*/
    },
    Team: {
        get: function (pk, callback) {
            fetchAPI('GET', getTeamsLink() + pk, '', function (response) {
                callback(response);
            });
        },
        put: function (data, callback) {
            fetchAPI('PUT', getTeamsLink() + data.pk, data, function (response) {
                callback(response);
            });
        },
        delete: function (pk, callback) {
            fetchAPI('DELETE', getTeamsLink() + pk, '', function (response) {
                callback(response);
            });
        }
        //    app.post('/teams/:teamId/trasnferownership', isAuthenticated, projectAccess('GET'), TeamController.transferOwnership);
        ,
        transerOwnership: function (teamPk, data, callback) {
            fetchAPI('POST', getTeamsLink() + teamPk + '/trasnferownership', data, function (response) {
                callback(response);
            });
        },
        /*	Statistics: {
                getStatistics: (teamPk: string, callback: (arg0: FetchResponseType<Types.TeamStatistics>) => void) => {
                    fetchAPI('GET', getTeamsLink() + teamPk + '/statistics', '', (response) => {
                        callback(response);
                    });
                }
            },*/
        Members: {
            getAll: function (teamPk, callback) {
                fetchAPI('GET', getTeamsLink() + teamPk + '/members', '', function (response) {
                    callback(response);
                });
            },
            post: function (teamPk, data, callback) {
                fetchAPI('POST', getTeamsLink() + teamPk + '/members', data, function (response) {
                    callback(response);
                });
            },
            delete: function (teamPk, userPk, callback) {
                fetchAPI('DELETE', getTeamsLink() + teamPk + '/members/' + userPk, '', function (response) {
                    callback(response);
                });
            }
        },
        Subscriptions: {
            getActive: function (teamPk, callback) {
                fetchAPI('GET', getTeamsLink() + teamPk + '/subscription', '', function (response) {
                    callback(response);
                });
            },
            getLinkUrlToManagePlans: function (teamPk, callback) {
                fetchAPI('GET', getTeamsLink() + teamPk + '/subscription/get_management_url', '', function (response) {
                    callback(response);
                });
            },
            createSubscription: function (teamPk, data, callback) {
                fetchAPI('POST', getTeamsLink() + teamPk + '/subscription/create_subscription', data, function (response) {
                    callback(response);
                });
            }
        }
        /*	, MachineTranslationStatistics: {
                getStatistics: (teamPk: string, callback: (arg0: FetchResponseType<Types.MachineTranslationStatistics>) => void) => {
                    fetchAPI('GET', getTeamsLink() + teamPk + '/machinetranslationsstatistics', '', (response) => {
                        callback(response);
                    });
                }
            }*/
    },
    Signup: {
        post: function (data, callback) {
            fetchAPI('POST', '/signup', data, function (response) {
                callback(response);
            });
        }
    },
    Login: {
        login: function (data, callback) {
            fetchAPI('POST', '/login', data, function (response) {
                callback(response);
            });
        }, logout: function (callback) {
            fetchAPI('GET', '/logout', '', function (response) {
                callback(response);
            });
        }
    }
};
var getTranslationLink = function (data) { return '/projects/' + data.projectPk + '/codes/' + data.codePk + '/translations/' + data.locale; };
var getCodeLink = function (projectPk) { return '/projects/' + projectPk + '/codes/'; };
var getTagLink = function (projectPk) { return '/projects/' + projectPk + '/tags/'; };
var getLanguageLink = function (projectPk) { return '/projects/' + projectPk + '/languages/'; };
var getContributorLink = function (projectPk) { return '/projects/' + projectPk + '/contributors/'; };
var getProjectApiLink = function (projectPk) { return '/projects/' + projectPk + '/apikeys/'; };
var getUsersLink = function () { return '/users/'; };
var getTeamsLink = function () { return '/teams/'; };
var getTeamAccessLink = function () { return '/teamsaccess/'; };
