import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
//@ts-ignore
import style from '../css/app.module.scss';
import OutsideAlerter, { Button, FlexDiv, Indicator, Line, ShowIf, Tooltip } from '../components/Components';
import { CircleWithNameAndEmail } from './project/ProjectContributors';
import { useTeamStatistics, useUserSettings } from '../components/ContextAndHooks';
import { undefinedOrEmpty } from '../utils/Helpers';
import { getSubscriptionNameFromSubscription } from './UserTeamPage/BIllingOverview';
import { ArrowUpSvg, OverviewSvg } from '../components/SvgItems';
import { LogoSvg } from '../website/WebsiteCompoents';
export var LeftMenu = function () {
    return (React.createElement("div", { className: style.menuWrapper },
        React.createElement("div", { className: 'flex-center ', style: { paddingTop: '20px' } },
            React.createElement(NavLink, { to: '/web' },
                React.createElement(LogoSvg, null))),
        React.createElement("div", { style: { marginTop: '30px' }, className: 'flex-center flex-column' },
            React.createElement(MenuItem, { hoverText: 'Projects', svgIcon: React.createElement(OverviewSvg, null), path: '/projects' })),
        React.createElement(CurrentlyLoggedIn, null)));
};
var MenuItem = function (props) {
    return (React.createElement(NavLink, { style: { width: '100%' }, className: function (g) { return ((g === null || g === void 0 ? void 0 : g.isActive) ? style.menuItemActive : ''); }, to: props.path },
        React.createElement(Tooltip, { displayFlex: true, className: style.menuItemOuter, placement: 'right', overlay: props.hoverText },
            React.createElement("div", { className: style.leftMenuItem }, props.svgIcon))));
};
var CurrentlyLoggedIn = function (props) {
    var _a = useState(false), clickedOnCircle = _a[0], setclickedOnCircle = _a[1];
    var _b = useUserSettings(), loggedInUser = _b[0], setLoggedInUser = _b[1], trigger = _b[2], fetchComplete2 = _b[3];
    var _c = useTeamStatistics(), activeSubscriptions = _c.activeSubscriptions, fetchComplete = _c.fetchComplete, teamStatistics = _c.teamStatistics, updateTeamStatistics = _c.updateTeamStatistics;
    var getTeamName = function () {
        var _a, _b, _c, _d, _e, _f;
        var teamName = (_c = (_b = (_a = loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.teamAccess) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.teamSettings) === null || _c === void 0 ? void 0 : _c.name;
        return undefinedOrEmpty((_f = (_e = (_d = loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.teamAccess) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.teamSettings) === null || _f === void 0 ? void 0 : _f.pk) ? 'No team' : teamName;
    };
    var getCurrentTeamOAccess = function () {
        var _a, _b, _c, _d, _e;
        var accessRole = (_e = (_d = (_c = (_b = (_a = loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.teamAccess) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.teamSettings) === null || _c === void 0 ? void 0 : _c.members) === null || _d === void 0 ? void 0 : _d.find(function (member) { return member.userPk == (loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.pk); })) === null || _e === void 0 ? void 0 : _e.role;
        return accessRole == 'owner' ? 'Owner' : (accessRole == 'admin' ? 'Admin' : '');
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: style.CurrentlyLoggedInCircle },
            React.createElement(FlexDiv, { alignCenter: true, style: { justifyContent: 'center', flexDirection: 'column', marginBottom: '20px' } },
                React.createElement(Indicator, { type: 'blue', style: { marginTop: '30px' } }, getSubscriptionNameFromSubscription(activeSubscriptions === null || activeSubscriptions === void 0 ? void 0 : activeSubscriptions[0])),
                React.createElement(NavLink, { onClick: function () { return setclickedOnCircle(false); }, to: '/user/team/billingoverview' },
                    React.createElement(Button, { buttonCss: { fontSize: '12px' }, style: { marginTop: '5px' }, type: 'gray-text' },
                        React.createElement(FlexDiv, { alignCenter: true },
                            React.createElement(ArrowUpSvg, null),
                            "Upgrade")))),
            React.createElement("div", { className: style.CurrentlyLoggedInCircleInner, onClick: function () { return setclickedOnCircle(true); } }, "PS"),
            React.createElement(OutsideAlerter, { onClickOutside: function () { return setclickedOnCircle(false); } },
                React.createElement(ShowIf, { className: style.menuExpandUserHead, show: clickedOnCircle },
                    React.createElement(CircleWithNameAndEmail, { disableCircle: true, style: { padding: '20px 20px 10px' }, email: getCurrentTeamOAccess(), name: getTeamName() }),
                    React.createElement(NavLink, { onClick: function () { return setclickedOnCircle(false); }, to: '/user/team/billingoverview' },
                        React.createElement("div", { className: style.menuExpandUserHeadItem }, "Billing")),
                    React.createElement(NavLink, { onClick: function () { return setclickedOnCircle(false); }, to: '/user/team/' },
                        " ",
                        React.createElement("div", { onClick: function () { return setclickedOnCircle(false); }, className: style.menuExpandUserHeadItem }, "Team settings")),
                    React.createElement(Line, { noMargin: true }),
                    React.createElement(NavLink, { onClick: function () { return setclickedOnCircle(false); }, to: '/user/user' },
                        "  ",
                        React.createElement("div", { className: style.menuExpandUserHeadItem },
                            React.createElement("div", null, "Profile settings"),
                            React.createElement("div", { style: { color: 'gray', fontSize: '12px' } }, loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.email))),
                    React.createElement(Line, { noMargin: true }),
                    React.createElement(NavLink, { onClick: function () { return setclickedOnCircle(false); }, to: '/logout' },
                        "  ",
                        React.createElement("div", { className: style.menuExpandUserHeadItem }, "Logout")))))));
};
var FolderSvg = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "33.859", height: "24.506", viewBox: "0 0 33.859 24.506" },
        React.createElement("path", { id: "Path_1", "data-name": "Path 1", d: "M41.872,162.705H28.065c-.453-1.087-.954-2.469-1.41-3.344-.27-.52-.525-.861-.743-.861H12.256A2.167,2.167,0,0,0,10,160.567v20.369a2.033,2.033,0,0,0,1.99,2.07H41.869a2.031,2.031,0,0,0,1.99-2.07v-16.16a2.1,2.1,0,0,0-.6-1.475A1.934,1.934,0,0,0,41.872,162.705Z", transform: "translate(-10 -158.5)", fill: "inherit" })));
};
var QuestionRoundSvg = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "39", height: "39", viewBox: "0 0 39 39" },
        React.createElement("g", { id: "Group_1", "data-name": "Group 1", transform: "translate(198 -140)" },
            React.createElement("g", { id: "Ellipse_2", "data-name": "Ellipse 2", transform: "translate(-198 140)", fill: "none", stroke: "#5489dc", "stroke-width": "3" },
                React.createElement("circle", { cx: "19.5", cy: "19.5", r: "19.5", stroke: "none" }),
                React.createElement("circle", { cx: "19.5", cy: "19.5", r: "18", fill: "none" })),
            React.createElement("text", { id: "_", "data-name": "?", transform: "translate(-183 170)", fill: "#5489dc", "font-size": "25", "font-family": "SegoeUI-Bold, Segoe UI", "font-weight": "700" },
                React.createElement("tspan", { x: "0", y: "0" }, "?")))));
};
