import React from 'react';
import { HashRouter, useLocation, Route, Routes, Navigate } from 'react-router-dom';
//Transitiotns
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { Project } from './pages/Project';
import '../src/css/flexbox/main.scss';
import { User } from './pages/User';
import { ShowIf, ToastProvider, useToast } from './components/Components';
import { TeamStatisticsContextProvider, UserContextProvider, WrapEnableDarkOverlay, useUserSettings } from './components/ContextAndHooks';
import { Signup } from './pages/Signup';
import { Logout } from './pages/Logout';
import { WebsiteWrapper } from './website/WebsiteWrapper';
export var Routing = function (props) {
    // const [prevPath, setPrevPath] = useState<string>("");
    /*
      useEffect(() => {
        //Assume locale has changed, update
        if (location?.search?.includes("locale")) {
          const urlSearchParams = new URLSearchParams(location?.search);
          //@ts-ignore
          const params = Object.fromEntries(urlSearchParams.entries());
          fetchAPI('GET', '?locale=' + params['locale'], '', (response) => {
            //  setTimeout(() => props.triggerLocaleUpdate(), 500); //Give the server some time to fetch the new locale (Async)
          });
        }
        if (location?.search?.includes("brand")) {
          const urlSearchParams = new URLSearchParams(location?.search);
          //@ts-ignore
          const params = Object.fromEntries(urlSearchParams.entries());
          fetchAPI('GET', '?brand=' + params['brand'], '', (response) => {
            //    setTimeout(() => props.triggerBrandUpdate(), 500); //Give the server some time to fetch the new locale (Async)
          });
        }
        //Make sure logins and logouts are triggered
        if (prevPath == '/login' || prevPath == '/logout')
          //  props.triggerLoggedInUpdate();
          setPrevPath(location?.pathname)
      }, [location]) */
    return (React.createElement(HashRouter, null,
        React.createElement(InternalRoute, null)));
};
var InternalRoute = function () {
    var location = useLocation();
    return (React.createElement(WrapEnableDarkOverlay, null,
        React.createElement(ToastProvider, null,
            React.createElement(UserContextProvider, null,
                React.createElement(TeamStatisticsContextProvider, null,
                    React.createElement(TransitionGroup, null,
                        React.createElement(CSSTransition, { key: '', classNames: "fade", timeout: 300, in: true, appear: true },
                            React.createElement(Routes, null,
                                React.createElement(Route, { element: React.createElement(Navigate, { to: "/login" }) }),
                                React.createElement(Route, { path: "/", element: React.createElement(Home, null) }),
                                React.createElement(Route, { path: "/web", element: React.createElement(WebsiteWrapper, null) }),
                                React.createElement(Route, { path: "/web/:nestedWebPath", element: React.createElement(WebsiteWrapper, null) }),
                                React.createElement(Route, { path: "/login", element: React.createElement(Login, null) }),
                                React.createElement(Route, { path: "/logout", element: React.createElement(Logout, null) }),
                                React.createElement(Route, { path: "/signup", element: React.createElement(Signup, null) }),
                                React.createElement(Route, { path: "/projects", element: React.createElement(LoginCheck, null,
                                        React.createElement(Home, null)) }),
                                React.createElement(Route, { path: "/user/:nestedUserPath", element: React.createElement(LoginCheck, null,
                                        React.createElement(User, null)) }),
                                React.createElement(Route, { path: "/user/:nestedUserPath/:nestedNestedUserPath", element: React.createElement(LoginCheck, null,
                                        React.createElement(User, null)) }),
                                React.createElement(Route, { path: "/projects", element: React.createElement(LoginCheck, null,
                                        React.createElement(Home, null)) }),
                                React.createElement(Route, { path: "/projects/:projectId/:nestedProjectPath", element: React.createElement(LoginCheck, null,
                                        React.createElement(Project, null)) }),
                                React.createElement(Route, { path: "/projects/:projectId/:nestedProjectPath/:nestedNestedProjectPath", element: React.createElement(LoginCheck, null,
                                        React.createElement(Project, null)) }),
                                React.createElement(Route, { path: "/projects/:projectId", element: React.createElement(LoginCheck, null,
                                        React.createElement(Project, null)) })))))))));
};
var LoginCheck = function (props) {
    var _a = useUserSettings(), loggedInUser = _a[0], setLoggedInUser = _a[1], trigger = _a[2], fetchComplete = _a[3];
    var addTost = useToast();
    var isLoggedIn = function () {
        var loggedIn = ((loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.pk) != null);
        return loggedIn;
    };
    return (React.createElement(React.Fragment, null,
        !(isLoggedIn()) && fetchComplete &&
            React.createElement(Navigate, { to: "/login" }),
        React.createElement(ShowIf, { show: fetchComplete && isLoggedIn() }, props.children)));
};
