import React from 'react';
import { CodeWindow, FlexDiv, Line, P } from '../components/Components';
import { CenterPart, Header, Hero } from './WebsiteWrapper';
export var ReactGuide = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Hero, null,
            React.createElement(CenterPart, { style: { alignItems: 'center' } },
                React.createElement(Header, null, "React Guide"),
                React.createElement(P, { style: { marginTop: '40px', maxWidth: '600px', textAlign: 'center' } }, "This guide explains how to install  and configure your project, then shows you how to synchronize your keys and translations, and presents the syntaxes you can use in your code."))),
        React.createElement(CenterPart, null,
            React.createElement(FlexDiv, null,
                React.createElement(Line, { thick: true, color: 'blue', style: { height: '68px', width: '2px' }, vertical: true }),
                React.createElement(Header, { size: 'medium' }, "1. Installation")),
            React.createElement(P, null, "Install our react component to get started."),
            React.createElement(CodeWindow, null, "npm install @translay/react"),
            React.createElement(FlexDiv, { style: { marginTop: '60px' } },
                React.createElement(Line, { thick: true, color: 'blue', style: { height: '68px', width: '2px' }, vertical: true }),
                React.createElement(Header, { size: 'medium' }, "2. Usage")),
            React.createElement(P, null, "Install our react component to get started."),
            React.createElement(CodeWindow, null, "import {i18n} from '@lingui/core'; \nimport {I18nProvider} from '@lingui/react'; \nimport {en} from 'make-plural/plurals';         // Plural rules for English \nimport {messages} from './locales/en/messages' // English catalog of translations \nimport Inbox from './Inbox'\n\ni18n.loadLocaleData('en', {plurals: en }) \ni18n.load('en', messages) \ni18n.activate('en')\n\nconst App = () => (\n    <I18nProvider i18n={i18n}>\n        <Inbox />\n    </I18nProvider>\n)"))));
};
