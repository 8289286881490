import { Button, Card, ExpandHeader, FlexDiv, Header5, Input, LoadingDots, Modal, ShowIf, useToast } from '@/src/components/Components';
import { ApiRequests } from '@/src/requests/ApiRequests';
import { updateAuto } from '@/src/utils/Helpers';
import React, { useEffect, useState } from 'react';
export var GeneralTeamSettings = function (props) {
    var _a, _b, _c;
    var _d = useState(props.teamSettings.name), teamName = _d[0], setTeamName = _d[1];
    var _e = useState(false), saving = _e[0], setSaving = _e[1];
    var addToast = useToast();
    useEffect(function () {
        var _a;
        setTeamName((_a = props.teamSettings) === null || _a === void 0 ? void 0 : _a.name);
    }, [(_a = props.teamSettings) === null || _a === void 0 ? void 0 : _a.name]);
    var save = function () {
        var _a;
        if (((_a = props.teamSettings) === null || _a === void 0 ? void 0 : _a.name) == teamName) {
            return;
        }
        setSaving(true);
        ApiRequests.Team.put(updateAuto(props.teamSettings, { name: { $set: teamName } }), function (response) {
            if (response.status == '200') {
                addToast('Saved!', 'success');
                props.afterSave(response.data);
            }
            else {
                addToast(response.errors, 'danger');
            }
            setSaving(false);
        });
    };
    var _f = useState(false), showDeleteWarning = _f[0], setShowDeleteWarning = _f[1];
    var _g = useState(''), deleteInputField = _g[0], setDeleteInputField = _g[1];
    var deleteTeam = function () {
        var _a;
        if (deleteInputField != 'DELETE') {
            addToast('Type DELETE to confirm', 'danger');
            return;
        }
        ApiRequests.Team.delete((_a = props.teamSettings) === null || _a === void 0 ? void 0 : _a.pk, function (response) {
            if (response.status == '200') {
                addToast('Deleted!', 'success');
                setShowDeleteWarning(false);
                props.onAfterDelete();
            }
            else {
                addToast(response.errors, 'danger');
            }
            setSaving(false);
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ExpandHeader, { defaultExpanded: true, style: { marginTop: '20px' }, header: 'General Settings', headerLevel: 2 },
            React.createElement(Card, null,
                React.createElement(Input, { inputStyle: { maxWidth: '250px' }, value: teamName, onChange: setTeamName, label: 'Team name', placeHolder: 'Team name' }),
                React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                    React.createElement(Button, { type: 'blue', size: 'medium', onClick: save, disabled: saving || ((_b = props.teamSettings) === null || _b === void 0 ? void 0 : _b.name) == teamName }, saving ? React.createElement(LoadingDots, null) : 'Save'),
                    React.createElement(ShowIf, { show: ((_c = props.teamSettings) === null || _c === void 0 ? void 0 : _c.name) != teamName },
                        React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: function () { var _a; setTeamName((_a = props.teamSettings) === null || _a === void 0 ? void 0 : _a.name); }, disabled: saving }, "Cancel"))))),
        React.createElement(ExpandHeader, { style: { marginTop: '20px' }, header: 'Delete team', headerLevel: 2 },
            React.createElement(Card, null,
                React.createElement(Header5, { black: true }, "Delete this team?"),
                React.createElement(Button, { style: { marginTop: '10px' }, size: 'small', type: 'redwhite', onClick: function () { return setShowDeleteWarning(true); } }, "Delete this team"))),
        React.createElement(Modal, { style: { maxWidth: '600px' }, show: showDeleteWarning, onHide: function () { return setShowDeleteWarning(false); } },
            React.createElement(Input, { value: deleteInputField, onChange: setDeleteInputField, label: 'Type DELETE to confirm' }),
            React.createElement(Button, { disabled: deleteInputField != 'DELETE', size: 'small', style: { marginTop: '20px' }, type: 'redwhite', onClick: deleteTeam }, "Confirm deletion"))));
};
