import { Button, FlexDiv, Header3, Input, LoadingDots, P, ShowIf, useToast } from '@/src/components/Components';
import { useTeamStatistics } from '@/src/components/ContextAndHooks';
import { ApiRequests } from '@/src/requests/ApiRequests';
import { updateAuto } from '@/src/utils/Helpers';
import React, { useEffect, useMemo, useState } from 'react';
export var BillingDetails = function (props) {
    var _a;
    var _b = useState((_a = props.teamSettings) === null || _a === void 0 ? void 0 : _a.billingDetails), billingDetails = _b[0], setBillingDetails = _b[1];
    var _c = useState(props.teamSettings.billingDetails), latestSaveBillingDetails = _c[0], setLatestSaveBillingDetails = _c[1];
    var _d = useState(false), saving = _d[0], setSaving = _d[1];
    var activeSubscriptions = useTeamStatistics().activeSubscriptions;
    var addToast = useToast();
    var activeSubscription = useMemo(function () {
        return activeSubscriptions === null || activeSubscriptions === void 0 ? void 0 : activeSubscriptions.find(function (s) { return (s === null || s === void 0 ? void 0 : s.teamPk) == props.teamSettings.pk; });
    }, [activeSubscriptions]);
    var isBeta = useMemo(function () {
        return (activeSubscription === null || activeSubscription === void 0 ? void 0 : activeSubscription.type) == 'beta';
    }, [activeSubscription]);
    var save = function () {
        setSaving(true);
        ApiRequests.Team.put(updateAuto(props.teamSettings, { billingDetails: { $set: billingDetails } }), function (response) {
            if (response.status == '200') {
                addToast('Saved!', 'success');
                props.afterSave(response.data);
                setLatestSaveBillingDetails(response.data.billingDetails);
            }
            else {
                addToast(response.errors, 'danger');
            }
            setSaving(false);
        });
    };
    useEffect(function () {
        var _a, _b;
        setBillingDetails((_a = props.teamSettings) === null || _a === void 0 ? void 0 : _a.billingDetails);
        setLatestSaveBillingDetails((_b = props.teamSettings) === null || _b === void 0 ? void 0 : _b.billingDetails);
    }, [props.teamSettings.billingDetails]);
    var changeFunction = function (attr) {
        return function (v) { return setBillingDetails(function (prev) {
            var _a;
            return updateAuto(prev, (_a = {}, _a[attr] = { $set: v }, _a));
        }); };
    };
    var needSave = useMemo(function () {
        return JSON.stringify(billingDetails) != JSON.stringify(latestSaveBillingDetails);
    }, [latestSaveBillingDetails, billingDetails]);
    var marginBottom = '15px';
    return (React.createElement(React.Fragment, null,
        React.createElement(Header3, { style: { marginBottom: '20px' }, black: true }, "Billing Details"),
        React.createElement(ShowIf, { show: isBeta },
            React.createElement(P, null, "During BETA, no billing details are required.")),
        React.createElement(ShowIf, { show: !isBeta },
            React.createElement(Input, { style: { maxWidth: '500px', marginBottom: marginBottom }, value: billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.companyName, onChange: changeFunction('companyName'), label: 'Company name', placeHolder: 'Enter name of company' }),
            React.createElement(Input, { style: { maxWidth: '500px', marginBottom: marginBottom }, value: billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.address1, onChange: changeFunction('address1'), label: 'Address line 1', placeHolder: 'Enter address' }),
            React.createElement(Input, { style: { maxWidth: '500px', marginBottom: marginBottom }, value: billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.address2, onChange: changeFunction('address2'), label: 'Address line 2', placeHolder: 'Enter address' }),
            React.createElement(FlexDiv, { className: 'row', style: { maxWidth: '500px', margin: '0 0 5px' } },
                React.createElement("div", { className: 'col-6' },
                    React.createElement(Input, { value: billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.zip, onChange: changeFunction('zip'), label: 'Zip/Postal code', placeHolder: 'Enter zip' })),
                React.createElement("div", { className: 'col-6' },
                    React.createElement(Input, { style: { marginLeft: '10px' }, value: billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.city, onChange: changeFunction('city'), label: 'City', placeHolder: 'Enter city' }))),
            React.createElement(Input, { style: { maxWidth: '500px', marginBottom: marginBottom }, value: billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.countryCode, onChange: changeFunction('countryCode'), label: 'Country', placeHolder: 'Enter country' }),
            React.createElement(Input, { style: { maxWidth: '500px', marginBottom: marginBottom }, value: billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.phoneNumber, onChange: changeFunction('phoneNumber'), label: 'Phone number', placeHolder: 'Enter phone number' }),
            React.createElement(Input, { style: { maxWidth: '500px', marginBottom: marginBottom }, value: billingDetails === null || billingDetails === void 0 ? void 0 : billingDetails.billingEmail, onChange: changeFunction('billingEmail'), label: 'Billing Email', placeHolder: 'Enter billing email' }),
            React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                React.createElement(Button, { type: 'blue', size: 'medium', onClick: save, disabled: saving }, saving ? React.createElement(LoadingDots, null) : 'Save'),
                React.createElement(ShowIf, { show: needSave },
                    React.createElement(Button, { style: { marginLeft: '10px' }, type: 'redwhite', size: 'medium', onClick: function () { setBillingDetails(props.teamSettings.billingDetails); }, disabled: saving }, "Cancel"))))));
};
