var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useMemo, useState } from 'react';
//@ts-ignore
import style from '../../css/app.module.scss';
import { ActionButtonSelect, BoldLabel, Button, CheckboxWithText, DarkenedElementExplaination, FlexDiv, Header2, Header5, Indicator, LabelWithInfoTooltip, Line, LoadingDots, Modal, P, SearchButtonInput, Select, ShowIf, useToast } from '@/src/components/Components';
import { notUndefinedOrEmpty, undefinedOrEmpty, updateAuto, useStateRef } from '@/src/utils/Helpers';
import { fullLocaleToLanguage, languageCodeToName } from '@/src/utils/LanguageHelpers';
import { getFlagName, getFlagSrcFromLanguageCode } from '@/src/components/PageComponents';
import { ApiRequests } from '@/src/requests/ApiRequests';
import { RobotSvg, UploadSvg } from '@/src/components/SvgItems';
import { AllLanguagesAndVariations } from '@/shared/Types';
import { UploadFileModal } from './UploadFileModal';
import { useDarkoverContext } from '@/src/components/ContextAndHooks';
import { NewKeyModal } from './NewKeyModal';
import { KeyRow } from './KeyRow';
//export const defaultNewKey: Types.Key = { code: '', pk: null, tagIds: [], translations: [], machineTranslateWhenAdded: true }
export var KeysAndTranslations = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10;
    var _11 = useState('multi'), bilingualOrMulti = _11[0], setBilingualOrMulti = _11[1];
    var _12 = useState((_c = (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.languages) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.language_code), sourceLanguage = _12[0], setSourceLanguage = _12[1];
    var _13 = useState((_f = (_e = (_d = props.detailedProject) === null || _d === void 0 ? void 0 : _d.languages) === null || _e === void 0 ? void 0 : _e[1]) === null || _f === void 0 ? void 0 : _f.language_code), destinationLanguage = _13[0], setDestinationLanguage = _13[1];
    // const [newKey, setnewKey] = useState<Types.Key>(defaultNewKey);
    var _14 = useStateRef(false), savingNewKey = _14[0], setSavingNewKey = _14[1], savingNewKeyRef = _14[2];
    //const [searchValue, setSearchValue] = useState<string>('');
    //const [selectValue, setSelectValue] = useState<Array<string>>([]);
    //const [showLanguages, setShowLanguages] = useState<Array<string>>(['all']);
    var _15 = useState(false), triggerFilterNextRender = _15[0], setTriggerFilterNextRender = _15[1];
    var _16 = useState(), filterSetting = _16[0], _setFilterSetting = _16[1];
    var _17 = useState([]), filteredCodesPkToHide = _17[0], setFilteredCodesPkToHide = _17[1];
    var _18 = useState([]), filteredTranslationsToHide = _18[0], setFilteredTranslationsToHide = _18[1];
    var _19 = useState(''), sortSetting = _19[0], setSortSetting = _19[1];
    var _20 = useState([]), selectedRowPks = _20[0], setSelectedRowPks = _20[1]; //Codes
    var _21 = useState([]), selectedTranslations = _21[0], setSelectedTranslations = _21[1];
    var _22 = useState(false), showNewKeyModal = _22[0], setShowNewKeyModal = _22[1];
    var _23 = useState(false), showUploadFileModal = _23[0], setShowUploadFileModal = _23[1];
    var darkOverlay = useDarkoverContext();
    var addToast = useToast();
    var setFilterSetting = function (v) {
        _setFilterSetting(v);
        filterAllTranslations(updateAuto(filterSetting, { $apply: v }));
        //    setTriggerFilterNextRender(true);
    };
    useEffect(function () {
        if (triggerFilterNextRender) {
            setTriggerFilterNextRender(false);
            //  filterAllTranslations();
        }
        setTriggerFilterNextRender(false);
    }, [triggerFilterNextRender]);
    var filterAllTranslations = function (filter) {
        var _a, _b;
        var keyPksToHide = [];
        var languagesAndKeysToHide = [];
        (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.keys) === null || _b === void 0 ? void 0 : _b.forEach(function (key) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            var translationLanguageCodesToHideForCurrentKey = [];
            //Filter search string
            var searchFilterOkForKey = notUndefinedOrEmpty(filter === null || filter === void 0 ? void 0 : filter.searchString) && ((_b = (_a = key === null || key === void 0 ? void 0 : key.code) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === null || _b === void 0 ? void 0 : _b.includes((_c = filter === null || filter === void 0 ? void 0 : filter.searchString) === null || _c === void 0 ? void 0 : _c.toLowerCase()));
            var noSearchFilterForKey = undefinedOrEmpty(filter === null || filter === void 0 ? void 0 : filter.searchString);
            //Filter tags
            var filterTagShow = true;
            if (((_e = (_d = filter === null || filter === void 0 ? void 0 : filter.filter) === null || _d === void 0 ? void 0 : _d.selectedTagPks) === null || _e === void 0 ? void 0 : _e.length) > 0) {
                filterTagShow = false;
                (_g = (_f = filter === null || filter === void 0 ? void 0 : filter.filter) === null || _f === void 0 ? void 0 : _f.selectedTagPks) === null || _g === void 0 ? void 0 : _g.forEach(function (selTag) {
                    var _a;
                    if ((_a = key === null || key === void 0 ? void 0 : key.tagIds) === null || _a === void 0 ? void 0 : _a.includes(selTag))
                        filterTagShow = true;
                });
            }
            //Filter all translations in the current code
            (_j = (_h = props.detailedProject) === null || _h === void 0 ? void 0 : _h.languages) === null || _j === void 0 ? void 0 : _j.forEach(function (language) {
                var _a, _b;
                var nonTranslatedTranslation = { codePk: key.pk, projectPk: (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.pk, full_locale: language === null || language === void 0 ? void 0 : language.language_code, origin_full_locale: 'none', translated: false };
                var translation = ((_b = key === null || key === void 0 ? void 0 : key.translations) === null || _b === void 0 ? void 0 : _b.find(function (s) { return (s === null || s === void 0 ? void 0 : s.full_locale) == (language === null || language === void 0 ? void 0 : language.language_code); })) || nonTranslatedTranslation;
                var showCurrentTranslation = filterTranslation(translation, filter);
                if (!showCurrentTranslation) {
                    translationLanguageCodesToHideForCurrentKey.push(language === null || language === void 0 ? void 0 : language.language_code);
                    languagesAndKeysToHide.push({ codePk: key.pk, fullLocale: language === null || language === void 0 ? void 0 : language.language_code });
                }
            });
            var noTranslationShouldBeShowedForCurrentKey = (translationLanguageCodesToHideForCurrentKey === null || translationLanguageCodesToHideForCurrentKey === void 0 ? void 0 : translationLanguageCodesToHideForCurrentKey.length) == ((_l = (_k = props.detailedProject) === null || _k === void 0 ? void 0 : _k.languages) === null || _l === void 0 ? void 0 : _l.length);
            //No search filter and also no translations match
            if (noSearchFilterForKey && noTranslationShouldBeShowedForCurrentKey) {
                keyPksToHide.push(key.pk);
            }
            //Tag limitation
            if (!filterTagShow) {
                keyPksToHide.push(key.pk);
            }
            //Search filter applied, no translations mateches and the key doesn't match
            if (noTranslationShouldBeShowedForCurrentKey && !noSearchFilterForKey && !searchFilterOkForKey) {
                keyPksToHide.push(key.pk);
            }
        });
        setFilteredCodesPkToHide(keyPksToHide);
        setFilteredTranslationsToHide(languagesAndKeysToHide);
    };
    var filterTranslation = function (translation, filter) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        var show = true;
        //Filter settings
        if (((_a = filter === null || filter === void 0 ? void 0 : filter.filter) === null || _a === void 0 ? void 0 : _a.onlyTranslated) == true && undefinedOrEmpty(translation === null || translation === void 0 ? void 0 : translation.text)) {
            show = false;
        }
        if (!((_b = filter === null || filter === void 0 ? void 0 : filter.filter) === null || _b === void 0 ? void 0 : _b.onlyTranslated) && notUndefinedOrEmpty(translation === null || translation === void 0 ? void 0 : translation.text)) {
            show = false;
        }
        if (((_c = filter === null || filter === void 0 ? void 0 : filter.filter) === null || _c === void 0 ? void 0 : _c.onlyReviewd) == true && !(translation === null || translation === void 0 ? void 0 : translation.reviewed)) {
            show = false;
        }
        if (((_d = filter === null || filter === void 0 ? void 0 : filter.filter) === null || _d === void 0 ? void 0 : _d.onlyReviewd) == false && (translation === null || translation === void 0 ? void 0 : translation.reviewed)) {
            show = false;
        }
        //Filter language
        if (((_f = (_e = filter === null || filter === void 0 ? void 0 : filter.filter) === null || _e === void 0 ? void 0 : _e.showLanguages) === null || _f === void 0 ? void 0 : _f[0]) != 'all' && !((_h = (_g = filter === null || filter === void 0 ? void 0 : filter.filter) === null || _g === void 0 ? void 0 : _g.showLanguages) === null || _h === void 0 ? void 0 : _h.includes(fullLocaleToLanguage(translation.full_locale)))) {
            show = false;
        }
        //Filter search string
        if (notUndefinedOrEmpty(filter === null || filter === void 0 ? void 0 : filter.searchString) && (!((_k = (_j = translation === null || translation === void 0 ? void 0 : translation.text) === null || _j === void 0 ? void 0 : _j.toLowerCase()) === null || _k === void 0 ? void 0 : _k.includes((_l = filter === null || filter === void 0 ? void 0 : filter.searchString) === null || _l === void 0 ? void 0 : _l.toLowerCase())))) {
            show = false;
        }
        return show;
    };
    /*
      const onAddKey = () => {
        if (savingNewKeyRef?.current)
          return;
        setSavingNewKey(true)
        ApiRequests.Codes.post(props.detailedProject?.pk, newKey, response => {
          if (response.status == '200') {
            props.onChange(prev => updateAuto(prev, {
              keys: {
                $push: [
                  response.data?.data
                ]
              }
            }))
            setnewKey(defaultNewKey);
            setShowNewKeyModal(false);
          }
          else {
            addToast(response.errors, 'danger')
          }
          setSavingNewKey(false);
        });
      } */
    //const keylogger = useKeystrokeLogger(showNewKeyModal, { onEnter: onAddKey })
    var sortKeys = function (a, b) {
        var _a, _b;
        if (sortSetting == 'created(desc)' || undefinedOrEmpty(sortSetting)) {
            if ((a === null || a === void 0 ? void 0 : a.createdAt) > (b === null || b === void 0 ? void 0 : b.createdAt))
                return -1;
            if ((a === null || a === void 0 ? void 0 : a.createdAt) < (b === null || b === void 0 ? void 0 : b.createdAt))
                return 1;
            return 0;
        }
        else if (sortSetting == 'created(asc)') {
            if ((b === null || b === void 0 ? void 0 : b.createdAt) > (a === null || a === void 0 ? void 0 : a.createdAt))
                return -1;
            if ((b === null || b === void 0 ? void 0 : b.createdAt) < (a === null || a === void 0 ? void 0 : a.createdAt))
                return 1;
            return 0;
        }
        if (sortSetting == 'name(desc)') {
            return ((_a = a === null || a === void 0 ? void 0 : a.code) === null || _a === void 0 ? void 0 : _a.localeCompare(b === null || b === void 0 ? void 0 : b.code));
        }
        if (sortSetting == 'name(asc)') {
            return ((_b = b === null || b === void 0 ? void 0 : b.code) === null || _b === void 0 ? void 0 : _b.localeCompare(a === null || a === void 0 ? void 0 : a.code));
        }
        return 0;
    };
    var filterKeys = function (key) {
        return !(filteredCodesPkToHide === null || filteredCodesPkToHide === void 0 ? void 0 : filteredCodesPkToHide.includes(key === null || key === void 0 ? void 0 : key.pk));
    };
    /* const filterKeys = (key: Types.Key) => {
       let show = true;
       //Filter search string
       if (notUndefinedOrEmpty(filterSetting?.searchString) && !key?.code?.includes(filterSetting?.searchString)) {
         //Search within code instead
         let tmpFound = false;
         key?.translations?.forEach(t => {
           if (t?.text?.toLowerCase()?.includes(filterSetting?.searchString?.toLowerCase()))
             tmpFound = true;
         })
         if (!tmpFound)
           show = false;
       }
       //Filter settings
   
       return show;
     } */
    var onSelectChange = function (pk, selected) {
        if (selected)
            setSelectedRowPks(function (prev) { return __spreadArray(__spreadArray([], prev, true), [pk], false); });
        else
            setSelectedRowPks(function (prev) { return prev.filter(function (d) { return d != pk; }); });
    };
    var onSelectTranslationChange = function (codePk, fullLocale, selected) {
        if (selected)
            setSelectedTranslations(function (prev) { return __spreadArray(__spreadArray([], prev, true), [{ codePk: codePk, fullLocale: fullLocale }], false); });
        else
            setSelectedTranslations(function (prev) { return prev.filter(function (d) { return d.codePk != codePk || d.fullLocale != fullLocale; }); });
    };
    var onAddAndSelectNewTag = function (nameOfNewTag) {
        var _a;
        ApiRequests.Tags.post((_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.pk, { name: nameOfNewTag, pk: null }, function (response) {
            if (response.status == '200') {
                //Add it to available tags
                props.onChange(function (prev) { return updateAuto(prev, { tags: { $push: [response.data] } }); });
                //Set it as a tag for the new code
                ///    setnewKey(prev => updateAuto(prev, { tagIds: { $push: [response.data.pk] } }))
            }
        });
    };
    var _24 = useState(false), showMachineTranslateCodeModal = _24[0], setShowMachineTranslateCodeModal = _24[1];
    var _25 = useState(false), showMacineTranslateTranslationsModal = _25[0], setShowMacineTranslateTranslationsModal = _25[1];
    var _26 = useState(false), showConfirmDeleteModal = _26[0], setShowConfirmDeleteModal = _26[1];
    var deleteAllSeleced = function () {
        var _a;
        ApiRequests.Codes.delele((_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.pk, selectedRowPks === null || selectedRowPks === void 0 ? void 0 : selectedRowPks[0], function (response) {
            if (response.status == '200') {
                props.onChange(function (prev) { return updateAuto(prev, { keys: { $apply: function (d) { return d === null || d === void 0 ? void 0 : d.filter(function (k) { return !(selectedRowPks === null || selectedRowPks === void 0 ? void 0 : selectedRowPks.includes(k === null || k === void 0 ? void 0 : k.pk)); }); } } }); });
                addToast('Deleted!', 'success');
            }
            else {
                addToast(response.errors, 'danger');
            }
        });
    };
    var reviewSelectedCodes = function () {
        //Implement
    };
    var machineTranslateSelecteTranslations = function () {
        setShowMacineTranslateTranslationsModal(true);
    };
    var machineTranslateSelectedCodes = function () {
        setShowMachineTranslateCodeModal(true);
    };
    var anyFilterIsApplied = useMemo(function () {
        var _a, _b, _c, _d, _e, _f;
        return notUndefinedOrEmpty(filterSetting === null || filterSetting === void 0 ? void 0 : filterSetting.searchString) || ((_a = filterSetting === null || filterSetting === void 0 ? void 0 : filterSetting.filter) === null || _a === void 0 ? void 0 : _a.onlyReviewd) != 'all' || ((_b = filterSetting === null || filterSetting === void 0 ? void 0 : filterSetting.filter) === null || _b === void 0 ? void 0 : _b.onlyTranslated) != 'all' || ((_d = (_c = filterSetting === null || filterSetting === void 0 ? void 0 : filterSetting.filter) === null || _c === void 0 ? void 0 : _c.selectedTagPks) === null || _d === void 0 ? void 0 : _d.length) > 0 || ((_f = (_e = filterSetting === null || filterSetting === void 0 ? void 0 : filterSetting.filter) === null || _e === void 0 ? void 0 : _e.showLanguages) === null || _f === void 0 ? void 0 : _f[0]) != 'all';
    }, [filterSetting]);
    return (React.createElement(React.Fragment, null,
        "    ",
        React.createElement("div", { style: {} },
            React.createElement(Button, { size: 'small', type: 'underline', onClick: function () { return darkOverlay === null || darkOverlay === void 0 ? void 0 : darkOverlay.setElementToShowDarkContextFor('test'); } }, "Onboard"),
            React.createElement(FlexDiv, null,
                React.createElement(DarkenedElementExplaination, { style: { borderRadius: '10px' }, darknessIdentifier: 'test', text: React.createElement(React.Fragment, null,
                        React.createElement(FlexDiv, { style: { flexDirection: 'column' } },
                            React.createElement(P, { style: { fontSize: '15px', margin: '0 0 10px' } }, "Create your first key by clicking on this button"),
                            React.createElement(FlexDiv, { style: { justifyContent: 'flex-end' } },
                                React.createElement(Button, { size: 'small', type: 'white', onClick: function () { return darkOverlay === null || darkOverlay === void 0 ? void 0 : darkOverlay.setElementToShowDarkContextFor('uploadFile'); } }, "Next")))) },
                    React.createElement(React.Fragment, null,
                        React.createElement(Button, { onClick: function () { return setShowNewKeyModal(true); }, size: 'medium', type: 'blue' }, "+ New key"))),
                React.createElement(DarkenedElementExplaination, { style: { borderRadius: '10px', marginLeft: '10px' }, darknessIdentifier: 'uploadFile', text: React.createElement(React.Fragment, null,
                        React.createElement(FlexDiv, { style: { flexDirection: 'column' } },
                            React.createElement(P, { style: { fontSize: '15px', margin: '0 0 10px' } }, "Create your first key by clicking on this button"),
                            React.createElement(FlexDiv, { style: { justifyContent: 'flex-end' } },
                                React.createElement(Button, { size: 'small', type: 'white', onClick: function () { return darkOverlay === null || darkOverlay === void 0 ? void 0 : darkOverlay.setElementToShowDarkContextFor(''); } }, "Close")))) },
                    React.createElement(React.Fragment, null,
                        React.createElement(Button, { onClick: function () { return setShowUploadFileModal(true); }, size: 'medium', type: 'blue' },
                            React.createElement(UploadSvg, null),
                            " Upload file"))),
                React.createElement(SearchButtonInput, { style: { marginLeft: '10px' }, onChange: function (v) { return setFilterSetting(function (prev) { return updateAuto(prev, { searchString: { $set: v } }); }); }, value: filterSetting === null || filterSetting === void 0 ? void 0 : filterSetting.searchString }),
                React.createElement(FilterButton, { style: { marginLeft: '5px' }, availableLanguages: (_h = (_g = props.detailedProject) === null || _g === void 0 ? void 0 : _g.languages) === null || _h === void 0 ? void 0 : _h.map(function (d) { return d === null || d === void 0 ? void 0 : d.language_code; }), availableTags: (_j = props.detailedProject) === null || _j === void 0 ? void 0 : _j.tags, onChangeFilter: function (filter) { return setFilterSetting(function (prev) { return updateAuto(prev, { filter: { $set: filter } }); }); } }),
                React.createElement(ActionButtonSelect, { dropdownStyle: { width: 'auto', whiteSpace: 'nowrap' }, style: { marginLeft: '5px', width: 'auto' }, value: sortSetting, onChange: setSortSetting, options: [{ value: 'created(desc)', displayValue: 'Created (DESC)' }, { value: 'created(asc)', displayValue: 'Created (ASC)' }, { value: 'name(desc)', displayValue: 'Name (Desc)' }, { value: 'name(asc)', displayValue: 'Name (Asc)' }], icon: 'sort' }, "Sort"),
                React.createElement(FlexDiv, { style: { marginLeft: '5px' } },
                    React.createElement(Button, { skipRightBorderRadius: true, type: bilingualOrMulti == 'bi' ? 'blue' : 'white', size: 'small', onClick: function () { return setBilingualOrMulti('bi'); } }, "Bilingual"),
                    React.createElement(Button, { skipLeftBoderRadius: true, type: bilingualOrMulti == 'multi' ? 'blue' : 'white', size: 'small', style: { marginLeft: '0px' }, onClick: function () { return setBilingualOrMulti('multi'); } }, "Multilingual"))),
            React.createElement(ShowIf, { style: { marginBottom: '10px', marginTop: '10px' }, show: bilingualOrMulti == 'bi' },
                React.createElement(FlexDiv, null,
                    React.createElement(Select, { label: 'Source language', style: { width: '250px', marginRight: '10px' }, value: sourceLanguage, onChange: setSourceLanguage, options: (_l = (_k = props.detailedProject) === null || _k === void 0 ? void 0 : _k.languages) === null || _l === void 0 ? void 0 : _l.map(function (d) { return { value: d === null || d === void 0 ? void 0 : d.language_code, imgSrc: getFlagSrcFromLanguageCode(d.language_code), displayValue: languageCodeToName(d === null || d === void 0 ? void 0 : d.language_code) }; }) }),
                    React.createElement("div", { style: { justifyContent: 'center', alignItems: 'center', alignSelf: 'flex-end', marginRight: '10px' } }),
                    React.createElement(Select, { label: 'Destination language', style: { width: '250px' }, value: destinationLanguage, onChange: setDestinationLanguage, options: (_o = (_m = props.detailedProject) === null || _m === void 0 ? void 0 : _m.languages) === null || _o === void 0 ? void 0 : _o.map(function (d) { return { value: d === null || d === void 0 ? void 0 : d.language_code, imgSrc: getFlagSrcFromLanguageCode(d === null || d === void 0 ? void 0 : d.language_code), displayValue: languageCodeToName(d === null || d === void 0 ? void 0 : d.language_code) }; }) }))),
            React.createElement(ShowIf, { show: ((_q = (_p = props.detailedProject) === null || _p === void 0 ? void 0 : _p.keys) === null || _q === void 0 ? void 0 : _q.length) > 0 },
                React.createElement("div", { style: { marginTop: '20px' } }),
                React.createElement(FlexDiv, null,
                    React.createElement(Button, { type: 'blue', size: 'small', onClick: function () { var _a, _b; return setSelectedRowPks((_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.keys) === null || _b === void 0 ? void 0 : _b.map(function (k) { return k.pk; })); } }, "Select all"),
                    React.createElement(Button, { style: { marginLeft: '5px' }, type: 'white', size: 'small', onClick: function () { return setSelectedRowPks([]); } }, "Deselect all"))),
            React.createElement("div", { style: { marginTop: '20px' } }),
            React.createElement(ShowIf, { show: anyFilterIsApplied },
                React.createElement(P, null,
                    "Filtered ",
                    React.createElement("b", null, (_t = (_s = (_r = props.detailedProject) === null || _r === void 0 ? void 0 : _r.keys) === null || _s === void 0 ? void 0 : _s.filter(filterKeys)) === null || _t === void 0 ? void 0 : _t.length),
                    " out of ",
                    React.createElement("b", null, (_v = (_u = props.detailedProject) === null || _u === void 0 ? void 0 : _u.keys) === null || _v === void 0 ? void 0 :
                        _v.length,
                        " keys"),
                    ". Filtered ",
                    React.createElement("b", null, ((_x = (_w = props.detailedProject) === null || _w === void 0 ? void 0 : _w.languages) === null || _x === void 0 ? void 0 : _x.length) * ((_z = (_y = props.detailedProject) === null || _y === void 0 ? void 0 : _y.keys) === null || _z === void 0 ? void 0 : _z.length) - (filteredTranslationsToHide === null || filteredTranslationsToHide === void 0 ? void 0 : filteredTranslationsToHide.length)),
                    " out of ",
                    React.createElement("b", null, ((_1 = (_0 = props.detailedProject) === null || _0 === void 0 ? void 0 : _0.languages) === null || _1 === void 0 ? void 0 : _1.length) * ((_3 = (_2 = props.detailedProject) === null || _2 === void 0 ? void 0 : _2.keys) === null || _3 === void 0 ? void 0 : _3.length)),
                    " translations.")),
            React.createElement(Line, { noMargin: true }), (_7 = (_6 = (_5 = (_4 = props.detailedProject) === null || _4 === void 0 ? void 0 : _4.keys) === null || _5 === void 0 ? void 0 : _5.filter(filterKeys)) === null || _6 === void 0 ? void 0 : _6.sort(sortKeys)) === null || _7 === void 0 ? void 0 :
            _7.map(function (key, i) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                return (React.createElement(React.Fragment, null,
                    React.createElement(KeyRow, { key: (key === null || key === void 0 ? void 0 : key.code) + i, highlightString: filterSetting === null || filterSetting === void 0 ? void 0 : filterSetting.searchString, translationsToHide: (_a = filteredTranslationsToHide === null || filteredTranslationsToHide === void 0 ? void 0 : filteredTranslationsToHide.filter(function (k) { return (k === null || k === void 0 ? void 0 : k.codePk) == (key === null || key === void 0 ? void 0 : key.pk); })) === null || _a === void 0 ? void 0 : _a.map(function (d) { return { fullLocale: d === null || d === void 0 ? void 0 : d.fullLocale }; }), onAfterReviewedCompleye: function (v) { setSelectedTranslations(function (prev) { return prev.filter(function (k) { return k.codePk != key.pk || k.fullLocale != v.fullLocale; }); }); }, 
                        // triggerDelete={triggerDeleteKeys?.includes(key.pk)}
                        //  triggerReviewed={triggerReviewKeys?.filter(k => k.codePk == key.pk)?.map(d => { return { fullLocale: d.fullLocale } })}
                        projectPk: (_b = props.detailedProject) === null || _b === void 0 ? void 0 : _b.pk, selectedTranslations: (_c = selectedTranslations === null || selectedTranslations === void 0 ? void 0 : selectedTranslations.filter(function (k) { return (k === null || k === void 0 ? void 0 : k.codePk) == key.pk; })) === null || _c === void 0 ? void 0 : _c.map(function (k) { return { fullLocale: k.fullLocale }; }), onSelectTranslation: function (selected, translation) { return onSelectTranslationChange(key.pk, translation.fullLocale, selected); }, onChangeAvailableTags: function (newTags) { return props.onChange(function (prev) { return updateAuto(prev, { tags: { $set: newTags } }); }); }, availableTagsForProject: (_d = props.detailedProject) === null || _d === void 0 ? void 0 : _d.tags, availableLanguages: (_e = props.detailedProject) === null || _e === void 0 ? void 0 : _e.languages, biLinugal: bilingualOrMulti == 'bi', destinationLanguage: destinationLanguage, sourceLanguage: sourceLanguage, selected: selectedRowPks === null || selectedRowPks === void 0 ? void 0 : selectedRowPks.includes(key.pk), onSelect: function (b) { return onSelectChange(key.pk, b); }, onAfterSave: function (u) { return props.onChange(function (prev) {
                            var _a;
                            return updateAuto(prev, { keys: (_a = {}, _a[i] = { $apply: u }, _a) });
                        }); }, onAfterDelete: function (u) { return props.onChange(function (prev) { var _a; return updateAuto(prev, { keys: { $splice: [[(_a = prev.keys) === null || _a === void 0 ? void 0 : _a.findIndex(function (k) { return k.pk == u.pk; }), 1]] } }); }); }, baseLanguage: (_h = (_g = (_f = props.detailedProject) === null || _f === void 0 ? void 0 : _f.languages) === null || _g === void 0 ? void 0 : _g.find(function (k) { return k === null || k === void 0 ? void 0 : k.is_base_language; })) === null || _h === void 0 ? void 0 : _h.language_code, item: key }),
                    React.createElement(ShowIf, { show: i < ((_k = (_j = props.detailedProject) === null || _j === void 0 ? void 0 : _j.keys) === null || _k === void 0 ? void 0 : _k.length) - 1 },
                        React.createElement(Line, { noMargin: true }))));
            }),
            React.createElement(ShowIf, { show: (selectedTranslations === null || selectedTranslations === void 0 ? void 0 : selectedTranslations.length) > 0 },
                React.createElement("div", { style: ((_8 = selectedRowPks === null || selectedRowPks === void 0 ? void 0 : selectedRowPks.filter(function (k) { var _a, _b, _c; return (_c = (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.keys) === null || _b === void 0 ? void 0 : _b.map(function (b) { return b.pk; })) === null || _c === void 0 ? void 0 : _c.includes(k); })) === null || _8 === void 0 ? void 0 : _8.length) > 0 ? { bottom: '112px' } : {}, className: style.ActionButtonWrapper },
                    React.createElement(FlexDiv, { alignCenter: true, style: { width: '100%' } },
                        React.createElement(Indicator, { type: 'blue' }, selectedTranslations === null || selectedTranslations === void 0 ? void 0 : selectedTranslations.length),
                        React.createElement("div", { style: { marginLeft: '11px', fontSize: '22px', fontWeight: 'bold', paddingBottom: '2px' } }, "Translations selected"),
                        React.createElement(Button, { style: { marginLeft: '20px' }, size: 'large', type: 'blue', onClick: function () {
                                var _a, _b, _c, _d;
                                setSelectedTranslations((_d = (_c = (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.keys) === null || _b === void 0 ? void 0 : _b.map(function (k) { var _a, _b; return (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.languages) === null || _b === void 0 ? void 0 : _b.map(function (l) { return { codePk: k.pk, fullLocale: l.language_code }; }); })) === null || _c === void 0 ? void 0 : _c.flat()) === null || _d === void 0 ? void 0 : _d.filter(function (k) { return k.fullLocale == destinationLanguage || bilingualOrMulti == 'multi'; }));
                            } }, "Select all"),
                        React.createElement(Button, { style: { marginLeft: '20px' }, size: 'large', type: 'white', onClick: function () { setSelectedTranslations([]); } }, "Deselect all"),
                        React.createElement(Button, { style: { marginLeft: 'auto' }, size: 'large', type: 'blue', onClick: function () { reviewSelectedCodes(); } }, "Select as review"),
                        React.createElement(Button, { style: { marginLeft: '10px' }, size: 'large', type: 'blue', onClick: function () { machineTranslateSelecteTranslations(); } }, "Machine translate")))),
            React.createElement(ShowIf, { show: ((_9 = selectedRowPks === null || selectedRowPks === void 0 ? void 0 : selectedRowPks.filter(function (k) { var _a, _b, _c; return (_c = (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.keys) === null || _b === void 0 ? void 0 : _b.map(function (b) { return b.pk; })) === null || _c === void 0 ? void 0 : _c.includes(k); })) === null || _9 === void 0 ? void 0 : _9.length) > 0 },
                React.createElement("div", { className: style.ActionButtonWrapper },
                    React.createElement(FlexDiv, { alignCenter: true, style: { width: '100%' } },
                        React.createElement(Indicator, { type: 'blue' }, selectedRowPks === null || selectedRowPks === void 0 ? void 0 : selectedRowPks.length),
                        React.createElement("div", { style: { marginLeft: '11px', fontSize: '22px', fontWeight: 'bold', paddingBottom: '2px' } }, "Codes selected"),
                        React.createElement(Button, { style: { marginLeft: '20px' }, size: 'large', type: 'blue', onClick: function () { var _a, _b; return setSelectedRowPks((_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.keys) === null || _b === void 0 ? void 0 : _b.map(function (k) { return k.pk; })); } }, "Select all"),
                        React.createElement(Button, { style: { marginLeft: '20px' }, size: 'large', type: 'white', onClick: function () { setSelectedRowPks([]); } }, "Deselect all"),
                        React.createElement(Button, { style: { marginLeft: 'auto' }, size: 'large', type: 'blue', onClick: function () { machineTranslateSelectedCodes(); } },
                            React.createElement(FlexDiv, { alignCenter: true },
                                React.createElement(RobotSvg, null),
                                React.createElement("div", { style: { marginLeft: '10px' } }, "Machine translate")),
                            " "),
                        React.createElement(Button, { style: { marginLeft: '10px' }, size: 'large', type: 'redwhite', onClick: function () { setShowConfirmDeleteModal(true); } }, "Delete selected")))),
            React.createElement("div", { style: { marginTop: '50px', display: 'none' } },
                React.createElement(Header5, { style: { marginBottom: '2px' }, gray: true }, "Page settings"),
                React.createElement(FlexDiv, null,
                    React.createElement(PaginatorBlock, { noMargin: true }, "1"),
                    React.createElement(PaginatorBlock, { active: true }, "2"),
                    React.createElement(PaginatorBlock, null, "3")))),
        React.createElement(Modal, { show: showConfirmDeleteModal, onHide: function () { return setShowConfirmDeleteModal(false); } },
            React.createElement(Header2, null, "Confirm deletion"),
            React.createElement(P, null,
                "Are you sure you want to delete the ",
                React.createElement("b", null, selectedRowPks === null || selectedRowPks === void 0 ? void 0 : selectedRowPks.length),
                " selected keys?"),
            React.createElement(FlexDiv, null,
                React.createElement(Button, { type: 'redwhite', size: 'medium', onClick: function () {
                        deleteAllSeleced();
                        setShowConfirmDeleteModal(false);
                    } }, "Delete"),
                React.createElement(Button, { type: 'white', style: { marginLeft: '10px' }, size: 'medium', onClick: function () { return setShowConfirmDeleteModal(false); } }, "Cancel"))),
        React.createElement(MachineTranslateAllSelectedCodesModal, { onMachineTranslateComplete: function () { return setSelectedRowPks([]); }, availableLanguages: (_10 = props.detailedProject) === null || _10 === void 0 ? void 0 : _10.languages, selectedCodesPk: selectedRowPks, show: showMachineTranslateCodeModal, setShow: setShowMachineTranslateCodeModal, detailedProject: props.detailedProject, onChange: props.onChange }),
        React.createElement(NewKeyModal, { onAddAndSelectNewTag: onAddAndSelectNewTag, show: showNewKeyModal, setShow: setShowNewKeyModal, detailedProject: props.detailedProject, onChange: props.onChange }),
        React.createElement(UploadFileModal, { show: showUploadFileModal, onClose: function () { return setShowUploadFileModal(false); }, detailedProject: props.detailedProject, onChange: props.onChange })));
};
//TODO: Rewrite this
export var MachineTranslateAllSelectedCodesModal = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var _r = useStateRef(false), saving = _r[0], setSaving = _r[1], savingRef = _r[2];
    var _s = useState(false), overwriteExistingTranslations = _s[0], setOverwriteExistingTranslations = _s[1];
    var _t = useState((_c = (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.languages) === null || _b === void 0 ? void 0 : _b.find(function (k) { return k === null || k === void 0 ? void 0 : k.is_base_language; })) === null || _c === void 0 ? void 0 : _c.language_code), machineTranslationSourceLangage = _t[0], setMachineTranslationSourceLangage = _t[1];
    //Settings
    var _u = useState(false), showWhichWillBeOverwritten = _u[0], setShowWhichWillBeOverwritten = _u[1];
    var _v = useState(0), numberOfMachineTranslations = _v[0], setNumberOfMachineTranslations = _v[1];
    var _w = useState(0), numberOfMachineTranslationsComplete = _w[0], setNumberOfMachineTranslationsComplete = _w[1];
    var addToast = useToast();
    useEffect(function () {
        var _a, _b, _c;
        setMachineTranslationSourceLangage((_c = (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.languages) === null || _b === void 0 ? void 0 : _b.find(function (k) { return k === null || k === void 0 ? void 0 : k.is_base_language; })) === null || _c === void 0 ? void 0 : _c.language_code);
    }, [(_e = (_d = props.detailedProject) === null || _d === void 0 ? void 0 : _d.languages) === null || _e === void 0 ? void 0 : _e.length]);
    useEffect(function () {
        if (saving && numberOfMachineTranslationsComplete == numberOfMachineTranslations && numberOfMachineTranslations > 0) {
            addToast('Machine translation complete', 'success');
            setSaving(false);
            props.setShow(false);
            props.onMachineTranslateComplete();
        }
    }, [numberOfMachineTranslations, numberOfMachineTranslationsComplete, saving]);
    var onClickAction = function () {
        var _a;
        if (savingRef === null || savingRef === void 0 ? void 0 : savingRef.current)
            return;
        setSaving(true);
        //Machine translate all that are selected
        var anyIsTranslated = false;
        (_a = props.selectedCodesPk) === null || _a === void 0 ? void 0 : _a.forEach(function (codePk) {
            var _a, _b;
            (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.languages) === null || _b === void 0 ? void 0 : _b.forEach(function (language) {
                var _a, _b, _c, _d, _e;
                var translation = ((_d = (_c = (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.keys) === null || _b === void 0 ? void 0 : _b.find(function (k) { return (k === null || k === void 0 ? void 0 : k.pk) == codePk; })) === null || _c === void 0 ? void 0 : _c.translations) === null || _d === void 0 ? void 0 : _d.find(function (s) { return (s === null || s === void 0 ? void 0 : s.full_locale) == (language === null || language === void 0 ? void 0 : language.language_code); })) || { codePk: codePk, projectPk: (_e = props.detailedProject) === null || _e === void 0 ? void 0 : _e.pk, origin_full_locale: language === null || language === void 0 ? void 0 : language.language_code, full_locale: language === null || language === void 0 ? void 0 : language.language_code };
                var translteThisTranslation = (translation === null || translation === void 0 ? void 0 : translation.full_locale) != machineTranslationSourceLangage && (overwriteExistingTranslations || !(translation === null || translation === void 0 ? void 0 : translation.text));
                if (translteThisTranslation) {
                    translation.codePk = codePk;
                    translation.full_locale = language === null || language === void 0 ? void 0 : language.language_code;
                    setNumberOfMachineTranslations(function (prev) { return prev + 1; });
                    anyIsTranslated = true;
                    ApiRequests.Translations.machineTranslate({ translation: translation, sourceLanguage: machineTranslationSourceLangage }, function (response) {
                        setNumberOfMachineTranslationsComplete(function (prev) { return prev + 1; });
                        if (response.status == '200') {
                            props.onChange(function (prev) {
                                var _a, _b, _c;
                                var _d, _e, _f, _g, _h, _j, _k, _l;
                                var keyIndex = (_d = prev.keys) === null || _d === void 0 ? void 0 : _d.findIndex(function (k) { return k.pk == codePk; });
                                var translationIndex = (_g = (_f = (_e = prev.keys) === null || _e === void 0 ? void 0 : _e[keyIndex]) === null || _f === void 0 ? void 0 : _f.translations) === null || _g === void 0 ? void 0 : _g.findIndex(function (k) { return k.full_locale == (language === null || language === void 0 ? void 0 : language.language_code); });
                                var translationExist = translationIndex != -1;
                                if (translationExist) {
                                    return updateAuto(prev, {
                                        keys: (_a = {},
                                            _a[keyIndex] = {
                                                translations: (_b = {},
                                                    _b[translationIndex] = { $set: (_j = (_h = response.data) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.data },
                                                    _b)
                                            },
                                            _a)
                                    });
                                }
                                else {
                                    return updateAuto(prev, {
                                        keys: (_c = {},
                                            _c[keyIndex] = {
                                                translations: {
                                                    $push: [(_l = (_k = response.data) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.data]
                                                }
                                            },
                                            _c)
                                    });
                                }
                            });
                        }
                    });
                    //   })
                }
            });
        });
        if (!anyIsTranslated) {
            addToast('Nothing to translate', 'danger');
            setSaving(false);
            props.onMachineTranslateComplete();
            props.setShow(false);
        }
    };
    var getNumberOfTranslationsThatWillBeTranslated = useMemo(function () {
        var _a;
        var count = 0;
        (_a = props.selectedCodesPk) === null || _a === void 0 ? void 0 : _a.forEach(function (codePk) {
            var _a, _b;
            (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.languages) === null || _b === void 0 ? void 0 : _b.forEach(function (language) {
                var _a, _b, _c, _d, _e;
                var translation = ((_d = (_c = (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.keys) === null || _b === void 0 ? void 0 : _b.find(function (k) { return (k === null || k === void 0 ? void 0 : k.pk) == codePk; })) === null || _c === void 0 ? void 0 : _c.translations) === null || _d === void 0 ? void 0 : _d.find(function (s) { return (s === null || s === void 0 ? void 0 : s.full_locale) == (language === null || language === void 0 ? void 0 : language.language_code); })) || { codePk: codePk, projectPk: (_e = props.detailedProject) === null || _e === void 0 ? void 0 : _e.pk, origin_full_locale: language.language_code, full_locale: language === null || language === void 0 ? void 0 : language.language_code };
                if ((translation === null || translation === void 0 ? void 0 : translation.full_locale) != machineTranslationSourceLangage)
                    count++;
            });
        });
        return count;
    }, [props.selectedCodesPk, (_g = (_f = props.detailedProject) === null || _f === void 0 ? void 0 : _f.languages) === null || _g === void 0 ? void 0 : _g.length, machineTranslationSourceLangage]);
    var getNumberOfTranslationsThatWillBeOverridden = useMemo(function () {
        var _a;
        var count = 0;
        (_a = props.selectedCodesPk) === null || _a === void 0 ? void 0 : _a.forEach(function (codePk) {
            var _a, _b;
            (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.languages) === null || _b === void 0 ? void 0 : _b.forEach(function (language) {
                var _a, _b, _c, _d, _e;
                var translation = ((_d = (_c = (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.keys) === null || _b === void 0 ? void 0 : _b.find(function (k) { return (k === null || k === void 0 ? void 0 : k.pk) == codePk; })) === null || _c === void 0 ? void 0 : _c.translations) === null || _d === void 0 ? void 0 : _d.find(function (s) { return (s === null || s === void 0 ? void 0 : s.full_locale) == (language === null || language === void 0 ? void 0 : language.language_code); })) || { codePk: codePk, projectPk: (_e = props.detailedProject) === null || _e === void 0 ? void 0 : _e.pk, full_locale: language === null || language === void 0 ? void 0 : language.language_code, origin_full_locale: language.language_code };
                if ((translation === null || translation === void 0 ? void 0 : translation.text) && (translation === null || translation === void 0 ? void 0 : translation.full_locale) != machineTranslationSourceLangage)
                    count++;
            });
        });
        return count;
    }, [props.selectedCodesPk, (_j = (_h = props.detailedProject) === null || _h === void 0 ? void 0 : _h.languages) === null || _j === void 0 ? void 0 : _j.length, machineTranslationSourceLangage]);
    return (React.createElement(Modal, { style: { maxWidth: '600px' }, show: props.show, onHide: function () { return props.setShow(false); } },
        React.createElement(Header2, null, "Machine translate selected keys"),
        React.createElement(P, null, (_k = props.selectedCodesPk) === null || _k === void 0 ? void 0 :
            _k.length,
            " selected keys to be translated. "),
        React.createElement(Select, { label: 'Select which language to do the machine translation from', style: { minWidth: '200px', maxWidth: '400px', marginRight: '10px' }, value: machineTranslationSourceLangage, onChange: setMachineTranslationSourceLangage, options: (_l = props.availableLanguages) === null || _l === void 0 ? void 0 : _l.map(function (d) { return { value: d === null || d === void 0 ? void 0 : d.language_code, displayValue: languageCodeToName(d === null || d === void 0 ? void 0 : d.language_code), imgSrc: getFlagSrcFromLanguageCode(d === null || d === void 0 ? void 0 : d.language_code) }; }) }),
        React.createElement(BoldLabel, { style: { marginTop: '5px' } }, "Settings"),
        React.createElement(CheckboxWithText, { checked: overwriteExistingTranslations, onChange: setOverwriteExistingTranslations },
            React.createElement(LabelWithInfoTooltip, { tooltip: '' }, "Overwrite already existing translations")),
        React.createElement(P, null,
            React.createElement("b", null, getNumberOfTranslationsThatWillBeTranslated),
            " texts will be translated from ",
            React.createElement("b", null, languageCodeToName(machineTranslationSourceLangage)),
            " to ",
            React.createElement("b", null, (_p = (_o = (_m = props.detailedProject) === null || _m === void 0 ? void 0 : _m.languages) === null || _o === void 0 ? void 0 : _o.filter(function (k) { return (k === null || k === void 0 ? void 0 : k.language_code) != machineTranslationSourceLangage; })) === null || _p === void 0 ? void 0 : _p.length),
            " languages."),
        React.createElement(ShowIf, { show: overwriteExistingTranslations },
            React.createElement(P, null,
                React.createElement(FlexDiv, { alignCenter: true },
                    React.createElement("div", { style: {} },
                        React.createElement("b", null, getNumberOfTranslationsThatWillBeOverridden),
                        " texts will be overridden"),
                    React.createElement(ShowIf, { show: getNumberOfTranslationsThatWillBeOverridden > 0 },
                        React.createElement(Button, { size: 'small', type: 'underline', style: { marginLeft: '5px' }, onClick: function () { return setShowWhichWillBeOverwritten(function (p) { return !p; }); } },
                            showWhichWillBeOverwritten ? 'Hide' : 'Show',
                            " which"))))),
        React.createElement(ShowIf, { show: showWhichWillBeOverwritten && overwriteExistingTranslations && getNumberOfTranslationsThatWillBeOverridden > 0 },
            React.createElement(P, { style: { maxHeight: '200px', overflow: 'auto' } }, (_q = props.selectedCodesPk) === null || _q === void 0 ? void 0 : _q.map(function (codePk) {
                var _a, _b, _c, _d, _e;
                return (React.createElement(React.Fragment, null,
                    React.createElement("b", null, (_c = (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.keys) === null || _b === void 0 ? void 0 : _b.find(function (k) { return (k === null || k === void 0 ? void 0 : k.pk) == codePk; })) === null || _c === void 0 ? void 0 : _c.code),
                    React.createElement("div", null, (_e = (_d = props.detailedProject) === null || _d === void 0 ? void 0 : _d.languages) === null || _e === void 0 ? void 0 : _e.map(function (language) {
                        var _a, _b, _c, _d, _e;
                        var translation = ((_d = (_c = (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.keys) === null || _b === void 0 ? void 0 : _b.find(function (k) { return (k === null || k === void 0 ? void 0 : k.pk) == codePk; })) === null || _c === void 0 ? void 0 : _c.translations) === null || _d === void 0 ? void 0 : _d.find(function (s) { return (s === null || s === void 0 ? void 0 : s.full_locale) == (language === null || language === void 0 ? void 0 : language.language_code); })) || { codePk: codePk, projectPk: (_e = props.detailedProject) === null || _e === void 0 ? void 0 : _e.pk, full_locale: language === null || language === void 0 ? void 0 : language.language_code, origin_full_locale: language.language_code };
                        if ((translation === null || translation === void 0 ? void 0 : translation.text) && (translation === null || translation === void 0 ? void 0 : translation.full_locale) != machineTranslationSourceLangage)
                            return (React.createElement("div", null,
                                React.createElement(FlexDiv, { alignCenter: true },
                                    React.createElement("img", { height: '11px', src: getFlagSrcFromLanguageCode(language === null || language === void 0 ? void 0 : language.language_code) }),
                                    React.createElement("div", { style: { marginLeft: '5px' } },
                                        languageCodeToName(language === null || language === void 0 ? void 0 : language.language_code),
                                        " : "),
                                    React.createElement("div", { style: { marginLeft: '5px' } }, translation === null || translation === void 0 ? void 0 : translation.text))));
                    }))));
            }))),
        React.createElement(FlexDiv, { style: { marginTop: '20px' } },
            React.createElement(Button, { type: 'blue', size: 'medium', onClick: onClickAction, disabled: saving }, saving ? React.createElement(LoadingDots, null) : 'Translate'),
            React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: function () { setNumberOfMachineTranslations(0); setNumberOfMachineTranslationsComplete(0); props.setShow(false); }, disabled: saving }, "Cancel"))));
};
var FilterButton = function (props) {
    var defaultFilter = { selectedTagPks: [], onlyTranslated: 'all', onlyReviewd: 'all', showLanguages: ['all'] };
    var _a = useState(defaultFilter), filterSetting = _a[0], setFilterSetting = _a[1];
    useEffect(function () {
        props.onChangeFilter(filterSetting);
    }, [filterSetting]);
    var getNumberOfSelected = useMemo(function () {
        var _a, _b;
        var tags = ((_a = filterSetting === null || filterSetting === void 0 ? void 0 : filterSetting.selectedTagPks) === null || _a === void 0 ? void 0 : _a.length) > 0 ? 1 : 0;
        var translated = (filterSetting === null || filterSetting === void 0 ? void 0 : filterSetting.onlyTranslated) == 'all' ? 0 : 1;
        var reviewed = (filterSetting === null || filterSetting === void 0 ? void 0 : filterSetting.onlyReviewd) == 'all' ? 0 : 1;
        var languages = ((_b = filterSetting === null || filterSetting === void 0 ? void 0 : filterSetting.showLanguages) === null || _b === void 0 ? void 0 : _b[0]) == 'all' ? 0 : 1;
        return tags + translated + reviewed + languages;
    }, [filterSetting]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ActionButtonSelect, { dropdownStyle: { width: '600px', padding: '20px' }, onClickClearOverride: function () { setFilterSetting(defaultFilter); }, overrideNumberOfSelected: getNumberOfSelected, style: props.style, customDropdownContent: React.createElement(FlexDiv, null,
                React.createElement(TagFilterList, { onChangeSelectedTags: function (selectedTags) { return setFilterSetting(function (prev) { return updateAuto(prev, { selectedTagPks: { $apply: selectedTags } }); }); }, selectedTags: filterSetting === null || filterSetting === void 0 ? void 0 : filterSetting.selectedTagPks, availableTags: props.availableTags }),
                React.createElement(FilterTranslated, { onChange: function (v) { return setFilterSetting(function (prev) { return updateAuto(prev, { onlyTranslated: { $apply: v } }); }); }, value: filterSetting === null || filterSetting === void 0 ? void 0 : filterSetting.onlyTranslated }),
                React.createElement(FilterReviwed, { onChange: function (v) { return setFilterSetting(function (prev) { return updateAuto(prev, { onlyReviewd: { $apply: v } }); }); }, value: filterSetting === null || filterSetting === void 0 ? void 0 : filterSetting.onlyReviewd }),
                React.createElement(FilterLanguages, { availableLanguages: props.availableLanguages, onChange: function (v) { return setFilterSetting(function (prev) { return updateAuto(prev, { showLanguages: { $apply: v } }); }); }, value: filterSetting === null || filterSetting === void 0 ? void 0 : filterSetting.showLanguages })), onChange: function () { }, icon: 'filter' }, "Filter")));
};
var FilterTranslated = function (props) {
    return (React.createElement(GeneralFilterColumn, { style: { marginLeft: '10px' }, header: 'Translated' }, ['all', true, false].map(function (k, i) {
        return (React.createElement(Indicator, { onClick: function () { return props.onChange(function (prev) { return k; }); }, type: props.value == k ? 'blue' : 'lightgray', style: { marginTop: '5px', padding: '7px 10px' } }, k == 'all' ? 'All' : k ? 'Translated' : 'Not translated'));
    })));
};
var FilterLanguages = function (props) {
    return (React.createElement(GeneralFilterColumn, { style: { marginLeft: '10px' }, header: 'Languages' }, ['all'].concat(props.availableLanguages).map(function (l, i) {
        var _a, _b;
        //@ts-ignore
        var k = l;
        return (React.createElement(Indicator, { onClick: function () {
                var _a;
                if (k == 'all') {
                    props.onChange(function (prev) { return ['all']; });
                }
                else {
                    //Toggle
                    if ((_a = props.value) === null || _a === void 0 ? void 0 : _a.includes(k))
                        props.onChange(function (prev) { return prev.filter(function (b) { return b != k; }); });
                    else
                        props.onChange(function (prev) { return __spreadArray(__spreadArray([], prev, true), [k], false); });
                    //Also make sure to deselect 'all'
                    props.onChange(function (prev) { return prev.filter(function (b) { return b != 'all'; }); });
                }
            }, type: ((_a = props.value) === null || _a === void 0 ? void 0 : _a.includes(k)) ? 'blue' : 'lightgray', style: { marginTop: '5px', padding: '7px 10px' } },
            React.createElement(ShowIf, { show: k != 'all' },
                React.createElement(FlexDiv, null,
                    React.createElement("img", { style: { height: '11px', alignSelf: 'center', marginRight: '5px' }, src: getFlagName((_b = AllLanguagesAndVariations.find(function (b) { return b.languageCode == k; })) === null || _b === void 0 ? void 0 : _b.defaultCountryCode) }),
                    React.createElement("div", null, languageCodeToName(k)))),
            React.createElement(ShowIf, { show: k == 'all' }, "All")));
    })));
};
var FilterReviwed = function (props) {
    return (React.createElement(GeneralFilterColumn, { style: { marginLeft: '10px' }, header: 'Reviewed' }, ['all', true, false].map(function (k, i) {
        return (React.createElement(Indicator, { onClick: function () { return props.onChange(function (prev) { return k; }); }, type: props.value == k ? 'blue' : 'lightgray', style: { marginTop: '5px', padding: '7px 10px' } }, k == 'all' ? 'All' : k ? 'Reviewed' : 'Not reviewed'));
    })));
};
var TagFilterList = function (props) {
    var _a, _b;
    var toggelTag = function (tagPk) {
        var _a;
        if ((_a = props.selectedTags) === null || _a === void 0 ? void 0 : _a.includes(tagPk))
            props.onChangeSelectedTags(function (prev) { return prev.filter(function (k) { return k != tagPk; }); });
        else
            props.onChangeSelectedTags(function (prev) { return __spreadArray(__spreadArray([], prev, true), [tagPk], false); });
    };
    return (React.createElement(GeneralFilterColumn, { header: 'Tags' }, (_a = props.availableTags) === null || _a === void 0 ? void 0 :
        _a.map(function (tag, i) {
            var _a;
            return (React.createElement(Indicator, { onClick: function () { return toggelTag(tag.pk); }, type: ((_a = props.selectedTags) === null || _a === void 0 ? void 0 : _a.includes(tag === null || tag === void 0 ? void 0 : tag.pk)) ? 'blue' : 'lightgray', style: { marginTop: '5px', padding: '7px 10px' } }, tag.name));
        }),
        React.createElement(ShowIf, { show: ((_b = props.availableTags) === null || _b === void 0 ? void 0 : _b.length) == 0 },
            React.createElement(P, null, "No tags available"))));
};
var GeneralFilterColumn = function (props) {
    return (React.createElement("div", { style: __assign({ width: '200px' }, props.style) },
        React.createElement(Header5, null, props.header),
        React.createElement(FlexDiv, { style: { flexWrap: 'wrap', flexDirection: 'column' } }, props.children)));
};
var PaginatorBlock = function (props) {
    return (React.createElement("div", { style: { marginLeft: props.noMargin ? '0' : '5px' }, className: style.paginatorBlock + ' ' + (props.active ? style.paginatorBlockActive : '') }, props.children));
};
