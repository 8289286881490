var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { Button, CheckboxWithText, CrossSvgWithHover, DragAndDropFileUpload, FlexDiv, Header2, Header5, Indicator, Line, LoadingDots, Modal, P, Select, ShowIf, Table, useToast } from '@/src/components/Components';
import { updateAuto } from '@/src/utils/Helpers';
import { AllLanguagesOptions } from '@/src/utils/LanguageHelpers';
import { ApiRequests } from '@/src/requests/ApiRequests';
import { AllLanguages } from '@/shared/Types';
import JSZip from 'jszip';
import { ParseFiles } from '@/shared/ParseFiles';
export var UploadFileModal = function (props) {
    var _a, _b, _c, _d, _e;
    var _f = useState(false), uploading = _f[0], setUploading = _f[1];
    var _g = useState(false), parsingFiles = _g[0], setparsingFiles = _g[1];
    var _h = useState([]), files = _h[0], _setFiles = _h[1];
    var _j = useState(0), numberOfParsedFiles = _j[0], setNumberOfParsedFiles = _j[1];
    var defaultFileUpload = { settings: { createNewLanguage: true, machineTranslateNewCodes: true, overrideExistingTranslations: true }, files: [] };
    var _k = useState(defaultFileUpload), parsedData = _k[0], setParsedData = _k[1];
    var _l = useState(false), importOnNextRender = _l[0], setImportOnNextRender = _l[1];
    var addToast = useToast();
    var setFiles = function (files) {
        setImportOnNextRender(true);
        _setFiles(files);
    };
    useEffect(function () {
        if (importOnNextRender) {
            setImportOnNextRender(false);
            onClickUpload();
        }
    }, [importOnNextRender]);
    var onClickUpload = function () {
        readFileContent(files);
    };
    var readFileContent = function (files) {
        setparsingFiles(true);
        // Read the file as text
        files.forEach(function (file) {
            var reader = new FileReader();
            var fileName = file.name;
            reader.onload = function (e) {
                var _a;
                try {
                    //If current file is a zip file, unzip it and handle each file separately
                    if ((_a = fileName === null || fileName === void 0 ? void 0 : fileName.toUpperCase()) === null || _a === void 0 ? void 0 : _a.endsWith('.ZIP')) {
                        var zip = new JSZip();
                        zip.loadAsync(file).then(function (zip) {
                            Object.keys(zip.files).forEach(function (fileName) {
                                zip.files[fileName].async('string').then(function (fileContent) {
                                    var data = ParseFiles.parseFileContentBasedOnContent(fileContent, fileName);
                                    var language = detectLanguageFromFilename(fileName);
                                    if (language) {
                                        data.selectedLanguage = language;
                                        data.status = 'readyForUpload';
                                    }
                                    else {
                                        data.status = 'waitingForLanguageCheck';
                                    }
                                    setParsedData(function (prev) { return updateAuto(prev, { files: { $push: [data] } }); }); //Add to parsed data
                                    setNumberOfParsedFiles(function (prev) { return prev + 1; }); //Increase number of parsed files
                                });
                            });
                        });
                    }
                    else {
                        var data_1 = ParseFiles.parseFileContentBasedOnContent(e.target.result, fileName);
                        var language = detectLanguageFromFilename(fileName);
                        if (language) {
                            data_1.selectedLanguage = language;
                            data_1.status = 'readyForUpload';
                        }
                        else {
                            data_1.status = 'waitingForLanguageCheck';
                        }
                        setParsedData(function (prev) { return updateAuto(prev, { files: { $push: [data_1] } }); });
                    }
                }
                catch (error) { }
                setNumberOfParsedFiles(function (prev) { return prev + 1; });
                setparsingFiles(false); //Stop parsing
                setFiles([]); //Clear the files
            };
            reader.readAsText(file);
        });
    };
    var onClickUploadToServer = function () {
        setUploading(true);
        ApiRequests.Project.FileUpload.uploadFile(props.detailedProject.pk, parsedData, function (response) {
            if (response.status == '200') {
                addToast('File uploaded', 'success');
                props.onChange(function (prev) { var _a, _b; return updateAuto(prev, { languages: { $push: (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.newLanguages } }); });
                props.onChange(function (prevArg) {
                    var _a, _b, _c;
                    var prev = __assign({}, prevArg);
                    (_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.updateCodes) === null || _c === void 0 ? void 0 : _c.forEach(function (item) {
                        var _a;
                        var existingCodeIndex = (_a = prev === null || prev === void 0 ? void 0 : prev.keys) === null || _a === void 0 ? void 0 : _a.findIndex(function (item2) { return item2.code === (item === null || item === void 0 ? void 0 : item.code); });
                        if (existingCodeIndex > -1) {
                            prev.keys[existingCodeIndex] = item;
                        }
                        else {
                            prev.keys.push(item);
                        }
                    });
                    console.log(prev);
                    return prev;
                });
            }
            else {
                addToast(response.errors, 'danger');
            }
            setUploading(false);
        });
    };
    var detectLanguageFromFilename = function (fileName) {
        var foundLanguage = null;
        AllLanguages === null || AllLanguages === void 0 ? void 0 : AllLanguages.forEach(function (language) {
            if (fileName === null || fileName === void 0 ? void 0 : fileName.toUpperCase().includes(language === null || language === void 0 ? void 0 : language.toUpperCase())) {
                foundLanguage = language;
            }
        });
        return foundLanguage;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { style: { maxWidth: '1600px' }, show: props.show, onHide: props.onClose },
            React.createElement(Header2, null, "Upload file"),
            React.createElement(ShowIf, { show: ((_a = parsedData === null || parsedData === void 0 ? void 0 : parsedData.files) === null || _a === void 0 ? void 0 : _a.length) > 0 },
                React.createElement(P, null, "Verify file content before upload."),
                React.createElement(FileUploadTable, { onChange: setParsedData, data: parsedData }),
                React.createElement("div", { style: { marginTop: '10px' } },
                    React.createElement(DragAndDropFileUpload, { files: files, setFiles: setFiles, alternativeButton: React.createElement(Button, { type: 'underline' }, "Select another file") })),
                React.createElement(Line, null),
                React.createElement(Header5, { style: { marginBottom: '10px' }, black: true }, "Upload settings"),
                React.createElement(CheckboxWithText, { label: 'Create new language', checked: (_b = parsedData === null || parsedData === void 0 ? void 0 : parsedData.settings) === null || _b === void 0 ? void 0 : _b.createNewLanguage, onChange: function (v) { return setParsedData(function (prev) { return updateAuto(prev, { settings: { createNewLanguage: { $set: v } } }); }); } }),
                React.createElement(CheckboxWithText, { style: { marginTop: '5px' }, label: 'Overwrite existing translations', checked: (_c = parsedData === null || parsedData === void 0 ? void 0 : parsedData.settings) === null || _c === void 0 ? void 0 : _c.overrideExistingTranslations, onChange: function (v) { return setParsedData(function (prev) { return updateAuto(prev, { settings: { overrideExistingTranslations: { $set: v } } }); }); } }),
                React.createElement(CheckboxWithText, { style: { marginTop: '5px' }, label: 'Machine translate all new codes', checked: (_d = parsedData === null || parsedData === void 0 ? void 0 : parsedData.settings) === null || _d === void 0 ? void 0 : _d.machineTranslateNewCodes, onChange: function (v) { return setParsedData(function (prev) { return updateAuto(prev, { settings: { machineTranslateNewCodes: { $set: v } } }); }); } }),
                React.createElement(FlexDiv, { style: { marginTop: '10px' } },
                    React.createElement(Button, { type: 'blue', size: 'medium', disabled: uploading, onClick: onClickUploadToServer }, uploading ? React.createElement(LoadingDots, null) : 'Upload'),
                    React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: props.onClose }, "Cancel"))),
            React.createElement(ShowIf, { show: ((_e = parsedData === null || parsedData === void 0 ? void 0 : parsedData.files) === null || _e === void 0 ? void 0 : _e.length) == 0 },
                React.createElement(P, null, "Upload a file to translate"),
                React.createElement(DragAndDropFileUpload, { files: files, setFiles: setFiles }),
                React.createElement(FlexDiv, { style: { marginTop: '30px' } },
                    React.createElement(Button, { style: { marginLeft: '0px' }, type: 'white', size: 'medium', onClick: props.onClose }, "Cancel"))))));
};
var FileUploadTable = function (props) {
    var _a, _b;
    var getNumberOfTranslations = function (data) {
        var _a;
        var numberOfTranslations = 0;
        (_a = data === null || data === void 0 ? void 0 : data.codes) === null || _a === void 0 ? void 0 : _a.forEach(function (code) {
            numberOfTranslations += 1; //Only one
        });
        return numberOfTranslations;
    };
    var getFileExtension = function (fileName) {
        var _a, _b;
        return (_b = (_a = fileName === null || fileName === void 0 ? void 0 : fileName.split('.')) === null || _a === void 0 ? void 0 : _a.pop()) === null || _b === void 0 ? void 0 : _b.toUpperCase();
    };
    var onClickRemoveFile = function (index) {
        props.onChange(updateAuto(props.data, { files: { $splice: [[index, 1]] } }));
    };
    var onSelectLanguage = function (index, language) {
        var _a;
        props.onChange(updateAuto(props.data, { files: (_a = {}, _a[index] = { selectedLanguage: { $set: language } }, _a) }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { style: { width: '100%' }, doNotShowLeftHeadercontent: true, header: [
                { content: 'File name' },
                { content: 'Detected language' },
                { content: '' }
            ], rows: (_b = (_a = props.data) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b.map(function (item, index) {
                var _a;
                return {
                    items: [
                        {
                            content: (React.createElement(React.Fragment, null,
                                React.createElement("div", null, item === null || item === void 0 ? void 0 : item.fileName),
                                React.createElement(FlexDiv, { style: { marginTop: '4px' } },
                                    React.createElement(Indicator, { size: 'small', rounded: true }, getFileExtension(item === null || item === void 0 ? void 0 : item.fileName)),
                                    React.createElement(Indicator, { style: { marginLeft: '5px' }, size: 'small', type: 'lightgray' }, (_a = item.codes) === null || _a === void 0 ? void 0 :
                                        _a.length,
                                        " keys"),
                                    React.createElement(Indicator, { style: { marginLeft: '5px' }, size: 'small', type: 'lightgray' },
                                        getNumberOfTranslations(item),
                                        " translations"))))
                        },
                        { content: React.createElement(Select, { options: AllLanguagesOptions, onChange: function (v) { return onSelectLanguage(index, v); }, value: item === null || item === void 0 ? void 0 : item.selectedLanguage }) },
                        { content: React.createElement(CrossSvgWithHover, { onClick: function () { return onClickRemoveFile(index); } }) }
                    ]
                };
            }) })));
};
