import React, { useMemo, useState } from 'react';
//@ts-ignore
import style from '../../css/app.module.scss';
import { Button, CrossSvgWithHover, FlexDiv, Header3, Header5, Label, Modal, ProgressBarWithText, Select, ShowIf, ThreeDotsWithMenu, Tooltip } from '@/src/components/Components';
import { notUndefinedOrEmpty, updateAuto } from '@/src/utils/Helpers';
import { countryCodeToName } from '@/src/utils/LanguageHelpers';
import { Flag, getFlagName } from '@/src/components/PageComponents';
import { StarSvg } from '@/src/components/SvgItems';
import { AllLanguagesAndVariations } from '@/shared/Types';
export var LanguageSummary = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var _l = useState(false), showAddVariationModal = _l[0], setShowAddVariationModal = _l[1];
    var _m = useState(props.simpleLanguage.variations), tmpVariations = _m[0], setTmpVariations = _m[1];
    var _o = useState(''), newVariation = _o[0], setNewVariation = _o[1];
    var saveProject = function () {
        props.onSave(updateAuto(props.simpleLanguage, { variations: { $set: tmpVariations } }));
        setShowAddVariationModal(false);
    };
    var cancel = function () {
        setTmpVariations(props.simpleLanguage.variations);
        setShowAddVariationModal(false);
    };
    var addVariation = function () {
        setTmpVariations(function (prev) { return updateAuto(prev, { $push: [newVariation] }); });
        setNewVariation("");
    };
    var variationNeedsSave = useMemo(function () {
        var _a;
        return JSON.stringify((_a = props.simpleLanguage) === null || _a === void 0 ? void 0 : _a.variations) != JSON.stringify(tmpVariations);
    }, [(_a = props.simpleLanguage) === null || _a === void 0 ? void 0 : _a.variations, tmpVariations]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: props.style, className: props.className + ' ' + style.LanguageSummarWrapper + ' ' },
            React.createElement(FlexDiv, { alignCenter: true },
                React.createElement(ShowIf, { show: !props.hideFlag },
                    React.createElement(Flag, { languageFlag: props.languageFlagShort })),
                React.createElement(Header5, { style: { marginLeft: '5px' }, black: true }, props.fullLanguageName),
                React.createElement(ShowIf, { show: notUndefinedOrEmpty(props.onClickDelete) && !props.disableMenu },
                    React.createElement(ThreeDotsWithMenu, { options: [{ text: 'Make base language', onClick: props.makeBaseLanguage }, { text: 'Delete', onClick: props.onClickDelete }], style: { marginLeft: '5px' } })),
                React.createElement(ShowIf, { show: (_b = props.simpleLanguage) === null || _b === void 0 ? void 0 : _b.is_base_language },
                    React.createElement(Tooltip, { overlay: 'Base language', style: { marginLeft: '5px' } },
                        "  ",
                        React.createElement(StarSvg, null)))),
            React.createElement(ProgressBarWithText, { style: { margin: '10px 0 0' }, text: 'Translated', progressInPercent: Math.floor(0.5 + props.percentTranslated) }),
            React.createElement(ProgressBarWithText, { text: 'Reviewed', progressInPercent: Math.floor(props.percentReviewed + 0.5) }),
            React.createElement(FlexDiv, null,
                React.createElement(Label, { style: { margin: '10px 0', color: (props.numberOfContributors > 0 ? 'black' : '#b50000') }, label: 'Contributors', text: props === null || props === void 0 ? void 0 : props.numberOfContributors }),
                React.createElement(Label, { style: { margin: '10px 0', color: (props.numberOfContributors > 0 ? 'black' : '#b50000') }, label: 'Region variations', text: React.createElement(FlexDiv, { alignCenter: true },
                        React.createElement(ShowIf, { show: !props.disableEditVariations && ((_d = (_c = props.simpleLanguage) === null || _c === void 0 ? void 0 : _c.variations) === null || _d === void 0 ? void 0 : _d.length) == 0 },
                            React.createElement(Button, { size: 'small', style: {}, onClick: function () { return setShowAddVariationModal(true); }, type: 'underline' }, "+ Add")),
                        React.createElement(FlexDiv, null, (_f = (_e = props.simpleLanguage) === null || _e === void 0 ? void 0 : _e.variations) === null || _f === void 0 ? void 0 : _f.map(function (variation, i) {
                            return (React.createElement(Flag, { style: { marginLeft: i > 0 ? '5px' : '0' }, languageFlag: variation }));
                        })),
                        React.createElement(ShowIf, { style: { marginLeft: '10px' }, show: !props.disableEditVariations && ((_h = (_g = props.simpleLanguage) === null || _g === void 0 ? void 0 : _g.variations) === null || _h === void 0 ? void 0 : _h.length) > 0 },
                            React.createElement(Button, { size: 'small', style: { marginLeft: 'auto' }, onClick: function () { return setShowAddVariationModal(true); }, type: 'underline' }, "Edit"))) }))),
        React.createElement(Modal, { style: { maxWidth: '600px' }, show: showAddVariationModal, onHide: function () { return setShowAddVariationModal(false); } },
            React.createElement(Header3, { style: { marginBottom: '20px' }, black: true }, "Edit region variations"),
            React.createElement(Header5, { style: { marginTop: '20px' }, black: true }, "Current region variations"),
            React.createElement("table", null,
                React.createElement("tr", null,
                    React.createElement("td", null),
                    React.createElement("td", null)), tmpVariations === null || tmpVariations === void 0 ? void 0 :
                tmpVariations.map(function (variation, i) {
                    return (React.createElement("tr", { style: { marginBottom: '15px' } },
                        React.createElement("td", { style: { paddingBottom: '10px', width: '200px' } },
                            React.createElement(FlexDiv, { alignCenter: true },
                                React.createElement(Flag, { languageFlag: variation }),
                                React.createElement("div", { style: { marginLeft: '10px' } }, countryCodeToName(variation)))),
                        React.createElement("td", null,
                            React.createElement("div", { style: { marginLeft: '10px', cursor: 'pointer' }, onClick: function () { setTmpVariations(function (prev) { return updateAuto(prev, { $splice: [[i, 1]] }); }); } },
                                React.createElement(CrossSvgWithHover, null)))));
                })),
            React.createElement(Header5, { style: { marginTop: '5px' }, black: true }, "Add new region variation"),
            React.createElement(FlexDiv, { style: { width: '100%' } },
                React.createElement(Select, { value: newVariation, onChange: setNewVariation, options: (_k = (_j = AllLanguagesAndVariations.find(function (k) { var _a; return k.languageCode == ((_a = props.simpleLanguage) === null || _a === void 0 ? void 0 : _a.language_code); })) === null || _j === void 0 ? void 0 : _j.variations) === null || _k === void 0 ? void 0 : _k.filter(function (k) { return !(tmpVariations === null || tmpVariations === void 0 ? void 0 : tmpVariations.includes(k)); }).map(function (k) { return { imgSrc: getFlagName(k), value: k, displayValue: countryCodeToName(k) + ' (' + k + ')' }; }) }),
                React.createElement(Button, { onClick: addVariation, style: { marginLeft: '10px' }, size: 'small', type: 'blue' }, "Add")),
            React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                React.createElement(Button, { disabled: !variationNeedsSave, type: 'blue', size: 'medium', onClick: saveProject }, "Save"),
                React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: cancel }, "Cancel")))));
};
