var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
//@ts-ignore
import style from '../../css/app.module.scss';
import { Button, Checkbox, CheckboxWithText, FlexDiv, Header2, Header5, Indicator, Input, LabelWithInfoTooltip, Line, LoadingDots, Modal, Select, ShowIf, ThreeDotsWithMenu, Tooltip, useToast } from '@/src/components/Components';
import { formatDateFromFormat, notUndefinedOrEmpty, undefinedOrEmpty, updateAuto } from '@/src/utils/Helpers';
import { extractDefaultCountryCodeFromLanguageCode } from '@/src/utils/LanguageHelpers';
import { ApiRequests } from '@/src/requests/ApiRequests';
import { CommentSvg } from '@/src/components/SvgItems';
import { useKeystrokeLogger } from '@/src/utils/CustomHooks';
import { TranslationTrRow } from './TranslationTrRow';
import { LocaleHelper } from '@/shared/LocaleHelper';
export var KeyRow = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var _t = useState(false), showCommentModal = _t[0], setshowCommentModal = _t[1];
    var _u = useState(props.item.comment), comment = _u[0], setComment = _u[1];
    var _v = useState(false), savingComment = _v[0], setsavingComment = _v[1];
    var _w = useState(false), savingTags = _w[0], setsavingTags = _w[1];
    var _x = useState(false), showTagEditModal = _x[0], setShowTagEditModal = _x[1];
    var _y = useState(props.item.tagIds || []), temporaryTags = _y[0], settemporaryTags = _y[1];
    var _z = useState(false), savingKey = _z[0], setSavingKey = _z[1];
    var _0 = useState(props.item), tmpKeyEdit = _0[0], setTmpKeyEdit = _0[1];
    useEffect(function () {
        setTmpKeyEdit(props.item);
    }, [props.item]);
    /*
      useEffect(() => {
        if (props.triggerDelete) {
          deleteCode(props.item);
        }
      }, [props.triggerDelete]) */
    var onEnterKeylogger = function () {
        if (showCommentModal)
            saveComment();
        if (showTagEditModal)
            onSaveTags();
    };
    var keystrokeLogger = useKeystrokeLogger(showCommentModal, { onEnter: onEnterKeylogger });
    var addToast = useToast();
    var saveComment = function () {
        setsavingComment(true);
        ApiRequests.Codes.put(updateAuto(props.item, { comment: { $set: comment } }), function (response) {
            if (response.status == '200') {
                setshowCommentModal(false);
                props.onAfterSave(function (prev) { return response.data; });
                addToast('Saved!', 'success');
            }
            else {
                addToast(response.errors, 'danger');
            }
            setsavingComment(false);
        });
    };
    var onSaveKey = function () {
        setSavingKey(true);
        ApiRequests.Codes.put(updateAuto(props.item, { $merge: tmpKeyEdit }), function (response) {
            if (response.status == '200') {
                setShowEditKeyModal(false);
                props.onAfterSave(function (prev) { return response.data; });
                addToast('Saved!', 'success');
            }
            else {
                addToast(response.errors, 'danger');
            }
            setSavingKey(false);
        });
    };
    var onSaveTags = function () {
        setsavingTags(true);
        ApiRequests.Codes.put(updateAuto(props.item, { tagIds: { $set: temporaryTags } }), function (response) {
            if (response.status == '200') {
                setShowTagEditModal(false);
                props.onAfterSave(function (prev) { return response.data; });
                addToast('Saved!', 'success');
            }
            else {
                addToast(response.errors, 'danger');
            }
            setsavingTags(false);
        });
    };
    var deleteCode = function (code) {
        ApiRequests.Codes.delele(code.projectPk, code.pk, function (response) {
            if (response.status == '200') {
                props.onAfterDelete(code);
            }
            else {
                addToast(response.errors, 'danger');
            }
        });
    };
    var onAddAndSelectNewTag = function (nameOfNewTag) {
        ApiRequests.Tags.post(props.projectPk, { name: nameOfNewTag, pk: null }, function (response) {
            if (response.status == '200') {
                settemporaryTags(function (prev) { return __spreadArray(__spreadArray([], prev, true), [response.data.pk], false); });
                props.onChangeAvailableTags(updateAuto(props.availableTagsForProject, { $push: [response.data] }));
            }
        });
    };
    var filterTranslations = function (translation) {
        var _a, _b;
        return !((_b = (_a = props.translationsToHide) === null || _a === void 0 ? void 0 : _a.map(function (d) { return d === null || d === void 0 ? void 0 : d.fullLocale; })) === null || _b === void 0 ? void 0 : _b.includes(translation === null || translation === void 0 ? void 0 : translation.full_locale));
    };
    /*
      const getChainedLanguages = (languageCode: Types.LanguageCode) => {
        const currentLanguageIsChainedOwner = props.availableLanguages?.find(k => k?.languageCode == languageCode)?.chainedMaster;
        const chainedLanguagesToCurrentLanguage = props.availableLanguages?.filter(d => fullLocaleToLanguage(d?.languageCode) == fullLocaleToLanguage(languageCode))?.filter(d => d?.languageCode != languageCode);
        return currentLanguageIsChainedOwner ? chainedLanguagesToCurrentLanguage : [];
      }
    
      const isPartOfAChainedLanguage = (languageCode: Types.LanguageCode) => {
        const currentLanguageIsChainedOwner = props.availableLanguages?.find(k => k?.languageCode == languageCode)?.chainedMaster;
        const returnVar = !currentLanguageIsChainedOwner && getChainedLanguages(fullLocaleToLanguage(languageCode))?.length > 0;
        return returnVar;
      }
    */
    var _1 = useState(false), showEditKeyModal = _1[0], setShowEditKeyModal = _1[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(FlexDiv, { style: { minHeight: '0px' } },
            React.createElement("div", { style: { margin: '14px 10px 0 0' } },
                React.createElement(Checkbox, { checked: props.selected, onChange: props.onSelect })),
            React.createElement("div", { style: { width: '300px' } },
                React.createElement(FlexDiv, { style: { height: '100%' } },
                    React.createElement("div", null,
                        React.createElement(FlexDiv, { alignCenter: true },
                            React.createElement(Header5, { style: { overflowWrap: 'anywhere' }, onClick: function () { return setShowEditKeyModal(true); } }, (_a = props.item) === null || _a === void 0 ? void 0 : _a.code),
                            React.createElement(ThreeDotsWithMenu, { style: { marginLeft: '15px', marginRight: '10px', alignSelf: 'center' }, options: [
                                    ,
                                    { onClick: function () { setShowEditKeyModal(true); }, text: 'Edit' },
                                    { onClick: function () { deleteCode(props.item); }, text: 'Delete' }
                                ] }),
                            React.createElement(ShowIf, { show: (_b = props.item) === null || _b === void 0 ? void 0 : _b.allowPlurals },
                                React.createElement(Indicator, { onClick: function () { return setShowEditKeyModal(true); }, size: 'small' }, "Plural"))),
                        React.createElement("div", { style: { color: 'gray', fontSize: '11px' } }, formatDateFromFormat((_c = props.item) === null || _c === void 0 ? void 0 : _c.createdAt, 'yyyy-MM-dd HH:mm')),
                        React.createElement(FlexDiv, { style: {} },
                            React.createElement(ShowIf, { show: notUndefinedOrEmpty((_d = props.item) === null || _d === void 0 ? void 0 : _d.screenshotId) }, "Screenshot"),
                            React.createElement("div", { style: { alignItems: 'flex-end', display: 'flex', marginLeft: notUndefinedOrEmpty((_e = props.item) === null || _e === void 0 ? void 0 : _e.screenshotId) ? '10px' : '0px' } },
                                React.createElement("div", { style: { marginTop: '3px' } },
                                    React.createElement(FlexDiv, { alignCenter: true, style: { flexWrap: 'wrap', marginLeft: '-5px', marginTop: '-5px' } }, (_g = (_f = props.item) === null || _f === void 0 ? void 0 : _f.tagIds) === null || _g === void 0 ? void 0 :
                                        _g.map(function (tagPk, i) {
                                            var _a, _b;
                                            return (React.createElement(Indicator, { size: 'small', style: { marginLeft: '5px', marginTop: '5px' } }, (_b = (_a = props.availableTagsForProject) === null || _a === void 0 ? void 0 : _a.find(function (k) { return k.pk == tagPk; })) === null || _b === void 0 ? void 0 : _b.name));
                                        }),
                                        React.createElement(Indicator, { size: 'small', onClick: function () { return setShowEditKeyModal(true); }, type: 'lightgray', style: { marginLeft: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' } },
                                            "+ ",
                                            React.createElement(ShowIf, { show: ((_j = (_h = props.item) === null || _h === void 0 ? void 0 : _h.tagIds) === null || _j === void 0 ? void 0 : _j.length) == 0 || undefinedOrEmpty(props.item.tagIds) }, "Add tag")))))),
                        React.createElement(ShowIf, { style: { fontSize: '10px', fontStyle: 'italic', color: 'gray', marginTop: '2px', marginBottom: '5px' }, show: notUndefinedOrEmpty(props.item.comment) }, props.item.comment)),
                    React.createElement(Tooltip, { onClick: function () { return setshowCommentModal(true); }, displayFlex: true, className: style.keyCommentWrapper, overlay: 'Comment', placement: 'top' },
                        React.createElement("div", null,
                            React.createElement(CommentSvg, null))))),
            React.createElement(Line, { style: { height: 'auto' }, vertical: true, noMargin: true }),
            React.createElement("div", { style: { flexGrow: '1' } },
                React.createElement("table", { style: { borderSpacing: '1px', height: '100%' } }, (_o = (_m = (_l = (_k = props.availableLanguages) === null || _k === void 0 ? void 0 : _k.filter(function (k) { return !props.biLinugal || ((k === null || k === void 0 ? void 0 : k.language_code) == props.destinationLanguage || (k === null || k === void 0 ? void 0 : k.language_code) == props.sourceLanguage); })) === null || _l === void 0 ? void 0 : _l.sort(function (a, b) {
                    if (a.language_code == props.sourceLanguage)
                        return -1;
                    if (b.language_code == props.sourceLanguage)
                        return 1;
                    return 0;
                })) === null || _m === void 0 ? void 0 : _m.filter(function (language, i) { var _a, _b, _c; return filterTranslations(((_b = (_a = props.item) === null || _a === void 0 ? void 0 : _a.translations) === null || _b === void 0 ? void 0 : _b.find(function (k) { return (k === null || k === void 0 ? void 0 : k.full_locale) == language.language_code; })) || { codePk: props.item.pk, projectPk: (_c = props.item) === null || _c === void 0 ? void 0 : _c.projectPk, full_locale: language.language_code, origin_full_locale: language.language_code }); })) === null || _o === void 0 ? void 0 :
                    _o.map(function (language, i) {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        return (React.createElement(React.Fragment, null,
                            React.createElement(TranslationTrRow, { allowPlurals: (_a = props.item) === null || _a === void 0 ? void 0 : _a.allowPlurals, haveChainedLanguages: ((_b = language.variations) === null || _b === void 0 ? void 0 : _b.length) > 0, chainedTranslationsRows: (_c = language === null || language === void 0 ? void 0 : language.variations) === null || _c === void 0 ? void 0 : _c.map(function (variation) {
                                    var _a, _b, _c, _d;
                                    return {
                                        highlightString: props === null || props === void 0 ? void 0 : props.highlightString,
                                        baseLanguage: props.baseLanguage
                                        //     , triggerReviewed: props.triggerReviewed?.filter(k => k.languageCode == chained?.languageCode)?.length > 0
                                        ,
                                        selected: ((_a = props.selectedTranslations) === null || _a === void 0 ? void 0 : _a.findIndex(function (k) { return k.fullLocale == LocaleHelper.toFullLocale(language.language_code, variation); })) > -1,
                                        onAfterMachineTranslated: function () { },
                                        onAfterSetAsRevied: function () { return props.onAfterReviewedCompleye({ fullLocale: LocaleHelper.toFullLocale(language.language_code, variation) }); },
                                        onSelect: function (v) { return props.onSelectTranslation(v, { fullLocale: LocaleHelper.toFullLocale(language.language_code, variation) }); },
                                        key: LocaleHelper.toFullLocale(language.language_code, variation) + i,
                                        fullLocale: LocaleHelper.toFullLocale(language.language_code, variation),
                                        projectPk: props.projectPk,
                                        availableLanguages: (_b = props.availableLanguages) === null || _b === void 0 ? void 0 : _b.map(function (d) { return d === null || d === void 0 ? void 0 : d.language_code; }),
                                        codePk: props.item.pk,
                                        isSourceLanguage: props.biLinugal && (language === null || language === void 0 ? void 0 : language.language_code) == props.sourceLanguage,
                                        onAfterSave: function (v) {
                                            var _a, _b;
                                            var translationIndex = (_b = (_a = props.item) === null || _a === void 0 ? void 0 : _a.translations) === null || _b === void 0 ? void 0 : _b.findIndex(function (k) { return (k === null || k === void 0 ? void 0 : k.full_locale) == LocaleHelper.toFullLocale(language.language_code, variation); });
                                            if (translationIndex == -1) {
                                                props.onAfterSave(function (prev) {
                                                    return updateAuto(prev, { translations: { $push: [v] } });
                                                });
                                            }
                                            else {
                                                props.onAfterSave(function (prev) {
                                                    var _a;
                                                    return updateAuto(prev, { translations: (_a = {}, _a[translationIndex] = { $set: v }, _a) });
                                                });
                                            }
                                        },
                                        variation: (variation),
                                        allowPlurals: (_c = props.item) === null || _c === void 0 ? void 0 : _c.allowPlurals,
                                        translation: (function () {
                                            var _a, _b, _c, _d;
                                            var translation = (_b = (_a = props.item) === null || _a === void 0 ? void 0 : _a.translations) === null || _b === void 0 ? void 0 : _b.find(function (k) { return (k === null || k === void 0 ? void 0 : k.full_locale) == LocaleHelper.toFullLocale(language.language_code, variation); });
                                            var translationFromOwnerOfChain = (_d = (_c = props.item) === null || _c === void 0 ? void 0 : _c.translations) === null || _d === void 0 ? void 0 : _d.find(function (k) { return (k === null || k === void 0 ? void 0 : k.full_locale) == language.language_code; });
                                            translationFromOwnerOfChain = updateAuto(translationFromOwnerOfChain, { full_locale: { $set: LocaleHelper.toFullLocale(language.language_code, variation) } });
                                            if (translation === null || translation === void 0 ? void 0 : translation.translated)
                                                return translation;
                                            else
                                                return translationFromOwnerOfChain;
                                        })(),
                                        isLast: ((_d = props.availableLanguages) === null || _d === void 0 ? void 0 : _d.length) == i + 1
                                    };
                                }), highlightString: props === null || props === void 0 ? void 0 : props.highlightString, baseLanguage: props.baseLanguage, 
                                //    triggerReviewed={props.triggerReviewed?.filter(k => k.languageCode == languageCode)?.length > 0}
                                selected: ((_d = props.selectedTranslations) === null || _d === void 0 ? void 0 : _d.findIndex(function (k) { return k.fullLocale == language.language_code; })) > -1, onAfterMachineTranslated: function () { }, onAfterSetAsRevied: function () { return props.onAfterReviewedCompleye({ fullLocale: language.language_code }); }, onSelect: function (v) { return props.onSelectTranslation(v, { fullLocale: language.language_code }); }, key: language.language_code + i, fullLocale: language.language_code, projectPk: props.projectPk, availableLanguages: (_e = props.availableLanguages) === null || _e === void 0 ? void 0 : _e.map(function (d) { return d === null || d === void 0 ? void 0 : d.language_code; }), codePk: props.item.pk, variation: extractDefaultCountryCodeFromLanguageCode(language.language_code), isSourceLanguage: props.biLinugal && language.language_code == props.sourceLanguage, onAfterSave: function (v) {
                                    var _a, _b;
                                    var translationIndex = (_b = (_a = props.item) === null || _a === void 0 ? void 0 : _a.translations) === null || _b === void 0 ? void 0 : _b.findIndex(function (k) { return (k === null || k === void 0 ? void 0 : k.full_locale) == language.language_code; });
                                    if (translationIndex == -1) {
                                        props.onAfterSave(function (prev) {
                                            return updateAuto(prev, { translations: { $push: [v] } });
                                        });
                                    }
                                    else {
                                        props.onAfterSave(function (prev) {
                                            var _a;
                                            return updateAuto(prev, { translations: (_a = {}, _a[translationIndex] = { $set: v }, _a) });
                                        });
                                    }
                                }, translation: (_g = (_f = props.item) === null || _f === void 0 ? void 0 : _f.translations) === null || _g === void 0 ? void 0 : _g.find(function (k) { return (k === null || k === void 0 ? void 0 : k.full_locale) == language.language_code; }), isLast: ((_h = props.availableLanguages) === null || _h === void 0 ? void 0 : _h.length) == i + 1 })));
                    }), (_p = props.availableLanguages) === null || _p === void 0 ? void 0 :
                    _p.filter(function (k) { return props.biLinugal && (k.language_code == props.destinationLanguage && k.language_code == props.sourceLanguage); }).map(function (language, i) {
                        var _a, _b, _c, _d, _e, _f;
                        return (React.createElement(React.Fragment, null,
                            React.createElement(TranslationTrRow, { allowPlurals: (_a = props.item) === null || _a === void 0 ? void 0 : _a.allowPlurals, highlightString: props === null || props === void 0 ? void 0 : props.highlightString, baseLanguage: props.baseLanguage, selected: ((_b = props.selectedTranslations) === null || _b === void 0 ? void 0 : _b.findIndex(function (k) { return k.fullLocale == language.language_code; })) > -1, onAfterMachineTranslated: function () { }, onAfterSetAsRevied: function () { return props.onAfterReviewedCompleye({ fullLocale: language.language_code }); }, onSelect: function (v) { return props.onSelectTranslation(v, { fullLocale: language.language_code }); }, key: language.language_code + i, fullLocale: language.language_code, projectPk: props.projectPk, availableLanguages: (_c = props.availableLanguages) === null || _c === void 0 ? void 0 : _c.map(function (d) { return d === null || d === void 0 ? void 0 : d.language_code; }), codePk: props.item.pk, variation: extractDefaultCountryCodeFromLanguageCode(language.language_code), isSourceLanguage: props.biLinugal && language.language_code == props.sourceLanguage, onAfterSave: function (v) {
                                    var _a, _b;
                                    var translationIndex = (_b = (_a = props.item) === null || _a === void 0 ? void 0 : _a.translations) === null || _b === void 0 ? void 0 : _b.findIndex(function (k) { return (k === null || k === void 0 ? void 0 : k.full_locale) == language.language_code; });
                                    if (translationIndex == -1) {
                                        props.onAfterSave(function (prev) {
                                            return updateAuto(prev, { translations: { $push: [v] } });
                                        });
                                    }
                                    else {
                                        props.onAfterSave(function (prev) {
                                            var _a;
                                            return updateAuto(prev, { translations: (_a = {}, _a[translationIndex] = { $set: v }, _a) });
                                        });
                                    }
                                }, translation: (_e = (_d = props.item) === null || _d === void 0 ? void 0 : _d.translations) === null || _e === void 0 ? void 0 : _e.find(function (k) { return (k === null || k === void 0 ? void 0 : k.full_locale) == language.language_code; }), isLast: ((_f = props.availableLanguages) === null || _f === void 0 ? void 0 : _f.length) == i + 1 })));
                    })))),
        React.createElement(Modal, { style: { maxWidth: '600px' }, show: showEditKeyModal, onHide: function () { return setShowEditKeyModal(false); } },
            React.createElement(Header2, null, (_q = props.item) === null || _q === void 0 ? void 0 : _q.code),
            React.createElement(Input, { autofocus: true, style: { marginTop: '10px' }, label: 'Code ', value: tmpKeyEdit.code, onChange: function (p) { return setTmpKeyEdit(function (prev) { return updateAuto(prev, { code: { $set: p } }); }); } }),
            React.createElement(Input, { type: 'textarea', style: { marginTop: '10px' }, label: 'Comment', value: tmpKeyEdit.comment, onChange: function (p) { return setTmpKeyEdit(function (prev) { return updateAuto(prev, { comment: { $set: p } }); }); } }),
            React.createElement(Select, { label: 'Tags', options: (_r = props.availableTagsForProject) === null || _r === void 0 ? void 0 : _r.map(function (k) { return { value: k.pk, displayValue: k.name }; }), style: { marginTop: '10px' }, values: tmpKeyEdit === null || tmpKeyEdit === void 0 ? void 0 : tmpKeyEdit.tagIds, onChanges: function (p) { return setTmpKeyEdit(function (prev) { return updateAuto(prev, { tagIds: { $set: p } }); }); }, allowNewIfNotExists: true, allowMultipleValues: true, onClickNewOption: onAddAndSelectNewTag }),
            React.createElement(CheckboxWithText, { style: { marginTop: '5px' }, checked: tmpKeyEdit === null || tmpKeyEdit === void 0 ? void 0 : tmpKeyEdit.allowPlurals, onChange: function (v) { return setTmpKeyEdit(function (prev) { return updateAuto(prev, { allowPlurals: { $set: v } }); }); } },
                React.createElement(LabelWithInfoTooltip, { tooltip: 'If defined as plural key, multiple translations can be added depending on the counter. Different languages have different available plurals.' }, "This is a plural key")),
            React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                React.createElement(Button, { type: 'blue', size: 'medium', onClick: onSaveKey, disabled: savingKey }, savingKey ? React.createElement(LoadingDots, null) : 'Save'),
                React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: function () { setTmpKeyEdit(props.item); setShowEditKeyModal(false); }, disabled: savingKey }, "Cancel"))),
        React.createElement(Modal, { style: { maxWidth: '600px' }, show: showTagEditModal, onHide: function () { return setShowTagEditModal(false); } },
            React.createElement(Header2, null, "Tags"),
            React.createElement(Select, { showDropdownOnMount: true, options: (_s = props.availableTagsForProject) === null || _s === void 0 ? void 0 : _s.map(function (k) { return { value: k.pk, displayValue: k.name }; }), style: { marginTop: '10px' }, values: temporaryTags, onChanges: settemporaryTags, allowNewIfNotExists: true, allowMultipleValues: true, onClickNewOption: onAddAndSelectNewTag }),
            React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                React.createElement(Button, { type: 'blue', size: 'medium', onClick: onSaveTags, disabled: savingTags }, savingTags ? React.createElement(LoadingDots, null) : 'Save'),
                React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: function () { setComment(props.item.comment); setShowTagEditModal(false); }, disabled: savingTags }, "Cancel"))),
        React.createElement(Modal, { style: { maxWidth: '600px' }, show: showCommentModal, onHide: function () { return setshowCommentModal(false); } },
            React.createElement(Header2, null, "Comment"),
            React.createElement(Input, { style: { marginTop: '10px' }, autofocus: true, type: 'textarea', value: comment, onChange: setComment }),
            React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                React.createElement(Button, { type: 'blue', size: 'medium', onClick: saveComment, disabled: savingComment }, savingComment ? React.createElement(LoadingDots, null) : 'Save'),
                React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: function () { setComment(props.item.comment); setshowCommentModal(false); }, disabled: savingComment }, "Cancel")))));
};
