import React, { useState } from 'react';
import { BoldLabel, Button, CheckboxWithText, FlexDiv, Header2, Input, LabelWithInfoTooltip, LoadingDots, Modal, Select, useToast } from '@/src/components/Components';
import { notUndefinedOrEmpty, updateAuto, useStateRef } from '@/src/utils/Helpers';
import { languageCodeToName } from '@/src/utils/LanguageHelpers';
import { ApiRequests } from '@/src/requests/ApiRequests';
var defaultNewKey = { code: '', pk: null, tagIds: [], translations: [], projectPk: null };
export var NewKeyModal = function (props) {
    var _a, _b, _c, _d, _e;
    var _f = useState(defaultNewKey), newKey = _f[0], setnewKey = _f[1];
    var _g = useStateRef(false), savingNewKey = _g[0], setSavingNewKey = _g[1], savingNewKeyRef = _g[2];
    var _h = useState(true), machineTranslateWhenAdded = _h[0], setmachineTranslateWhenAdded = _h[1];
    var _j = useState(''), defaultTranslationText = _j[0], setDefaultTranslationText = _j[1];
    var addToast = useToast();
    var onAddKey = function () {
        var _a, _b, _c, _d, _e, _f;
        if (savingNewKeyRef === null || savingNewKeyRef === void 0 ? void 0 : savingNewKeyRef.current)
            return;
        setSavingNewKey(true);
        var data = { key: newKey, settings: { machineTranslateToAllLanguages: machineTranslateWhenAdded } };
        if (notUndefinedOrEmpty(defaultTranslationText)) {
            var defaultLocale = (_c = (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.languages) === null || _b === void 0 ? void 0 : _b.find(function (k) { return k === null || k === void 0 ? void 0 : k.is_base_language; })) === null || _c === void 0 ? void 0 : _c.language_code;
            data = updateAuto(data, { key: { translations: { $push: [{ codePk: null, full_locale: defaultLocale, projectPk: (_d = props.detailedProject) === null || _d === void 0 ? void 0 : _d.pk, origin_full_locale: defaultLocale, text: defaultTranslationText }] } } });
        }
        data = updateAuto(data, { key: { projectPk: { $set: (_e = props.detailedProject) === null || _e === void 0 ? void 0 : _e.pk } } });
        ApiRequests.Codes.post((_f = props.detailedProject) === null || _f === void 0 ? void 0 : _f.pk, data, function (response) {
            if (response.status == '200') {
                props.onChange(function (prev) {
                    var _a;
                    return updateAuto(prev, {
                        keys: {
                            $push: [
                                (_a = response.data) === null || _a === void 0 ? void 0 : _a.data
                            ]
                        }
                    });
                });
                setnewKey(defaultNewKey);
                props.setShow(false);
            }
            else {
                addToast(response.errors, 'danger');
            }
            setSavingNewKey(false);
            setDefaultTranslationText("");
        });
    };
    //(Allowed characters: A-Z 0-9._)'
    //limitation={{ regexAllow: '^[a-zA-Z0-9_.]+$' }} 
    return (React.createElement(Modal, { style: { maxWidth: '600px' }, show: props.show, onHide: function () { return props.setShow(false); } },
        React.createElement(Header2, null, "New key"),
        React.createElement(Input, { autofocus: true, style: { marginTop: '10px' }, label: 'Code (Can be a key or some text)', placeHolder: 'Enter your new code', value: newKey.code, onChange: function (p) { return setnewKey(function (prev) { return updateAuto(prev, { code: { $set: p } }); }); } }),
        React.createElement(Input, { type: 'input', style: { marginTop: '10px' }, placeHolder: 'Enter default translation for the new code', label: 'Default translation (' + languageCodeToName((_c = (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.languages) === null || _b === void 0 ? void 0 : _b.find(function (k) { return k === null || k === void 0 ? void 0 : k.is_base_language; })) === null || _c === void 0 ? void 0 : _c.language_code) + ')', value: defaultTranslationText, onChange: setDefaultTranslationText }),
        React.createElement(Input, { placeHolder: 'Enter any comment', type: 'textarea', style: { marginTop: '10px' }, label: 'Comment', value: newKey.comment, onChange: function (p) { return setnewKey(function (prev) { return updateAuto(prev, { comment: { $set: p } }); }); } }),
        React.createElement(Select, { label: 'Tags', options: (_e = (_d = props.detailedProject) === null || _d === void 0 ? void 0 : _d.tags) === null || _e === void 0 ? void 0 : _e.map(function (k) { return { value: k.pk, displayValue: k.name }; }), style: { marginTop: '10px' }, values: newKey === null || newKey === void 0 ? void 0 : newKey.tagIds, onChanges: function (d) { setnewKey(function (prev) { return updateAuto(prev, { tagIds: { $set: d } }); }); }, allowNewIfNotExists: true, allowMultipleValues: true, onClickNewOption: props.onAddAndSelectNewTag }),
        React.createElement(BoldLabel, { style: { marginTop: '5px' } }, "Settings"),
        React.createElement(CheckboxWithText, { checked: machineTranslateWhenAdded, onChange: setmachineTranslateWhenAdded },
            React.createElement(LabelWithInfoTooltip, { tooltip: 'Translate this translation to all existing languages in project.' }, "Machine translate when added")),
        React.createElement(CheckboxWithText, { style: { marginTop: '5px' }, checked: newKey === null || newKey === void 0 ? void 0 : newKey.allowPlurals, onChange: function (v) { return setnewKey(function (prev) { return updateAuto(prev, { allowPlurals: { $set: v } }); }); } },
            React.createElement(LabelWithInfoTooltip, { tooltip: 'If defined as plural key, multiple translations can be added depending on the counter. Different languages have different available plurals.' }, "This is a plural key")),
        React.createElement(FlexDiv, { style: { marginTop: '20px' } },
            React.createElement(Button, { type: 'blue', size: 'medium', onClick: onAddKey, disabled: savingNewKey }, savingNewKey ? React.createElement(LoadingDots, null) : 'Save'),
            React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: function () { setnewKey(defaultNewKey); props.setShow(false); }, disabled: savingNewKey }, "Cancel"))));
};
