import React, { useState } from 'react';
import { Button, FlexDiv, Header1, Header2, Input, Line, LoadingDots, P, ShowIf, useToast } from '../components/Components';
import { md5Encrypt, notUndefinedOrEmpty, undefinedOrEmpty, verifyEmail as verifyEmailPattern } from '../utils/Helpers';
import { ApiRequests } from '../requests/ApiRequests';
import { NavLink, Navigate } from 'react-router-dom';
import { useUserSettings } from '../components/ContextAndHooks';
import { LogoSvg } from '../website/WebsiteCompoents';
export var Signup = function () {
    var _a;
    var _b = useState('1'), name = _b[0], setName = _b[1];
    var _c = useState('1@1.com'), email = _c[0], setEmail = _c[1];
    var _d = useState('12345678'), password = _d[0], setPassword = _d[1];
    var _e = useState('12345678'), passwordVerify = _e[0], setpasswordVerify = _e[1];
    var _f = useState(false), nameTouched = _f[0], setNameTouched = _f[1];
    var _g = useState(false), emailTouched = _g[0], setEmailTouched = _g[1];
    var _h = useState(false), passwordTouched = _h[0], setPasswordTouched = _h[1];
    var _j = useState(false), passwordVerifyTouched = _j[0], setPasswordVerifyTouched = _j[1];
    var _k = useState(false), creatingAccount = _k[0], setCreatingAccount = _k[1];
    var _l = useState(), createdUser = _l[0], setCreatedUser = _l[1];
    var _m = useState(''), teamName = _m[0], setTeamName = _m[1];
    var _o = useState(), createdTeam = _o[0], setCreatedTeam = _o[1];
    var _p = useState(false), creatingTeam = _p[0], setCreatingTeam = _p[1];
    var _q = useUserSettings(), loggedInUser = _q[0], setLoggedInUser = _q[1], triggerFetchUser = _q[2];
    var addToast = useToast();
    var enforcePasswordRules = function (value) {
        if (value.length < 8) {
            return ('Password must be at least 8 characters long');
        }
        else {
            return ('');
        }
    };
    var verifyEmail = function (value) {
        return verifyEmailPattern(value) ? '' : 'Must be a valid email';
    };
    var verifyName = function (value) {
        return undefinedOrEmpty(value) ? 'Name is required' : '';
    };
    var onCreateAccount = function () {
        if (notUndefinedOrEmpty(verifyName(name)))
            return;
        if (notUndefinedOrEmpty(verifyEmail(email)))
            return;
        if (notUndefinedOrEmpty(enforcePasswordRules(password)))
            return;
        if (notUndefinedOrEmpty(password != passwordVerify && 'Passwords must be equal'))
            return;
        setCreatingAccount(true);
        ApiRequests.Signup.post({ email: email, name: name, password: md5Encrypt(password) }, function (response) {
            if (response.status == '200') {
                addToast('Account created', 'success');
                setCreatedUser(response.data);
            }
            else {
                addToast(response === null || response === void 0 ? void 0 : response.errors, 'danger');
            }
            setCreatingAccount(false);
        });
    };
    var onClickCreateTeam = function () {
        var currentUserId = notUndefinedOrEmpty(createdUser === null || createdUser === void 0 ? void 0 : createdUser.pk) ? createdUser === null || createdUser === void 0 ? void 0 : createdUser.pk : loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.pk;
        if (undefinedOrEmpty(currentUserId)) {
            addToast('No user to create team for', 'danger');
            return;
        }
        setCreatingTeam(true);
        ApiRequests.User.postTeamToUser(currentUserId, { name: teamName, members: [], pk: null }, function (response) {
            if (response.status == '200') {
                addToast('Team created', 'success');
                setCreatedTeam(response.data);
                triggerFetchUser();
            }
            else {
                addToast(response === null || response === void 0 ? void 0 : response.errors, 'danger');
            }
            setCreatingTeam(false);
        });
    };
    return (React.createElement(React.Fragment, null,
        notUndefinedOrEmpty(((_a = loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.teamAccess) === null || _a === void 0 ? void 0 : _a.length) > 0) && React.createElement(Navigate, { to: "/home" }),
        React.createElement(FlexDiv, { style: { width: '100vw', height: '100vh', justifyContent: 'center' } },
            React.createElement("div", { style: { width: '350px', margin: '+ auto' } },
                React.createElement(ShowIf, { style: { marginTop: '100px' }, show: undefinedOrEmpty(createdUser === null || createdUser === void 0 ? void 0 : createdUser.pk) },
                    React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } },
                        React.createElement(LogoSvg, null)),
                    React.createElement("div", { style: { display: 'flex', marginTop: '10px', justifyContent: 'center' } },
                        React.createElement(Header1, { black: true }, "Signup")),
                    React.createElement(NavLink, { to: '/login' },
                        React.createElement(Button, { type: 'white', size: 'small', style: { margin: '20px 0' } },
                            '<',
                            " Back to login page")),
                    React.createElement(Input, { onBlur: function () { return setNameTouched(true); }, showErrorMessage: nameTouched && verifyName(name), autofocus: true, style: { marginTop: '5px' }, label: 'Name', value: name, onChange: setName }),
                    React.createElement(Input, { onBlur: function () { return setEmailTouched(true); }, showErrorMessage: emailTouched && verifyEmail(email), style: { marginTop: '5px' }, label: 'Email', value: email, onChange: setEmail }),
                    React.createElement(Input, { onBlur: function () { return setPasswordTouched(true); }, inputType: 'password', showErrorMessage: (passwordTouched && enforcePasswordRules(password)) || (passwordTouched && passwordVerifyTouched && password != passwordVerify && 'Passwords must be equal'), style: { marginTop: '5px' }, label: 'Password', value: password, onChange: setPassword }),
                    React.createElement(Input, { onBlur: function () { return setPasswordVerifyTouched(true); }, inputType: 'password', showErrorMessage: (passwordTouched && passwordVerifyTouched && password != passwordVerify && 'Passwords must be equal'), style: { marginTop: '5px' }, label: 'Verify password', value: passwordVerify, onChange: setpasswordVerify }),
                    React.createElement(Button, { style: { marginTop: '10px' }, buttonCss: { width: '100%' }, onClick: onCreateAccount, disabled: creatingAccount }, createdUser ? React.createElement(LoadingDots, null) : 'Create account'),
                    React.createElement(FlexDiv, { style: { justifyContent: 'space-between' } })),
                React.createElement(ShowIf, { style: { marginTop: '50px' }, show: notUndefinedOrEmpty(createdUser === null || createdUser === void 0 ? void 0 : createdUser.pk) },
                    React.createElement("div", { style: { display: 'flex', marginTop: '10px', justifyContent: 'center' } },
                        React.createElement(Header1, { black: true }, "Awesome, your account has been created!")),
                    React.createElement(P, null, "Please check your email for a confirmation link."),
                    React.createElement(Line, null),
                    React.createElement(Header2, { black: true, style: { marginTop: '20px' } }, "Next, let's create your Team!"),
                    React.createElement(Input, { style: { marginTop: '20px' }, placeHolder: 'Enter the name of your Team', value: teamName, onChange: setTeamName, label: 'Team name' }),
                    React.createElement(Button, { style: { marginTop: '10px' }, buttonCss: { width: '100%' }, type: 'blue', size: 'large', onClick: onClickCreateTeam, disabled: creatingTeam }, createdTeam ? React.createElement(LoadingDots, null) : 'Create Team'),
                    React.createElement(NavLink, { to: '/home' },
                        React.createElement(Button, { style: { marginTop: '10px' }, buttonCss: { width: 'auto' }, type: 'underline', size: 'small' }, "Skip this, I want to join another team")))))));
};
