var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Button, CheckboxWithText, FlexDiv, Header2, Input, ShowIf } from '@/src/components/Components';
import { ApiRequests } from '@/src/requests/ExternalApiRequests';
import { undefinedOrEmpty, updateAuto } from '@/src/utils/Helpers';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
//@ts-ignore
import style from './reactComponent.module.scss';
import { fetchAPI } from './ajax';
import { useKeystrokeLogger } from '../utils/CustomHooks';
var TranslationContext = createContext(null);
export function useTranslation() {
    var state = useContext(TranslationContext);
    var t = useMemo(function () { return state; }, [state]);
    return t;
}
export var ReactTranslatorWrapper = function (props) {
    var _a, _b, _c, _d;
    var _e = useState(props.defaultLocale), currentLocale = _e[0], setCurrentLocale = _e[1];
    var _f = useState(false), initiated = _f[0], setInitiated = _f[1];
    var _g = useState(false), controlButtonDown = _g[0], setControlButtonDown = _g[1];
    var _h = useState([]), currentTranslations = _h[0], setCurrentTranslations = _h[1];
    var _j = useState(''), currentTextToEdit = _j[0], setCurrentTextToEdit = _j[1];
    var _k = useState(false), isLoggedIn = _k[0], setIsLoggedIn = _k[1];
    var _l = useState(''), isLoggedInAsEmail = _l[0], setIsLoggedInAsEmail = _l[1];
    var _m = useState(false), checkingLoggedIn = _m[0], setCheckingLoggedIn = _m[1];
    var _o = useState(false), editMode = _o[0], setEditMode = _o[1];
    var _p = useState(false), editModeExpanded = _p[0], setEditModeExpanded = _p[1];
    var _q = useState(), detailedProject = _q[0], setDetailedProject = _q[1];
    useEffect(function () {
        var _a;
        var debugVariablePresent = false;
        //Check if it contains reacttranslator, contains, but can be empty
        if (((_a = extractAllQueryParamsFromUrl(window.location.href)) === null || _a === void 0 ? void 0 : _a['reacttranslator']) != null) {
            debugVariablePresent = true;
        }
        setEditMode(debugVariablePresent);
    }, [window.location.href]);
    var extractAllQueryParamsFromUrl = function (url) {
        var queryParamsObject = {};
        // Extracting the query string from the URL
        var queryString = url.split('?')[1];
        // Checking if there is a query string
        if (queryString) {
            // Splitting the query string into individual key-value pairs
            var queryParams = queryString.split('&');
            // Iterating through each key-value pair and populating the queryParamsObject
            queryParams.forEach(function (param) {
                var _a = param.split('='), key = _a[0], value = _a[1];
                queryParamsObject[key] = value || 'present';
            });
        }
        return queryParamsObject;
    };
    useEffect(function () {
        if (editMode && isLoggedIn) {
            fetchFullDetailedProject();
        }
    }, [editMode, isLoggedIn]);
    var fetchFullDetailedProject = function () {
        ApiRequests.Project.get(props.translayConiguration.translayProjectId, function (response) {
            if (response.status == '200' || response.status == '304') {
                setDetailedProject(response.data);
            }
        });
    };
    //Listen for when holding down CTRL on keyboard
    useEffect(function () {
        var handleKeyDown = function (event) {
            if (event.key == 'Control') {
                setControlButtonDown(true);
            }
        };
        var handleKeyUp = function (event) {
            if (event.key == 'Control') {
                setControlButtonDown(false);
            }
        };
        if (editMode) {
            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('keyup', handleKeyUp);
            if (!isLoggedIn)
                checkIfLoggedInToProject();
        }
        else {
            setControlButtonDown(false);
        }
        return function () {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [editMode]);
    useEffect(function () {
        atStartup();
    }, []);
    var atStartup = function () {
        loadTranslationDynamicFromFile(currentLocale);
    };
    useEffect(function () {
        if (notUndefinedOrEmpty(currentTextToEdit) && !isLoggedIn) {
            checkIfLoggedInToProject();
        }
    }, [currentTextToEdit]);
    var checkIfLoggedInToProject = function (increment) {
        setCheckingLoggedIn(true);
        fetchAPI('GET', 'api/v1/loggedin', {}, function (response) {
            var _a, _b;
            var isLoggedInResponse = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.loggedIn;
            setIsLoggedIn(isLoggedInResponse);
            setIsLoggedInAsEmail((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.email);
            setCheckingLoggedIn(false);
            if (!isLoggedInResponse && (increment || 0) < 15) {
                setTimeout(function () { return checkIfLoggedInToProject(((increment || 0) + 1)); }, 5000);
            }
        });
    };
    var translate = useCallback(function (key, onlyReturnText) {
        var _a, _b, _c;
        if (undefinedOrEmpty(key)) {
            return '';
        }
        //Find by key in currentTranslations
        var index = currentTranslations === null || currentTranslations === void 0 ? void 0 : currentTranslations.findIndex(function (x) { return (x === null || x === void 0 ? void 0 : x.key) == key; });
        var text = null;
        if (index < 0) {
            text = key + '_' + currentLocale;
        }
        else {
            text = (_a = currentTranslations === null || currentTranslations === void 0 ? void 0 : currentTranslations[index]) === null || _a === void 0 ? void 0 : _a.value;
        }
        //Return the translation from the full API instead
        if (isLoggedIn && editMode && notUndefinedOrEmpty(detailedProject === null || detailedProject === void 0 ? void 0 : detailedProject.id)) {
            var keyFromDetailedProject_1 = (_b = detailedProject === null || detailedProject === void 0 ? void 0 : detailedProject.keys) === null || _b === void 0 ? void 0 : _b.find(function (x) { return x.code == key; });
            if (notUndefinedOrEmpty(keyFromDetailedProject_1 === null || keyFromDetailedProject_1 === void 0 ? void 0 : keyFromDetailedProject_1.id)) {
                var translation = (_c = keyFromDetailedProject_1 === null || keyFromDetailedProject_1 === void 0 ? void 0 : keyFromDetailedProject_1.translations) === null || _c === void 0 ? void 0 : _c.find(function (x) { return x.full_locale == currentLocale; });
                if (translation === null || translation === void 0 ? void 0 : translation.translated) {
                    text = translation === null || translation === void 0 ? void 0 : translation.text;
                }
                //If the key doesn't exist, crate it!
                else {
                    var newTranslation = { full_locale: currentLocale, text: keyFromDetailedProject_1 === null || keyFromDetailedProject_1 === void 0 ? void 0 : keyFromDetailedProject_1.code };
                    ApiRequests.Translations.post(keyFromDetailedProject_1 === null || keyFromDetailedProject_1 === void 0 ? void 0 : keyFromDetailedProject_1.id, newTranslation, function (response) {
                        if (response.status == '200') {
                            //Add the new translation to the detailed project
                            setDetailedProject(function (prev) {
                                var _a;
                                var _b, _c;
                                return updateAuto(prev, { keys: (_a = {}, _a[(_b = prev.keys) === null || _b === void 0 ? void 0 : _b.findIndex(function (x) { return (x === null || x === void 0 ? void 0 : x.id) == (keyFromDetailedProject_1 === null || keyFromDetailedProject_1 === void 0 ? void 0 : keyFromDetailedProject_1.id); })] = { translations: { $push: [(_c = response.data) === null || _c === void 0 ? void 0 : _c.data] } }, _a) });
                            });
                        }
                    });
                }
            }
            //Key doesnt exist, create it
            else {
                var newKey = { id: null, code: key, tagIds: [], translations: [{ languageCode: currentLocale, text: key }] };
                ApiRequests.Codes.post(props.translayConiguration.translayProjectId, newKey, function (response) {
                    if (response.status == '200') {
                        //Add the new key to the detailed project
                        setDetailedProject(function (prev) { var _a; return updateAuto(prev, { keys: { $push: [(_a = response.data) === null || _a === void 0 ? void 0 : _a.data] } }); });
                    }
                });
            }
        }
        if (controlButtonDown && !onlyReturnText) {
            return React.createElement("span", { onClick: function (e) { setCurrentTextToEdit(key === null || key === void 0 ? void 0 : key.valueOf()); e.stopPropagation(); e.preventDefault(); }, className: style.wrapControlText }, text);
        }
        return text;
    }, [currentTranslations, currentLocale, controlButtonDown, isLoggedIn, editMode, detailedProject]);
    var changeLocale = function (locale) {
        setCurrentLocale(locale);
        loadTranslationDynamicFromFile(locale);
    };
    var loadTranslationDynamicFromFile = function (locale) { return __awaiter(void 0, void 0, void 0, function () {
        var loaddedTranslations, resultArray, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, import('../locales/' + locale + '/messages.js')];
                case 1:
                    loaddedTranslations = _a.sent();
                    resultArray = Object.entries(loaddedTranslations === null || loaddedTranslations === void 0 ? void 0 : loaddedTranslations.messages).map(function (_a) {
                        var key = _a[0], value = _a[1];
                        return ({ key: key, value: value });
                    });
                    setCurrentTranslations(resultArray);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 3:
                    setInitiated(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var exitEditMode = function () {
        // Remove the reacttranslator from the hash
        var currentHash = window.location.hash;
        var updatedHash = currentHash.replace(/([&?])reacttranslator=[^&]+&?/, '$1');
        // Update the browser hash without reloading the page
        window.location.hash = updatedHash;
    };
    var onClickLoginExternal = function () {
        //Start timer to check for login
        if (!isLoggedIn) {
            setTimeout(function () { return checkIfLoggedInToProject(1); }, 3000);
        }
    };
    var findKeyFromSelectedCode = function (keyString) {
        var _a;
        var dtoKey = (_a = detailedProject === null || detailedProject === void 0 ? void 0 : detailedProject.keys) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.code == keyString; });
        return dtoKey;
    };
    var afterCreateCode = function (newCode) {
        //Add the new code to the detailed project
        setDetailedProject(function (p) { return updateAuto(p, { keys: { $push: [newCode] } }); });
    };
    var afterUpdateTranslation = function (code, translation) {
        //Update the translation in the detailed project
        setDetailedProject(function (prev) {
            var _a, _b, _c;
            var _d, _e, _f, _g;
            var keyIndex = (_d = prev === null || prev === void 0 ? void 0 : prev.keys) === null || _d === void 0 ? void 0 : _d.findIndex(function (x) { return x.id == (code === null || code === void 0 ? void 0 : code.id); });
            var translationIndex = (_g = (_f = (_e = prev === null || prev === void 0 ? void 0 : prev.keys) === null || _e === void 0 ? void 0 : _e[keyIndex]) === null || _f === void 0 ? void 0 : _f.translations) === null || _g === void 0 ? void 0 : _g.findIndex(function (x) { return x.languageCode == (translation === null || translation === void 0 ? void 0 : translation.languageCode); });
            if (translationIndex < 0) {
                return updateAuto(prev, { keys: (_a = {}, _a[keyIndex] = { translations: { $push: [translation] } }, _a) });
            }
            else {
                return updateAuto(prev, { keys: (_b = {}, _b[keyIndex] = { translations: (_c = {}, _c[translationIndex] = { $merge: translation }, _c) }, _b) });
            }
        });
        //Also make sure to update the current translations list
        setCurrentTranslations(function (prev) {
            var _a;
            var keyIndex = prev === null || prev === void 0 ? void 0 : prev.findIndex(function (x) { return x.key == (code === null || code === void 0 ? void 0 : code.code); });
            if (keyIndex < 0) {
                return updateAuto(prev, { $push: [{ key: code === null || code === void 0 ? void 0 : code.code, value: translation === null || translation === void 0 ? void 0 : translation.text }] });
            }
            else {
                return updateAuto(prev, (_a = {}, _a[keyIndex] = { $merge: { value: translation === null || translation === void 0 ? void 0 : translation.text } }, _a));
            }
        });
        setCurrentTextToEdit('');
    };
    return (React.createElement(TranslationContext.Provider, { value: { translate: translate, changeLocale: changeLocale, currentLocale: currentLocale } },
        React.createElement(ShowIf, { show: ((_a = props.loader) === null || _a === void 0 ? void 0 : _a.showLoaderWhenInitating) && !initiated }, (_b = props.loader) === null || _b === void 0 ? void 0 : _b.loaderComponent),
        React.createElement(ShowIf, { show: initiated || !((_c = props.loader) === null || _c === void 0 ? void 0 : _c.showLoaderWhenInitating) }, props.children),
        React.createElement(ShowIf, { show: editMode },
            React.createElement("div", { className: style.editModeWrapper },
                React.createElement("div", { style: { fontWeight: 'bold' } }, "Translation handler"),
                React.createElement(ShowIf, { show: editModeExpanded },
                    React.createElement(ShowIf, { show: isLoggedIn },
                        React.createElement("p", { style: { color: 'gray', maxWidth: '400px' } }, "You are in edit mode of translations. Hold down CTRL-key on the keyboard and click on a text to edit it."),
                        React.createElement("p", { style: { color: 'gray', maxWidth: '400px' } },
                            React.createElement("b", null, "Note: "),
                            "You are currently seeing and editing the most recent translations. This web page might have cached versions of the translations. Depending on your configuration and when you leave the edit mode, some translations might still have the old values. This is normal and will be updated when the cache is updated."),
                        React.createElement("div", { style: { fontWeight: 'bold' } }, "Change current locale"),
                        React.createElement("select", { style: { marginTop: '10px', padding: '5px', borderRadius: '5px' }, value: currentLocale, onChange: function (e) { return changeLocale(e.target.value); } }, (_d = props.availableLocales) === null || _d === void 0 ? void 0 : _d.map(function (x) { return React.createElement("option", { style: { borderRadius: '5px', padding: '10px' }, value: x }, x); })),
                        React.createElement("div", { style: { fontWeight: 'bold', marginTop: '20px' } }, "Logged in status"),
                        React.createElement("div", { style: { color: 'gray' } },
                            "You are logged in as: ",
                            isLoggedInAsEmail,
                            " ")),
                    React.createElement(ShowIf, { show: !isLoggedIn && !checkingLoggedIn },
                        React.createElement("div", { style: { fontWeight: 'bold', marginTop: '20px' } }, "Logged in status"),
                        React.createElement("div", { style: { color: 'gray', marginTop: '5px' } }, "You are not logged in to the project. Please login to edit translations."),
                        React.createElement("div", { style: { marginTop: '10px' } },
                            React.createElement("a", { style: { marginLeft: '10px' }, href: 'http://localhost:3000/#/login?loginfromreact=true', target: '_blank' },
                                React.createElement(Button, { size: 'small', onClick: function () { return onClickLoginExternal(); } }, "Login"))))),
                React.createElement(FlexDiv, { style: { marginTop: '5px', justifyContent: 'flex-end' } },
                    React.createElement(Button, { type: 'white', size: 'small', onClick: function () { setEditModeExpanded(function (p) { return !p; }); } }, editModeExpanded ? 'Deexpand' : 'Expand'),
                    React.createElement(Button, { type: 'redwhite', size: 'small', style: { marginLeft: '10px' }, onClick: function () { return exitEditMode(); } }, "Exit")))),
        React.createElement(CurrentlyEditingTextModal, { onAfterSaveTranslation: function (v) { return afterUpdateTranslation(findKeyFromSelectedCode(currentTextToEdit), v); }, projectid: props.translayConiguration.translayProjectId, afterCreateCode: afterCreateCode, dtoKey: findKeyFromSelectedCode(currentTextToEdit), currentLocale: currentLocale, checkingLoggedIn: checkingLoggedIn, isLoggedIn: isLoggedIn, code: currentTextToEdit, onHide: function () { return setCurrentTextToEdit(''); }, show: currentTextToEdit != '', valueBeforeEdit: translate(currentTextToEdit, true), afterUpdate: function (newText) { } })));
};
var CurrentlyEditingTextModal = function (props) {
    var _a = useState(props.valueBeforeEdit), value = _a[0], setValue = _a[1];
    var _b = useState(true), selectAsReviewed = _b[0], setSelectAsReviewed = _b[1];
    useEffect(function () {
        setValue(props.valueBeforeEdit);
    }, [props.valueBeforeEdit]);
    var needSave = useMemo(function () { return value != props.valueBeforeEdit; }, [value, props.valueBeforeEdit]);
    var checkIfSaved = function (codeid, languageCode, translation, callback, onError) {
        if (!notUndefinedOrEmpty(translation === null || translation === void 0 ? void 0 : translation.id)) {
            ApiRequests.Translations.post(codeid, updateAuto(translation, { $merge: { languageCode: languageCode, id: null, latestChangeAt: (new Date()).getTime(), latestChangeByName: 'Patrik' } }), function (response) {
                var _a;
                if (response.status == '200') {
                    callback((_a = response.data) === null || _a === void 0 ? void 0 : _a.data);
                }
                else {
                    onError(response.errors);
                }
            });
        }
        else {
            callback(translation);
        }
    };
    var onSave = function () {
        var _a, _b, _c, _d;
        if (!needSave)
            return;
        var keyExists = notUndefinedOrEmpty((_a = props.dtoKey) === null || _a === void 0 ? void 0 : _a.id);
        if (!keyExists) {
            //Create the code
            var newKey = { id: null, code: props.code, tagIds: [], translations: [{ languageCode: props.currentLocale, text: value }] };
            ApiRequests.Codes.post(props.projectid, newKey, function (response) {
                var _a;
                if (response.status == '200') {
                    props.afterCreateCode((_a = response.data) === null || _a === void 0 ? void 0 : _a.data);
                }
            });
        }
        //Update the translation
        var translationForCurrentLocale = (_c = (_b = props.dtoKey) === null || _b === void 0 ? void 0 : _b.translations) === null || _c === void 0 ? void 0 : _c.find(function (x) { return x.languageCode == props.currentLocale; });
        checkIfSaved((_d = props.dtoKey) === null || _d === void 0 ? void 0 : _d.id, props.currentLocale, updateAuto(translationForCurrentLocale, { $merge: { text: value, translated: notUndefinedOrEmpty(value) || (translationForCurrentLocale === null || translationForCurrentLocale === void 0 ? void 0 : translationForCurrentLocale.translated), reviewed: selectAsReviewed, latestChangeAt: (new Date()).getTime(), latestChangeByName: 'Patrik' } }), function (responseData) {
            ApiRequests.Translations.put(responseData, function (response) {
                var _a;
                if (response.status == '200') {
                    props.onAfterSaveTranslation((_a = response.data) === null || _a === void 0 ? void 0 : _a.data);
                }
            });
        });
    };
    var onCancel = function () {
        setValue(props.valueBeforeEdit);
        props.onHide();
    };
    var keypress = useKeystrokeLogger(true, { onEnter: onSave });
    return (React.createElement(React.Fragment, null,
        React.createElement(LocalModal, { onHide: props.onHide, show: props.show },
            React.createElement(Header2, { style: { fontWeight: '400' } },
                "Edit translation for ",
                React.createElement("b", null, props.currentLocale)),
            React.createElement(ShowIf, { show: props.checkingLoggedIn }, "Waiting for authentication check..."),
            React.createElement(ShowIf, { show: !props.checkingLoggedIn && props.isLoggedIn },
                React.createElement("div", { style: { fontWeight: 'bold', marginTop: '10px', color: 'gray' } }, "Key:"),
                React.createElement("div", { style: { fontWeight: '300', margin: '10px 0', color: 'gray' } }, props.code),
                React.createElement(Input, { type: 'textarea', autofocus: true, value: value, onChange: setValue }),
                React.createElement(CheckboxWithText, { style: { margin: '10px 0' }, checked: selectAsReviewed, onChange: setSelectAsReviewed }, "Select as reviwed"),
                React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                    React.createElement(Button, { disabled: !needSave, size: 'small', onClick: onSave }, "Save"),
                    React.createElement(Button, { size: 'small', style: { marginLeft: '10px' }, type: 'redwhite', onClick: onCancel }, "Cancel"))),
            React.createElement(ShowIf, { show: !props.checkingLoggedIn && !props.isLoggedIn },
                React.createElement("div", null, "You are not logged in to the project. Please login to edit translations."),
                React.createElement("div", { style: { marginTop: '10px' } },
                    React.createElement("a", { style: { marginLeft: '10px' }, href: 'http://localhost:3000/#/login?loginfromreact=true', target: '_blank' },
                        React.createElement(Button, { size: 'small' }, "Login")))))));
};
export var Translate = function (props) {
    var translate = useTranslation().translate;
    return (React.createElement(React.Fragment, null, translate(props.children)));
};
function nu(value) {
    var r = (typeof value !== 'undefined') && (value != null);
    return r;
}
function notUndefinedOrEmpty(value) {
    return (typeof value !== 'undefined') && (value != null) && value != '';
}
var LocalModal = function (props) {
    var getOuterClass = useMemo(function () {
        var classNames = [style.modalWrapper];
        if (!props.show)
            classNames.push(style.modalHidden);
        return classNames.join(' ');
    }, [props.show]);
    var getContentClass = useMemo(function () {
        var classNames = [style.modalContent];
        //Props className 
        if (nu(props.className))
            classNames.push(props.className);
        return classNames.join(' ');
    }, [props.className, props.show]);
    useEffect(function () {
        if (props.show && !props.doNotUseModal)
            document.body.classList.add('no-scroll');
        else if (nu(props.show))
            removeNoScroll();
    }, [props.show, props.doNotUseModal]);
    var removeNoScroll = function () {
        document.body.classList.remove('no-scroll');
    };
    var onClose = function () {
        props.onHide();
        removeNoScroll();
    };
    var onClickOutside = function () {
        if (!props.disableCloseOnClickOutside) {
            onClose();
        }
    };
    return (React.createElement(React.Fragment, null,
        props.show && props.doNotUseModal &&
            props.children,
        props.show && !props.doNotUseModal &&
            React.createElement(Portal, { domElement: document.body },
                React.createElement("div", { className: getOuterClass },
                    React.createElement("div", { style: props.style, className: getContentClass },
                        React.createElement(OutsideAlerterLocal, { onClickOutside: onClickOutside },
                            React.createElement("div", { style: { marginTop: '10px' } },
                                notUndefinedOrEmpty(props.titleHeader) &&
                                    React.createElement("div", { className: style.modalHeaderWrapper }, props.titleHeader),
                                React.createElement("div", { style: props.innerStyle, className: style.modalTextContent },
                                    props.showClose && React.createElement("div", { className: style.modalClose },
                                        React.createElement(Button, { onClick: onClose, size: "small", type: 'white' }, props.closeText)),
                                    props.children))))))));
};
var Portal = function (props) {
    return (ReactDOM.createPortal(props.children, props.domElement));
};
/**
* Hook that alerts clicks outside of the passed ref
*/
function useOutsideAlerter(ref, callback, debug) {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
        if (debug)
            console.log("mousedown event");
        if (ref.current && !ref.current.contains(event.target)) {
            callback();
        }
    }
    useEffect(function () {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
}
/**
* Component that alerts if you click outside of it
*/
function OutsideAlerterLocal(props) {
    var wrapperRef = useRef();
    useOutsideAlerter(wrapperRef, props.onClickOutside);
    return React.createElement("div", { style: props.style, ref: wrapperRef }, props.children);
}
