var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as JSZip from 'jszip';
import { fullLocaleToCountryCode, fullLocaleToLanguage, languageCodeToName } from "./LanguageHelpers";
import Types from '@/shared/Types';
export var ExportFiles;
(function (ExportFiles) {
    function exportAndDownloadFile(data) {
        switch (data.fileType) {
            case 'po':
                return generatePOFileContent(data).then(function (blob) {
                    downloadBlobToClient(blob, "".concat(data.detailedProject.name, ".zip"));
                });
            case 'java':
                return generateJavaPropertiesFileContent(data).then(function (blob) {
                    downloadBlobToClient(blob === null || blob === void 0 ? void 0 : blob.blob, blob === null || blob === void 0 ? void 0 : blob.fileName);
                });
            case 'json':
                return downloadJsonFile(data);
            case 'flutter':
                return downloadFlutterFile(data);
            case 'json_struct':
                return downloadJsonStructFile(data);
            case 'laravel_json':
                return downloadLaravelJsonFile(data);
            case 'php_array':
                return downloadPhpArrayFile(data);
            case 'ruby_on_rails':
                return downloadRubyOnRailsFile(data);
            default:
                console.error("Unsupported file extension: ".concat(data.fileType));
                return null;
        }
    }
    ExportFiles.exportAndDownloadFile = exportAndDownloadFile;
    function downloadJsonFile(data) {
        return JSON.stringify(data);
    }
    function downloadFlutterFile(data) {
        return JSON.stringify(data);
    }
    function downloadJsonStructFile(data) {
        return JSON.stringify(data);
    }
    function downloadLaravelJsonFile(data) {
        return JSON.stringify(data);
    }
    function downloadPhpArrayFile(data) {
        return JSON.stringify(data);
    }
    function downloadRubyOnRailsFile(data) {
        return JSON.stringify(data);
    }
    function generatePOFileContent(data) {
        return __awaiter(this, void 0, Promise, function () {
            var project, zip, translationsByLanguage, languageCode, poContent, zipContent;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        project = data.detailedProject;
                        zip = new JSZip();
                        translationsByLanguage = {};
                        project.keys.forEach(function (key) {
                            key.translations.forEach(function (translation) {
                                var languageCode = translation.full_locale;
                                var entry = "\n    #: ".concat(key.comment || '', "\n    msgid \"").concat(key.code.replace(/"/g, '\\"'), "\"\n    msgstr \"").concat(translation.text.replace(/"/g, '\\"'), "\"\n    ");
                                if (!translationsByLanguage[languageCode]) {
                                    translationsByLanguage[languageCode] = '';
                                }
                                translationsByLanguage[languageCode] += entry;
                            });
                        });
                        // Create a PO file for each language
                        for (languageCode in translationsByLanguage) {
                            poContent = translationsByLanguage[languageCode];
                            zip.file("".concat(languageCode, ".po"), poContent);
                        }
                        return [4 /*yield*/, zip.generateAsync({ type: 'blob' })];
                    case 1:
                        zipContent = _a.sent();
                        return [2 /*return*/, zipContent];
                }
            });
        });
    }
    function generateJavaPropertiesFileContent(data) {
        var _a, _b, _c;
        return __awaiter(this, void 0, Promise, function () {
            var translationsByLanguage;
            return __generator(this, function (_d) {
                translationsByLanguage = [];
                (_b = (_a = data.detailedProject) === null || _a === void 0 ? void 0 : _a.keys) === null || _b === void 0 ? void 0 : _b.forEach(function (key) {
                    var _a, _b, _c;
                    //@ts-ignore
                    var languages = ((_a = data.languagesToExport) === null || _a === void 0 ? void 0 : _a[0]) == 'all' ? (_c = (_b = data === null || data === void 0 ? void 0 : data.detailedProject) === null || _b === void 0 ? void 0 : _b.languages) === null || _c === void 0 ? void 0 : _c.map(function (t) { return t.language_code; }) : data.languagesToExport;
                    languages === null || languages === void 0 ? void 0 : languages.forEach(function (languageCode) {
                        var _a;
                        var translation = key.translations.find(function (t) { return t.full_locale == languageCode; }) || { text: '', languageCode: languageCode };
                        //File name should be named according to data.fileStructure values-%LANG_ISO%/strings.%FORMAT%.
                        //If slash, that means a new folder should be used in the zip file
                        var fileName = ExportFiles.convertFileStructure({
                            projectName: (_a = data.detailedProject) === null || _a === void 0 ? void 0 : _a.name,
                            selectedFileFormat: data.fileType,
                            locale: languageCode,
                            fileStructure: data.fileStructure
                        });
                        //  const fileName = `${languageCode}.properties`;
                        //Each line
                        var line = "".concat(key.code, "=").concat(translation.text.replace(/[\n\r]/g, '\\n'));
                        var lineAndComment = "# ".concat(key.comment || '', "\n").concat(line, "\n");
                        console.log(JSON.stringify(translationsByLanguage));
                        translationsByLanguage[findIndexOrElse(translationsByLanguage, function (t) { return (t === null || t === void 0 ? void 0 : t.fileName) == fileName; }, { fileName: fileName, content: '' })].content += lineAndComment;
                        console.log(JSON.stringify(translationsByLanguage));
                    });
                });
                return [2 /*return*/, zipContentIfRequired((_c = data.detailedProject) === null || _c === void 0 ? void 0 : _c.name, translationsByLanguage)];
            });
        });
    }
    ExportFiles.convertFileStructure = function (d) {
        //Replace %FORMAT% with selected file format
        //Replace %LANG_ISO% with language
        //Replace %LANG_NAME% with language name
        //Replace %PROJECT_NAME% with project name
        //Replace %COUNTRY_ISO% with country iso
        var _a, _b;
        var countryIso = fullLocaleToCountryCode(d === null || d === void 0 ? void 0 : d.locale);
        var langName = languageCodeToName(d === null || d === void 0 ? void 0 : d.locale);
        var langIso = fullLocaleToLanguage(d === null || d === void 0 ? void 0 : d.locale);
        var tmp = d === null || d === void 0 ? void 0 : d.fileStructure;
        tmp = tmp.replace('%FORMAT%', (_b = (_a = Types.FileTypeExports) === null || _a === void 0 ? void 0 : _a.find(function (k) { return (k === null || k === void 0 ? void 0 : k.value) === (d === null || d === void 0 ? void 0 : d.selectedFileFormat); })) === null || _b === void 0 ? void 0 : _b.extension);
        tmp = tmp.replace('%LANG_ISO%', langIso);
        tmp = tmp.replace('%LANG_NAME%', langName);
        tmp = tmp.replace('%PROJECT_NAME%', d === null || d === void 0 ? void 0 : d.projectName);
        tmp = tmp.replace('%COUNTRY_ISO%', countryIso);
        return tmp;
    };
})(ExportFiles || (ExportFiles = {}));
/* Helpers below */
var findIndexOrElse = function (array, predicate, defaultValue) {
    var index = array.findIndex(predicate);
    if (index !== -1) {
        // If the item is found, modify the original item and return it.
        array[index] = Object.assign(array[index], defaultValue);
        return index;
    }
    else {
        // If the item is not found, push the defaultValue into the array and return it.
        array.push(defaultValue);
        return (array === null || array === void 0 ? void 0 : array.length) - 1;
    }
};
var zipContentIfRequired = function (nameOfZipFile, data) { return __awaiter(void 0, void 0, Promise, function () {
    var zip, zipContent;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                zip = new JSZip();
                data.forEach(function (d) {
                    zip.file("".concat(d.fileName), d.content);
                });
                return [4 /*yield*/, zip.generateAsync({ type: 'blob' })];
            case 1:
                zipContent = _a.sent();
                return [2 /*return*/, { blob: zipContent, fileName: "".concat(nameOfZipFile, ".zip") }];
        }
    });
}); };
function downloadBlobToClient(blob, fileName) {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
}
function generateBlob(content, fileName) {
    // Your logic for initiating file download to client
    var blob = new Blob([content], { type: 'text/plain' });
    return blob;
}
