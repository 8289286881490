import update, { extend } from 'immutability-helper';
import React, { useEffect, useState, useRef } from 'react';
import { format as formatDateFns, parse as parseDateFns } from 'date-fns';
export var md5Encrypt = function (value) {
    var md5 = require('md5');
    return md5(value);
    return value;
};
export var compareEqalOr = function (v, compare) {
    var anyEqual = false;
    compare === null || compare === void 0 ? void 0 : compare.forEach(function (d, i) { if (d == v) {
        anyEqual = true;
    } });
    return anyEqual;
};
export var verifyEmail = function (email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
};
export var absoluteToRelaitveUrl = function (url) {
    try {
        var tmp = url.replace(/^[^#]*?:\/\/.*?(\/.*)$/, '$1');
        return tmp;
    }
    catch (e) {
        console.log(e);
    }
    return url;
};
export var stopPropagation = function (e) {
    e.preventDefault();
    e.stopPropagation();
    console.log("stop it!");
};
//Converts timestamp to format HH:mm
export var convertHM = function (valueInSeconds) {
    var sec = parseInt(valueInSeconds, 10); // convert value to number if it's string
    var hours = Math.floor(sec / 3600); // get hours
    var minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    var seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return hours + ':' + minutes; // Return is HH : MM 
};
export var convertHMS = function (value) {
    var sec = parseInt(value, 10); // convert value to number if it's string
    var hours = Math.floor(sec / 3600); // get hours
    var minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    var seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
};
export var copyToClipboard = function (value) {
    var element = document.getElementById('hidden_copy');
    //@ts-ignore
    element.value = value;
    element.style.display = "block";
    /* Select the text field */
    //@ts-ignore
    element.select();
    //@ts-ignore
    element.setSelectionRange(0, 99999); /*For mobile devices*/
    /* Copy the text inside the text field */
    //@ts-ignore
    document.execCommand("copy");
    /* Alert the copied text */
    //@ts-ignore
    element.style.display = "none";
};
/* Combines React useState and useRef to be accessed in callback functions that have to use the ref rather than the object */
export function useStateRef(initialState) {
    var _a = useState(initialState), myState = _a[0], setMyState = _a[1];
    var myStateRef = useRef(myState);
    useEffect(function () {
        myStateRef.current = myState;
    }, [myState]);
    return [myState, setMyState, myStateRef];
}
export var validateEmail = function (email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};
export var isFunction = function (functionToCheck) {
    if (typeof functionToCheck === "function") {
        return true;
    }
    return false;
};
export var stringify = function (v) {
    try {
        return JSON.stringify(v).replace(/["']/g, "");
    }
    catch (e) {
        return v;
    }
};
//Not undefined
export function nu(value) {
    var r = (typeof value !== 'undefined') && (value != null);
    return r;
}
export var notMinusOne = function (v) {
    return (v != '-1' && v != -1);
};
export function emptyStringIfNull(value) {
    return nu(value) ? value : '';
}
export function zeroIfNull(value) {
    return nu(value) ? value : 0;
}
export function notUndefinedOrEmpty(value) {
    return (typeof value !== 'undefined') && (value != null) && value != '';
}
export function undefinedOrEmpty(value) {
    return !notUndefinedOrEmpty(value);
}
export function getNestedValue(obj, path) {
    var keys = path.split('.'); // Split the path into separate keys
    // Traverse the object recursively
    var currentObj = obj;
    for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
        var key = keys_1[_i];
        if (currentObj && currentObj.hasOwnProperty(key)) {
            currentObj = currentObj[key];
        }
        else {
            return undefined; // Return undefined if the path is invalid
        }
    }
    return currentObj; // Return the final nested value
}
//Autovivification of objects
extend('$auto', function (value, object) {
    return object ?
        update(object, value) :
        update({}, value);
});
//@ts-ignore
export var updateAuto = function (object, spec) {
    var t = applyAutoToObject(spec, true);
    return update(object ? object : {}, t);
};
//Helper that adds custom commando $auto in between attributes. Autovivification of objects
var applyAutoToObject = function (object, isFirst) {
    var _a;
    if (isFunction(object)) {
        return object;
    }
    if (Array.isArray(object)) {
        // Handle arrays by applying auto to each element
        return object.map(function (item) { return applyAutoToObject(item, false); });
    }
    var properties = {};
    for (var prop in object) {
        if (Object.prototype.hasOwnProperty.call(object, prop)) {
            if (prop.includes('$')) {
                properties[prop] = object[prop];
            }
            else {
                if (!isFirst) {
                    properties['$auto'] = (_a = {}, _a[prop] = applyAutoToObject(object[prop], false), _a);
                }
                else {
                    properties[prop] = applyAutoToObject(object[prop], false);
                }
            }
        }
    }
    return properties;
};
//	export function setValueFunc<T extends { [key: string]: any }, P extends Paths<T, 2>>(setter: Dispatch<SetStateAction<T>>, objectOrFunction: ((arg1: PathValue<T, P>) => PathValue<T, P> )| PathValue<T, P>, path: P): void {
export function setValueFunc(setter, objectOrFunction, path) {
    //export function setValueFunc(setter,objectOrFunction,path) {
    //export function setValueFunc(setter: any, path: any, objectOrFunction: any): void {
    if (typeof path === "string") {
        //@ts-ignore
        var pList_1 = path.split(".");
        if (isFunction(objectOrFunction)) {
            if (pList_1.length == 1)
                //@ts-ignore
                setter(function (prev) {
                    var _a;
                    return updateAuto(prev, (_a = {}, _a[pList_1[0]] = { $apply: objectOrFunction }, _a));
                });
            if (pList_1.length == 2)
                //@ts-ignore
                setter(function (prev) {
                    var _a, _b;
                    return updateAuto(prev, (_a = {}, _a[pList_1[0]] = (_b = {}, _b[pList_1[1]] = { $apply: objectOrFunction }, _b), _a));
                });
            if (pList_1.length == 3)
                //@ts-ignore	
                setter(function (prev) {
                    var _a, _b, _c;
                    return updateAuto(prev, (_a = {}, _a[pList_1[0]] = (_b = {}, _b[pList_1[1]] = (_c = {}, _c[pList_1[2]] = { $apply: objectOrFunction }, _c), _b), _a));
                });
            if (pList_1.length == 4)
                //@ts-ignore
                setter(function (prev) {
                    var _a, _b, _c, _d;
                    return updateAuto(prev, (_a = {}, _a[pList_1[0]] = (_b = {}, _b[pList_1[1]] = (_c = {}, _c[pList_1[1]] = (_d = {}, _d[pList_1[3]] = { $apply: objectOrFunction }, _d), _c), _b), _a));
                });
            if (pList_1.length == 5)
                //@ts-ignore
                setter(function (prev) {
                    var _a, _b, _c, _d, _e;
                    return updateAuto(prev, (_a = {}, _a[pList_1[0]] = (_b = {}, _b[pList_1[1]] = (_c = {}, _c[pList_1[1]] = (_d = {}, _d[pList_1[1]] = (_e = {}, _e[pList_1[4]] = { $apply: objectOrFunction }, _e), _d), _c), _b), _a));
                });
            if (pList_1.length == 6)
                //@ts-ignore
                setter(function (prev) {
                    var _a, _b, _c, _d, _e, _f;
                    return updateAuto(prev, (_a = {}, _a[pList_1[0]] = (_b = {}, _b[pList_1[1]] = (_c = {}, _c[pList_1[1]] = (_d = {}, _d[pList_1[1]] = (_e = {}, _e[pList_1[1]] = (_f = {}, _f[pList_1[5]] = { $apply: objectOrFunction }, _f), _e), _d), _c), _b), _a));
                });
            if (pList_1.length == 7)
                //@ts-ignore
                setter(function (prev) {
                    var _a, _b, _c, _d, _e, _f, _g;
                    return updateAuto(prev, (_a = {}, _a[pList_1[0]] = (_b = {}, _b[pList_1[1]] = (_c = {}, _c[pList_1[1]] = (_d = {}, _d[pList_1[1]] = (_e = {}, _e[pList_1[1]] = (_f = {}, _f[pList_1[1]] = (_g = {}, _g[pList_1[6]] = { $apply: objectOrFunction }, _g), _f), _e), _d), _c), _b), _a));
                });
        }
        else {
            if (pList_1.length == 1)
                //@ts-ignore
                setter(function (prev) {
                    var _a;
                    return updateAuto(prev, (_a = {}, _a[pList_1[0]] = { $set: objectOrFunction }, _a));
                });
            if (pList_1.length == 2)
                //@ts-ignore
                setter(function (prev) {
                    var _a, _b;
                    return updateAuto(prev, (_a = {}, _a[pList_1[0]] = (_b = {}, _b[pList_1[1]] = { $set: objectOrFunction }, _b), _a));
                });
            if (pList_1.length == 3)
                //@ts-ignore
                setter(function (prev) {
                    var _a, _b, _c;
                    return updateAuto(prev, (_a = {}, _a[pList_1[0]] = (_b = {}, _b[pList_1[1]] = (_c = {}, _c[pList_1[2]] = { $set: objectOrFunction }, _c), _b), _a));
                });
            if (pList_1.length == 4)
                //@ts-ignore
                setter(function (prev) {
                    var _a, _b, _c, _d;
                    return updateAuto(prev, (_a = {}, _a[pList_1[0]] = (_b = {}, _b[pList_1[1]] = (_c = {}, _c[pList_1[1]] = (_d = {}, _d[pList_1[3]] = { $set: objectOrFunction }, _d), _c), _b), _a));
                });
            if (pList_1.length == 5)
                //@ts-ignore
                setter(function (prev) {
                    var _a, _b, _c, _d, _e;
                    return updateAuto(prev, (_a = {}, _a[pList_1[0]] = (_b = {}, _b[pList_1[1]] = (_c = {}, _c[pList_1[1]] = (_d = {}, _d[pList_1[1]] = (_e = {}, _e[pList_1[4]] = { $set: objectOrFunction }, _e), _d), _c), _b), _a));
                });
            if (pList_1.length == 6)
                //@ts-ignore
                setter(function (prev) {
                    var _a, _b, _c, _d, _e, _f;
                    return updateAuto(prev, (_a = {}, _a[pList_1[0]] = (_b = {}, _b[pList_1[1]] = (_c = {}, _c[pList_1[1]] = (_d = {}, _d[pList_1[1]] = (_e = {}, _e[pList_1[1]] = (_f = {}, _f[pList_1[5]] = { $set: objectOrFunction }, _f), _e), _d), _c), _b), _a));
                });
            if (pList_1.length == 7)
                //@ts-ignore
                setter(function (prev) {
                    var _a, _b, _c, _d, _e, _f, _g;
                    return updateAuto(prev, (_a = {}, _a[pList_1[0]] = (_b = {}, _b[pList_1[1]] = (_c = {}, _c[pList_1[1]] = (_d = {}, _d[pList_1[1]] = (_e = {}, _e[pList_1[1]] = (_f = {}, _f[pList_1[1]] = (_g = {}, _g[pList_1[6]] = { $set: objectOrFunction }, _g), _f), _e), _d), _c), _b), _a));
                });
        }
    }
}
export var propagateUpdate = function (objectOrFunction, nestedAttribute, secondAttribute, thirdAttribute, fourthAttribute, fithAttribute) {
    if (secondAttribute === void 0) { secondAttribute = null; }
    if (thirdAttribute === void 0) { thirdAttribute = null; }
    if (fourthAttribute === void 0) { fourthAttribute = null; }
    if (fithAttribute === void 0) { fithAttribute = null; }
    //If five nested attributes
    if (fithAttribute != null) {
        if (isFunction(objectOrFunction)) {
            var func = function (prevState) {
                var _a, _b, _c, _d, _e;
                return updateAuto(prevState, (_a = {},
                    _a[nestedAttribute] = (_b = {}, _b[secondAttribute] = (_c = {}, _c[thirdAttribute] = (_d = {}, _d[fourthAttribute] = (_e = {}, _e[fithAttribute] = { $apply: objectOrFunction }, _e), _d), _c), _b),
                    _a));
            };
            return func;
        }
        else {
            var func = function (prevState) {
                var _a, _b, _c, _d, _e;
                return updateAuto(prevState, (_a = {},
                    _a[nestedAttribute] = (_b = {}, _b[secondAttribute] = (_c = {}, _c[thirdAttribute] = (_d = {}, _d[fourthAttribute] = (_e = {}, _e[fithAttribute] = { $set: objectOrFunction }, _e), _d), _c), _b),
                    _a));
            };
            return func;
        }
    }
    //If four nested attributes
    if (fourthAttribute != null) {
        if (isFunction(objectOrFunction)) {
            var func = function (prevState) {
                var _a, _b, _c, _d;
                return updateAuto(prevState, (_a = {},
                    _a[nestedAttribute] = (_b = {}, _b[secondAttribute] = (_c = {}, _c[thirdAttribute] = (_d = {}, _d[fourthAttribute] = { $apply: objectOrFunction }, _d), _c), _b),
                    _a));
            };
            return func;
        }
        else {
            var func = function (prevState) {
                var _a, _b, _c, _d;
                return updateAuto(prevState, (_a = {},
                    _a[nestedAttribute] = (_b = {}, _b[secondAttribute] = (_c = {}, _c[thirdAttribute] = (_d = {}, _d[fourthAttribute] = { $set: objectOrFunction }, _d), _c), _b),
                    _a));
            };
            return func;
        }
    }
    //If three nested attributes
    if (thirdAttribute != null) {
        if (isFunction(objectOrFunction)) {
            var func = function (prevState) {
                var _a, _b, _c;
                return updateAuto(prevState, (_a = {},
                    _a[nestedAttribute] = (_b = {}, _b[secondAttribute] = (_c = {}, _c[thirdAttribute] = { $apply: objectOrFunction }, _c), _b),
                    _a));
            };
            return func;
        }
        else {
            var func = function (prevState) {
                var _a, _b, _c;
                return updateAuto(prevState, (_a = {},
                    _a[nestedAttribute] = (_b = {}, _b[secondAttribute] = (_c = {}, _c[thirdAttribute] = { $set: objectOrFunction }, _c), _b),
                    _a));
            };
            return func;
        }
    }
    //If two nested attributes
    if (secondAttribute != null) {
        if (isFunction(objectOrFunction)) {
            var func = function (prevState) {
                var _a, _b;
                return updateAuto(prevState, (_a = {},
                    _a[nestedAttribute] = (_b = {}, _b[secondAttribute] = { $apply: objectOrFunction }, _b),
                    _a));
            };
            return func;
        }
        else {
            var func = function (prevState) {
                var _a, _b;
                return updateAuto(prevState, (_a = {},
                    _a[nestedAttribute] = (_b = {}, _b[secondAttribute] = { $set: objectOrFunction }, _b),
                    _a));
            };
            return func;
        }
    }
    if (isFunction(objectOrFunction)) {
        var func = function (prevState) {
            var _a;
            return updateAuto(prevState, (_a = {},
                _a[nestedAttribute] = { $apply: objectOrFunction },
                _a));
        };
        return func;
    }
    else {
        var func = function (prevState) {
            var _a;
            return updateAuto(prevState, (_a = {},
                _a[nestedAttribute] = { $set: objectOrFunction },
                _a));
        };
        return func;
    }
};
// This always returns a string, but if I set the return type, I have to refactor a lot. Decided not to do that now. /BL
export var numberWithSpace = function (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
export var parseNumber = function (x) {
    var number = Number.parseFloat(x.toString());
    if (isNaN(number))
        return 0;
    return number;
};
// This always returns a string, but if I set the return type, I have to refactor a lot. Decided not to do that now. /BL
export var formatPrice = function (x, numberOfDecimals) {
    if (numberOfDecimals === void 0) { numberOfDecimals = 0; }
    try {
        if (x == '-')
            return x;
        var number = Number.parseFloat(x.toString());
        if (isNaN(number))
            return '';
        var price = numberWithSpace(Number.parseFloat(x.toString()).toFixed(numberOfDecimals));
        return price;
    }
    catch (e) {
        return '';
    }
};
export var parseDateFromFormat = function (dateString, format) {
    try {
        if (undefinedOrEmpty(dateString))
            return null;
        return parseDateFns(dateString, format, new Date());
    }
    catch (e) {
    }
    return null;
};
export var formatDateFromFormat = function (dateOrTimestamp, format, defaultReturn) {
    try {
        if (undefinedOrEmpty(dateOrTimestamp))
            return '';
        var date = (dateOrTimestamp instanceof Date) ? (dateOrTimestamp) : (new Date(dateOrTimestamp));
        return formatDateFns(date, format);
    }
    catch (e) {
    }
    return nu(defaultReturn) ? defaultReturn : '';
};
export var isValidDate = function (d) {
    //@ts-ignore
    return d instanceof Date && !isNaN(d);
};
export var deepFind = function (obj, pathString) {
    try {
        stringToPath(pathString).forEach(function (path, i) {
            obj = obj[path];
        });
        /*	for (var i = 0, path = pathString.split('.'), len = path.length; i < len; i++) {
                obj = obj[path[i]];
                
            };*/
        return obj;
    }
    catch (e) { }
    return '';
};
export var setValueAutoFromPath = function (value, path) {
    return function (v) { return updateAuto(v, appendVal(stringToPath(path), value)); };
};
export var unsesetValueAutoFromPath = function (path) {
    return function (v) { return updateAuto(v, appendVal(stringToPath(path), '', true)); };
};
var appendVal = function (keys, value, unset) {
    var key = keys[0], rest = keys.slice(1);
    //Where no keys are present
    if (!nu(key)) {
        return { $set: value };
    }
    var object = {};
    if (keys.length > 1) {
        object[key] = appendVal(rest, value, unset);
    }
    else {
        if (unset)
            object = { $unset: [key] };
        else
            object[key] = { $set: value };
    }
    return object;
};
var stringToPath = function (path) {
    // If the path isn't a string, return it
    if (typeof path !== 'string')
        return path;
    // Create new array
    var output = [];
    // Split to an array with dot notation
    path.split('.').forEach(function (item, index) {
        // Split to an array with bracket notation
        item.split(/\[([^}]+)\]/g).forEach(function (key) {
            // Push to the new array
            if (key.length > 0) {
                output.push(key);
            }
        });
    });
    return output;
};
export function capitalizeFirstLetter(string) {
    try {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    catch (e) {
        return string;
    }
}
export function formatDateSimple(value) {
    if (!notUndefinedOrEmpty(value))
        return '-';
    var date = (value instanceof Date) ? (value) : (new Date(value));
    return zeroPad(date.getDate()) + "/" + zeroPad(date.getMonth() + 1);
}
export function formatDateFull(value) {
    if (!notUndefinedOrEmpty(value))
        return '-';
    var date = (value instanceof Date) ? (value) : (new Date(value));
    return date.getFullYear() + '-' + zeroPad(date.getMonth() + 1) + "-" + zeroPad(date.getDate());
}
//Returns a date on yyyyMMdd
export function dateToStringForSage(value) {
    if (!notUndefinedOrEmpty(value))
        return '';
    return value.getFullYear() + '' + zeroPad(value.getMonth() + 1) + "" + zeroPad(value.getDate());
}
//Assumes a date on yyyyMMdd
export function stringToDateFromSage(value) {
    if (!notUndefinedOrEmpty(value))
        return null;
    //Assume yyyyMMdd, try to parse
    try {
        var y = value.substr(0, 4);
        var m = value.substr(4, 2);
        var dd = value.substr(6, 2);
        var newDate = y + '-' + m + '-' + dd;
        return new Date(newDate);
    }
    catch (e) {
        return null;
    }
}
export function formatDateDetail(value) {
    if (!notUndefinedOrEmpty(value))
        return '-';
    var date = (value instanceof Date) ? (value) : (new Date(value));
    return date.getFullYear() + "-" + zeroPad(date.getMonth() + 1) + "-" + zeroPad(date.getDate()) + ' ' + zeroPad(date.getHours()) + ':' + zeroPad(date.getMinutes());
}
function zeroPad(value) {
    return (value > 9 ? '' : '0') + value;
}
export var concatTwoString = function (str1, str2) {
    var returnVar = '';
    if (notUndefinedOrEmpty(str1))
        returnVar = str1;
    if (notUndefinedOrEmpty(str2))
        returnVar += ' ' + str2;
    return returnVar;
};
/*Hook that saves the state in local storage */
export function usePersistedState(key, defaultValue) {
    var _a = React.useState(function () {
        try {
            var item = localStorage.getItem(key);
            if (notUndefinedOrEmpty(item))
                return JSON.parse(item);
            else
                return defaultValue;
        }
        catch (e) {
            console.log(e);
        }
        return defaultValue;
    }), state = _a[0], setState = _a[1];
    useEffect(function () {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
}
var getFa = function (icon) {
    return {
        icon: icon
    };
};
export function findAllIndexesByLikeKey(array, key, value) {
    var returnArr = [];
    for (var i = 0; i < array.length; i++) {
        if (array[i][key].startsWith(value)) {
            returnArr.push(i);
        }
    }
    return returnArr;
}
export function findIndexByKey(array, key, value) {
    for (var i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
            return i;
        }
    }
    return null;
}
export function findIndexByLikeKey(array, key, value) {
    for (var i = 0; i < array.length; i++) {
        if (array[i][key].startsWith(value)) {
            return i;
        }
    }
    return null;
}
export function roundToInteger(val) {
    return Math.round(val);
}
export function formatDateSimpleYYYYMMDD(value) {
    try {
        if (!(value instanceof Date)) {
            var year = value.substring(0, 4);
            var month = value.substring(4, 6);
            var day = value.substring(6, 8);
            return year + '-' + month + '-' + day;
        }
        else {
            return formatDateFull(value);
        }
    }
    catch (e) {
    }
    return '';
}
