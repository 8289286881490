import React, { useEffect, useMemo, useState } from 'react';
import { Button, FlexDiv, Header3, Input, LoadingDots, Modal, P, Select, ShowIf, useToast } from '@/src/components/Components';
import { notUndefinedOrEmpty, undefinedOrEmpty, updateAuto } from '@/src/utils/Helpers';
import { languageCodeToName } from '@/src/utils/LanguageHelpers';
import { getFlagSrcFromLanguageCode } from '@/src/components/PageComponents';
import { ApiRequests } from '@/src/requests/ApiRequests';
import { AllLanguages } from '@/shared/Types';
import { useUserSettings } from '@/src/components/ContextAndHooks';
export var NewProjectModal = function (props) {
    var _a, _b, _c, _d;
    var _e = useState(), projectData = _e[0], setProjectData = _e[1];
    var _f = useState([]), selectedLanguages = _f[0], setSelectedLanguages = _f[1];
    var _g = useState(), selectedBaseLanguage = _g[0], setSelectedBaseLanguage = _g[1];
    var _h = useState(), selectedTeam = _h[0], setSelectedTeam = _h[1];
    var userSettings = useUserSettings()[0];
    var _j = useState(false), creatingProject = _j[0], setcreatingProject = _j[1];
    var toast = useToast();
    useEffect(function () {
        var _a, _b, _c;
        setSelectedTeam((_c = (_b = (_a = userSettings === null || userSettings === void 0 ? void 0 : userSettings.teamAccess) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.teamSettings) === null || _c === void 0 ? void 0 : _c.pk);
    }, [(_a = userSettings === null || userSettings === void 0 ? void 0 : userSettings.teamAccess) === null || _a === void 0 ? void 0 : _a.length]);
    var onAddProject = function (p, onSuccess, onError) {
        ApiRequests.Project.post(p, function (response) {
            if (response.status == '200') {
                onSuccess();
            }
            else {
                onError(response === null || response === void 0 ? void 0 : response.errors);
            }
        });
    };
    var onClickAddProject = function () {
        var _a;
        setcreatingProject(true);
        var languages = (_a = selectedLanguages === null || selectedLanguages === void 0 ? void 0 : selectedLanguages.map(function (d) { return { project_pk: null, language_code: d, is_base_language: false, variations: [] }; })) === null || _a === void 0 ? void 0 : _a.concat([{ project_pk: null, language_code: selectedBaseLanguage, is_base_language: true, variations: [] }]);
        var project = updateAuto(projectData, { languages: { $set: languages } });
        project = updateAuto(project, { teamPk: { $set: selectedTeam } });
        onAddProject(project, function () {
            props.onHide();
            setcreatingProject(false);
            props.afterAddProject(project);
        }, function (error) {
            toast('Error', 'danger');
            props.onHide();
            setcreatingProject(false);
        });
    };
    var cancel = function () {
        props.onHide();
        setProjectData(null);
    };
    var languageOptions = useMemo(function () {
        var _a;
        console.log("asdf");
        return (_a = AllLanguages === null || AllLanguages === void 0 ? void 0 : AllLanguages.sort(function (a, b) { return languageCodeToName(a).localeCompare(languageCodeToName(b)); })) === null || _a === void 0 ? void 0 : _a.map(function (d) { return { value: d, displayValue: languageCodeToName(d), imgSrc: getFlagSrcFromLanguageCode(d) }; });
    }, [AllLanguages]);
    var selectedTeamId = useMemo(function () {
        var _a, _b;
        if (((_a = userSettings === null || userSettings === void 0 ? void 0 : userSettings.teamAccess) === null || _a === void 0 ? void 0 : _a.length) == 1) {
            return (_b = userSettings === null || userSettings === void 0 ? void 0 : userSettings.teamAccess[0]) === null || _b === void 0 ? void 0 : _b.pk;
        }
        else {
            return selectedTeam;
        }
    }, [(_b = userSettings === null || userSettings === void 0 ? void 0 : userSettings.teamAccess) === null || _b === void 0 ? void 0 : _b.length, selectedTeam]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { style: { maxWidth: '600px' }, show: props.show, onHide: props.onHide },
            React.createElement(Header3, { style: { marginBottom: '20px' }, black: true }, "Add Project"),
            props.extraExplanation,
            React.createElement(Input, { autofocus: true, style: { width: '500px' }, label: 'Project name', placeHolder: 'Enter the name of your project', value: projectData === null || projectData === void 0 ? void 0 : projectData.name, onChange: function (v) { return setProjectData(function (p) { return updateAuto(p, { name: { $set: v } }); }); } }),
            React.createElement(ShowIf, { show: ((_c = userSettings === null || userSettings === void 0 ? void 0 : userSettings.teamAccess) === null || _c === void 0 ? void 0 : _c.length) > 1 },
                React.createElement(Select, { label: 'Select team', style: { width: '500px', marginTop: '15px' }, value: selectedTeam, options: (_d = userSettings === null || userSettings === void 0 ? void 0 : userSettings.teamAccess) === null || _d === void 0 ? void 0 : _d.map(function (d) { var _a; return { value: d.pk, displayValue: (_a = d.teamSettings) === null || _a === void 0 ? void 0 : _a.name }; }), onChange: setSelectedTeam })),
            React.createElement(Select, { label: 'Select base languages', style: { width: '500px', marginTop: '15px' }, options: languageOptions, value: selectedBaseLanguage, onChange: setSelectedBaseLanguage }),
            React.createElement(ShowIf, { show: notUndefinedOrEmpty(selectedBaseLanguage) },
                React.createElement(Select, { label: 'Select languages', style: { width: '500px', marginTop: '15px' }, options: languageOptions === null || languageOptions === void 0 ? void 0 : languageOptions.filter(function (k) { return (k === null || k === void 0 ? void 0 : k.value) != selectedBaseLanguage; }), allowMultipleValues: true, values: selectedLanguages, onChanges: setSelectedLanguages }),
                React.createElement(ShowIf, { show: selectedLanguages.length > 0 },
                    React.createElement(P, { style: { marginTop: '15px' } },
                        "You have selected ",
                        selectedLanguages.length,
                        " languages. You can add more languages later."))),
            React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                React.createElement(Button, { type: 'blue', size: 'medium', onClick: onClickAddProject, disabled: creatingProject || (undefinedOrEmpty(selectedBaseLanguage) && (selectedLanguages === null || selectedLanguages === void 0 ? void 0 : selectedLanguages.length) == 0 && undefinedOrEmpty(selectedTeamId)) }, creatingProject ? React.createElement(LoadingDots, null) : 'Add project'),
                React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: cancel, disabled: creatingProject }, "Cancel")))));
};
