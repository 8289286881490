import { Button, Header3, LoadingDots, P, ShowIf, Table, useToast } from '@/src/components/Components';
import { useTeamStatistics } from '@/src/components/ContextAndHooks';
import { ApiRequests } from '@/src/requests/ApiRequests';
import { formatDateFromFormat } from '@/src/utils/Helpers';
import React, { useEffect, useMemo, useState } from 'react';
export var BillingHistory = function (props) {
    var _a = useState([]), invoices = _a[0], setInvoices = _a[1];
    var _b = useState(false), fetchingInvoices = _b[0], setFetchingInvoices = _b[1];
    var activeSubscriptions = useTeamStatistics().activeSubscriptions;
    var addToast = useToast();
    useEffect(function () {
        setFetchingInvoices(true);
        ApiRequests.Team.Invoices.getAll(props.teamSettings.pk, function (response) {
            if (response.status == '200') {
                //    setInvoices(response.data);
            }
            else {
                addToast(response.errors, 'danger');
            }
            setFetchingInvoices(false);
        });
    }, [props.teamSettings.billingDetails]);
    var activeSubscription = useMemo(function () {
        return activeSubscriptions === null || activeSubscriptions === void 0 ? void 0 : activeSubscriptions.find(function (s) { return (s === null || s === void 0 ? void 0 : s.teamPk) == props.teamSettings.pk; });
    }, [activeSubscriptions]);
    var isBeta = useMemo(function () {
        return (activeSubscription === null || activeSubscription === void 0 ? void 0 : activeSubscription.type) == 'beta';
    }, [activeSubscription]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Header3, { style: { marginBottom: '20px' }, black: true }, "Invoicing History"),
        React.createElement(ShowIf, { show: fetchingInvoices },
            React.createElement(LoadingDots, null)),
        React.createElement(ShowIf, { show: isBeta },
            React.createElement(P, null, "During BETA, no invoices are created.")),
        React.createElement(ShowIf, { show: !isBeta },
            React.createElement(ShowIf, { show: !fetchingInvoices },
                React.createElement(Table, { doNotShowLeftHeadercontent: true, style: { width: 'calc(100% - 20px)', maxWidth: '1000px' }, header: [
                        { content: 'Number' },
                        { content: 'Created' },
                        { content: 'Status' },
                        { content: '' }
                    ], rows: invoices === null || invoices === void 0 ? void 0 : invoices.map(function (invoice, i) {
                        return {
                            items: [
                                { content: invoice.number },
                                { content: formatDateFromFormat(invoice.created, 'yyyy-MM-dd') },
                                { content: React.createElement("div", { style: { color: (invoice === null || invoice === void 0 ? void 0 : invoice.payed) ? 'green' : 'red' } }, (invoice === null || invoice === void 0 ? void 0 : invoice.payed) ? 'Payed' : 'Not payed') },
                                { content: React.createElement("a", { href: invoice.downloadUrl, target: '_blank' },
                                        React.createElement(Button, { style: { justifyContent: 'flex-end', display: 'flex' }, type: 'white', size: 'small' }, "View")) }
                            ]
                        };
                    }) })))));
};
