import React, { useEffect, useState } from 'react';
//@ts-ignore
import style from './website.module.scss';
import { FlexDiv, ShowIf } from '../components/Components';
export var Card = function (props) {
    return (React.createElement("div", { style: props.style, className: style.card + ' ' + props.className }, props.children));
};
export var Downbar = function (props) {
    var getCurrentYear = function () {
        return new Date().getFullYear();
    };
    return (React.createElement("div", { style: props.style, className: style.downbar + ' ' + props.className },
        React.createElement(FlexDiv, { style: { justifyContent: 'center' } },
            React.createElement("div", { style: { fontSize: '11px', color: 'gray' } },
                "\u00A9 ",
                getCurrentYear(),
                " Translay.io / ",
                React.createElement("a", { style: { color: '#5d8adf' }, href: "mailto: contact@translay.io" }, " contact@translay.io"),
                " "),
            React.createElement("div", { style: { marginLeft: '100px', fontSize: '11px', color: 'gray' } },
                React.createElement(BlueHref, { href: '/terms' }, "Terms of Service"),
                " \u2022 ",
                React.createElement(BlueHref, { href: '/privacy' }, "Privacy Policy")))));
};
var BlueHref = function (props) {
    return (React.createElement("a", { href: props.href, style: { color: '#5d8adf' } }, props.children));
};
export var TextHeaderTyper = function (props) {
    var _a = useState(''), currentText = _a[0], setCurrentText = _a[1];
    var _b = useState(0), currentTextIndex = _b[0], setCurrentTextIndex = _b[1];
    var _c = useState(true), isTyping = _c[0], setIsTyping = _c[1];
    useEffect(function () {
        var interval = setInterval(function () {
            if (isTyping) {
                // Typing effect
                setCurrentText(function (prevText) {
                    var nextCharIndex = prevText.length + 1;
                    var nextText = props.texts[currentTextIndex].slice(0, nextCharIndex);
                    if (nextText != prevText && nextText === props.texts[currentTextIndex]) {
                        // Finished typing, switch to deletion
                        setTimeout(function () { return setIsTyping(false); }, 1000);
                    }
                    return nextText;
                });
            }
            else {
                // Deleting effect
                setCurrentText(function (prevText) {
                    var nextCharIndex = Math.max(0, prevText.length - 1);
                    var nextText = props.texts[currentTextIndex].slice(0, nextCharIndex);
                    if (nextText === '' && prevText != nextText) {
                        // Finished deletion, switch to typing the next text
                        setTimeout(function () { return setIsTyping(true); }, 1000);
                        console.log("next text");
                        setCurrentTextIndex(function (prevIndex) { return (prevIndex + 1) % props.texts.length; }); //Loop around if required
                    }
                    return nextText;
                });
            }
        }, 100); // Adjust the interval as needed
        return function () { return clearInterval(interval); };
    }, [currentText, currentTextIndex, isTyping, props.texts]);
    return (React.createElement("div", { style: props.style, className: style.TextHeaderTyperOuter },
        currentText,
        React.createElement("div", { className: style.TextHeaderTyperLine }, "\u00A0")));
};
export var LogoSvg = function () {
    return (React.createElement("div", null));
};
export var HeroMultipleWithImages = function (props) {
    var _a;
    var _b = useState(0), selectedIndex = _b[0], setSelectedIndex = _b[1];
    return (React.createElement("div", { className: style.HeroMultipleWithImages },
        React.createElement("div", { className: style.HeroMultipleWithImagesHeaderWrapper },
            React.createElement("div", { className: style.HeroMultipleWithImagesHeader }, props.header),
            React.createElement("div", { className: style.HeroMultipleWithImagesBelowHeader }, props.belowHeader),
            React.createElement(FlexDiv, { className: style.HeroMultipleWithImagesObjectsSelectorWrapper, style: { position: 'relative', overflow: 'hidden' } },
                React.createElement(ShowIf, { show: selectedIndex > 0, className: style.HeroMultipleWithImagesObjectsSelectorArrow, style: { left: 0 }, onClick: function () { return setSelectedIndex(function (prevIndex) { return (prevIndex - 1 + props.objects.length) % props.objects.length; }); } },
                    React.createElement("div", { className: style.HeroMultipleWithImagesObjectsSelectorArrowInner }, "<")),
                React.createElement("div", { style: { transition: 'All 1s', transform: ' translateX(' + (20 - selectedIndex * 320) + 'px)' }, className: style.HeroMultipleWithImagesObjectsSelector }, props.objects.map(function (object) {
                    return (React.createElement("div", { className: style.HeroMultipleWithImagesObject },
                        React.createElement("div", { className: style.HeroMultipleWithImagesObjectHeader }, object.header),
                        React.createElement("div", { className: style.HeroMultipleWithImagesObjectText }, object.text)));
                })),
                React.createElement(ShowIf, { show: selectedIndex < (((_a = props.objects) === null || _a === void 0 ? void 0 : _a.length) - 1), className: style.HeroMultipleWithImagesObjectsSelectorArrow, style: { right: 0 }, onClick: function () { return setSelectedIndex(function (prevIndex) { return (prevIndex + 1) % props.objects.length; }); } },
                    React.createElement("div", { className: style.HeroMultipleWithImagesObjectsSelectorArrowInner }, ">"))),
            React.createElement("div", { className: style.HeroMultipleWithImagesObjectsIndicator }, props.objects.map(function (object, index) {
                return (React.createElement("div", { className: style.HeroMultipleWithImagesObjectsIndicatorDot, onClick: function () { return setSelectedIndex(index); }, style: { backgroundColor: index == selectedIndex ? '#d45395' : '#f1f1f1' } }));
            }))),
        React.createElement("div", { className: style.HeroMultipleWithImagesObjects },
            React.createElement("img", { src: props.objects[selectedIndex].image }))));
};
