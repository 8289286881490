var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
//@ts-ignore
import style from './website.module.scss';
import { Button, FlexDiv, Indicator, LabelWithInfoTooltip, Line, LoadingDots, P } from '../components/Components';
import { CenterPart, Header, Hero } from './WebsiteWrapper';
import { Card } from './WebsiteCompoents';
import { CrossSvg } from '../components/SvgItems';
export var Pricing = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Hero, null,
            React.createElement(CenterPart, { style: { alignItems: 'center' } },
                React.createElement(Header, null, "Our Pricing"),
                React.createElement(P, { style: { marginTop: '40px', maxWidth: '600px', textAlign: 'center' } }, "Localize is a no-code translation solution for software platforms, allowing you to easily translate your web app, UI, website, help docs, emails, and more."))),
        React.createElement(CenterPart, { style: { alignItems: 'center' } },
            React.createElement(Card, { style: { maxWidth: '800px' } },
                React.createElement(FullPricingTable, null)))));
};
export var FullPricingTable = function (props) {
    var _a, _b, _c;
    var benefits = useMemo(function () {
        var benefits = [[React.createElement(Header, { size: 'medium' }, "Benefits"), '', ''],
            ['', React.createElement(Line, { noMargin: true }), React.createElement(Line, { noMargin: true }), React.createElement(Line, { noMargin: true })],
            [React.createElement(FlexDiv, null,
                    React.createElement("div", null, "Number of Projects"),
                    React.createElement(LabelWithInfoTooltip, { tooltip: 'Number of projects that your team can create.' })), React.createElement(Center, null, "2"), React.createElement(Center, null, "10"), React.createElement(Center, null, "Unlimited")],
            [React.createElement(FlexDiv, null,
                    React.createElement("div", null, "Number of Words"),
                    React.createElement(LabelWithInfoTooltip, { tooltip: 'Number of projects that your team can create.' })), React.createElement(Center, null, "10k"), React.createElement(Center, null, "60k"), React.createElement(Center, null, "Unlimited")],
            [React.createElement(FlexDiv, null,
                    React.createElement("div", null, "Machine Translations"),
                    React.createElement(LabelWithInfoTooltip, { tooltip: 'Number of projects that your team can create.' })), React.createElement(Center, null, "10k words per month"), React.createElement(Center, null, "50k words per month"), React.createElement(Center, null, "100k words per month")],
            [React.createElement(FlexDiv, null,
                    React.createElement("div", null, "Google CDN"),
                    React.createElement(LabelWithInfoTooltip, { tooltip: 'Number of projects that your team can create.' })), React.createElement(PricingCross, null), React.createElement(PricingCross, null), React.createElement(Checkbox, null)],
            [React.createElement(FlexDiv, null,
                    React.createElement("div", null, "Number of Users"),
                    React.createElement(LabelWithInfoTooltip, { tooltip: 'Number of projects that your team can create.' })), React.createElement(Center, null, "5"), React.createElement(Center, null, "10"), React.createElement(Center, null, "Unlimited")],
            [React.createElement(FlexDiv, null,
                    React.createElement("div", null, "API"),
                    React.createElement(LabelWithInfoTooltip, { tooltip: 'Number of projects that your team can create.' })), React.createElement(Checkbox, null), React.createElement(Checkbox, null), React.createElement(Checkbox, null)],
            [React.createElement(FlexDiv, null,
                    React.createElement("div", null, "Components"),
                    React.createElement(LabelWithInfoTooltip, { tooltip: 'Number of projects that your team can create.' })), React.createElement(Checkbox, null), React.createElement(Checkbox, null), React.createElement(Checkbox, null)]
        ];
        return props.disableBenefits ? [] : benefits;
    }, [props.disableBenefits]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PricingTable, { rows: ([
                ['', React.createElement(PriceExplain, { color: 'blue', price: 7, indicator: React.createElement(Indicator, { type: 'blue' }, "Basic"), text: 'Best for translating simple websites into 1-4 languages.', callToAction: React.createElement(Button, { disabled: props.doingAction, onClick: (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.basic }, props.doingAction ? React.createElement(LoadingDots, null) : 'Get started') }), React.createElement(PriceExplain, { price: 12, color: 'silver', indicator: React.createElement(Indicator, { type: 'silver' }, "Plus"), text: 'Best for translating simple websites into 1-4 languages.', callToAction: React.createElement(Button, { disabled: props.doingAction, onClick: (_b = props.onClick) === null || _b === void 0 ? void 0 : _b.plus, type: 'darkgray' }, props.doingAction ? React.createElement(LoadingDots, null) : 'Get started') }), React.createElement(PriceExplain, { price: 15, color: 'gold', indicator: React.createElement(Indicator, { type: 'yellow' }, "Premium"), text: 'Best for translating simple websites into 1-4 languages.', callToAction: React.createElement(Button, { disabled: props.doingAction, onClick: (_c = props.onClick) === null || _c === void 0 ? void 0 : _c.premium, type: 'orange' }, props.doingAction ? React.createElement(LoadingDots, null) : 'Get started') })
                ]
            ]).concat(benefits) })));
};
var PriceExplain = function (props) {
    var getColor = function () {
        if (props.color == 'silver')
            return '#f1f1f1';
        if (props.color == 'gold')
            return '#ffebb9';
    };
    return (React.createElement("div", { style: { borderRadius: '15px 15px 0 0 ', background: getColor(), padding: '15px' } },
        React.createElement(FlexDiv, { alignCenter: true, style: { flexDirection: 'column', maxWidth: '200px' } },
            props.indicator,
            React.createElement(P, { style: { textAlign: 'center' } }, props.text),
            props.callToAction),
        React.createElement(Price, { color: props.color, style: { marginTop: '20px' } }, props.price)));
};
var Price = function (props) {
    var getColor = function () {
        if (props.color == 'silver')
            return '#5c5c5c';
        if (props.color == 'gold')
            return '#d68334';
        else {
            return '#5d8adf';
        }
    };
    return (React.createElement(Center, { style: props.style },
        React.createElement(FlexDiv, null,
            React.createElement("div", { style: { fontSize: '30px', fontWeight: 'bold', color: getColor() } },
                "\u20AC",
                props.children,
                " "),
            React.createElement("div", { style: { fontSize: '15px', marginLeft: '5px', alignSelf: 'center' } }, "/month"))));
};
var Center = function (props) {
    return (React.createElement("div", { style: __assign({ textAlign: 'center', display: 'flex', justifyContent: 'center' }, props.style) }, props.children));
};
var PricingTable = function (props) {
    return (React.createElement("table", { className: style.pricingTable }, props.rows.map(function (row, index) {
        return (React.createElement("tr", null, row.map(function (cell, index) {
            return (React.createElement("td", null, cell));
        })));
    })));
};
var PricingCross = function () {
    return (React.createElement(Center, null,
        React.createElement(CrossSvg, { style: { fontSize: '29px', color: 'gray' } })));
};
var Checkbox = function () {
    return (React.createElement(Center, null,
        React.createElement("div", { className: style.smallRoundCirlce },
            React.createElement(CheckmarkSvg, { color: 'white' }))));
};
export var CheckmarkSvg = function (props) {
    return (React.createElement("svg", { width: "14", height: "11", viewBox: "0 0 14 11", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M1 5.5L5.5 10L13 1", stroke: props.color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })));
};
