import React, { useEffect, useMemo, useState } from 'react';
import { LeftMenu } from './LeftMenu';
import { Button, Card, ExpandHeader, FlexDiv, Header3, Header5, Input, LabelWrapper, Line, LoadingDots, MainViewWrapper, Menu, Modal, P, Select, ShowIf, SmallColumnMenu, useToast } from '../components/Components';
import { md5Encrypt, notUndefinedOrEmpty, undefinedOrEmpty, updateAuto, usePersistedState } from '../utils/Helpers';
import { ApiRequests } from '../requests/ApiRequests';
import { Navigate, useParams } from 'react-router-dom';
import { useUserSettings } from '../components/ContextAndHooks';
import { CreateNewTeamModal } from './UserTeamPage/CreateNewTeamModal';
import { TeamUsers } from './UserTeamPage/TeamUsers';
import { BillingOverview } from './UserTeamPage/BIllingOverview';
import { BillingHistory } from './UserTeamPage/BillingHistory';
import { BillingDetails } from './UserTeamPage/BillingDetails';
import { GeneralTeamSettings } from './UserTeamPage/GeneralTeamSettings';
export var User = function () {
    var nestedUserPath = useParams().nestedUserPath;
    return (React.createElement("div", { className: 'flex h-100' },
        React.createElement(LeftMenu, null),
        React.createElement(MainViewWrapper, null,
            React.createElement(Header3, { style: { marginBottom: '20px' } }, "Settings"),
            React.createElement(Menu, { items: [
                    { urlLink: '/user/user', title: 'User Settings', isActive: nestedUserPath == 'user' },
                    { urlLink: '/user/team', title: 'Team Settings', isActive: nestedUserPath == 'team' }
                ] }),
            React.createElement("div", { style: { marginTop: '10px' } },
                React.createElement(ShowIf, { show: nestedUserPath == 'user' },
                    React.createElement(GeneralSettingsUser, null)),
                React.createElement(ShowIf, { show: nestedUserPath == 'team' },
                    React.createElement(TeamSettings, null))))));
};
var TeamSettings = function (props) {
    var _a, _b, _c;
    var _d = useUserSettings(), loggedInUser = _d[0], setLoggedInUser = _d[1], trigger = _d[2], fetchComplete = _d[3];
    var addToast = useToast();
    var nestedNestedUserPath = useParams().nestedNestedUserPath;
    //Used if there are multiple team access
    var _e = usePersistedState('currentlySelectedTeamPk', null), currentlySelectedTeamPk = _e[0], setCurrentlySelectedTeamPk = _e[1];
    var teamSettings = useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        if (undefinedOrEmpty(currentlySelectedTeamPk)) {
            setCurrentlySelectedTeamPk((_b = (_a = loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.teamAccess) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.teamSettings.pk);
        }
        if (((_c = loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.teamAccess) === null || _c === void 0 ? void 0 : _c.length) == 1 || undefinedOrEmpty(currentlySelectedTeamPk)) {
            return (_e = (_d = loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.teamAccess) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.teamSettings;
        }
        else {
            return (_g = (_f = loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.teamAccess) === null || _f === void 0 ? void 0 : _f.find(function (t) { return t.teamSettings.pk == currentlySelectedTeamPk; })) === null || _g === void 0 ? void 0 : _g.teamSettings;
        }
    }, [loggedInUser, currentlySelectedTeamPk]);
    var onChangeTeamSettings = function (v) {
        setLoggedInUser(function (prev) { return updateAuto(prev, { teamAccess: { $set: prev.teamAccess.map(function (t) { return t.teamSettings.pk == v.pk ? updateAuto(t, { teamSettings: { $set: v } }) : t; }) } }); });
    };
    var onAfterDeleteTeam = function (pk) {
        setCurrentlySelectedTeamPk(null);
        setLoggedInUser(function (prev) { return updateAuto(prev, { teamAccess: { $set: prev.teamAccess.filter(function (t) { return t.teamSettings.pk != pk; }) } }); });
    };
    var _f = useState(false), showCreateNewTeamModal = _f[0], setshowCreateNewTeamModal = _f[1];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { marginTop: '10px' } },
            React.createElement(ShowIf, { show: ((_a = loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.teamAccess) === null || _a === void 0 ? void 0 : _a.length) > 1 },
                React.createElement("div", { style: { marginBottom: '20px' } },
                    React.createElement(FlexDiv, null,
                        React.createElement(P, { style: { marginRight: '20px', maxWidth: '400px' } }, "You have access to multiple teams, please select which team you want to edit."),
                        React.createElement(LabelWrapper, { label: 'Select which team to edit' },
                            React.createElement(Select, { hideClearIcon: true, style: { maxWidth: '250px' }, value: currentlySelectedTeamPk, onChange: setCurrentlySelectedTeamPk, options: (_b = loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.teamAccess) === null || _b === void 0 ? void 0 : _b.map(function (t) { var _a; return { value: t.pk, displayValue: (_a = t.teamSettings) === null || _a === void 0 ? void 0 : _a.name }; }) })))),
                React.createElement(Line, null),
                React.createElement(Header3, { style: { marginBottom: '20px' }, black: true },
                    "Editing your team '", teamSettings === null || teamSettings === void 0 ? void 0 :
                    teamSettings.name,
                    "'")),
            React.createElement(ShowIf, { show: ((_c = loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.teamAccess) === null || _c === void 0 ? void 0 : _c.length) == 0 },
                React.createElement(P, null, "You have no team associated with your account"),
                React.createElement(Button, { onClick: function () { return setshowCreateNewTeamModal(true); } }, "Create my team")),
            React.createElement(ShowIf, { show: notUndefinedOrEmpty(teamSettings === null || teamSettings === void 0 ? void 0 : teamSettings.pk) },
                React.createElement(FlexDiv, { style: { marginTop: '40px' } },
                    React.createElement("div", { style: { width: '200px' } },
                        React.createElement(SmallColumnMenu, { items: [
                                { urlLink: '/user/team/', title: 'General', isActive: undefinedOrEmpty(nestedNestedUserPath) },
                                { urlLink: '/user/team/members', title: 'Members', isActive: nestedNestedUserPath == 'members' },
                                { urlLink: '/user/team/billingoverview', title: 'Billing', isActive: nestedNestedUserPath == 'billingoverview' }
                                /*   , {
                                     urlLink: '/user/team/billingoverview', title: 'Billing', isActive: compareEqalOr(nestedNestedUserPath, ['billingoverview', 'billinghistory', 'billingdetails']),
                                     subItems: [
                                       { urlLink: '/user/team/billingoverview', title: 'Overview', isActive: nestedNestedUserPath == 'billingoverview' }
                                       , { urlLink: '/user/team/billinghistory', title: 'History', isActive: nestedNestedUserPath == 'billinghistory' }
                                       , { urlLink: '/user/team/billingdetails', title: 'Billing Details', isActive: nestedNestedUserPath == 'billingdetails' }
                                     ]
                   
                                   } */
                            ] })),
                    React.createElement("div", { style: { marginLeft: '20px', flexGrow: 1 } },
                        React.createElement(ShowIf, { show: nestedNestedUserPath == 'members' },
                            React.createElement(TeamUsers, { afterSave: onChangeTeamSettings, teamSettings: teamSettings })),
                        React.createElement(ShowIf, { show: nestedNestedUserPath == 'billingoverview' },
                            React.createElement(BillingOverview, { teamSettings: teamSettings })),
                        React.createElement(ShowIf, { show: nestedNestedUserPath == 'billinghistory' },
                            React.createElement(BillingHistory, { teamSettings: teamSettings })),
                        React.createElement(ShowIf, { show: nestedNestedUserPath == 'billingdetails' },
                            React.createElement(BillingDetails, { afterSave: onChangeTeamSettings, teamSettings: teamSettings })),
                        React.createElement(ShowIf, { show: undefinedOrEmpty(nestedNestedUserPath) },
                            React.createElement(GeneralTeamSettings, { onAfterDelete: function () { return onAfterDeleteTeam(teamSettings === null || teamSettings === void 0 ? void 0 : teamSettings.pk); }, afterSave: onChangeTeamSettings, teamSettings: teamSettings })))))),
        React.createElement(CreateNewTeamModal, { showModal: showCreateNewTeamModal, setShowModal: setshowCreateNewTeamModal })));
};
var GeneralSettingsUser = function (props) {
    var _a = useUserSettings(), loggedInUser = _a[0], setLoggedInUser = _a[1], trigger = _a[2], fetchComplete = _a[3];
    var addToast = useToast();
    var _b = useState(loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.name), name = _b[0], setName = _b[1];
    var _c = useState(false), showEmailChangeModal = _c[0], setShowEmailChangeModal = _c[1];
    var _d = useState(false), showPasswordChangeModal = _d[0], setShowPasswordChangeModal = _d[1];
    var _e = useState(""), newEmail = _e[0], setNewEmail = _e[1];
    var _f = useState(false), saving = _f[0], setSaving = _f[1];
    var _g = useState(""), newPassword = _g[0], setNewPassword = _g[1];
    var _h = useState(""), oldPassword = _h[0], setOldPassword = _h[1];
    var _j = useState(false), showDeleteWarning = _j[0], setShowDeleteWarning = _j[1];
    var _k = useState(''), deleteInputField = _k[0], setDeleteInputField = _k[1];
    var _l = useState(false), successFulDelete = _l[0], setSuccessFulDelete = _l[1];
    useEffect(function () {
        setName(loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.name);
    }, [loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.name]);
    var saveEmail = function () {
        setSaving(true);
        ApiRequests.User.put({ pk: loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.pk, changeEmail: true, email: newEmail }, function (response) {
            if (response.status == '200') {
                addToast('Saved!', 'success');
                setShowEmailChangeModal(false);
                setLoggedInUser(function (prev) { return updateAuto(prev, { email: { $set: newEmail } }); });
            }
            else {
                addToast(response.errors, 'danger');
            }
            setSaving(false);
        });
    };
    var savePassword = function () {
        setSaving(true);
        ApiRequests.User.put({ changePassword: true, pk: loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.pk, newPassword: md5Encrypt(newPassword), oldPassword: md5Encrypt(oldPassword) }, function (response) {
            if (response.status == '200') {
                addToast('Saved!', 'success');
                setShowPasswordChangeModal(false);
                setNewPassword('');
                setOldPassword('');
            }
            else {
                addToast(response.errors, 'danger');
            }
            setSaving(false);
        });
    };
    var deleteUser = function () {
        if (deleteInputField != 'DELETE') {
            addToast('Type DELETE to confirm', 'danger');
            return;
        }
        ApiRequests.User.delete(loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.pk, function (response) {
            if (response.status == '200') {
                addToast('Deleted!', 'success');
                setShowDeleteWarning(false);
                setSuccessFulDelete(true);
            }
            else {
                addToast(response.errors, 'danger');
            }
            setSaving(false);
        });
    };
    var saveName = function () {
        setSaving(true);
        ApiRequests.User.put({ pk: loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.pk, changeName: true, name: name }, function (response) {
            if (response.status == '200') {
                addToast('Saved!', 'success');
                setLoggedInUser(function (prev) { return updateAuto(prev, { name: { $set: name } }); });
            }
            else {
                addToast(response.errors, 'danger');
            }
            setSaving(false);
        });
    };
    return (React.createElement("div", { style: { maxWidth: '10000px' } },
        successFulDelete && React.createElement(Navigate, { to: '/login' }),
        React.createElement(ExpandHeader, { defaultExpanded: true, style: { marginTop: '20px' }, header: 'General Settings', headerLevel: 2 },
            React.createElement(Card, null,
                React.createElement(FlexDiv, { style: { alignItems: 'flex-end' } },
                    React.createElement(Input, { className: '', inputStyle: { width: '250px', maxWidth: '250px' }, value: name, onChange: setName, label: 'Name' }),
                    React.createElement(ShowIf, { show: (loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.name) != name },
                        React.createElement(Button, { className: 'col-auto', style: { marginLeft: '10px' }, type: 'blue', size: 'medium', onClick: saveName, disabled: saving }, saving ? React.createElement(LoadingDots, null) : 'Save'))),
                React.createElement(FlexDiv, { style: { alignItems: 'flex-end', marginTop: '10px' } },
                    React.createElement(Input, { inputStyle: { width: '250px', maxWidth: '250px' }, disabled: true, className: '', value: loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.email, label: 'Email' }),
                    React.createElement(Button, { className: 'col-auto', style: { marginLeft: '10px' }, onClick: function () { return setShowEmailChangeModal(true); }, size: 'small', type: 'white' }, "Change email")),
                React.createElement(FlexDiv, { style: { alignItems: 'flex-end', marginTop: '10px' } },
                    React.createElement(Input, { inputStyle: { width: '250px', maxWidth: '250px' }, label: 'Password', disabled: true, className: '', value: '******' }),
                    React.createElement(Button, { className: 'col-auto', style: { marginLeft: '10px', whiteSpace: 'nowrap' }, type: 'white', size: 'small', onClick: function () { return setShowPasswordChangeModal(true); } }, "Change password")))),
        React.createElement(ExpandHeader, { style: { marginTop: '20px' }, header: 'Delete user', headerLevel: 2 },
            React.createElement(Card, null,
                React.createElement(Header5, { black: true }, "Delete your account?"),
                React.createElement(Button, { style: { marginTop: '5px' }, size: 'small', type: 'redwhite', onClick: function () { return setShowDeleteWarning(true); } }, "Delete current user"))),
        React.createElement(Modal, { style: { maxWidth: '600px' }, show: showDeleteWarning, onHide: function () { return setShowDeleteWarning(false); } },
            React.createElement(Input, { value: deleteInputField, onChange: setDeleteInputField, label: 'Type DELETE to confirm' }),
            React.createElement(Button, { disabled: deleteInputField != 'DELETE', size: 'small', style: { marginTop: '20px' }, type: 'redwhite', onClick: deleteUser }, "Confirm deletion")),
        React.createElement(Modal, { style: { maxWidth: '600px' }, show: showPasswordChangeModal, onHide: function () { return setShowPasswordChangeModal(false); } },
            React.createElement(Input, { inputType: 'password', value: oldPassword, onChange: setOldPassword, label: 'Old password' }),
            React.createElement(Input, { inputType: 'password', style: { marginTop: '20px' }, value: newPassword, onChange: setNewPassword, label: 'New password' }),
            React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                React.createElement(Button, { type: 'blue', size: 'medium', onClick: savePassword, disabled: saving }, saving ? React.createElement(LoadingDots, null) : 'Save'),
                React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: function () { setShowPasswordChangeModal(false); setNewPassword(''); setOldPassword(''); }, disabled: saving }, "Cancel"))),
        React.createElement(Modal, { style: { maxWidth: '600px' }, show: showEmailChangeModal, onHide: function () { return setShowEmailChangeModal(false); } },
            React.createElement(Input, { value: newEmail, onChange: setNewEmail, label: 'New email' }),
            React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                React.createElement(Button, { type: 'blue', size: 'medium', onClick: saveEmail, disabled: saving }, saving ? React.createElement(LoadingDots, null) : 'Save'),
                React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: function () { setShowEmailChangeModal(false); setNewEmail(''); }, disabled: saving }, "Cancel")))));
};
