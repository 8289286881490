import React, { useEffect, useState } from 'react';
import { LeftMenu } from './LeftMenu';
import { ActionButtonSelect, Button, FlexDiv, Line, LoadingDots, MainViewWrapper, P, SearchButtonInput, ShowIf, useToast } from '../components/Components';
import { ApiRequests } from '../requests/ApiRequests';
import { useUserSettings } from '../components/ContextAndHooks';
import { CreateNewTeamModal } from './UserTeamPage/CreateNewTeamModal';
import { NewProjectModal } from './project/CreateNewProjectModal';
import { ProjectSummary } from './project/ProjectSummary';
import { useNavigate } from 'react-router-dom';
//import * as pxll from '../pxxl.js';
export var Home = function () {
    var _a = useState(''), searchValue = _a[0], setSearchValue = _a[1];
    var _b = useState(''), sortSetting = _b[0], setSortSetting = _b[1];
    var _c = useState([]), projects = _c[0], setProjects = _c[1];
    var _d = useState(false), fetchingProjects = _d[0], setFetchingProjects = _d[1];
    var _e = useState(false), showNewProjectModal = _e[0], setShowNewProjectModal = _e[1];
    var _f = useUserSettings(), loggedInUser = _f[0], setLoggedInUser = _f[1], trigger = _f[2], fetchComplete = _f[3];
    var _g = useState(false), showCreateNewTeamModal = _g[0], setShowCreateNewTeamModal = _g[1];
    var addToast = useToast();
    var navigate = useNavigate();
    useEffect(function () {
        fetchProjects();
    }, []);
    var fetchProjects = function (redirectToProject) {
        setFetchingProjects(true);
        ApiRequests.Project.getAll(function (response) {
            if (response.status == '200') {
                setProjects(response.data);
                if (redirectToProject) {
                    navigate('/projects/' + response.data[0].pk + '/keys');
                }
            }
            else {
                addToast(response === null || response === void 0 ? void 0 : response.errors, 'danger');
            }
            setFetchingProjects(false);
        });
    };
    var onClickNewProject = function () {
        var _a;
        if (((_a = loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.teamAccess) === null || _a === void 0 ? void 0 : _a.length) == 0) {
            setShowCreateNewTeamModal(true);
        }
        else {
            setShowNewProjectModal(true);
        }
    };
    var _h = useState(false), teamJustCreated = _h[0], setTeamJustCreated = _h[1];
    var afterAddProject = function (project) {
        // setProjects(prev=>updateAuto(prev,{$push:[project]}));
        fetchProjects(true);
    };
    var _j = useState(''), test = _j[0], setTest = _j[1];
    return (React.createElement("div", { className: 'flex h-100' },
        React.createElement(LeftMenu, null),
        React.createElement(MainViewWrapper, null,
            React.createElement(FlexDiv, { style: { justifyContent: 'space-between', maxWidth: '1000px' } },
                React.createElement(FlexDiv, null,
                    React.createElement(Button, { onClick: onClickNewProject, size: 'medium', type: 'blue' }, "New project"),
                    React.createElement(SearchButtonInput, { style: { marginLeft: '10px' }, onChange: setSearchValue, value: searchValue }),
                    React.createElement(ActionButtonSelect, { value: sortSetting, onChange: setSortSetting, options: [{ value: 'created', displayValue: 'Created' }, { value: 'name', displayValue: 'Name' }], icon: 'sort' }, "Sort"))),
            React.createElement(Line, null),
            React.createElement(ShowIf, { style: { marginTop: '30px' }, show: fetchingProjects },
                React.createElement(LoadingDots, null)), projects === null || projects === void 0 ? void 0 :
            projects.map(function (project, i) {
                return (React.createElement(React.Fragment, null,
                    React.createElement(ShowIf, { show: i != 0 },
                        React.createElement(Line, { style: { margin: '40px 0' } })),
                    React.createElement(ProjectSummary, { key: i, simpleProject: project })));
            }),
            React.createElement(ShowIf, { show: (projects === null || projects === void 0 ? void 0 : projects.length) == 0 && !fetchingProjects },
                React.createElement(P, null, "You have no projects yet, click the button above to create your first project!"))),
        React.createElement(NewProjectModal, { extraExplanation: teamJustCreated ? React.createElement(P, null, "Awesome, your team is now created! Let's continue to create your project.") : '', afterAddProject: afterAddProject, show: showNewProjectModal, onHide: function () { setShowNewProjectModal(false); setTeamJustCreated(false); } }),
        React.createElement(CreateNewTeamModal, { extraExplanation: React.createElement(P, null, "You have no team associated with your account, before creating your first project, you need to create a team!"), showModal: showCreateNewTeamModal, setShowModal: setShowCreateNewTeamModal, onSuccessCreation: function () { setShowNewProjectModal(true); setTeamJustCreated(true); } })));
};
