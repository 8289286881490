var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useMemo, useState } from 'react';
import { Button, ExpandHeader, FlexDiv, GeneralNameEdit, Header2, InfoBox, Input, LoadingDots, Modal, P, ShowIf, SmallColumnMenu, Table, ThreeDotsWithMenu, useToast } from '@/src/components/Components';
import { copyToClipboard, formatDateFromFormat, updateAuto, usePersistedState } from '@/src/utils/Helpers';
import { AccessToLanguageEdit, AccessTypeEdit, AllCustomAccessCheckboxes, LanguageEditSelect } from './ProjectContributors';
import { ApiRequests } from '@/src/requests/ApiRequests';
import { CopySvg } from '@/src/components/SvgItems';
export var SettingsAndMore = function (props) {
    var _a = usePersistedState('selectedMenuItemAPI', 0), selectedMenuItem = _a[0], setSelectedMenuItem = _a[1];
    return (React.createElement(FlexDiv, null,
        React.createElement(SmallColumnMenu, { items: [
                { onClick: function () { return setSelectedMenuItem(0); }, title: 'Settings', isActive: selectedMenuItem == 0 },
                { onClick: function () { return setSelectedMenuItem(1); }, title: 'API Tokens', isActive: selectedMenuItem == 1 }
                //  , { onClick: () => setSelectedMenuItem(2), title: 'Automations', isActive: selectedMenuItem == 2 }
            ] }),
        React.createElement("div", { style: { marginLeft: '100px', flexGrow: 1, marginRight: '100px' } },
            React.createElement(ShowIf, { show: selectedMenuItem == 0 },
                React.createElement(GeneralProjectSettings, __assign({}, props))),
            React.createElement(ShowIf, { show: selectedMenuItem == 1 },
                React.createElement(ApiTokens, __assign({}, props))))));
};
var GeneralProjectSettings = function (props) {
    var _a = useState(props.detailedProject), project = _a[0], setProject = _a[1];
    //Before saved
    var _b = useState(props.detailedProject), projectBeforeSave = _b[0], setProjectBeforeSave = _b[1];
    var _c = useState(false), saving = _c[0], setSaving = _c[1];
    var addToast = useToast();
    useEffect(function () {
        setProject(props.detailedProject);
        setProjectBeforeSave(props.detailedProject);
    }, [props.detailedProject]);
    var saveProject = function () {
        setSaving(true);
        ApiRequests.Project.put(project, function (response) {
            if (response.status == '200') {
                addToast('Saved!', 'success');
                props.onChange(function (prev) {
                    return updateAuto(updateAuto(prev, { name: { $set: project.name } }), { description: { $set: project.description } });
                });
            }
            else {
                addToast(response.errors, 'danger');
            }
            setSaving(false);
        });
    };
    var needsSave = useMemo(function () {
        return JSON.stringify(project) != JSON.stringify(projectBeforeSave);
    }, [project, projectBeforeSave]);
    var _d = useState(false), showDeleteWarning = _d[0], setShowDeleteWarning = _d[1];
    var _e = useState(''), deleteInputField = _e[0], setDeleteInputField = _e[1];
    var deleteProject = function () {
        ApiRequests.Project.delele(project === null || project === void 0 ? void 0 : project.pk, function (response) {
            if (response.status == '200') {
                addToast('Deleted!', 'success');
                window.location.href = '/projects';
            }
            else {
                addToast(response.errors, 'danger');
            }
        });
    };
    return (React.createElement("div", { style: { maxWidth: '500px' } },
        React.createElement(ExpandHeader, { style: { marginTop: '20px' }, header: 'General settings', defaultExpanded: true, headerLevel: 2 },
            React.createElement(Input, { label: 'Project name', inputStyle: { minWidth: '500px' }, placeHolder: 'Project name', onChange: function (v) { return setProject(function (prev) { return updateAuto(prev, { name: { $set: v } }); }); }, value: project === null || project === void 0 ? void 0 : project.name }),
            React.createElement(Input, { style: { marginTop: '10px' }, label: 'Description', inputStyle: { minWidth: '500px', height: '100px' }, type: 'textarea', placeHolder: 'Enter description here', value: project === null || project === void 0 ? void 0 : project.description, onChange: function (v) { return setProject(function (prev) { return updateAuto(prev, { description: { $set: v } }); }); } }),
            React.createElement(FlexDiv, { style: { justifyContent: 'space-between', marginTop: '10px' } },
                React.createElement(Button, { onClick: saveProject, size: 'medium', disabled: saving || !needsSave }, saving ? React.createElement(LoadingDots, null) : 'Save project'))),
        React.createElement(ExpandHeader, { style: { marginTop: '20px' }, header: 'Delete project', headerLevel: 2 },
            React.createElement(Button, { style: { marginTop: '10px' }, size: 'small', type: 'redwhite', onClick: function () { return setShowDeleteWarning(true); } }, "Delete this project")),
        React.createElement(Modal, { style: { maxWidth: '600px' }, show: showDeleteWarning, onHide: function () { return setShowDeleteWarning(false); } },
            React.createElement(Input, { value: deleteInputField, onChange: setDeleteInputField, label: 'Type DELETE to confirm' }),
            React.createElement(Button, { disabled: deleteInputField != 'DELETE', size: 'small', style: { marginTop: '20px' }, type: 'redwhite', onClick: deleteProject }, "Confirm deletion"))));
};
var ApiTokens = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var _h = useState(false), showAddNewKeyModal = _h[0], setShowAddNewKeyModal = _h[1];
    var _j = useState(false), addingNewKey = _j[0], setAddingNewKey = _j[1];
    var defaultNewKey = { pk: null, projectPk: (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.pk, created: null, validUntil: null, token: '', createdByEmail: '', name: '', accessToEditLanguages: ['all'], customizedAccess: { canDeleteUsers: true, canAddKeys: true, canAddLanguages: true, canAddUsers: true, canDeleteKeys: true, canDeleteLanguages: true } };
    var _k = useState(defaultNewKey), newApiKey = _k[0], setNewApiKey = _k[1];
    var addToast = useToast();
    var addNewKey = function () {
        var _a;
        setAddingNewKey(true);
        ApiRequests.ApiKeys.post((_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.pk, newApiKey, function (response) {
            if (response.status == '200') {
                addToast('Added!', 'success');
                props.onChange(function (prev) { return updateAuto(prev, {
                    apiKeys: {
                        $push: [response.data]
                    }
                }); });
                setShowAddNewKeyModal(false);
            }
            else {
                addToast(response.errors, 'danger');
            }
            setAddingNewKey(false);
        });
    };
    var saveName = function (apiKey, name) {
        saveApiKey(updateAuto(apiKey, { name: { $set: name } }));
    };
    var saveEditLanguages = function (apiKey, access) {
        saveApiKey(updateAuto(apiKey, { accessToEditLanguages: { $set: access } }));
    };
    var saveAccessType = function (apiKey, access) {
        saveApiKey(updateAuto(apiKey, { customizedAccess: { $set: access === null || access === void 0 ? void 0 : access.customizedAccess } }));
    };
    var saveApiKey = function (apiKey) {
        //  const newValue = updateAuto(apiKey, { [attribute]: { $set: access } });
        ApiRequests.ApiKeys.put(apiKey, function (response) {
            if (response.status == '200') {
                addToast('Saved!', 'success');
                props.onChange(function (prev) {
                    var _a;
                    var _b;
                    return updateAuto(prev, { apiKeys: (_a = {}, _a[(_b = prev.apiKeys) === null || _b === void 0 ? void 0 : _b.findIndex(function (k) { return (k === null || k === void 0 ? void 0 : k.pk) == (apiKey === null || apiKey === void 0 ? void 0 : apiKey.pk); })] = { $set: apiKey }, _a) });
                });
            }
            else {
                addToast(response.errors, 'danger');
            }
        });
    };
    var afterDelete = function (apiKey) {
        props.onChange(function (prev) { var _a; return updateAuto(prev, { apiKeys: { $splice: [[(_a = prev.apiKeys) === null || _a === void 0 ? void 0 : _a.findIndex(function (k) { return (k === null || k === void 0 ? void 0 : k.pk) == apiKey.pk; }), 1]] } }); });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(InfoBox, { style: { maxWidth: '10000px' } }, "API Keys are used to access the API. You can create multiple keys with different access rights."),
        React.createElement(ShowIf, { show: ((_c = (_b = props.detailedProject) === null || _b === void 0 ? void 0 : _b.apiKeys) === null || _c === void 0 ? void 0 : _c.length) == 0 },
            React.createElement(P, null, " No API keys yet. Create one to access the API.")),
        React.createElement(ShowIf, { show: ((_e = (_d = props.detailedProject) === null || _d === void 0 ? void 0 : _d.apiKeys) === null || _e === void 0 ? void 0 : _e.length) > 0 },
            React.createElement(Table, { style: { width: '100%', marginTop: '20px' }, header: [
                    { content: 'Name' },
                    { content: 'Token' }
                    //   , { content: 'Valid Until' }
                    //     , { content: 'Access to view languages' }
                    ,
                    { content: 'Access to edit languages' },
                    { content: 'Attributes' },
                    { content: 'Created' }
                ], doNotShowLeftHeadercontent: true, rows: (_f = props.detailedProject) === null || _f === void 0 ? void 0 : _f.apiKeys.map(function (apiKey, i) {
                    var _a;
                    return {
                        leftHeaderContent: '',
                        items: [
                            { content: React.createElement(GeneralNameEdit, { name: apiKey.name, onChangeName: (function (v) { saveName(apiKey, v); }) }) },
                            { content: React.createElement(FlexDiv, null,
                                    React.createElement("div", null, apiKey.token),
                                    React.createElement("div", { style: { marginLeft: '5px', cursor: 'pointer' }, onClick: function () { copyToClipboard(apiKey.token); addToast('Copied!', 'success'); } },
                                        React.createElement(CopySvg, null))) }
                            // ,{content: undefinedOrEmpty(apiKey?.validUntil)? '-':formatDateFromFormat(apiKey?.validUntil,'yyyy-MM-dd hh:mm')}
                            //     , { content: <AccessToLanguageEdit avaiableLanguages={props.detailedProject?.languages} label='Access to view languages' access={apiKey?.accessToViewLanguages} onChangeAndSave={(a) => saveViewLanguages(apiKey, a)} /> }
                            ,
                            { content: React.createElement(AccessToLanguageEdit, { avaiableLanguages: (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.languages, label: 'Access to edit languages', access: apiKey === null || apiKey === void 0 ? void 0 : apiKey.accessToEditLanguages, onChangeAndSave: function (a) { return saveEditLanguages(apiKey, a); } }) },
                            { content: React.createElement(AccessTypeEdit, { onChangeAndSave: function (a) { return saveAccessType(apiKey, a); }, hideAccessType: true, accessType: { customizedAccess: apiKey === null || apiKey === void 0 ? void 0 : apiKey.customizedAccess } }) },
                            { content: React.createElement(FlexDiv, { style: { color: 'gray' } },
                                    React.createElement("div", null, formatDateFromFormat(apiKey === null || apiKey === void 0 ? void 0 : apiKey.created, 'yyyy-MM-dd')),
                                    React.createElement("div", { style: { marginLeft: '10px' } }, apiKey === null || apiKey === void 0 ? void 0 : apiKey.createdByEmail)) },
                            { content: React.createElement(ApiKeyMenu, { apiKey: apiKey, onAfterDelete: function () { return afterDelete(apiKey); } }) }
                        ]
                    };
                }) })),
        React.createElement(Button, { style: { marginTop: '20px', marginBottom: '10px' }, onClick: function () { return setShowAddNewKeyModal(true); }, size: 'medium', type: 'blue' }, "New API Key"),
        React.createElement(Modal, { style: { maxWidth: '600px' }, show: showAddNewKeyModal, onHide: function () { return setShowAddNewKeyModal(false); } },
            React.createElement(Header2, null, "Add new API Key"),
            React.createElement(Input, { style: { marginTop: '10px' }, label: 'Name of the Key', value: newApiKey === null || newApiKey === void 0 ? void 0 : newApiKey.name, onChange: function (v) { return setNewApiKey(function (prev) { return updateAuto(prev, { name: { $set: v } }); }); } }),
            React.createElement(LanguageEditSelect, { style: { marginTop: '10px' }, label: 'Access to edit languages', access: newApiKey === null || newApiKey === void 0 ? void 0 : newApiKey.accessToEditLanguages, onChangeAccess: function (v) { return setNewApiKey(function (prev) { return updateAuto(prev, { accessToEditLanguages: { $set: v } }); }); }, avaiableLanguages: (_g = props.detailedProject) === null || _g === void 0 ? void 0 : _g.languages }),
            React.createElement(AllCustomAccessCheckboxes, { accessType: newApiKey.customizedAccess, onChangeAccessType: function (func) { return setNewApiKey(function (prev) { return updateAuto(prev, { customizedAccess: { $apply: func } }); }); } }),
            React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                React.createElement(Button, { type: 'blue', size: 'medium', onClick: addNewKey, disabled: addingNewKey }, addingNewKey ? React.createElement(LoadingDots, null) : 'Save'),
                React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: function () { setNewApiKey(defaultNewKey); setShowAddNewKeyModal(false); }, disabled: addingNewKey }, "Cancel")))));
};
var ValidUntil = function (props) {
    return (React.createElement(React.Fragment, null));
};
var ApiKeyMenu = function (props) {
    var _a = useState(false), showMenu = _a[0], setShowMenu = _a[1];
    var addToast = useToast();
    var onDelete = function () {
        var _a;
        ApiRequests.ApiKeys.delele(props.apiKey.projectPk, (_a = props.apiKey) === null || _a === void 0 ? void 0 : _a.pk, function (response) {
            if (response.status == '200') {
                addToast('Success', 'success');
                props.onAfterDelete();
            }
            else {
                addToast(response.errors, 'danger');
            }
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ThreeDotsWithMenu, { options: [{ text: 'Delete', onClick: onDelete }] })));
};
