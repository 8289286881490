var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
//@ts-ignore
import style from './website.module.scss';
import { NavLink, useParams } from 'react-router-dom';
import { Button, FlexDiv, Header1, Line, LoadingDots, P, ShowIf } from '../components/Components';
import { undefinedOrEmpty } from '../utils/Helpers';
import { Pricing } from './Pricing';
import { Documentation } from './Documentation';
import { Downbar, HeroMultipleWithImages, LogoSvg, TextHeaderTyper } from './WebsiteCompoents';
import { ReactGuide } from './ReactGuide';
import { ReactTranslatorWrapper, Translate, useTranslation } from '../usageComponents/forReact';
export var WebsiteWrapper = function (props) {
    var nestedWebPath = useParams().nestedWebPath;
    return (React.createElement(React.Fragment, null,
        React.createElement(ReactTranslatorWrapper, { translayConiguration: { allowInlineEdit: true, enableCDN: true, translayProjectId: 1 }, loader: { showLoaderWhenInitating: true, loaderComponent: React.createElement(LoadingDots, null) }, defaultLocale: 'en_US', availableLocales: ['en_US', 'sv_SE'] },
            React.createElement(WebsiteMenu, null),
            React.createElement(ShowIf, { show: undefinedOrEmpty(nestedWebPath) },
                React.createElement(Main, null)),
            React.createElement(ShowIf, { show: nestedWebPath == 'pricing' },
                React.createElement(Pricing, null)),
            React.createElement(ShowIf, { show: nestedWebPath == 'document' },
                React.createElement(Documentation, null)),
            React.createElement(ShowIf, { show: nestedWebPath == 'reactguide' },
                React.createElement(ReactGuide, null)),
            React.createElement(Downbar, null))));
};
var Main = function () {
    var translate = useTranslation().translate;
    return (React.createElement(React.Fragment, null,
        React.createElement(Hero, null,
            React.createElement(CenterPart, null,
                React.createElement(Header, null,
                    React.createElement(FlexDiv, { alignCenter: true },
                        "Translate Your ",
                        React.createElement(TextHeaderTyper, { style: { marginLeft: '10px' }, texts: ['App', 'Website', 'Game'] }))),
                React.createElement(FlexDiv, { alignCenter: true, style: { flexDirection: 'column' } },
                    React.createElement(P, { style: { marginTop: '40px', maxWidth: '600px' } }, "Localize is a no-code translation solution for software platforms, allowing you to easily translate your web app, UI, website, help docs, emails, and more."),
                    React.createElement(FlexDiv, { style: { marginTop: '30px' }, alignCenter: true },
                        React.createElement(Button, { moreRounded: true, type: 'pink' }, "Get started"),
                        React.createElement(Button, { type: 'white-no-border', style: { marginLeft: '20px' } }, "Login"))))),
        React.createElement(CenterPart, { style: { marginTop: '40px' } },
            React.createElement(HeroMultipleWithImages, { header: translate('Go global with a scalable localization software'), belowHeader: React.createElement(FlexDiv, null,
                    React.createElement(Button, { type: 'pink' },
                        React.createElement(Translate, null, "Get started")),
                    React.createElement(Button, { type: 'white-no-border', style: { marginLeft: '20px' } }, "View video")), objects: [
                    {
                        header: translate('Manage your multilingual content in one place'),
                        image: 'srchref',
                        text: translate('Localize is a no-code translation solution for software platforms, allowing you to easily translate your web app, UI, website, help docs, emails, and more.')
                    },
                    {
                        header: translate('Manage your multilingual content in one place'),
                        image: 'srchref',
                        text: translate('Localize is a no-code translation solution for software platforms, allowing you to easily translate your web app, UI, website, help docs, emails, and more.')
                    }
                ] })),
        React.createElement(CenterPart, { style: { marginTop: '40px' } },
            React.createElement(Header, { size: 'medium' }, "Helping 1000+ Companies Go Global"),
            React.createElement(Line, { style: { width: '100%', marginBottom: '40px' } }),
            React.createElement(FlexDiv, null,
                React.createElement(HeaderAndText, { header: '90%', color: '#9b51e0' }, "Reduction in time to market"),
                React.createElement(HeaderAndText, { style: { marginLeft: '60px' }, header: 'Translate your website', color: '#2e7997' }, "Localize is a no-code translation solution for software platforms, allowing you to easily translate your web app, UI, website, help docs, emails, and more."),
                React.createElement(HeaderAndText, { style: { marginLeft: '60px' }, header: 'Translate your website', color: '#517be0' }, "Localize is a no-code translation solution for software platforms, allowing you to easily translate your web app, UI, website, help docs, emails, and more."))),
        React.createElement("div", null)));
};
export var HeaderAndText = function (props) {
    return (React.createElement("div", { style: __assign({ maxWidth: '260px', textAlign: 'center' }, props.style) },
        React.createElement(Header1, { style: { fontSize: '36px', color: props.color } }, props.header),
        React.createElement(P, { style: { fontSize: '18px', maxWidth: '' } }, props.children)));
};
export var CenterPart = function (props) {
    return (React.createElement(FlexDiv, { style: __assign({ justifyContent: 'center', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto', maxWidth: '900px', padding: '50px' }, props.style) }, props.children));
};
export var Header = function (props) {
    return (React.createElement("div", { className: style.Header + ' ' + (props.size == 'medium' ? style.HeaderMedium : '') }, props.children));
};
export var WebsiteMenu = function (props) {
    var nestedWebPath = useParams().nestedWebPath;
    var _a = useTranslation(), translate = _a.translate, changeLocale = _a.changeLocale, currentLocale = _a.currentLocale;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: style.MenuOuterWrapper },
            React.createElement("div", { className: style.MenuWrapper },
                React.createElement("div", { style: { flex: 1 } },
                    React.createElement(FlexDiv, { alignCenter: true },
                        React.createElement(NavLink, { to: '/web' },
                            " ",
                            React.createElement(LogoSvg, null)),
                        React.createElement(WebsiteMenuItem, { title: 'Home', urlLink: '/web/', isActive: undefinedOrEmpty(nestedWebPath) }),
                        React.createElement(WebsiteMenuItem, { title: 'Pricing', urlLink: '/web/pricing', isActive: nestedWebPath == 'pricing' }),
                        React.createElement(WebsiteMenuItem, { title: 'Documentation', urlLink: '/web/document', isActive: nestedWebPath == 'document' }))),
                React.createElement(FlexDiv, { alignCenter: true },
                    React.createElement(NavLink, { to: '/' },
                        React.createElement(Button, { style: { marginRight: '20px' }, size: 'large', type: 'white-no-border' }, "Login")),
                    React.createElement(Button, { type: 'pink' }, " Try it now")))),
        React.createElement(FlexDiv, null)));
};
var WebsiteMenuItem = function (props) {
    return (React.createElement(NavLink, { to: props.urlLink },
        React.createElement("div", { className: style.MenuItemWrapper },
            React.createElement("div", { className: style.MenuItem + ' ' + (props.isActive ? style.MenuItemActive : ''), onClick: props.onClick }, props.title))));
};
export var Hero = function (props) {
    return (React.createElement("div", { style: props.style, className: style.Hero }, props.children));
};
