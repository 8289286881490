import React, { useEffect, useMemo, useState } from 'react';
import { Button, ExpandCard, ExpandHeader, FlexDiv, InfoBox, Input, Label, LiveCircle, LoadingDots, P, Select, ShowIf, SmallColumnMenu, Tooltip, useToast } from '@/src/components/Components';
import { copyToClipboard, formatDateFromFormat, undefinedOrEmpty, usePersistedState } from '@/src/utils/Helpers';
import { extractDefaultCountryCodeFromLanguageCode, languageCodeToName } from '@/src/utils/LanguageHelpers';
import { getFlagName } from '@/src/components/PageComponents';
import { ApiRequests } from '@/src/requests/ApiRequests';
import { CLISvg, CodeSvg, CopySvg, DownloadArrowSvg, GoogleCloudSvg, InfoSvg } from '@/src/components/SvgItems';
import Types from '@/shared/Types';
import { useParams } from 'react-router-dom';
import { ExportFiles } from '@/src/utils/ExportFiles';
import { stopPropagation } from '@/shared/Helpers';
export var ExportProject = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var _g = useState('values-%LANG_ISO%/strings.%FORMAT%'), fileStructure = _g[0], setfileStructure = _g[1];
    var _h = usePersistedState('selectedFileFormat_export', ''), selectedFileFormat = _h[0], setSelectedFileFormat = _h[1];
    var _j = useState(['all']), selectedLanguages = _j[0], setselectedLanguages = _j[1];
    var nestedNestedProjectPath = useParams().nestedNestedProjectPath;
    var options = useMemo(function () {
        var _a, _b;
        return (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.languages) === null || _b === void 0 ? void 0 : _b.map(function (d) { return { value: d === null || d === void 0 ? void 0 : d.language_code, displayValue: languageCodeToName(d === null || d === void 0 ? void 0 : d.language_code), imgSrc: getFlagName(extractDefaultCountryCodeFromLanguageCode(d === null || d === void 0 ? void 0 : d.language_code)) }; });
    }, [(_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.languages]);
    /*
    
      const getFormattedExample = () => {
        //Replace %FORMAT% with selected file format
        //Replace %LANG_ISO% with language
        //Replace %LANG_NAME% with language name
        //Replace %PROJECT_NAME% with project name
        //Replace %COUNTRY_ISO% with country iso
        let tmp = fileStructure;
        tmp = tmp.replace('%FORMAT%', Types.FileTypeExports?.find(d => d?.value === selectedFileFormat)?.extension);
        tmp = tmp.replace('%LANG_ISO%', 'en');
        tmp = tmp.replace('%LANG_NAME%', 'English');
        tmp = tmp.replace('%PROJECT_NAME%', props.detailedProject?.name);
        tmp = tmp.replace('%COUNTRY_ISO%', 'US');
    
        return tmp;
      }
     */
    var downloadFile = function () {
        ExportFiles.exportAndDownloadFile({ detailedProject: props.detailedProject, fileStructure: fileStructure, fileType: selectedFileFormat, languagesToExport: selectedLanguages });
    };
    var availableExportFormats = useMemo(function () {
        return [
            { value: 'uniq123', displayValue: 'JSON', isHeader: true },
            { value: 'json', displayValue: 'JSON (.json)' },
            { value: 'json_struct', displayValue: 'Structured JSON (.json)' },
            { value: 'uniq2', displayValue: 'Java', isHeader: true },
            { value: 'java', displayValue: 'Java Properties (.properties)' },
            { value: 'uniq2', displayValue: 'PHP', isHeader: true },
            { value: 'po', displayValue: 'Gettext (.po)' },
            { value: 'php_array', displayValue: 'PHP Array (.php)' },
            { value: 'laravel_json', displayValue: 'Laravel JSON (.json)' },
            { value: 'uniq3', displayValue: 'Flutter', isHeader: true },
            { value: 'flutter', displayValue: 'Flutter (.arb)' },
            { value: 'uniq4', displayValue: 'Ruby on Rails', isHeader: true },
            { value: 'ruby_on_rails', displayValue: 'Ruby on Rails (.yml)' }
        ];
    }, []);
    useEffect(function () {
        var _a, _b;
        setfileStructure(((_b = (_a = Types.FileTypeExports) === null || _a === void 0 ? void 0 : _a.find(function (k) { return (k === null || k === void 0 ? void 0 : k.value) == selectedFileFormat; })) === null || _b === void 0 ? void 0 : _b.defaultBundle) || 'locale/%LANG_ISO%.%FORMAT%');
    }, [selectedFileFormat]);
    return (React.createElement("div", null,
        React.createElement(InfoBox, { style: { marginBottom: '20px', maxWidth: '1050px' } }, "There are many multiple ways to get access to your translated string. Select what suits your needs the best."),
        React.createElement(FlexDiv, null,
            React.createElement("div", { style: { width: '250px' } },
                React.createElement(SmallColumnMenu, { items: [
                        { style: { width: '200px' }, urlLink: '/projects/' + ((_b = props.detailedProject) === null || _b === void 0 ? void 0 : _b.pk) + '/export/', title: React.createElement(FlexDiv, { alignCenter: true },
                                React.createElement(DownloadArrowSvg, null),
                                React.createElement("div", { style: { marginLeft: '10px' } }, "Download File")), isActive: undefinedOrEmpty(nestedNestedProjectPath) },
                        { style: { width: '200px' }, urlLink: '/projects/' + ((_c = props.detailedProject) === null || _c === void 0 ? void 0 : _c.pk) + '/export/cdn', title: React.createElement(FlexDiv, { alignCenter: true },
                                React.createElement(GoogleCloudSvg, null),
                                React.createElement("div", { style: { marginLeft: '10px' } }, "CDN")), isActive: nestedNestedProjectPath == 'cdn' },
                        { style: { width: '200px' }, urlLink: '/projects/' + ((_d = props.detailedProject) === null || _d === void 0 ? void 0 : _d.pk) + '/export/api', title: React.createElement(FlexDiv, { alignCenter: true },
                                React.createElement(CodeSvg, null),
                                React.createElement("div", { style: { marginLeft: '10px' } }, "API")), isActive: nestedNestedProjectPath == 'api' },
                        { style: { width: '200px' }, urlLink: '/projects/' + ((_e = props.detailedProject) === null || _e === void 0 ? void 0 : _e.pk) + '/export/cli', title: React.createElement(FlexDiv, { alignCenter: true },
                                React.createElement(CLISvg, null),
                                React.createElement("div", { style: { marginLeft: '10px' } }, "CLI")), isActive: nestedNestedProjectPath == 'cli' }
                    ] })),
            React.createElement(ShowIf, { style: { marginBottom: '100px' }, show: undefinedOrEmpty(nestedNestedProjectPath) },
                React.createElement(ExpandHeader, { defaultExpanded: true, headerLevel: 1, header: 'Download File' },
                    React.createElement(P, null, "Download a translation file for your project."),
                    React.createElement("div", null,
                        React.createElement(ExpandCard, { defaultExpanded: true, header: 'File format' },
                            React.createElement(Select, { style: { width: '600px' }, onChange: setSelectedFileFormat, value: selectedFileFormat, options: availableExportFormats })),
                        React.createElement(ExpandCard, { style: { marginTop: '20px' }, defaultExpanded: true, header: 'Languages' },
                            React.createElement(P, null, "Select which languages to export"),
                            React.createElement(Select, { style: { width: '600px' }, label: 'Select language', allowMultipleValues: true, values: selectedLanguages, onChanges: setselectedLanguages, options: options.concat([{ value: 'all', displayValue: 'All', singleOption: true }]) })),
                        React.createElement(ExpandCard, { style: { marginTop: '20px' }, defaultExpanded: true, header: 'File structure' },
                            React.createElement(Input, { label: React.createElement(FlexDiv, null,
                                    React.createElement("div", null, "Bundle structure"),
                                    React.createElement(Tooltip, { style: { marginLeft: '5px' }, overlay: 'Configure bundle structure using placeholders. You can use %LANG_ISO%, %LANG_NAME%,%COUNTRY_ISO%, %FORMAT% and %PROJECT_NAME% as specifiers.' },
                                        React.createElement(InfoSvg, null))), value: fileStructure, onChange: setfileStructure }),
                            React.createElement(P, { style: { fontSize: '12px' } },
                                "Example: ",
                                ExportFiles.convertFileStructure({ fileStructure: fileStructure, locale: 'en_US', projectName: (_f = props.detailedProject) === null || _f === void 0 ? void 0 : _f.name, selectedFileFormat: selectedFileFormat })))),
                    React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                        React.createElement(Button, { size: 'small', onClick: function () { downloadFile(); } }, "Download")))),
            React.createElement(ShowIf, { show: nestedNestedProjectPath == 'cdn' },
                React.createElement(ExpandHeader, { defaultExpanded: true, style: { marginTop: '0px' }, headerLevel: 1, header: 'CDN (Content Delivery Network)' },
                    React.createElement(PublishToCDNSection, { detailedProject: props.detailedProject }))))));
};
var PublishToCDNSection = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var _h = useState(false), fetchingCdnStatus = _h[0], setFetchingCdnStatus = _h[1];
    var _j = useState(), cdnStatus = _j[0], setCdnStatus = _j[1];
    var _k = useState('json'), selectFormat = _k[0], setSelectFormat = _k[1];
    var _l = useState((_c = (_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.languages) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.language_code), selectLocale = _l[0], setSelectLocale = _l[1];
    //const [autoPublish, setAutoPublish] = useState<boolean>(false);
    // const [showVerifyDeleteModal, setShowVerifyDeleteModal] = useState<boolean>(false);
    var addToast = useToast();
    useEffect(function () {
        fetchCdnStatus();
    }, [(_d = props.detailedProject) === null || _d === void 0 ? void 0 : _d.pk]);
    var fetchCdnStatus = function () {
        var _a;
        setFetchingCdnStatus(true);
        ApiRequests.Project.CDN.getLatestCdnStatus((_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.pk, function (response) {
            setCdnStatus(response === null || response === void 0 ? void 0 : response.data);
            setFetchingCdnStatus(false);
        });
    };
    var getStatus = function () {
        if ((cdnStatus === null || cdnStatus === void 0 ? void 0 : cdnStatus.status) == 'notPublished')
            return React.createElement(FlexDiv, { alignCenter: true },
                React.createElement(LiveCircle, { size: 'large', color: 'red' }),
                React.createElement("div", { style: { marginLeft: '5px', fontSize: '17px', color: 'black' } }, "Not published"));
        if ((cdnStatus === null || cdnStatus === void 0 ? void 0 : cdnStatus.status) == 'live')
            return React.createElement(FlexDiv, { alignCenter: true },
                React.createElement(LiveCircle, { size: 'large', color: 'green' }),
                React.createElement("div", { style: { marginLeft: '5px', fontSize: '17px', color: 'black' } }, "Live"));
        if ((cdnStatus === null || cdnStatus === void 0 ? void 0 : cdnStatus.status) == 'error')
            return 'Error';
    };
    /*  const onClickPublish = () => {
        publishToCdn();
      }
    
      const publishToCdn = () => {
        const dataToPublish: Types.PublishToCdnRequest = { fileType: 'json' };
        ApiRequests.Project.CDN.publishToCdn(props.detailedProject?.pk, dataToPublish, (response) => {
          if (response?.status == '200') {
            fetchCdnStatus();
          }
        })
      }
    
      const unpublishCdn = () => {
        ApiRequests.Project.CDN.unpublishCdn(props.detailedProject?.pk, (response) => {
          if (response?.status == '200') {
            fetchCdnStatus();
          }
          else {
            addToast(response?.errors, 'danger');
          }
        })
      } */
    var fullLink = useMemo(function () {
        return (cdnStatus === null || cdnStatus === void 0 ? void 0 : cdnStatus.url) + '?format=' + selectFormat + '&locale=' + selectLocale;
    }, [cdnStatus === null || cdnStatus === void 0 ? void 0 : cdnStatus.url, selectFormat, selectLocale]);
    return (React.createElement("div", { style: { maxWidth: '800px' } },
        React.createElement(ExpandCard, { defaultExpanded: true, header: 'STATUS', style: { position: 'relative' } },
            React.createElement(ShowIf, { show: fetchingCdnStatus },
                React.createElement(LoadingDots, null)),
            React.createElement(ShowIf, { show: !fetchingCdnStatus },
                React.createElement("div", null, getStatus()),
                React.createElement(ShowIf, { show: (cdnStatus === null || cdnStatus === void 0 ? void 0 : cdnStatus.status) == 'live' },
                    React.createElement(Label, { size: 'small', style: { margin: '10px 0' }, label: 'Published', text: formatDateFromFormat(cdnStatus === null || cdnStatus === void 0 ? void 0 : cdnStatus.publishedDate, 'yyyy-MM-dd hh:mm') }),
                    React.createElement(Label, { size: 'small', style: { margin: '10px 0' }, label: 'URL' },
                        React.createElement(FlexDiv, { alignCenter: true },
                            React.createElement("div", { style: { color: 'gray' } },
                                React.createElement("a", { href: fullLink, target: '_blank' },
                                    React.createElement("div", { style: { display: 'block', color: '#516fce', fontSize: '12px', textDecoration: 'underline' } },
                                        (cdnStatus === null || cdnStatus === void 0 ? void 0 : cdnStatus.url) + '?format=',
                                        React.createElement("select", { onClick: function (e) { return stopPropagation(e); }, style: { width: '100px', display: 'inline-block' }, value: selectFormat, onChange: function (e) { setSelectFormat(e.target.value); } }, (_e = Types.FileTypeExports) === null || _e === void 0 ? void 0 : _e.map(function (d) { return React.createElement("option", { value: d === null || d === void 0 ? void 0 : d.extension }, d === null || d === void 0 ? void 0 : d.extension); })),
                                        '&locale=',
                                        React.createElement("select", { onClick: function (e) { return stopPropagation(e); }, style: { width: '100px', display: 'inline-block' }, value: selectLocale, onChange: function (e) { return setSelectLocale(e.target.value); } }, (_g = (_f = props.detailedProject) === null || _f === void 0 ? void 0 : _f.languages) === null || _g === void 0 ? void 0 : _g.map(function (d) { return React.createElement("option", { value: d === null || d === void 0 ? void 0 : d.language_code }, (d === null || d === void 0 ? void 0 : d.language_code)); }))))),
                            React.createElement("div", { style: { marginLeft: '10px' }, onClick: function () { return copyToClipboard(fullLink); } },
                                React.createElement(Button, { size: 'small', type: 'gray-text' },
                                    React.createElement(CopySvg, null))))),
                    React.createElement("div", { style: { marginTop: '20px' } },
                        React.createElement(Button, { size: 'small', type: 'redwhite' }, "Disable"),
                        React.createElement(FlexDiv, null,
                            React.createElement(Tooltip, { overlay: 'Disabling the CDN will make the endpoint unavailable. This means that your application will not be able to fetch translations from the CDN.', style: { marginLeft: '0px' } },
                                React.createElement("div", { style: { fontSize: '12px', color: 'gray' } }, "Help?"))))))),
        React.createElement(InfoBox, { type: 'gray', style: { marginTop: '20px' } },
            React.createElement("b", null, "Note:"),
            " you can use different file formats for the same CDN endpoint by specifying the `format` parameter."),
        React.createElement(InfoBox, { type: 'gray', style: { marginTop: '10px' } },
            React.createElement("b", null, "Also note:"),
            " the published translations are cached in the CDN. This means that after a publish is made, it can take up to 1 hour before all translations are live.")));
};
/*


  <ExpandHeader defaultExpanded header='Download translation file' headerLevel={1} headerRightPart={<Button size='small'>Quick Download (Saved preset)</Button>}>

        <P>Vivamus vestibulum mi turpis, quis rhoncus libero tincidunt ac. Maecenas a eros fermentum, interdum velit in, lacinia nulla. In hac habitasse platea dictumst. Praesent risus mi, pretium condimentum rutrum id, tempus ut ex. </P>

      </ExpandHeader>

      <ExpandHeader defaultExpanded header='API Integration' headerLevel={1}>

        <P>Vivamus vestibulum mi turpis, quis rhoncus libero tincidunt ac. Maecenas a eros fermentum, interdum velit in, lacinia nulla. In hac habitasse platea dictumst. Praesent risus mi, pretium condimentum rutrum id, tempus ut ex. </P>

        <ExpandHeader header='GET ALL TRANSLATIONS THROUGH HTTP' headerLevel={2}>

          <P>Vivamus vestibulum mi turpis, quis rhoncus libero tincidunt ac. Maecenas a eros fermentum, interdum velit in, lacinia nulla. In hac habitasse platea dictumst. Praesent risus mi, pretium condimentum rutrum id, tempus ut ex. </P>

        </ExpandHeader>

        <ExpandHeader defaultExpanded header='INTEGRATE TRANSLATIONS IN YOUR PLATFORM' headerLevel={2}>

          <P>Vivamus vestibulum mi turpis, quis rhoncus libero tincidunt ac. Maecenas a eros fermentum, interdum velit in, lacinia nulla. In hac habitasse platea dictumst. Praesent risus mi, pretium condimentum rutrum id, tempus ut ex. </P>

          <ExpandHeader defaultExpanded header='React' headerLevel={2}>

            <P>Vivamus vestibulum mi turpis, quis rhoncus libero tincidunt ac. Maecenas a eros fermentum, interdum velit in, lacinia nulla. In hac habitasse platea dictumst. Praesent risus mi, pretium condimentum rutrum id, tempus ut ex. </P>


            <CodeWindow>
              {'import { useTranslate } from "react-translate";'}
            </CodeWindow>
          </ExpandHeader>


        </ExpandHeader>



      </ExpandHeader>

      <ExpandHeader defaultExpanded header='Webhooks' headerLevel={1} >

        <P>Vivamus vestibulum mi turpis, quis rhoncus libero tincidunt ac. Maecenas a eros fermentum, interdum velit in, lacinia nulla. In hac habitasse platea dictumst. Praesent risus mi, pretium condimentum rutrum id, tempus ut ex. </P>

      </ExpandHeader> */ 
