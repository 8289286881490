'use strict';
import 'whatwg-fetch';
function getMeta(metaName) {
    //return '*'; //Debug
    var metas = document.getElementsByTagName('meta');
    for (var i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === metaName) {
            return metas[i].getAttribute('content');
        }
    }
    return '';
}
var saveXCsrf = function (x) {
    //return '*'; //Debug
    var metas = document.getElementsByTagName('meta');
    for (var i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === '_csrf') {
            metas[i].setAttribute('content', x);
        }
    }
    return '';
};
function nu(value) {
    var r = (typeof value !== 'undefined') && (value != null);
    return r;
}
export function buildUrl(url, parameters) {
    var qs = "";
    var urlCopy = url;
    //@ts-ignore
    for (var key in parameters) {
        var value = parameters[key];
        if (isValidDate(value))
            value = value.toISOString();
        if (nu(value))
            qs += encodeURIComponent(key) + "=" + encodeURIComponent(value) + "&";
    }
    if (qs.length > 0) {
        qs = qs.substring(0, qs.length - 1); //chop off last "&"
        urlCopy = urlCopy + "?" + qs;
    }
    return urlCopy;
}
var isValidDate = function (d) {
    //@ts-ignore
    return d instanceof Date && !isNaN(d);
};
export var fetchAPI = function (type, url, data, cb, sendFile) {
    var param = type != 'DELETE' ? {
        method: type,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            //	'x-csrf-token': getMeta('_csrf')
        },
        body: JSON.stringify(data)
    } : { method: type };
    if (sendFile) {
        param = {
            method: type,
            //@ts-ignore
            headers: {
                Accept: 'application/json',
                //		'x-csrf-token': getMeta('_csrf')
            },
            body: data
        };
    }
    if (type == 'GET') {
        delete param.body;
    }
    var returnResponse = { data: '', status: '', errors: '' };
    window.fetch(url, param).then(function (response) {
        var newXCrsf = response.headers.get('x-csrf-token');
        saveXCsrf(newXCrsf);
        //@ts-ignore
        returnResponse.status = response.status;
        //@ts-ignore
        if (response.status == '400' || response.status == '500')
            return response.text();
        try {
            return response.json();
        }
        catch (e) {
            return response.text();
        }
    })
        .catch(function (error) { return ""; })
        .then(function (content) {
        try {
            var content = content.replace(/\\/g, ""); //Replace all backslashes
            content = JSON.parse(content);
        }
        catch (e) {
            var k = ''; //Debug
        }
        if (content || content == "") {
            if (nu(content === null || content === void 0 ? void 0 : content.errors)) {
                returnResponse.errors = content.errors;
            }
            returnResponse.data = content;
            if (Array.isArray(cb)) {
                cb.forEach(function (method) {
                    method(returnResponse);
                });
            }
            else {
                cb(returnResponse);
            }
        }
    });
};
export var fetchAPIFile = function (url) {
    var param = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-csrf-token': getMeta('_csrf')
        },
    };
    return window.fetch(url, param);
};
