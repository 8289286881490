import { notUndefinedOrEmpty } from '@/src/utils/Helpers';
import { updateAuto } from './Helpers';
export var ParseFiles;
(function (ParseFiles) {
    function appendFileName(d, fileName) {
        return updateAuto(d, { fileName: { $set: fileName } });
    }
    ParseFiles.appendFileName = appendFileName;
    function parseFileContentBasedOnContent(content, fileName) {
        var fileExtension = fileName.split('.').pop();
        switch (fileExtension) {
            case 'po':
                return appendFileName(parsePOFile(content), fileName);
            case 'strings':
                return appendFileName(parseIOSStringsFile(content), fileName);
            case 'php':
                return appendFileName(parsePhpFile(content), fileName);
            case 'properties':
                return appendFileName(parseJavaPropertiesFile(content), fileName);
            default:
                console.error("Unsupported file extension: ".concat(fileExtension));
                return null;
        }
    }
    ParseFiles.parseFileContentBasedOnContent = parseFileContentBasedOnContent;
    function parsePhpFile(content) {
        //Check if array
        var tmpFile = parsePhpArrayFile(content);
        if (!tmpFile) {
            tmpFile = parsePhpLaravelFile(content);
        }
        if (!tmpFile) {
            tmpFile = parsePhpFromContent(content);
        }
        return tmpFile;
        //Check if php laravel array
    }
    ParseFiles.parsePhpFile = parsePhpFile;
    function parsePhpFromContent(content) {
        //Extract __('') and _e('') and _x('','','')
        return null;
    }
    ParseFiles.parsePhpFromContent = parsePhpFromContent;
    function parsePhpArrayFile(content) {
        var _a, _b;
        try {
            // Map to the desired types
            var fileUpload = { fileName: '', codes: [] };
            for (var _i = 0, _c = content.split('\n'); _i < _c.length; _i++) {
                var line = _c[_i];
                var trimmedLine = line.trim();
                // Ignore comments and empty lines
                if (trimmedLine.startsWith('//') || trimmedLine.startsWith('#') || trimmedLine === '') {
                    continue;
                }
                // THis is an example, extract before and after => 'Ditt medlemsnummer är: ' => '',
                var regex = /'([^']+)'\s*=>\s*'([^']+)'/;
                var match = regex.exec(trimmedLine);
                if (match) {
                    var key = (_a = match[1]) === null || _a === void 0 ? void 0 : _a.trim();
                    var value = (_b = match[2]) === null || _b === void 0 ? void 0 : _b.trim();
                    fileUpload = updateAuto(fileUpload, { codes: { $push: [{ code: key, translation: { text: value } }] } });
                }
            }
            return fileUpload;
        }
        catch (error) {
            console.error('Error parsing file:', error);
            return null;
        }
    }
    ParseFiles.parsePhpArrayFile = parsePhpArrayFile;
    function parsePhpLaravelFile(content) {
        try {
            // Map to the desired types
            var fileUpload = { fileName: '', codes: [] };
            for (var _i = 0, _a = content.split('\n'); _i < _a.length; _i++) {
                var line = _a[_i];
                var trimmedLine = line.trim();
                // Ignore comments and empty lines
                if (trimmedLine.startsWith('//') || trimmedLine.startsWith('#') || trimmedLine === '') {
                    continue;
                }
                var regex = /\$code\["([^"]+)"\]\s*=\s*"(.*?)";/;
                var match = regex.exec(trimmedLine);
                if (match) {
                    var key = match[1];
                    var value = match[2];
                    fileUpload = updateAuto(fileUpload, { codes: { $push: [{ code: key, translation: { text: value } }] } });
                }
            }
            return fileUpload;
        }
        catch (error) {
            console.error('Error parsing file:', error);
            return null;
        }
    }
    ParseFiles.parsePhpLaravelFile = parsePhpLaravelFile;
    function parsePOFile(content) {
        var _a, _b;
        try {
            // Split the content into lines
            var lines = content.split('\n');
            // Initialize variables for parsing
            var currentCode = void 0;
            // Map to the desired types
            var fileUpload = { fileName: '', codes: [] };
            var _loop_1 = function (line) {
                //This is the code line
                if (line.startsWith('msgid')) {
                    //Add the previos code if non empty
                    if (notUndefinedOrEmpty(currentCode)) {
                        fileUpload = updateAuto(fileUpload, { codes: { $push: [currentCode] } });
                    }
                    currentCode = null;
                    // Extract the content between "msgid" and the quotes. Use regex
                    var extractedCodeFromLin = (_a = line.match(/"([^"]+)"/)) === null || _a === void 0 ? void 0 : _a[1];
                    currentCode = updateAuto(currentCode, { code: { $set: extractedCodeFromLin } });
                }
                //This is the translation line for the previous code
                else if (line.startsWith('msgstr')) {
                    // Start of a new message string
                    // Extract the content between "msgstr" and the quotes. Use regex safely
                    var translationText_1 = (_b = line.match(/"([^"]+)"/)) === null || _b === void 0 ? void 0 : _b[1];
                    // Add the translation to the current code
                    currentCode = updateAuto(currentCode, { translation: { text: { $apply: function (p) { return p + translationText_1; } } } });
                }
            };
            //Loop all codes
            for (var _i = 0, lines_1 = lines; _i < lines_1.length; _i++) {
                var line = lines_1[_i];
                _loop_1(line);
            }
            //Add the last one
            if (notUndefinedOrEmpty(currentCode)) {
                fileUpload = updateAuto(fileUpload, { codes: { $push: [currentCode] } });
            }
            return fileUpload;
        }
        catch (error) {
            console.error('Error parsing PO file content:', error);
            // Handle parsing error as needed
            return null;
        }
    }
    ParseFiles.parsePOFile = parsePOFile;
    function parseIOSStringsFile(content) {
        try {
            // Map to the desired types
            var fileUpload = { fileName: '', codes: [] };
            for (var _i = 0, _a = content.split('\n'); _i < _a.length; _i++) {
                var line = _a[_i];
                var trimmedLine = line.trim();
                // Ignore comments and empty lines
                if (trimmedLine.startsWith('//') || trimmedLine.startsWith('#') || trimmedLine === '') {
                    continue;
                }
                // Handle key-value pairs
                var _b = trimmedLine.split('='), key = _b[0], value = _b[1];
                if (key && value) {
                    var cleanedKey = key.trim();
                    var cleanedValue = value.trim().replace(/[";]/g, ''); // Remove quotes and semicolon
                    fileUpload = updateAuto(fileUpload, { codes: { $push: [{ code: cleanedKey, translation: { text: cleanedValue } }] } });
                }
            }
            return fileUpload;
        }
        catch (error) {
            console.error('Error parsing iOS strings file content:', error);
            // Handle parsing error as needed
            return null;
        }
    }
    ParseFiles.parseIOSStringsFile = parseIOSStringsFile;
    function parseJavaPropertiesFile(content) {
        return parseIOSStringsFile(content); //Same as the other
    }
    ParseFiles.parseJavaPropertiesFile = parseJavaPropertiesFile;
})(ParseFiles || (ParseFiles = {}));
