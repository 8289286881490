import { Button, Card, ExpandHeader, FlexDiv, Header2, Input, LoadingDots, Modal, Table, ThreeDotsWithMenu, useToast } from '@/src/components/Components';
import { ApiRequests } from '@/src/requests/ApiRequests';
import { updateAuto } from '@/src/utils/Helpers';
import React, { useState } from 'react';
import { CircleWithNameAndEmail } from '../project/ProjectContributors';
export var TeamUsers = function (props) {
    var _a, _b;
    var _c = useState(false), showAddNewUserModal = _c[0], setShowAddNewUserModal = _c[1];
    var defaultTeamMember = { email: '', name: '', role: 'admin', userPk: null };
    var _d = useState(defaultTeamMember), newTeamMember = _d[0], setNewTeamMember = _d[1];
    var _e = useState(false), addingTeamMember = _e[0], setAddingTeamMember = _e[1];
    var _f = useState(), deletingTeamMemberWithPk = _f[0], setDeletingTeamMemberWithPk = _f[1];
    var addToast = useToast();
    var deleteTeamMember = function (teamMember) {
        var _a;
        setDeletingTeamMemberWithPk(teamMember.userPk);
        ApiRequests.Team.Members.delete((_a = props.teamSettings) === null || _a === void 0 ? void 0 : _a.pk, teamMember.userPk, function (response) {
            var _a, _b;
            if (response.status == '200') {
                addToast('Deleted!', 'success');
                props.afterSave(updateAuto(props.teamSettings, { members: { $splice: [[(_b = (_a = props.teamSettings) === null || _a === void 0 ? void 0 : _a.members) === null || _b === void 0 ? void 0 : _b.findIndex(function (m) { return m.userPk == teamMember.userPk; }), 1]] } }));
            }
            else {
                addToast(response.errors, 'danger');
            }
            setDeletingTeamMemberWithPk(undefined);
        });
    };
    var addTeamMember = function () {
        var _a;
        setAddingTeamMember(true);
        ApiRequests.Team.Members.post((_a = props.teamSettings) === null || _a === void 0 ? void 0 : _a.pk, newTeamMember, function (response) {
            if (response.status == '200') {
                setNewTeamMember(defaultTeamMember);
                setShowAddNewUserModal(false);
                addToast('Saved!', 'success');
                props.afterSave(updateAuto(props.teamSettings, { members: { $push: [response.data] } }));
            }
            else {
                addToast(response.errors, 'danger');
            }
            setAddingTeamMember(false);
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ExpandHeader, { defaultExpanded: true, style: { marginTop: '20px' }, header: 'Team Members', headerLevel: 2 },
            React.createElement(Card, { style: { maxWidth: '1000px' } },
                React.createElement(Table, { doNotShowLeftHeadercontent: true, style: { width: 'calc(100% - 20px)', maxWidth: '1000px', borderSpacing: '0 1em' }, header: [
                        { content: 'Name' },
                        { content: 'Email' },
                        { content: 'Role' },
                        { content: 'Joined' },
                        { content: 'Invited by' },
                        { content: 'Last active' },
                        { content: '' }
                    ], rows: (_b = (_a = props.teamSettings) === null || _a === void 0 ? void 0 : _a.members) === null || _b === void 0 ? void 0 : _b.map(function (member, i) {
                        return {
                            items: [
                                { content: React.createElement(FlexDiv, null,
                                        React.createElement(CircleWithNameAndEmail, { name: 'Patrik Starck', email: '' })) },
                                { content: member === null || member === void 0 ? void 0 : member.email },
                                { content: member.role == 'owner' ? 'Owner' : 'Admin' },
                                { content: 'Oct 03, 2023' },
                                { content: 'Patrik Starck' },
                                { content: 'Oct 13, 2023' },
                                { style: { width: '2px' }, content: React.createElement(TeamMemberMenu, { disableDelete: member.role == 'owner', onClickDelete: function () { return deleteTeamMember(member); }, teamMember: member })
                                }
                            ]
                        };
                    }) }),
                React.createElement(Button, { style: { marginTop: '20px' }, buttonCss: { minWidth: '250px' }, size: 'medium', type: 'blue', onClick: function () { return setShowAddNewUserModal(true); } }, "+ Add new member"))),
        React.createElement(Modal, { style: { maxWidth: '600px' }, show: showAddNewUserModal, onHide: function () { return setShowAddNewUserModal(false); } },
            React.createElement(Header2, null, "Add new team member"),
            React.createElement(Input, { placeHolder: 'Enter name', style: { marginTop: '10px' }, label: 'Name', value: newTeamMember === null || newTeamMember === void 0 ? void 0 : newTeamMember.name, onChange: function (v) { return setNewTeamMember(function (prev) { return updateAuto(prev, { name: { $set: v } }); }); } }),
            React.createElement(Input, { placeHolder: 'Enter email', style: { marginTop: '10px' }, label: 'Email', value: newTeamMember === null || newTeamMember === void 0 ? void 0 : newTeamMember.email, onChange: function (v) { return setNewTeamMember(function (prev) { return updateAuto(prev, { email: { $set: v } }); }); } }),
            React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                React.createElement(Button, { type: 'blue', size: 'medium', onClick: addTeamMember, disabled: addingTeamMember }, addingTeamMember ? React.createElement(LoadingDots, null) : 'Save'),
                React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: function () { setNewTeamMember(defaultTeamMember); setShowAddNewUserModal(false); }, disabled: addingTeamMember }, "Cancel")))));
};
var TeamMemberMenu = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(ThreeDotsWithMenu, { options: [{ text: 'Delete', disabled: props.disableDelete, onClick: props.onClickDelete }] })));
};
