import { fetchAPI } from "../utils/http/ajax";
export var ApiRequests = {
    Project: {
        get: function (pk, callback) {
            fetchAPI('GET', getProjectLink(pk), '', function (response) {
                callback(response);
            });
        },
        put: function (data, callback) {
            fetchAPI('PUT', getProjectLink(data.id), data, function (response) {
                callback(response);
            });
        },
        FileUpload: {
            uploadFile: function (project_id, data, callback) {
                fetchAPI('POST', '/projects/' + project_id + '/upload', data, function (response) {
                    callback(response);
                });
            }
        }
    },
    Codes: {
        post: function (project_id, data, callback) {
            fetchAPI('POST', getCodeLink(project_id), data, function (response) {
                callback(response);
            });
        },
        get: function (project_id, code_id, callback) {
            fetchAPI('GET', getCodeLink(project_id) + code_id, '', function (response) {
                callback(response);
            });
        },
        getAll: function (project_id, callback) {
            fetchAPI('GET', getCodeLink(project_id), '', function (response) {
                callback(response);
            });
        },
        put: function (data, callback) {
            fetchAPI('PUT', getCodeLink(data.project_id) + data.id, data, function (response) {
                callback(response);
            });
        },
        delele: function (project_id, pk, callback) {
            fetchAPI('DELETE', getCodeLink(project_id) + pk, '', function (response) {
                callback(response);
            });
        }
    },
    Tags: {
        post: function (project_id, data, callback) {
            fetchAPI('POST', getTagLink(project_id), data, function (response) {
                callback(response);
            });
        },
        getAll: function (project_id, callback) {
            fetchAPI('GET', getTagLink(project_id), '', function (response) {
                callback(response);
            });
        },
        delele: function (project_id, pk, callback) {
            fetchAPI('DELETE', getTagLink(project_id) + pk, '', function (response) {
                callback(response);
            });
        }
    },
    Translations: {
        /*post: (code_id: string, data: ApiTypes.Translation, callback: (arg0: FetchResponseType<ApiTypes.WrapStatisticsChange<ApiTypes.Translation>>) => void) => {
            fetchAPI('POST', getTranslationLink({ code_id: code_id, translationPk: '' }), data, (response) => {
                callback(response);
            });
        },*/
        put: function (data, callback) {
            fetchAPI('PUT', getTranslationLink({ project_id: data.project_id, code_id: data.code_id, locale: data.full_locale }), data, function (response) {
                callback(response);
            });
        },
        delete: function (data, callback) {
            fetchAPI('DELETE', getTranslationLink({ project_id: data.project_id, code_id: data.code_id, locale: data.full_locale }), '', function (response) {
                callback(response);
            });
        },
        machineTranslate: function (data, callback) {
            fetchAPI('POST', getTranslationLink({ project_id: data === null || data === void 0 ? void 0 : data.translation.project_id, code_id: data.translation.code_id, locale: data.translation.full_locale }) + '/machinetranslate', data, function (response) {
                callback(response);
            });
        }
    },
    Languages: {
        post: function (project_id, data, callback) {
            fetchAPI('POST', getLanguageLink(project_id), data, function (response) {
                callback(response);
            });
        },
        getAll: function (project_id, callback) {
            fetchAPI('GET', getLanguageLink(project_id), '', function (response) {
                callback(response);
            });
        },
        get: function (project_id, locale, callback) {
            fetchAPI('GET', getLanguageLink(project_id) + locale, '', function (response) {
                callback(response);
            });
        },
        put: function (data, callback) {
            fetchAPI('PUT', getLanguageLink(data.project_id) + data.language_code, data, function (response) {
                callback(response);
            });
        },
        delele: function (project_id, locale, callback) {
            fetchAPI('DELETE', getLanguageLink(project_id) + locale, '', function (response) {
                callback(response);
            });
        }
    },
    Contributors: {
        post: function (project_id, data, callback) {
            fetchAPI('POST', getContributorLink(project_id), data, function (response) {
                callback(response);
            });
        },
        getAll: function (project_id, callback) {
            fetchAPI('GET', getContributorLink(project_id), '', function (response) {
                callback(response);
            });
        },
        get: function (project_id, pk, callback) {
            fetchAPI('GET', getContributorLink(project_id) + pk, '', function (response) {
                callback(response);
            });
        },
        put: function (data, callback) {
            fetchAPI('PUT', getContributorLink(data.project_id) + data.id, data, function (response) {
                callback(response);
            });
        },
        delele: function (project_id, pk, callback) {
            fetchAPI('DELETE', getContributorLink(project_id) + pk, '', function (response) {
                callback(response);
            });
        }
    }
};
var getTranslationLink = function (data) { return '/api/v1/projects/' + data.project_id + '/codes/' + data.code_id + '/translations/' + data.locale; };
var getCodeLink = function (project_id) { return '/api/v1/projects/' + project_id + '/codes/'; };
var getTagLink = function (project_id) { return '/api/v1/projects/' + project_id + '/tags/'; };
var getProjectLink = function (project_id) { return '/api/v1/projects/' + (project_id ? project_id : ''); };
var getLanguageLink = function (project_id) { return '/api/v1/projects/' + project_id + '/languages/'; };
var getContributorLink = function (project_id) { return '/api/v1/projects/' + project_id + '/contributors/'; };
