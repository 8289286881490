import { useCallback, useEffect, useRef, useState } from 'react';
function useStateCallback(initialState) {
    var _a = useStateRef(initialState), state = _a[0], _setState = _a[1], stateRef = _a[2];
    var callbackRef = useRef();
    var isFirstCallbackCall = useRef(true);
    var setState = useCallback(function (setStateAction, callback) {
        callbackRef.current = callback;
        _setState(setStateAction);
    }, []);
    useEffect(function () {
        var _a;
        if (isFirstCallbackCall.current) {
            isFirstCallbackCall.current = false;
            return;
        }
        (_a = callbackRef.current) === null || _a === void 0 ? void 0 : _a.call(callbackRef, state);
    }, [state]);
    return [state, setState, stateRef];
}
export default useStateCallback;
/* Combines React useState and useRef to be accessed in callback functions that have to use the ref rather than the object */
function useStateRef(initialState) {
    var _a = useState(initialState), myState = _a[0], setMyState = _a[1];
    var myStateRef = useRef(myState);
    useEffect(function () {
        myStateRef.current = myState;
    }, [myState]);
    return [myState, setMyState, myStateRef];
}
export var useKeystrokeLogger = function (allowKeystrokes, options) {
    // State to store the keystrokes
    var _a = useState(''), keystrokes = _a[0], setKeystrokes = _a[1];
    // Event handler for keydown
    var handleKeyDown = function (event) {
        // Check if keystrokes are allowed
        if (!allowKeystrokes) {
            return;
        }
        // Check if the pressed key is alphanumeric, a specific non-alphanumeric key, or "Escape"
        if (/^[a-zA-Z0-9_ ]$/.test(event.key) || event.key === 'Backspace') {
            // If "Backspace" is pressed, clear the existing state
            if (event.key === 'Backspace') {
                setKeystrokes(function (prevKeystrokes) { return prevKeystrokes.slice(0, -1); });
            }
            else {
                // Concatenate the new alphanumeric or specific non-alphanumeric key to the existing state
                setKeystrokes(function (prevKeystrokes) { return prevKeystrokes + event.key; });
            }
        }
        else if (event.key === 'Escape') {
            // If "Escape" is pressed, clear all the existing state and call the onEscape callback
            setKeystrokes('');
            options.onEscape && options.onEscape();
        }
        else if (event.key === 'Enter') {
            // If "Enter" is pressed, call the onEnter callback
            options.onEnter && options.onEnter();
        }
    };
    // Effect to add and remove the event listener
    useEffect(function () {
        if (allowKeystrokes) {
            // Add the event listener when the component mounts
            window.addEventListener('keydown', handleKeyDown);
        }
        // Remove the event listener when the component unmounts or when keystrokes are not allowed
        return function () {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [allowKeystrokes, options === null || options === void 0 ? void 0 : options.onEnter, options === null || options === void 0 ? void 0 : options.onEscape, keystrokes]); // Include the relevant variables in the dependency array
    // Return the alphanumeric and specific non-alphanumeric keystrokes and a function to clear them
    return { keystrokes: keystrokes, clearKeystrokes: function () { return setKeystrokes(''); } };
};
