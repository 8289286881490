var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useMemo, useState } from 'react';
//@ts-ignore
import style from '../../css/app.module.scss';
import OutsideAlerter, { Button, Checkbox, CodeWindow, CrossSvgWithHover, FlexDiv, Header2, Indicator, Input, LoadingDots, Modal, Select, ShowIf, Tooltip, useToast } from '@/src/components/Components';
import { formatDateFromFormat, notUndefinedOrEmpty, stopPropagation, updateAuto } from '@/src/utils/Helpers';
import { countryCodeToName, getPluraFormFromLanguageCode, languageCodeToName } from '@/src/utils/LanguageHelpers';
import { Flag, getFlagSrcFromLanguageCode } from '@/src/components/PageComponents';
import { BlueCircleCustomAccess } from './ProjectContributors';
import { ApiRequests } from '@/src/requests/ApiRequests';
import { CommentSvg, StarSvg, StarSvgHidden } from '@/src/components/SvgItems';
import { useKeystrokeLogger } from '@/src/utils/CustomHooks';
import { useTeamStatistics } from '@/src/components/ContextAndHooks';
export var TranslationTrRow = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    var _u = useState((_a = props.translation) === null || _a === void 0 ? void 0 : _a.text), tmpValue = _u[0], setTmpValue = _u[1];
    var _v = useState(false), showMachineTranslationModal = _v[0], setShowMachineTranslationModal = _v[1];
    var _w = useState(false), machineTranslating = _w[0], setmachineTranslating = _w[1];
    var _x = useState(props.baseLanguage), machineTranslationSourceLangage = _x[0], setmachineTranslationSourceLangage = _x[1];
    var _y = useState(false), showEditCommentModal = _y[0], setShowEditCommentModal = _y[1];
    var _z = useState((_b = props.translation) === null || _b === void 0 ? void 0 : _b.comment), tmpComment = _z[0], setTmpComment = _z[1];
    var _0 = useState(false), savingComment = _0[0], setSavingComment = _0[1];
    var _1 = useState(false), chainedLanguagesAreExpanded = _1[0], setChainedLanguagesAreExpanded = _1[1];
    var _2 = useState('other'), selectedPluralKey = _2[0], setSelectedPluralKey = _2[1];
    var _3 = useTeamStatistics(), teamStatistics = _3.teamStatistics, forceUpdate = _3.forceUpdate, updateTeamStatistics = _3.updateTeamStatistics, activeSubscriptions = _3.activeSubscriptions;
    var addTost = useToast();
    useEffect(function () {
        var _a;
        if (!props.allowPlurals)
            setTmpValue((_a = props.translation) === null || _a === void 0 ? void 0 : _a.text);
        //Set value depending on the current selected plural
        else {
            setTmpValue(getCorrectOriginalKey(selectedPluralKey));
        }
    }, [(_c = props.translation) === null || _c === void 0 ? void 0 : _c.text, selectedPluralKey, props.allowPlurals]);
    var getCorrectOriginalKey = function (plural) {
        var _a, _b, _c, _d, _e, _f;
        var returnVar = '';
        if (plural == 'other')
            returnVar = (_a = props.translation) === null || _a === void 0 ? void 0 : _a.text;
        else if (plural == 'few')
            returnVar = (_b = props.translation) === null || _b === void 0 ? void 0 : _b.valueFew;
        else if (plural == 'many')
            returnVar = (_c = props.translation) === null || _c === void 0 ? void 0 : _c.valueMany;
        else if (plural == 'one') {
            returnVar = (_d = props.translation) === null || _d === void 0 ? void 0 : _d.valueOne;
        }
        else if (plural == 'two')
            returnVar = (_e = props.translation) === null || _e === void 0 ? void 0 : _e.valueTwo;
        else if (plural == 'zero')
            returnVar = (_f = props.translation) === null || _f === void 0 ? void 0 : _f.valueZero;
        return returnVar ? returnVar : '';
    };
    /*
      useEffect(() => {
        if (props.triggerReviewed) {
          if (props.translation?.reviewed)
            props.onAfterSetAsRevied();
          else
            onToggleReview({ forceReviewed: true });
        }
      }, [props.triggerReviewed]) */
    var changesExist = useMemo(function () {
        return tmpValue != getCorrectOriginalKey(selectedPluralKey);
    }, [tmpValue, props.translation, selectedPluralKey]);
    var deleteTranslation = function () {
        ApiRequests.Translations.delete(getCurrentTranslationToSave(), function (response) {
            var _a;
            if (response.status == '200') {
                addTost('Saved!', 'success');
                props.onAfterSave({ translated: false, codePk: props.codePk, full_locale: props.fullLocale, origin_full_locale: props.fullLocale, projectPk: props.projectPk });
                updateTeamStatistics((_a = response.data) === null || _a === void 0 ? void 0 : _a.statisticsChange);
            }
            else {
                addTost(response.errors, 'danger');
            }
        });
    };
    var getCurrentTranslationToSave = function () {
        var translationToPush = props.translation;
        translationToPush = updateAuto(translationToPush, { full_locale: { $set: props.fullLocale } });
        translationToPush = updateAuto(translationToPush, { codePk: { $set: props.codePk } });
        translationToPush = updateAuto(translationToPush, { projectPk: { $set: props.projectPk } });
        return translationToPush;
    };
    var onSave = function () {
        if (!changesExist)
            return;
        //If the current translation is part of a chained language, first verify that the actual translation is correct language
        var translationToPush = getCurrentTranslationToSave();
        //Update different props depending on which plural is selected
        if (props.allowPlurals) {
            if (selectedPluralKey == 'other')
                translationToPush = __assign(__assign({}, translationToPush), { text: tmpValue });
            else if (selectedPluralKey == 'few')
                translationToPush = __assign(__assign({}, translationToPush), { valueFew: tmpValue });
            else if (selectedPluralKey == 'many')
                translationToPush = __assign(__assign({}, translationToPush), { valueMany: tmpValue });
            else if (selectedPluralKey == 'one')
                translationToPush = __assign(__assign({}, translationToPush), { valueOne: tmpValue });
            else if (selectedPluralKey == 'two')
                translationToPush = __assign(__assign({}, translationToPush), { valueTwo: tmpValue });
            else if (selectedPluralKey == 'zero')
                translationToPush = __assign(__assign({}, translationToPush), { valueZero: tmpValue });
        }
        else {
            translationToPush.text = tmpValue;
        }
        translationToPush.translated = true;
        translationToPush.latestChangeAt = (new Date()).getTime();
        ApiRequests.Translations.put(translationToPush, function (response) {
            var _a, _b;
            if (response.status == '200') {
                addTost('Saved!', 'success');
                props.onAfterSave((_a = response.data) === null || _a === void 0 ? void 0 : _a.data);
                updateTeamStatistics((_b = response.data) === null || _b === void 0 ? void 0 : _b.statisticsChange);
            }
            else {
                addTost(response.errors, 'danger');
            }
        });
        //  })
    };
    var onToggleReview = function (p) {
        var _a;
        var setAsReviewed = !((_a = props.translation) === null || _a === void 0 ? void 0 : _a.reviewed);
        if (notUndefinedOrEmpty(p.forceReviewed))
            setAsReviewed = p.forceReviewed;
        var translationToPush = getCurrentTranslationToSave();
        translationToPush.reviewed = setAsReviewed;
        translationToPush.translated = true;
        translationToPush.latestChangeAt = (new Date()).getTime();
        ApiRequests.Translations.put(translationToPush, function (response) {
            var _a;
            if (response.status == '200') {
                props.onAfterSave((_a = response.data) === null || _a === void 0 ? void 0 : _a.data);
                props.onAfterSetAsRevied();
            }
            else {
                addTost(response.errors, 'danger');
            }
        });
    };
    var machineTranslate = function () {
        //If the current translation is part of a chained language, first verify that the actual translation is correct language
        var translationToPush = getCurrentTranslationToSave();
        translationToPush.machineTranslated = true;
        translationToPush.translated = true;
        translationToPush.latestChangeAt = (new Date()).getTime();
        //  checkIfSaved(props.codePk, props.languageCode, translationToPush, responseData => {
        ApiRequests.Translations.machineTranslate({ translation: translationToPush, sourceLanguage: machineTranslationSourceLangage }, function (response) {
            var _a, _b;
            if (response.status == '200') {
                props.onAfterSave((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.data);
                setShowMachineTranslationModal(false);
            }
            else {
                addTost(response.errors, 'danger');
            }
            setmachineTranslating(false);
        });
        //  })
    };
    /* Comment Edit section  */
    useEffect(function () {
        var _a;
        setTmpComment((_a = props.translation) === null || _a === void 0 ? void 0 : _a.comment);
    }, [(_d = props.translation) === null || _d === void 0 ? void 0 : _d.comment]);
    var saveComment = function () {
        setSavingComment(true);
        var translationToPush = props.translation;
        translationToPush.codePk = props.codePk;
        translationToPush.full_locale = props.fullLocale;
        translationToPush.comment = tmpComment;
        translationToPush.latestChangeAt = (new Date()).getTime();
        //   checkIfSaved(props.codePk, props.languageCode, updateAuto(props.translation, { $merge: { comment: tmpComment, latestChangeAt: (new Date()).getTime(), latestChangeByName: 'Patrik' } }), responseData => {
        ApiRequests.Translations.put(translationToPush, function (response) {
            var _a;
            if (response.status == '200') {
                props.onAfterSave((_a = response.data) === null || _a === void 0 ? void 0 : _a.data);
                setShowEditCommentModal(false);
            }
            else {
                addTost(response.errors, 'danger');
            }
            setSavingComment(false);
        });
        //   })
    };
    var currentTranslationDoNotHaveItsOwnTranslation = function () {
        var _a, _b;
        return ((_a = props.translation) === null || _a === void 0 ? void 0 : _a.full_locale) != ((_b = props.translation) === null || _b === void 0 ? void 0 : _b.origin_full_locale);
    };
    var keylogger = useKeystrokeLogger(showEditCommentModal, { onEnter: saveComment });
    var getPluralFormsForCurrentLanguage = function () {
        return getPluraFormFromLanguageCode(props.fullLocale);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("tr", null,
            React.createElement("td", { className: style.TranslationTrRowFirstTd, style: { borderBottom: props.isLast ? '0px' : '1px #d7d7d7 solid' } },
                React.createElement(ShowIf, { show: !props.isSourceLanguage },
                    " ",
                    React.createElement(Checkbox, { style: { margin: '0 5px' }, checked: props.selected, onChange: props.onSelect }))),
            React.createElement("td", { className: style.TranslationTrRowSecondTd + ' ' + (props.isNestedUnderChain ? style.IsNestedUnderChain : ''), style: { borderBottom: props.isLast ? '0px' : '1px #d7d7d7 solid' } },
                React.createElement(FlexDiv, { onClick: function () { return setChainedLanguagesAreExpanded(function (p) { return !p; }); }, style: { margin: '0 5px' }, alignCenter: true },
                    props.haveChainedLanguages ? React.createElement(Button, { size: 'tiny', style: { marginRight: '8px' }, type: 'darkgray' }, chainedLanguagesAreExpanded ? '-' : '+') : '',
                    React.createElement(ShowIf, { show: !props.haveChainedLanguages },
                        React.createElement(FlexDiv, { alignCenter: true },
                            React.createElement(Flag, { languageFlag: props.variation }))),
                    React.createElement(FlexDiv, { style: { marginLeft: '5px', fontWeight: 'normal', whiteSpace: 'nowrap', color: currentTranslationDoNotHaveItsOwnTranslation() ? 'gray' : 'black' } },
                        props.isNestedUnderChain ? countryCodeToName(props.variation) : languageCodeToName(props.fullLocale),
                        React.createElement(ShowIf, { show: props.isNestedUnderChain },
                            " ",
                            React.createElement("div", { style: { marginLeft: '2px' } },
                                "(",
                                props.variation,
                                ")")),
                        ((_e = props.chainedTranslationsRows) === null || _e === void 0 ? void 0 : _e.length) > 0 ? (' (' + ((_f = props.chainedTranslationsRows) === null || _f === void 0 ? void 0 : _f.length) + ')') : ''),
                    React.createElement(ShowIf, { show: props.baseLanguage == props.fullLocale },
                        React.createElement(Tooltip, { overlay: 'Base language', style: { marginLeft: '5px' } },
                            "  ",
                            React.createElement(StarSvg, null))),
                    React.createElement(ShowIf, { show: props.baseLanguage != props.fullLocale },
                        React.createElement(StarSvgHidden, null)),
                    React.createElement(ShowIf, { show: props.isNestedUnderChain && !currentTranslationDoNotHaveItsOwnTranslation() },
                        React.createElement(Tooltip, { overlay: 'Remove this translation, fallback to chained translation instead.' },
                            React.createElement(CrossSvgWithHover, { onClick: deleteTranslation, style: { cursor: 'pointer' } }))),
                    React.createElement(ShowIf, { show: props.allowPlurals },
                        React.createElement(FlexDiv, { onClick: function (e) { return stopPropagation(e); }, style: { flexDirection: 'column', marginLeft: 'auto', marginBottom: '2px' } }, (_g = getPluralFormsForCurrentLanguage()) === null || _g === void 0 ? void 0 : _g.map(function (k, i) {
                            return (React.createElement(Indicator, { size: 'small', onClick: function (e) { setSelectedPluralKey(k); stopPropagation(e); }, type: selectedPluralKey == k ? 'gray' : 'lightgray', style: { marginLeft: '5px', marginTop: '5px' } }, k));
                        }))))),
            React.createElement("td", { style: { width: '100%', background: 'white', height: '1px', borderBottom: props.isLast ? '0px' : '1px #d7d7d7 solid' } },
                React.createElement(ShowIf, { show: props.isSourceLanguage },
                    React.createElement("div", { style: { height: '100%', fontWeight: 'bold', background: 'whitesmoke', display: 'flex', alignItems: 'center', paddingLeft: '4px' } }, notUndefinedOrEmpty(tmpValue) ? tmpValue : React.createElement("span", { style: { color: 'red' } }, "Empty"))),
                React.createElement(FlexDiv, null,
                    React.createElement(ShowIf, { show: !props.isSourceLanguage },
                        React.createElement(OutsideAlerter, { style: { width: '100%' }, onClickOutside: function () { } },
                            React.createElement(Input, { inputStyle: { color: currentTranslationDoNotHaveItsOwnTranslation() ? 'gray' : 'inherit' }, redPlaceholder: true, placeHolder: 'Empty', onBlur: onSave, noBorder: true, value: tmpValue, onChange: setTmpValue }))),
                    React.createElement(ShowIf, { show: !props.isSourceLanguage && !currentTranslationDoNotHaveItsOwnTranslation() },
                        React.createElement(ShowIf, { show: notUndefinedOrEmpty((_h = props.translation) === null || _h === void 0 ? void 0 : _h.comment) },
                            React.createElement("div", { style: { fontSize: '10px', color: 'gray', marginRight: '5px', justifyContent: 'center', alignSelf: 'center' } }, (_j = props.translation) === null || _j === void 0 ? void 0 : _j.comment)),
                        React.createElement("div", null,
                            React.createElement(Tooltip, { overlay: 'Click to edit comment', placement: 'left' },
                                React.createElement(BlueCircleCustomAccess, { filled: false, noBorder: true, onClick: function () { setShowEditCommentModal(true); }, noHoverEffect: true },
                                    React.createElement(CommentSvg, null)))),
                        React.createElement("div", { style: { marginLeft: '5px' } },
                            React.createElement(Tooltip, { overlay: 'Reviewed', placement: 'left' },
                                React.createElement(BlueCircleCustomAccess, { filled: (_k = props.translation) === null || _k === void 0 ? void 0 : _k.reviewed, onClick: onToggleReview }, "R"))),
                        React.createElement("div", { style: { marginLeft: '5px' } },
                            React.createElement(Tooltip, { overlay: 'Machine Translated', placement: 'left' },
                                React.createElement(BlueCircleCustomAccess, { noHoverEffect: true, filled: (_l = props.translation) === null || _l === void 0 ? void 0 : _l.machineTranslated, onClick: function () { setShowMachineTranslationModal(true); } }, "M"))),
                        React.createElement(FlexDiv, { style: { marginLeft: '10px', width: '124px' }, alignCenter: true },
                            React.createElement("div", { style: { fontSize: '10px', color: 'gray' } },
                                "Last edited by ", (_m = props.translation) === null || _m === void 0 ? void 0 :
                                _m.latestChangeByName,
                                " ",
                                formatDateFromFormat((_o = props.translation) === null || _o === void 0 ? void 0 : _o.latestChangeAt, 'dd/MM HH:mm'))))))),
        React.createElement(Modal, { style: { maxWidth: '600px' }, show: showMachineTranslationModal, onHide: function () { return setShowMachineTranslationModal(false); } },
            React.createElement(Header2, null, "Machine translate"),
            React.createElement("div", { style: { margin: '10px 0', color: 'gray' } }, "Select which language to do the machine translation from."),
            React.createElement(Select, { label: 'Source language', style: { minWidth: '200px', maxWidth: '400px', marginRight: '10px' }, value: machineTranslationSourceLangage, onChange: setmachineTranslationSourceLangage, options: (_q = (_p = props.availableLanguages) === null || _p === void 0 ? void 0 : _p.filter(function (d) { var _a; return d != ((_a = props.translation) === null || _a === void 0 ? void 0 : _a.full_locale); })) === null || _q === void 0 ? void 0 : _q.map(function (d) { return { value: d, displayValue: languageCodeToName(d), imgSrc: getFlagSrcFromLanguageCode(d) }; }) }),
            React.createElement(ShowIf, { show: notUndefinedOrEmpty(machineTranslationSourceLangage) && notUndefinedOrEmpty((_r = props.translation) === null || _r === void 0 ? void 0 : _r.text) },
                React.createElement("div", { style: { margin: '10px 0', color: 'gray' } }, "Machine translation will overwrite the current translation:"),
                React.createElement(CodeWindow, { hideCopy: true }, (_s = props.translation) === null || _s === void 0 ? void 0 : _s.text)),
            React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                React.createElement(Button, { type: 'blue', size: 'medium', onClick: machineTranslate, disabled: machineTranslating }, machineTranslating ? React.createElement(LoadingDots, null) : 'Machine translate'),
                React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: function () { setShowMachineTranslationModal(false); }, disabled: machineTranslating }, "Cancel"))),
        React.createElement(Modal, { style: { maxWidth: '600px' }, show: showEditCommentModal, onHide: function () { return setShowEditCommentModal(false); } },
            React.createElement(Header2, null, "Comment"),
            React.createElement(Input, { style: { marginTop: '10px' }, autofocus: true, type: 'textarea', value: tmpComment, onChange: setTmpComment }),
            React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                React.createElement(Button, { type: 'blue', size: 'medium', onClick: saveComment, disabled: savingComment }, savingComment ? React.createElement(LoadingDots, null) : 'Save'),
                React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: function () { setTmpComment(props.translation.comment); setShowEditCommentModal(false); }, disabled: savingComment }, "Cancel"))),
        React.createElement(ShowIf, { show: chainedLanguagesAreExpanded }, (_t = props.chainedTranslationsRows) === null || _t === void 0 ? void 0 : _t.map(function (chain) {
            return (React.createElement(TranslationTrRow, __assign({}, chain, { isNestedUnderChain: true })));
        }))));
};
