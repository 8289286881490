import { undefinedOrEmpty } from '@/src/utils/Helpers';
export var LocaleHelper;
(function (LocaleHelper) {
    /* const generateJsonContent: (data:Types.DetailedProject,locale:Types.LanguageCode):string => {
        return '';
    } */
    LocaleHelper.toFullLocale = function (language, variation) {
        if (undefinedOrEmpty(variation))
            return language;
        else {
            return language + "_" + variation;
        }
    };
})(LocaleHelper || (LocaleHelper = {}));
