var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import { extractDefaultCountryCodeFromLanguageCode } from '../utils/LanguageHelpers';
export var Flag = function (props) {
    var getFlagName = useMemo(function () {
        var _a;
        var split = (_a = props.languageFlag) === null || _a === void 0 ? void 0 : _a.split("_");
        return split === null || split === void 0 ? void 0 : split[(split === null || split === void 0 ? void 0 : split.length) - 1];
    }, [props.languageFlag]);
    return (React.createElement("div", { style: __assign(__assign({}, props.style), { height: '11px' }) },
        React.createElement("img", { src: "resources/flags/" + getFlagName + '.png' })));
};
export var getFlagSrcFromLanguageCode = function (languageCode) {
    var countryCode = extractDefaultCountryCodeFromLanguageCode(languageCode);
    return "resources/flags/" + countryCode + '.png';
};
export var getFlagName = function (countryCodeArg) {
    // const countryCode =fullLocaleToCountry(countryCodeArg);
    return "resources/flags/" + countryCodeArg + '.png';
};
