var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useMemo, useRef, useState, createContext, useCallback } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark as darkk } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ReactDOM from 'react-dom';
import { copyToClipboard, notUndefinedOrEmpty, nu, stopPropagation, undefinedOrEmpty, updateAuto } from '../utils/Helpers';
//@ts-ignore
import style from '../css/app.module.scss';
import { CrossSvg, DropdownArrrowSvg, FilterSvg, InfoSvg, MagnifierSvg, PersonSvg, SaveSvg, SortSvg } from './SvgItems';
import { NavLink } from 'react-router-dom';
import { useDarkoverContext } from './ContextAndHooks';
var getSvgElement = function (icon) {
    if (icon == 'filter')
        return React.createElement(FilterSvg, null);
    if (icon == 'magnifier')
        return React.createElement(MagnifierSvg, null);
    if (icon == 'person')
        return React.createElement(PersonSvg, null);
    if (icon == 'sort')
        return React.createElement(SortSvg, null);
    return null;
};
var MenuItem = function (props) {
    var getResult = function (includeStyle) {
        return (React.createElement("div", { style: includeStyle ? __assign(__assign({}, props.style), {}) : {}, className: style.smallMenuItem + ' ' + (props.isActive ? style.smallMenuItemActive : ''), onClick: props.onClick },
            React.createElement("div", { className: style.itemText }, props.title),
            React.createElement("div", { className: style.itemLine })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ShowIf, { style: props.style, show: notUndefinedOrEmpty(props.urlLink) },
            React.createElement(NavLink, { to: props.urlLink }, getResult(false))),
        React.createElement(ShowIf, { show: undefinedOrEmpty(props.urlLink) }, getResult(true))));
};
export var Menu = function (props) {
    return (React.createElement("div", { className: style.menuContainer }, props.items.map(function (item, index) { return (React.createElement(React.Fragment, null,
        React.createElement(MenuItem, __assign({ index: index, key: index }, item)))); })));
};
export var Card = function (props) {
    return (React.createElement("div", { style: props.style, className: style.card + ' ' + props.className }, props.children));
};
/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, callback, debug) {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
        if (debug)
            console.log("mousedown event");
        if (ref.current && !ref.current.contains(event.target)) {
            callback();
        }
    }
    useEffect(function () {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
}
/**
 * Component that alerts if you click outside of it
 */
export default function OutsideAlerter(props) {
    var wrapperRef = useRef();
    useOutsideAlerter(wrapperRef, props.onClickOutside);
    return React.createElement("div", { style: props.style, ref: wrapperRef }, props.children);
}
export var LiveCircle = function (props) {
    var getStyle = useMemo(function () {
        var style = __assign({}, props.style);
        style.borderRadius = '50%';
        style.height = props.size == 'large' ? '15px' : '10px';
        style.width = props.size == 'large' ? '15px' : '10px';
        if (props.color == 'green')
            style.backgroundColor = '#0d8d0d';
        if (props.color == 'red')
            style.backgroundColor = '#8d0d0d';
        if (props.color == 'yellow')
            style.backgroundColor = '#fbc542';
        return style;
    }, [props.color]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: getStyle }, "\u00A0")));
};
export var Table = function (props) {
    var _a, _b;
    return (React.createElement(React.Fragment, null,
        "    ",
        React.createElement("table", { style: props.style, className: style.Table },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement(ShowIf, { show: !props.doNotShowLeftHeadercontent },
                        "  ",
                        React.createElement("th", { style: { width: '100px' } })), (_a = props.header) === null || _a === void 0 ? void 0 :
                    _a.map(function (h, i) {
                        return (React.createElement("th", { key: i }, h.content));
                    })),
                React.createElement("tr", null,
                    React.createElement(ShowIf, { show: !props.doNotShowLeftHeadercontent },
                        " ",
                        React.createElement("th", null)),
                    React.createElement("th", { colSpan: 100, style: { borderBottom: '1px gray solid' } }))),
            React.createElement("tbody", null, (_b = props.rows) === null || _b === void 0 ? void 0 : _b.map(function (row, i) {
                var _a;
                return (React.createElement("tr", { style: row.style, key: row.key },
                    React.createElement(ShowIf, { show: !props.doNotShowLeftHeadercontent },
                        "  ",
                        React.createElement("td", null, row === null || row === void 0 ? void 0 : row.leftHeaderContent)), (_a = row === null || row === void 0 ? void 0 : row.items) === null || _a === void 0 ? void 0 :
                    _a.map(function (item, j) {
                        return (React.createElement("td", { key: j, style: item.style }, item.content));
                    })));
            })))));
};
export var Circle = function (props) {
    return (React.createElement("div", { style: props.style, className: style.ComponentCircle },
        React.createElement("div", { className: style.ComponentCircleInner }, props.children)));
};
export var FlexDiv = function (props) {
    var getCss = useMemo(function () {
        if (props.alignCenter)
            return __assign(__assign({}, props.style), { alignItems: 'center' });
        else
            return props.style;
    }, [props.style, props.alignCenter]);
    return (React.createElement("div", { onClick: props.onClick, style: getCss, className: style.flex + ' ' + props.className }, props.children));
};
export var Button = function (props) {
    var onClick = function () {
        if (notUndefinedOrEmpty(props.onClick) && !props.disabled)
            props.onClick();
    };
    var className = useMemo(function () {
        var classNames = [];
        /* Types */
        if (props.type == 'white')
            classNames.push(style.customButtonWhite);
        else if (props.type == 'redwhite')
            classNames.push(style.customButtonRedWhite);
        else if (props.type == 'white-no-border')
            classNames.push(style.customButtonWhiteNoBorder);
        else if (props.type == 'lightBlue-no-border')
            classNames.push(style.customButtonLightBlueNoBorder);
        else if (props.type == 'underline')
            classNames.push(style.customButtonUnderline);
        else if (props.type == 'orange')
            classNames.push(style.customButtomOrange);
        else if (props.type == 'darkgray')
            classNames.push(style.customButtonDarkGray);
        else if (props.type == 'pink')
            classNames.push(style.customButtonPink);
        else if (props.type == 'gray-text')
            classNames.push(style.customButtonGrayText);
        else
            classNames.push(style.customButton);
        if (props.moreRounded)
            classNames.push(style.customButtonMoreRounded);
        //Skip left and right
        if (props.skipLeftBoderRadius) {
            classNames.push(style.customButtonNoLeftRadiues);
        }
        if (props.skipRightBorderRadius) {
            classNames.push(style.customButtonNoRightRadiues);
        }
        /* Sizes */
        if (props.size == 'small')
            classNames.push(style.customButtonSmall);
        else if (props.size == 'medium')
            classNames.push(style.customButtonMedium);
        else if (props.size == 'tiny')
            classNames.push(style.customButtonTiny);
        else
            classNames.push(style.customButtonLarge);
        if (props.notBold)
            classNames.push(style.customButtonNotBold);
        return classNames === null || classNames === void 0 ? void 0 : classNames.join(' ');
    }, [props.type, props.skipLeftBoderRadius, props.size, props.skipRightBorderRadius]);
    var svgElement = useMemo(function () {
        return getSvgElement(props.icon);
    }, [props.icon]);
    return (React.createElement("div", { style: props.style, className: props.className },
        React.createElement("button", { disabled: props.disabled, style: props.buttonCss, className: className, onClick: onClick },
            React.createElement(ShowIf, { show: notUndefinedOrEmpty(svgElement) },
                React.createElement(FlexDiv, { style: { justifyContent: 'center', alignItems: 'center' } },
                    React.createElement("div", null, svgElement),
                    React.createElement("div", { style: { marginLeft: '5px' } }, props.children))),
            React.createElement(ShowIf, { show: undefinedOrEmpty(svgElement) }, props.children))));
};
export var LabelWithInfoTooltip = function (props) {
    return (React.createElement(FlexDiv, null,
        React.createElement("div", null, props.children),
        React.createElement(ShowIf, { show: notUndefinedOrEmpty(props.tooltip) },
            React.createElement(Tooltip, { style: { marginLeft: '5px', fill: 'gray' }, overlay: props.tooltip },
                React.createElement(InfoSvg, null)))));
};
export var Label = function (props) {
    var getClassName = useMemo(function () {
        var classNames = [];
        if (props.type == 'bold')
            classNames.push(style.labelOuterBold);
        if (props.size == 'large')
            classNames.push(style.labelOuterLarge);
        if (props.size == 'small')
            classNames.push(style.labelOuterSmall);
        else
            classNames.push(style.labelOuter);
        return classNames.join(' ');
    }, [props.type, props.size]);
    return (React.createElement("div", { style: props.style, className: getClassName + ' ' + props.className },
        React.createElement("div", { className: style.text }, props.text || props.children),
        React.createElement(ShowIf, { show: notUndefinedOrEmpty(props === null || props === void 0 ? void 0 : props.progressInPercent) },
            React.createElement(ProgressBar, { progressInPercent: props.progressInPercent })),
        React.createElement("div", { className: style.label }, props.label)));
};
export var DarkenedElementExplaination = function (props) {
    var darkOverlay = useDarkoverContext();
    var showDarkness = useMemo(function () {
        return (darkOverlay === null || darkOverlay === void 0 ? void 0 : darkOverlay.elementToShowDarkContextFor) == props.darknessIdentifier;
    }, [darkOverlay === null || darkOverlay === void 0 ? void 0 : darkOverlay.elementToShowDarkContextFor, props.darknessIdentifier]);
    var hideDarkness = function () {
        darkOverlay === null || darkOverlay === void 0 ? void 0 : darkOverlay.setElementToShowDarkContextFor('');
    };
    var hideDarknessDoNotShowAgain = function () {
        darkOverlay === null || darkOverlay === void 0 ? void 0 : darkOverlay.disableElementToBeShownAgain(props.darknessIdentifier);
    };
    var getClassName = useMemo(function () {
        var classNames = [style.darkOverlayContextItemDefault];
        if (showDarkness)
            classNames.push(style.darkOverlayContextItemShow);
        if (showDarkness && !props.doNotShowDarknessOverlay)
            classNames.push(style.darkOverlayContextItemDarkness);
        return classNames.join(' ');
    }, [showDarkness, props.doNotShowDarknessOverlay]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: props.style, className: getClassName },
            React.createElement("div", null, props.children),
            React.createElement(ShowIf, { style: { position: 'relative' }, show: showDarkness },
                React.createElement(OutsideAlerter, { onClickOutside: hideDarkness },
                    React.createElement("div", { className: style.darkOverlayContextItemExplanation },
                        React.createElement(FlexDiv, null, props.text)))))));
};
export var ThreeDotsWithMenu = function (props) {
    var _a;
    var _b = useState(false), showMenu = _b[0], setShowMenu = _b[1];
    return (React.createElement("div", { onClick: function () { return setShowMenu(function (p) { return !p; }); }, style: props.style, className: style.dotContainer },
        React.createElement("div", { className: style.dot }),
        React.createElement("div", { className: style.dot }),
        React.createElement("div", { className: style.dot }),
        React.createElement(OutsideAlerter, { onClickOutside: function () { return setShowMenu(false); } },
            React.createElement(ShowIf, { className: style.menuExpandUserHeadDots, show: showMenu }, (_a = props.options) === null || _a === void 0 ? void 0 : _a.map(function (d, i) {
                return (React.createElement("div", { onClick: function () { if (!(d === null || d === void 0 ? void 0 : d.disabled))
                        d.onClick(); }, className: style.menuExpandUserHeadItemDot + ' ' + ((d === null || d === void 0 ? void 0 : d.disabled) ? style.menuExpandUserHeadItemDotDisabled : '') }, d.text));
            })))));
};
export var ThreeDotsIcon = function (props) {
    return (React.createElement("div", { onClick: props.onClick, style: props.style, className: style.dotContainer },
        React.createElement("div", { className: style.dot }),
        React.createElement("div", { className: style.dot }),
        React.createElement("div", { className: style.dot })));
};
export var ProgressBarWithText = function (props) {
    return (React.createElement(FlexDiv, { alignCenter: true, style: props.style },
        React.createElement(Header5, { gray: true }, props.text),
        React.createElement(ProgressBar, { style: { marginLeft: '5px' }, progressInPercent: props.progressInPercent }),
        React.createElement(Header5, { style: { marginLeft: '5px' }, black: true },
            Math.round(props.progressInPercent),
            "%")));
};
export var ProgressBar = function (props) {
    var filledWidth = "".concat(props.progressInPercent, "%");
    // Interpolate between red (255,0,0) and green (0,255,0)
    var red = 255 * (1 - props.progressInPercent / 100);
    var green = 165 * props.progressInPercent / 100;
    var bgColor = "rgb(".concat(Math.round(red), ", ").concat(Math.round(green), ", 0)");
    return (React.createElement("div", { style: props.style, className: style.progressBarWrapper },
        React.createElement("div", { className: style.progressFill, style: { width: filledWidth, backgroundColor: bgColor } })));
};
export var ShowIf = function (props) {
    return (React.createElement(React.Fragment, null,
        props.show && (notUndefinedOrEmpty(props.style) || notUndefinedOrEmpty(props.className) || notUndefinedOrEmpty(props.onClick)) && React.createElement("div", { onClick: props.onClick, style: props.style, className: props.className }, props.children),
        props.show && undefinedOrEmpty(props.style) && undefinedOrEmpty(props.className) &&
            React.createElement(React.Fragment, null, props.children)));
};
export var Row = function (props) {
    return (React.createElement("div", { className: 'row' }, props.children));
};
export var Header1 = function (props) {
    return (React.createElement("div", { style: props.style, onClick: props.onClick, className: style.header1 + ' ' + (props.gray ? style.headerGray : '') + ' ' + (props.black ? style.headerBlack : '') }, props.children));
};
export var Header2 = function (props) {
    return (React.createElement("div", { style: props.style, onClick: props.onClick, className: style.header2 + ' ' + (props.gray ? style.headerGray : '') + ' ' + (props.black ? style.headerBlack : '') }, props.children));
};
export var Header3 = function (props) {
    return (React.createElement("div", { style: props.style, onClick: props.onClick, className: style.header3 + ' ' + (props.gray ? style.headerGray : '') + ' ' + (props.black ? style.headerBlack : '') }, props.children));
};
export var Header4 = function (props) {
    return (React.createElement("div", { style: props.style, onClick: props.onClick, className: style.header4 + ' ' + (props.gray ? style.headerGray : '') + ' ' + (props.black ? style.headerBlack : '') }, props.children));
};
export var Header5 = function (props) {
    return (React.createElement("div", { style: props.style, onClick: props.onClick, className: style.header5 + ' ' + (props.gray ? style.headerGray : '') + ' ' + (props.black ? style.headerBlack : '') }, props.children));
};
export var Line = function (props) {
    var getClassName = useMemo(function () {
        var classNames = [];
        if (props.thick)
            classNames.push(style.customLineThick);
        if (props.color == 'blue')
            classNames.push(style.customLineBlue);
        else
            classNames.push(style.customLineGray);
        if (props.vertical)
            classNames.push(style.customLineVertical);
        else
            classNames.push(style.customLine);
        return classNames.join(' ');
    }, [props.thick, props.color, props.vertical]);
    return (React.createElement("div", { style: __assign(__assign({}, props.style), (props.noMargin ? { margin: '0px' } : {})), className: getClassName }));
};
export var DragAndDropFileUpload = function (props) {
    // const [files, setFiles] = useState([]);
    var _a;
    var fileInputRef = useRef(null);
    var handleDrop = function (e) {
        e.preventDefault();
        var droppedFiles = Array.from(e.dataTransfer.files);
        // props.setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
        props.setFiles(function (prevFiles) { return updateAuto(prevFiles, { $push: droppedFiles }); });
    };
    var handleFileInputChange = function (e) {
        var selectedFiles = Array.from(e.target.files);
        props.setFiles(function (prevFiles) { return updateAuto(prevFiles, { $push: selectedFiles }); });
        //Clear this input
        fileInputRef.current.value = null;
    };
    var handleRemoveFile = function (index) {
        props.setFiles(function (prevFiles) { return prevFiles.filter(function (file, i) { return i !== index; }); });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ShowIf, { show: undefinedOrEmpty(props.alternativeButton) },
            React.createElement("div", { style: { border: '1px black dashed', padding: '0px 10px 10px' } },
                React.createElement("div", { className: "drop-zone", onDrop: handleDrop, onDragOver: function (e) { return e.preventDefault(); } },
                    React.createElement("p", null, "Drag & drop files here or click to select"),
                    React.createElement(Button, { type: 'blue', size: 'small', onClick: function () { var _a; return (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click(); } }, "Select Files")),
                props.files.length > 0 && (React.createElement("div", null,
                    React.createElement("h3", null, "Selected Files:"),
                    React.createElement("div", null, (_a = props.files) === null || _a === void 0 ? void 0 : _a.map(function (file, index) { return (React.createElement(FlexDiv, { key: index },
                        React.createElement("div", null,
                            file.name,
                            ' '),
                        React.createElement("div", { style: { marginLeft: '5px' }, onClick: function () { return handleRemoveFile(index); } },
                            React.createElement(CrossSvg, null)))); })))))),
        React.createElement(ShowIf, { show: !undefinedOrEmpty(props.alternativeButton) },
            React.createElement("div", { style: { display: 'flex' }, onClick: function () { var _a; return (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click(); } }, props.alternativeButton)),
        React.createElement("input", { type: "file", multiple: true, onChange: handleFileInputChange, style: { display: 'none' }, ref: fileInputRef })));
};
export var BoldLabel = function (props) {
    return (React.createElement("div", { style: props.style, className: style.inputOuterLabel }, props.children));
};
export var Input = function (props) {
    var ref = useRef(null);
    useEffect(function () {
        if (props.forceSelect) {
            ref.current.select();
        }
    }, [props.forceSelect]);
    var fiterChangeAndSendToParent = function (newValue) {
        var _a, _b;
        if (((_a = props.limitation) === null || _a === void 0 ? void 0 : _a.maxCharacters) && newValue.length > props.limitation.maxCharacters) {
            return;
        }
        if ((_b = props.limitation) === null || _b === void 0 ? void 0 : _b.regexAllow) {
            var regex = new RegExp(props.limitation.regexAllow);
            if (!regex.test(newValue) && notUndefinedOrEmpty(newValue)) {
                return;
            }
        }
        //Fallback
        props.onChange(newValue);
    };
    var anyIcon = useMemo(function () {
        return notUndefinedOrEmpty(props.icon);
    }, [props.icon]);
    var onChange = function (e) {
        fiterChangeAndSendToParent(e.target.value);
    };
    var onClearValue = function () {
        props.onChange('');
    };
    var svgElement = useMemo(function () {
        return getSvgElement(props.icon);
    }, [props.icon]);
    var inputclassName = useMemo(function () {
        var classNames = [style.inputInner];
        if (anyIcon)
            classNames.push(style.inputInnerWithIcon);
        if (props.blueInput)
            classNames.push(style.inputInnerBlue);
        if (props.noBorderOnNotFocus)
            classNames.push(style.inputNoBorderNotFocus);
        if (props.noBorder)
            classNames.push(style.inputNoBorder);
        if (notUndefinedOrEmpty(props.showErrorMessage))
            classNames.push(style.inputRedBorder);
        if (props.redPlaceholder)
            classNames.push(style.redPlacerholder);
        return classNames.join(' ');
    }, [anyIcon, props.blueInput, props.noBorderOnNotFocus, props.showErrorMessage]);
    var getClearIcon = useMemo(function () {
        if (props.showClearIcon && notUndefinedOrEmpty(props.value)) {
            return React.createElement("div", { onClick: onClearValue, className: style.clearIconWrapper },
                React.createElement(CrossSvg, null));
        }
    }, [props.showClearIcon, props.value]);
    return (React.createElement("div", { onClick: props.onClick, style: props.style, className: props.className },
        React.createElement(ShowIf, { show: notUndefinedOrEmpty(props.label) || props.showMandatoryIndication },
            React.createElement(FlexDiv, { className: style.inputOuterLabel },
                React.createElement("div", null, props.label),
                React.createElement(ShowIf, { show: props.showMandatoryIndication, style: { color: 'red', marginLeft: '3px' } }, "*"))),
        React.createElement("div", { className: style.inputOuter },
            React.createElement(ShowIf, { style: { position: 'absolute', left: '8px' }, show: notUndefinedOrEmpty(svgElement) }, svgElement),
            React.createElement(ShowIf, { show: undefinedOrEmpty(props.type) || props.type == 'input' || props.inputType == 'file' },
                React.createElement("input", { onFocus: props.onFocus, onBlur: props.onBlur, type: props.inputType, ref: ref, autoFocus: props.autofocus, disabled: props.disabled, value: props.value, onChange: onChange, style: props.inputStyle, className: inputclassName, placeholder: props.placeHolder })),
            React.createElement(ShowIf, { show: props.type == 'textarea' },
                React.createElement("textarea", { autoFocus: props.autofocus, disabled: props.disabled, value: props.value, onChange: onChange, style: props.inputStyle, className: style.inputInner + ' ' + (anyIcon ? style.inputInnerWithIcon : ''), placeholder: props.placeHolder })),
            getClearIcon),
        React.createElement(ShowIf, { show: notUndefinedOrEmpty(props.showErrorMessage) },
            React.createElement("div", { style: { color: 'red', marginTop: '-1px', fontSize: '13px' } }, props.showErrorMessage))));
};
export var LabelWrapper = function (props) {
    return (React.createElement("div", { style: props.style, className: props.className },
        React.createElement(ShowIf, { show: notUndefinedOrEmpty(props.label) },
            React.createElement("div", { className: style.inputOuterLabel }, props.label)),
        React.createElement("div", null,
            "  ",
            props.children)));
};
export var MainViewWrapper = function (props) {
    return (React.createElement("div", { className: style.mainViewWrapper },
        React.createElement("div", { className: style.mainViewWrapperInner }, props.children)));
};
export var SmallColumnMenu = function (props) {
    var _a;
    return (React.createElement("div", { className: style.smallColumnMenuContainer }, (_a = props.items) === null || _a === void 0 ? void 0 : _a.map(function (item, index) {
        var _a, _b;
        return (React.createElement(React.Fragment, null,
            React.createElement(SmallColumnMenuItem, __assign({ key: index }, item)),
            React.createElement(ShowIf, { show: ((_a = item === null || item === void 0 ? void 0 : item.subItems) === null || _a === void 0 ? void 0 : _a.length) > 0 && (item === null || item === void 0 ? void 0 : item.isActive) },
                React.createElement("div", { style: { marginLeft: '20px' } }, (_b = item.subItems) === null || _b === void 0 ? void 0 : _b.map(function (item, index) { return (React.createElement(React.Fragment, null,
                    React.createElement(SmallColumnMenuItem, __assign({ key: index }, item)))); })))));
    })));
};
var SmallColumnMenuItem = function (props) {
    var getResult = function (includeStyle) {
        return (React.createElement("div", { style: includeStyle ? props.style : {}, className: style.smallColumnMenuContainerItem + ' ' + (props.isActive ? style.smallColumnMenuContainerItemActive : ''), onClick: props.onClick },
            React.createElement("div", null, props.title)));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ShowIf, { style: props.style, show: notUndefinedOrEmpty(props.urlLink) },
            React.createElement(NavLink, { to: props.urlLink }, getResult(false))),
        React.createElement(ShowIf, { show: undefinedOrEmpty(props.urlLink) }, getResult(true))));
};
export var Portal = function (props) {
    return (ReactDOM.createPortal(props.children, props.domElement));
};
export var Modal = function (props) {
    var getOuterClass = useMemo(function () {
        var classNames = [style.modalWrapper];
        if (!props.show)
            classNames.push(style.modalHidden);
        return classNames.join(' ');
    }, [props.show]);
    var getContentClass = useMemo(function () {
        var classNames = [style.modalContent];
        //Props className 
        if (nu(props.className))
            classNames.push(props.className);
        return classNames.join(' ');
    }, [props.className, props.show]);
    useEffect(function () {
        if (props.show && !props.doNotUseModal)
            document.body.classList.add('no-scroll');
        else if (nu(props.show))
            removeNoScroll();
    }, [props.show, props.doNotUseModal]);
    var removeNoScroll = function () {
        document.body.classList.remove('no-scroll');
    };
    var onClose = function () {
        props.onHide();
        removeNoScroll();
    };
    var onClickOutside = function () {
        if (!props.disableCloseOnClickOutside) {
            onClose();
        }
    };
    return (React.createElement(React.Fragment, null,
        props.show && props.doNotUseModal &&
            props.children,
        props.show && !props.doNotUseModal &&
            React.createElement(Portal, { domElement: document.body },
                React.createElement("div", { className: getOuterClass },
                    React.createElement("div", { style: props.style, className: getContentClass },
                        React.createElement(OutsideAlerter, { onClickOutside: onClickOutside },
                            React.createElement("div", { style: { marginTop: '10px' } },
                                notUndefinedOrEmpty(props.titleHeader) &&
                                    React.createElement("div", { className: style.modalHeaderWrapper }, props.titleHeader),
                                React.createElement("div", { style: props.innerStyle, className: style.modalTextContent },
                                    props.showClose && React.createElement("div", { className: style.modalClose },
                                        React.createElement(Button, { onClick: onClose, size: "small", type: 'white' }, props.closeText)),
                                    props.children))))))));
};
export var GeneralNameEdit = function (props) {
    var _a = useState(props.initInEditMode), editMode = _a[0], setEditMode = _a[1];
    var _b = useState(props.name), tmpValue = _b[0], setTmpValue = _b[1];
    //Listen for eneter and call save, bot only if in edit mode
    useEffect(function () {
        var onKeyDown = function (e) {
            if (e.keyCode == 13 && editMode) {
                save();
            }
        };
        document.addEventListener("keydown", onKeyDown);
        return function () {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [editMode, tmpValue]);
    useEffect(function () {
        //setTmpValue(props.name)
    }, [props.name]);
    var save = function () {
        setEditMode(false);
        if (props.name != tmpValue)
            props.onChangeName(tmpValue);
    };
    var cancel = function () {
        setEditMode(false);
        setTmpValue(props.name);
    };
    return (React.createElement("div", { style: __assign(__assign({}, props.style), { display: 'flex' }) },
        React.createElement("div", { className: style.onHoverOuterWrapper },
            React.createElement(ShowIf, { show: !editMode },
                React.createElement(FlexDiv, null,
                    React.createElement(ShowIf, { show: props.size == 'header1' },
                        React.createElement(Header1, { style: props.textStyle }, props.name)),
                    React.createElement(ShowIf, { show: props.size == 'normal' || undefinedOrEmpty(props.size) },
                        React.createElement("div", { style: props.textStyle }, props.name)),
                    React.createElement(ShowIf, { show: props.size == 'p' },
                        React.createElement(P, { style: props.textStyle }, props.name)),
                    React.createElement(ShowIf, { show: !props.disabled },
                        React.createElement("div", { onClick: function () { return setEditMode(true); }, style: { marginLeft: '10px' }, className: style.onHoverInnerShowWrapper + ' ' + style.saveOrEditIconNameEdit },
                            React.createElement(EditSvg, null))))),
            React.createElement(ShowIf, { show: editMode },
                React.createElement(OutsideAlerter, { onClickOutside: cancel },
                    React.createElement(FlexDiv, null,
                        React.createElement(Input, { type: props.textArea ? 'textarea' : 'input', autofocus: true, value: tmpValue, onChange: setTmpValue }),
                        React.createElement("div", { onClick: function (e) { save(); }, style: { marginLeft: '5px' }, className: style.saveOrEditIconNameEdit },
                            React.createElement(SaveSvg, null))))))));
};
export var SearchButtonInput = function (props) {
    var _a = useState(false), showAction = _a[0], _setShowAction = _a[1];
    var _b = useState(false), forceSelect = _b[0], setForceSelect = _b[1];
    var setShowAction = function (v) {
        if (v) {
            setForceSelect(true);
        }
        _setShowAction(v);
    };
    useEffect(function () {
        if (forceSelect)
            setForceSelect(false);
    }, [forceSelect]);
    var showInputField = useMemo(function () {
        return showAction || notUndefinedOrEmpty(props.value);
    }, [props.value, showAction]);
    return (React.createElement("div", { style: __assign(__assign({}, props.style), { position: 'relative' }) },
        React.createElement(ShowIf, { style: { position: showInputField ? 'absolute' : 'relative', top: '0px', left: '0px', zIndex: '1' }, show: !showInputField },
            React.createElement(Button, { size: 'small', buttonCss: { height: '33px' }, style: props.buttonStyle, onClick: function () { return setShowAction(function (p) { return !p; }); }, type: showAction ? 'lightBlue-no-border' : 'white-no-border', icon: 'magnifier' }, "Search")),
        React.createElement(ShowIf, { style: { position: showInputField ? 'relative' : 'absolute', top: '0px', left: '0px' }, show: true, className: style.SearchButtonInputWrapper + ' ' + (showInputField ? style.SearchButtonInputWrapperShow : '') },
            React.createElement(OutsideAlerter, { onClickOutside: function () { if (showInputField)
                    setShowAction(false); } },
                React.createElement(Input, { forceSelect: forceSelect, showClearIcon: true, noBorderOnNotFocus: true, blueInput: showInputField, autofocus: true, icon: 'magnifier', value: props.value, onChange: props.onChange })))));
};
export var Select = function (props) {
    var _a, _b, _c;
    var _d = useState(props.alwaysShowDropdown || props.showDropdownOnMount), showDropdown = _d[0], _setShowDropdown = _d[1];
    var _e = useState(''), filterInput = _e[0], setFilterInput = _e[1];
    var _f = useState(-1), selectedIndexWithArrows = _f[0], setSelectedIndexWithArrows = _f[1];
    useEffect(function () {
        setSelectedIndexWithArrows(-1);
    }, [filterInput]);
    useEffect(function () {
        if (!showDropdown) {
            setSelectedIndexWithArrows(-1);
        }
    }, [showDropdown]);
    //List on arrow down and up and increment the selected index
    var onKeyDown = function (e) {
        var _a;
        //Down
        if (e.keyCode == 40) {
            setSelectedIndexWithArrows(function (p) { var _a; return incrementWrap(p + 1, (_a = props.options) === null || _a === void 0 ? void 0 : _a.length); });
        }
        //Up
        else if (e.keyCode == 38) {
            setSelectedIndexWithArrows(function (p) { var _a; return decrementWrap(p - 1, (_a = props.options) === null || _a === void 0 ? void 0 : _a.length); });
        }
        else if (e.keyCode == 13 && showDropdown) {
            var selectedElement = (_a = sortAndFilterOptions()) === null || _a === void 0 ? void 0 : _a[selectedIndexWithArrows];
            if (selectedElement && !(selectedElement === null || selectedElement === void 0 ? void 0 : selectedElement.isHeader)) {
                onClickOption(selectedElement);
            }
        }
    };
    var incrementWrap = function (v, max) {
        if (v >= max)
            return 0;
        return v;
    };
    var decrementWrap = function (v, max) {
        if (v < 0)
            return max - 1;
        return v;
    };
    //Register the listeners
    useEffect(function () {
        if (showDropdown)
            window.addEventListener('keydown', onKeyDown);
        return function () {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [selectedIndexWithArrows, (_a = props.options) === null || _a === void 0 ? void 0 : _a.length, showDropdown, filterInput]);
    var setShowDropdown = function (v) {
        if (v)
            console.log("show dropdown");
        if (!props.alwaysShowDropdown)
            _setShowDropdown(v);
    };
    var formatOption = function (option, options) {
        var classNames = [style.selectOption];
        /* Types */
        if (options === null || options === void 0 ? void 0 : options.grayOnHover)
            classNames.push(style.selectOptionGrayHover);
        if (options === null || options === void 0 ? void 0 : options.isSelectedValue)
            classNames.push(style.selectOptionSelected);
        if (!(options === null || options === void 0 ? void 0 : options.disablePadding))
            classNames.push(style.selectOptionPadding);
        if (options.isHeader)
            classNames.push(style.selectOptionHeader);
        if (options === null || options === void 0 ? void 0 : options.isSelectedByArrows)
            classNames.push(style.selectOptionsIsSelectedWithArrow);
        var className = classNames === null || classNames === void 0 ? void 0 : classNames.join(' ');
        return (React.createElement(React.Fragment, null,
            React.createElement(FlexDiv, { className: className, onClick: function () { if ((options === null || options === void 0 ? void 0 : options.onClick) && !options.isHeader)
                    options.onClick(option); } },
                React.createElement(ShowIf, { style: { marginRight: '5px' }, show: notUndefinedOrEmpty(option.icon) }, getSvgElement(option.icon)),
                React.createElement(ShowIf, { style: { marginRight: '5px' }, show: notUndefinedOrEmpty(option.imgSrc) },
                    React.createElement("img", { src: option.imgSrc })),
                React.createElement("div", null, option.displayValue),
                React.createElement("div", { style: { marginLeft: 'auto' } },
                    React.createElement(FlexDiv, null,
                        React.createElement(ShowIf, { onClick: options === null || options === void 0 ? void 0 : options.onClickCross, className: style.selectCrossDropdownIcon, style: { marginLeft: '5px' }, show: options === null || options === void 0 ? void 0 : options.addCross },
                            React.createElement(CrossSvg, null)),
                        React.createElement(ShowIf, { className: style.selectCrossDropdownIcon, style: { marginLeft: '5px' }, show: notUndefinedOrEmpty(options === null || options === void 0 ? void 0 : options.addDownArrow) },
                            React.createElement(DropdownArrrowSvg, null)))))));
    };
    var formatMultiOption = function (option, onClickCross) {
        var classNames = [style.selectMultiOption];
        var className = classNames === null || classNames === void 0 ? void 0 : classNames.join(' ');
        return (React.createElement(FlexDiv, { className: className },
            React.createElement("div", { className: style.selectMultiOptionInner }, option.displayValue),
            React.createElement("div", { style: { marginLeft: 'auto' } },
                React.createElement(FlexDiv, null,
                    React.createElement("div", { onClick: onClickCross, className: style.selectCrossDropdownIcon, style: { marginLeft: '5px' } },
                        React.createElement(CrossSvg, null))))));
    };
    var onClickCross = function (e) {
        stopPropagation(e);
        if (notUndefinedOrEmpty(props.onClickClearOverride)) {
            props.onClickClearOverride();
            return;
        }
        if (props.onChange)
            props.onChange('');
        if (props.onChanges)
            props.onChanges([]);
        setShowDropdown(false);
    };
    var onClickCrossMulti = function (e, value) {
        stopPropagation(e);
        if (props.onChanges) {
            props.onChanges(updateAuto(props.values, { $splice: [[props.values.findIndex(function (d) { return d == value; }), 1]] }));
        }
        setShowDropdown(false);
    };
    var emptyValue = useMemo(function () {
        return formatOption({ displayValue: React.createElement("div", { style: { color: 'gray', paddingLeft: '10px' } }, 'No selected value'), value: null }, { onClick: function () { return setShowDropdown(true); }, addDownArrow: true, disablePadding: true });
    }, []);
    var selectedValue = useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        var selectedValueIsSingleOption = (_c = (_b = (_a = props.options) === null || _a === void 0 ? void 0 : _a.filter(function (o) { var _a; return (_a = props.values) === null || _a === void 0 ? void 0 : _a.includes(o.value); })) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.singleOption;
        if (selectedValueIsSingleOption) {
            return formatOption((_e = (_d = props.options) === null || _d === void 0 ? void 0 : _d.filter(function (o) { var _a; return (_a = props.values) === null || _a === void 0 ? void 0 : _a.includes(o.value); })) === null || _e === void 0 ? void 0 : _e[0], { onClick: function () { return setShowDropdown(true); }, addCross: !props.hideClearIcon, addDownArrow: true, onClickCross: onClickCross, disablePadding: true });
        }
        else if (props.allowMultipleValues) {
            var displayValues = (_f = props.options) === null || _f === void 0 ? void 0 : _f.filter(function (o) { var _a; return (_a = props.values) === null || _a === void 0 ? void 0 : _a.includes(o.value); });
            if (displayValues.length == 0)
                return emptyValue;
            else {
                return (React.createElement("div", { className: style.selectMultiOptionWrapper }, displayValues === null || displayValues === void 0 ? void 0 :
                    displayValues.map(function (d) {
                        return (formatMultiOption(d, function (e) { return onClickCrossMulti(e, d.value); }));
                    }),
                    React.createElement(ShowIf, { show: !props.hideClearIcon },
                        React.createElement("div", { onClick: onClickCross, className: style.selectCrossDropdownIcon, style: { marginLeft: 'auto' } },
                            React.createElement(CrossSvg, null))),
                    React.createElement("div", { className: style.selectCrossDropdownIcon, style: { marginLeft: '5px' } },
                        React.createElement(DropdownArrrowSvg, null))));
            }
        }
        else {
            var found = (_g = props.options) === null || _g === void 0 ? void 0 : _g.find(function (k) { return (k === null || k === void 0 ? void 0 : k.value) == props.value; });
            if (undefinedOrEmpty(found))
                return emptyValue;
            //Format
            return formatOption(found, { onClick: function () { return setShowDropdown(true); }, addCross: !props.hideClearIcon, addDownArrow: true, onClickCross: onClickCross, disablePadding: true });
        }
    }, [props.options, props.value, onClickCross, filterInput, props.hideClearIcon]);
    var onClickOption = function (option) {
        var _a;
        setShowDropdown(false);
        if (props.onChange)
            props.onChange(option.value);
        if (props.onChanges && props.allowMultipleValues) {
            if (!option.singleOption) {
                //Also remove all previous single options
                props.onChanges(updateAuto((_a = props.values) === null || _a === void 0 ? void 0 : _a.filter(function (d) { var _a, _b; return !((_b = (_a = props.options) === null || _a === void 0 ? void 0 : _a.find(function (k) { return (k === null || k === void 0 ? void 0 : k.value) == d; })) === null || _b === void 0 ? void 0 : _b.singleOption); }), { $push: [option.value] }));
            }
            else
                props.onChanges([option.value]);
        }
    };
    var filterOption = function (o) {
        var _a, _b;
        if (typeof o.displayValue == 'string') {
            return (_b = (_a = o.displayValue) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === null || _b === void 0 ? void 0 : _b.includes(filterInput === null || filterInput === void 0 ? void 0 : filterInput.toLowerCase());
        }
        return true;
    };
    var onClickNewOption = function () {
        if (props.onClickNewOption)
            props.onClickNewOption(filterInput);
        setFilterInput("");
    };
    var sortAndFilterOptions = function () {
        var _a, _b;
        return (_b = (_a = props.options) === null || _a === void 0 ? void 0 : _a.sort(function (a, b) { return (a.singleOption ? 1 : 0) - (b.singleOption ? 1 : 0); })) === null || _b === void 0 ? void 0 : _b.filter(filterOption);
    };
    var _g = useState(false), isFocused = _g[0], setIsFocused = _g[1];
    useEffect(function () {
        console.log(isFocused ? 'Focus' : 'Not focus');
    }, [isFocused]);
    return (React.createElement("div", { onBlur: function () { setIsFocused(false); }, onFocus: function () { setIsFocused(true); }, tabIndex: 0, style: __assign(__assign({}, props.style), { outline: 0 }) },
        React.createElement(ShowIf, { className: style.inputOuterLabel, show: notUndefinedOrEmpty(props.label) }, props.label),
        React.createElement(OutsideAlerter, { debug: props.debug, onClickOutside: function () {
                setShowDropdown(false);
            } },
            React.createElement(ShowIf, { show: notUndefinedOrEmpty(props.customSelect) },
                React.createElement("div", { onClick: function (e) { setShowDropdown(true); } }, props.customSelect)),
            React.createElement(ShowIf, { show: undefinedOrEmpty(props.customSelect) },
                React.createElement("div", { onClick: function () { return setShowDropdown(true); }, className: style.SelectButtonWrapper }, selectedValue)),
            React.createElement("div", { style: { position: 'relative' } },
                React.createElement(ShowIf, { show: showDropdown, style: props.dropdownStyle, className: style.SelectDropdownOuter },
                    React.createElement(ShowIf, { show: notUndefinedOrEmpty(props.customDropdownContent) }, props.customDropdownContent),
                    React.createElement(ShowIf, { show: undefinedOrEmpty(props.customDropdownContent) },
                        React.createElement(ShowIf, { show: !props.hideSearchField },
                            React.createElement("div", { style: { padding: '12px 9px 6px' } },
                                React.createElement("div", { style: { fontWeight: 'bold', fontSize: '12px' } }, "Filter"),
                                React.createElement(Input, { autofocus: true, value: filterInput, onChange: setFilterInput }))),
                        React.createElement(ShowIf, { show: props.allowNewIfNotExists && notUndefinedOrEmpty(filterInput) }, formatOption({ value: filterInput, displayValue: React.createElement("span", null,
                                React.createElement("b", null, "Add new:"),
                                " ",
                                filterInput) }, { onClick: onClickNewOption, grayOnHover: true, isSelectedValue: false })), (_b = sortAndFilterOptions()) === null || _b === void 0 ? void 0 :
                        _b.map(function (d, i) {
                            var _a;
                            var isSelectedByArrows = i == selectedIndexWithArrows;
                            return (React.createElement(React.Fragment, null, formatOption(d, { isHeader: d === null || d === void 0 ? void 0 : d.isHeader, onClick: onClickOption, isSelectedByArrows: isSelectedByArrows, grayOnHover: true, isSelectedValue: d.value == props.value || (props.allowMultipleValues && ((_a = props.values) === null || _a === void 0 ? void 0 : _a.includes(d.value))) })));
                        }),
                        React.createElement(ShowIf, { show: props.allowNewIfNotExists && ((_c = props.options) === null || _c === void 0 ? void 0 : _c.length) == 0 && undefinedOrEmpty(filterInput) }, formatOption({ value: -1, displayValue: 'Start typing to add a new option' }, { onClick: function () { }, grayOnHover: true, isSelectedValue: false }))))))));
};
export var CrossSvgWithHover = function (props) {
    return (React.createElement("div", { onClick: props.onClick, style: props.style, className: style.selectCrossDropdownIcon },
        React.createElement(CrossSvg, null)));
};
export var ActionButtonSelect = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Select, { hideSearchField: true, debug: props.debug, onClickClearOverride: props.onClickClearOverride, alwaysShowDropdown: props.alwaysShowDropdown, dropdownStyle: props.dropdownStyle, customDropdownContent: props.customDropdownContent, style: __assign({ maxWidth: '300px' }, props.style), value: props.value, onChange: props.onChange, options: props.options, customSelect: React.createElement(FlexDiv, { style: { position: 'relative' } },
                React.createElement(Button, { size: 'small', buttonCss: __assign({ height: '33px' }, props.buttonStyle), type: (notUndefinedOrEmpty(props.value) || props.overrideNumberOfSelected > 0) ? 'lightBlue-no-border' : 'white-no-border', icon: props.icon },
                    React.createElement(FlexDiv, null,
                        React.createElement("div", null, props.children + ((notUndefinedOrEmpty(props.value) || notUndefinedOrEmpty(props.overrideNumberOfSelected)) ? (' / ' + (notUndefinedOrEmpty(props.overrideNumberOfSelected) ? props.overrideNumberOfSelected : '1')) : '')),
                        React.createElement(ShowIf, { show: notUndefinedOrEmpty(props.value) || notUndefinedOrEmpty(props.overrideNumberOfSelected), onClick: function (e) { stopPropagation(e); if (props.onClickClearOverride) {
                                props.onClickClearOverride();
                                return;
                            }
                            else {
                                console.log("click");
                                props.onChange('');
                            } }, className: style.selectCrossDropdownIcon, style: { right: '5px', zIndex: '1' } },
                            React.createElement(CrossSvg, null))))) })));
};
export var ActionButton = function (props) {
    var _a = useState(false), showAction = _a[0], setShowAction = _a[1];
    return (React.createElement(OutsideAlerter, { onClickOutside: function () { return setShowAction(false); } },
        React.createElement("div", { style: __assign(__assign({}, props.style), { position: 'relative' }) },
            React.createElement(Button, { size: 'small', buttonCss: __assign({ height: '33px' }, props.buttonStyle), onClick: function () { return setShowAction(function (p) { return !p; }); }, type: showAction ? 'lightBlue-no-border' : 'white-no-border', icon: props.icon }, props.children),
            React.createElement(ShowIf, { show: showAction, className: style.ActionButtonDropdownOuter }, props.dropdownCustomContent))));
};
export var LoadingDots = function (props) {
    return (React.createElement("div", { style: { display: 'flex' } },
        React.createElement("div", { className: style.dotFlashing }),
        React.createElement("div", null, "\u00A0")));
};
var ToastContext = createContext('ToastContext');
export var ToastProvider = function (_a) {
    var children = _a.children;
    var _b = useState([]), toasts = _b[0], setToasts = _b[1];
    useEffect(function () {
        updateProgress();
    }, []);
    var removeToast = function (id) {
        setToasts(function (prevToasts) { return prevToasts.filter(function (toast) { return toast.id !== id; }); });
    };
    var addToast = useCallback(function (message, style, timeout) {
        if (timeout === void 0) { timeout = 10000; }
        var newToast = { id: Date.now(), message: message, style: style, timeout: timeout, startTime: Date.now() };
        setToasts(function (prevToasts) { return __spreadArray(__spreadArray([], prevToasts, true), [newToast], false); });
        setTimeout(function () {
            updateProgress();
        }, 500);
        setTimeout(function () {
            removeToast(newToast.id);
        }, newToast.timeout);
    }, [setToasts, removeToast]);
    var updateProgress = function () {
        setToasts(function (prevToasts) {
            return prevToasts.map(function (toast) { return (__assign(__assign({}, toast), { progress: Math.max(0, 100 - ((Date.now() - toast.startTime) / toast.timeout) * 100) })); });
        });
        //   setTimeout(updateProgress, 100);
    };
    return (React.createElement(ToastContext.Provider, { value: { addToast: addToast, removeToast: removeToast } },
        children,
        React.createElement("div", { className: "toasts-container" }, toasts.map(function (toast) { return (React.createElement("div", { key: toast.id, className: 'toast ' + ((toast.progress < 990 && toast.progress > 20) ? 'active ' : ' ') + toast.style },
            React.createElement("div", { className: "toast-content" },
                toast.message,
                React.createElement("div", { className: "progress-bar", style: { width: "".concat(toast.progress, "%") } })),
            React.createElement("button", { onClick: function () { return removeToast(toast.id); } }, "\u00D7"))); }))));
};
export var useToast = function () {
    var addToast = React.useContext(ToastContext).addToast;
    var showToast = useCallback(function (message, type, timeout) {
        if (type === void 0) { type = 'default'; }
        if (timeout === void 0) { timeout = 5000; }
        var style = type === 'success' ? 'success' : type === 'warning' ? 'warning' : 'danger';
        addToast(message, style, timeout);
    }, [addToast]);
    return showToast;
};
export var Checkbox = function (props) {
    var isBoolean = useMemo(function () {
        return typeof (props.checked) === "boolean" || !props.useIntermediate;
    }, [props.checked]);
    var onClick = function () {
        if (props.inactive)
            return;
        if (nu(props.onClick))
            props.onClick();
        if (nu(props.onChange)) {
            //Return boolean
            if (isBoolean) {
                props.onChange(!props.checked);
            }
            //Return numbers
            else {
                props.onChange((props.checked == 0 || props.checked == 1) ? 2 : 0);
            }
        }
    };
    var getClassName = useMemo(function () {
        if (isBoolean) {
            return props.checked ? (props.type == 'radio' ? style.checkboxRadioChecked : style.checkboxChecked) : (props.type == 'radio' ? style.checkboxRadioNotChecked : '');
        }
        else {
            if (props.checked == 1)
                return style.checkboxIntermediate;
            if (props.checked == 2)
                return style.checkboxChecked;
            return '';
        }
    }, [props.checked, props.type]);
    var isChecked = useMemo(function () {
        if (isBoolean) {
            return props.checked ? true : false;
        }
        else {
            return props.checked == 2;
        }
    }, [props.checked]);
    var outerClassName = useMemo(function () {
        var classNames = [style.checkboxOuter];
        return classNames.join(' ');
    }, [props.className]);
    var spanClassName = useMemo(function () {
        var classNames = [props.type == 'radio' ? style.checkboxCheckmarkRadio : style.checkboxCheckmark];
        if (props.inactive)
            classNames.push(style.checkboxOuterDisableHover);
        return classNames.join(' ');
    }, [props.className, props.inactive, props.type]);
    return (React.createElement("div", { style: props.style, className: props.className },
        React.createElement(ShowIf, { show: notUndefinedOrEmpty(props.label) },
            React.createElement(FlexDiv, { className: style.inputOuterLabel },
                React.createElement("div", null, props.label))),
        React.createElement("div", null,
            React.createElement("label", { className: outerClassName },
                React.createElement("input", { tabIndex: -1, onChange: function (v) { }, type: undefinedOrEmpty(props.type) ? 'checkbox' : props.type, className: getClassName }),
                React.createElement("span", { style: props.checked ? props.checkedStyle : props.notCheckedStyle, onClick: onClick, className: spanClassName })))));
};
export var CheckboxWithText = function (props) {
    var onChange = function (v) {
        props.onChange(v);
    };
    return (React.createElement(FlexDiv, { style: props.style },
        React.createElement(Checkbox, { type: props.type, checked: props.checked, onClick: function () { onChange(!props.checked); } }),
        React.createElement("div", { style: { marginLeft: '5px', cursor: 'pointer', alignSelf: 'center' }, onClick: function () { return onChange(!props.checked); } }, props.children || props.label)));
};
export var Indicator = function (props) {
    var outerClassName = useMemo(function () {
        var classNames = [style.Indicator];
        if (props.type == 'blue')
            classNames.push(style.IndicatorBlue);
        if (props.type == 'lightgray')
            classNames.push(style.IndicatorLightGray);
        if (props.type == 'silver')
            classNames.push(style.IndicatorSilver);
        if (props.type == 'gold')
            classNames.push(style.IndicatorGold);
        if (props.type == 'yellow')
            classNames.push(style.IndicatorYellow);
        if (props.size == 'small')
            classNames.push(style.IndicatorSmall);
        if (props.rounded)
            classNames.push(style.IndicatorRounded);
        return classNames.join(' ');
    }, [props.type, props.size]);
    return (React.createElement("div", { style: props.style, onClick: props.onClick, className: outerClassName }, props.children));
};
export var Tooltip = function (props) {
    var getClassName = useMemo(function () {
        var classNames = [style.tooltipOuter];
        if (nu(props.className))
            classNames.push(props.className);
        return classNames.join(" ");
    }, [props.className]);
    var getSpanClassName = useMemo(function () {
        var classNames = [style.tooltipOverlay];
        if (props.placement == "bottom")
            classNames.push(style.tooltipOverlayBottom);
        else if (props.placement == "left")
            classNames.push(style.tooltipOverlayLeft);
        else if (props.placement == "right")
            classNames.push(style.tooltipOverlayRight);
        else
            classNames.push(style.tooltipOverlayTop);
        return classNames.join(" ");
    }, [props.placement]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { onClick: props.onClick, className: props.disable ? "" : getClassName, style: props.disable
                ? {}
                : __assign(__assign({}, props.style), {
                    display: props.displayInline
                        ? "inline-block"
                        : props.displayFlex
                            ? "flex"
                            : "block",
                }) },
            props.children,
            !props.disable && (React.createElement("span", { style: props.style, className: getSpanClassName }, props.overlay)))));
};
export var ExpandCard = function (props) {
    var _a = useState(props.defaultExpanded), expanded = _a[0], setExpanded = _a[1];
    var getClassName = useMemo(function () {
        var classNames = [style.ExpandCardWrapper];
        if (nu(props.className))
            classNames.push(props.className);
        return classNames.join(" ");
    }, [props.className]);
    var getInnerClassName = useMemo(function () {
        var classNames = [style.ExpandCardWrapperHeader];
        return classNames.join(" ");
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: props.style, className: getClassName },
            React.createElement(FlexDiv, { alignCenter: true, className: getInnerClassName },
                React.createElement(ShowIf, { show: !props.hideArrow },
                    React.createElement("div", { onClick: function () { return setExpanded(function (p) { return !p; }); }, className: style.ExpandHeaderArrow + ' ' + (expanded ? style.ExpandHeaderArrorExpand : '') }, '>')),
                React.createElement("div", { style: { marginLeft: '5px' }, onClick: function () { return setExpanded(function (p) { return !p; }); }, className: style.ExpandHeaderText }, props.header),
                React.createElement("div", { style: { marginLeft: '15px' } }, props.headerRightPart)),
            React.createElement(ShowIf, { className: style.ExpandCardInnerWrapper, show: expanded },
                React.createElement("div", { style: { padding: '0 10px' } }, props.children)))));
};
export var ExpandHeader = function (props) {
    var _a = useState(props.defaultExpanded), expanded = _a[0], setExpanded = _a[1];
    var getClassName = useMemo(function () {
        var classNames = [style.ExpandHeaderWrapper];
        if (nu(props.className))
            classNames.push(props.className);
        return classNames.join(" ");
    }, [props.className]);
    var getInnerClassName = useMemo(function () {
        var classNames = [];
        if (props.headerLevel == 1)
            classNames.push(style.ExpandHeaderHeader1);
        else if (props.headerLevel == 2)
            classNames.push(style.ExpandHeaderHeader2);
        else if (props.headerLevel == 3)
            classNames.push(style.ExpandHeaderHeader3);
        else
            classNames.push(style.ExpandHeaderHeader1);
        return classNames.join(" ");
    }, [props.headerLevel]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: props.style, className: getClassName },
            React.createElement(FlexDiv, { alignCenter: true, className: getInnerClassName },
                React.createElement(ShowIf, { show: !props.hideArrow },
                    React.createElement("div", { onClick: function () { return setExpanded(function (p) { return !p; }); }, className: style.ExpandHeaderArrow + ' ' + (expanded ? style.ExpandHeaderArrorExpand : '') }, '>')),
                React.createElement("div", { style: { marginLeft: '5px' }, onClick: function () { return setExpanded(function (p) { return !p; }); }, className: style.ExpandHeaderText }, props.header),
                React.createElement("div", { style: { marginLeft: '15px' } }, props.headerRightPart)),
            React.createElement(Line, { style: { margin: '5px 0 5px 0', width: props.enableLine ? 'auto' : '0' } }),
            React.createElement(ShowIf, { show: expanded },
                React.createElement("div", { style: { padding: '0 10px' } }, props.children)))));
};
export var Expandeable = function (props) {
    var _a = useState(props.expanded ? 'auto' : '0px'), height = _a[0], setHeight = _a[1];
    var _b = useState(false), initiated = _b[0], setInitiated = _b[1];
    useEffect(function () {
        if (!initiated) {
            setInitiated(true);
            return;
        }
        if (props.expanded) {
            setHeight('100px');
            setTimeout(function () {
                setHeight('auto');
            }, 500);
        }
        else {
            setHeight('100px');
            setTimeout(function () {
                setHeight('0');
            }, 10);
        }
    }, [props.expanded]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { height: height }, className: style.expandable }, props.children)));
};
export var P = function (props) {
    return (React.createElement("p", { style: __assign({ color: 'gray', maxWidth: '800px' }, props.style) }, props.children));
};
export var CodeWindow = function (props) {
    var addToast = useToast();
    var wordToColor = [{ word: 'install', color: 'orange' }, { word: 'import', color: 'red' }, { word: 'from', color: 'red' }];
    var colorFormatWord = function (text) {
        var newText = text;
        wordToColor.forEach(function (w) {
            newText = newText.replace(w.word, "<span style='color:".concat(w.color, "'>").concat(w.word, "</span>"));
        });
        return newText;
    };
    /*
      const formatText = (text: string) => {
        return text?.split('\\n').map((d, i) => {
          return (<div style={{ marginTop: '15px' }} key={i} dangerouslySetInnerHTML={{ __html: colorFormatWord(d) }}></div>)
        })
      } */
    var onClickCopy = function () {
        var _a;
        //Add actual new lines to clipboard as well
        var textWithNewLines = (_a = props.children) === null || _a === void 0 ? void 0 : _a.replace('\\n', '\r\n');
        console.log(textWithNewLines);
        copyToClipboard(textWithNewLines);
        addToast('Copied to clipboard', 'success');
    };
    return (React.createElement("div", { style: { backgroundColor: '#292c34', fontFamily: 'Courier new', color: 'white', position: 'relative', maxWidth: '800px', padding: '10px', borderRadius: '5px' } },
        React.createElement(SyntaxHighlighter, { showLineNumbers: true, style: darkk }, props.children),
        React.createElement(ShowIf, { show: !props.hideCopy, style: { position: 'absolute', top: '5px', right: '5px' } },
            React.createElement(Button, { onClick: function () { onClickCopy(); }, size: 'small', type: 'white' }, "Copy"))));
};
export var CenterItems = function (props) {
    return (React.createElement(FlexDiv, { style: { width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'center' } },
        React.createElement("div", { style: { marginTop: '100px', width: '400px' } }, props.children)));
};
export var InfoBox = function (props) {
    var getClassName = useMemo(function () {
        var classNames = [style.InfoBoxWrapper];
        if (props.type == 'gray')
            classNames.push(style.InfoBoxWrapperGray);
        return classNames.join(' ');
    }, [props.type]);
    return (React.createElement("div", { className: getClassName, style: props.style },
        React.createElement(FlexDiv, null,
            React.createElement("div", { style: { fontSize: '20px', marginRight: '10px' } },
                React.createElement(InfoSvg, null)),
            React.createElement("div", null, props.children))));
};
var EditSvg = function () {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", viewBox: "0 0 6.324 6.324" },
        React.createElement("path", { id: "Path_27", "data-name": "Path 27", d: "M3,8V9.322H4.317L8.2,5.436,6.885,4.119ZM9.222,4.418a.35.35,0,0,0,0-.5L8.4,3.1a.35.35,0,0,0-.5,0l-.643.643L8.579,5.061Z", transform: "translate(-3 -2.998)", fill: "#777" })));
};
