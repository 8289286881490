var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useMemo, useState } from 'react';
//@ts-ignore
import style from '../../css/app.module.scss';
import { Button, CheckboxWithText, Circle, FlexDiv, Header2, InfoBox, Input, LoadingDots, Modal, P, Select, ShowIf, Table, ThreeDotsWithMenu, useToast } from '@/src/components/Components';
import { notUndefinedOrEmpty, undefinedOrEmpty, updateAuto } from '@/src/utils/Helpers';
import { extractDefaultCountryCodeFromLanguageCode, languageCodeToName } from '@/src/utils/LanguageHelpers';
import { Flag, getFlagSrcFromLanguageCode } from '@/src/components/PageComponents';
import { ApiRequests } from '@/src/requests/ApiRequests';
export var ProjectContributors = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var defaultNewContributor = useMemo(function () {
        return { projectPk: props.projectPk, pk: null, email: '', name: '', accessToEditLanguages: ['all'], generalAccess: { customizedAccess: { canAddKeys: true, canAddLanguages: true, canAddUsers: true, canDeleteKeys: true, canDeleteLanguages: true, canDeleteUsers: true } } };
    }, [props.projectPk]);
    var _h = useState(false), showNewContributorModal = _h[0], setShowNewContributorModal = _h[1];
    var _j = useState(defaultNewContributor), newContributor = _j[0], setnewContributor = _j[1];
    var _k = useState(false), addingNewContributor = _k[0], setAddingNewContributor = _k[1];
    var addToast = useToast();
    var addNewContributor = function () {
        if (undefinedOrEmpty(newContributor === null || newContributor === void 0 ? void 0 : newContributor.name) || undefinedOrEmpty(newContributor === null || newContributor === void 0 ? void 0 : newContributor.email) || !validateEmail(newContributor === null || newContributor === void 0 ? void 0 : newContributor.email)) {
            setNameTouched(true);
            setEmailTouched(true);
            return;
        }
        setAddingNewContributor(true);
        ApiRequests.Contributors.post(props.projectPk, newContributor, function (response) {
            if (response.status == '200') {
                addToast('Added!', 'success');
                props.onChange(function (prev) { return updateAuto(prev, {
                    contributors: {
                        $push: [newContributor]
                    }
                }); });
                setShowNewContributorModal(false);
            }
            else {
                addToast(response.errors, 'danger');
            }
            setAddingNewContributor(false);
        });
    };
    var saveEditLanguages = function (contributor, access) {
        saveContributor(contributor, access, 'accessToEditLanguages');
    };
    var saveAccessType = function (contributor, access) {
        saveContributor(contributor, access, 'generalAccess');
    };
    var saveContributor = function (contributor, access, attribute) {
        var _a;
        var newValue = updateAuto(contributor, (_a = {}, _a[attribute] = { $set: access }, _a));
        ApiRequests.Contributors.put(newValue, function (response) {
            if (response.status == '200') {
                addToast('Saved!', 'success');
                props.onChange(function (prev) {
                    var _a;
                    var _b;
                    return updateAuto(prev, { contributors: (_a = {}, _a[(_b = prev.contributors) === null || _b === void 0 ? void 0 : _b.findIndex(function (k) { return (k === null || k === void 0 ? void 0 : k.pk) == (contributor === null || contributor === void 0 ? void 0 : contributor.pk); })] = { $set: newValue }, _a) });
                });
            }
            else {
                addToast(response.errors, 'danger');
            }
        });
    };
    var afterDelete = function (contributor) {
        props.onChange(function (prev) { var _a; return updateAuto(prev, { contributors: { $splice: [[(_a = prev.contributors) === null || _a === void 0 ? void 0 : _a.findIndex(function (k) { return (k === null || k === void 0 ? void 0 : k.pk) == contributor.pk; }), 1]] } }); });
    };
    var _l = useState(false), nameTouched = _l[0], setNameTouched = _l[1];
    var _m = useState(false), emailTouched = _m[0], setEmailTouched = _m[1];
    var validateEmail = function (email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };
    return (React.createElement("div", { style: { maxWidth: '1000px' } },
        React.createElement(InfoBox, { style: { maxWidth: '10000px' } }, "Contributors are people with access to this project. "),
        React.createElement(ShowIf, { style: { marginTop: '5px' }, show: ((_b = (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.contributors) === null || _b === void 0 ? void 0 : _b.length) == 0 },
            React.createElement(P, null, "No contributors yet...")),
        React.createElement(ShowIf, { show: ((_d = (_c = props.detailedProject) === null || _c === void 0 ? void 0 : _c.contributors) === null || _d === void 0 ? void 0 : _d.length) > 0 },
            React.createElement(Table, { style: { width: '100%', marginTop: '10px', borderSpacing: '0 1em' }, header: [
                    //   { content: 'Access to view languages' },
                    { content: 'Contributor for languages' },
                    { content: 'General access' }
                ], rows: (_f = (_e = props.detailedProject) === null || _e === void 0 ? void 0 : _e.contributors) === null || _f === void 0 ? void 0 : _f.map(function (c) {
                    var _a;
                    return {
                        key: c.pk,
                        items: [
                            { content: React.createElement(AccessToLanguageEdit, { label: 'Contributor for languages', avaiableLanguages: (_a = props.detailedProject) === null || _a === void 0 ? void 0 : _a.languages, access: c === null || c === void 0 ? void 0 : c.accessToEditLanguages, onChangeAndSave: function (a) { return saveEditLanguages(c, a); } }) }
                            // , { content: <AccessToLanguageEdit label='Access to view languages' avaiableLanguages={props.detailedProject?.languages} access={c?.accessToEditLanguages} onChangeAndSave={(a) => saveEditLanguages(c, a)} /> }
                            ,
                            { content: React.createElement(AccessTypeEdit, { accessType: c === null || c === void 0 ? void 0 : c.generalAccess, onChangeAndSave: function (a) { return saveAccessType(c, a); } }) },
                            { content: React.createElement(ContributorMenu, { onAfterDelete: function () { return afterDelete(c); }, contributor: c }) }
                        ],
                        leftHeaderContent: React.createElement(CircleWithNameAndEmail, { email: c === null || c === void 0 ? void 0 : c.email, name: c === null || c === void 0 ? void 0 : c.name })
                    };
                }) })),
        React.createElement(Button, { style: { marginTop: '20px' }, onClick: function () { return setShowNewContributorModal(true); }, size: 'medium', type: 'blue' }, "New contributor"),
        React.createElement(Modal, { style: { maxWidth: '600px' }, show: showNewContributorModal, onHide: function () { return setShowNewContributorModal(false); } },
            React.createElement(Header2, null, "Add new contributor"),
            React.createElement(Input, { autofocus: true, onBlur: function () { return setNameTouched(true); }, showErrorMessage: nameTouched && undefinedOrEmpty(newContributor === null || newContributor === void 0 ? void 0 : newContributor.name) && 'Cannot be empty', showMandatoryIndication: true, style: { marginTop: '10px' }, label: 'Name', value: newContributor === null || newContributor === void 0 ? void 0 : newContributor.name, onChange: function (v) { return setnewContributor(function (prev) { return updateAuto(prev, { name: { $set: v } }); }); } }),
            React.createElement(Input, { onBlur: function () { return setEmailTouched(true); }, showMandatoryIndication: true, showErrorMessage: emailTouched && !validateEmail(newContributor === null || newContributor === void 0 ? void 0 : newContributor.email) && 'Must be valid email', style: { marginTop: '10px' }, label: 'Email', value: newContributor === null || newContributor === void 0 ? void 0 : newContributor.email, onChange: function (v) { return setnewContributor(function (prev) { return updateAuto(prev, { email: { $set: v } }); }); } }),
            React.createElement(LanguageEditSelect, { style: { marginTop: '10px' }, label: 'Access to edit languages', access: newContributor === null || newContributor === void 0 ? void 0 : newContributor.accessToEditLanguages, onChangeAccess: function (v) { return setnewContributor(function (prev) { return updateAuto(prev, { accessToEditLanguages: { $set: v } }); }); }, avaiableLanguages: (_g = props.detailedProject) === null || _g === void 0 ? void 0 : _g.languages }),
            React.createElement(AllCustomAccessCheckboxes, { style: { marginTop: '15px' }, accessType: newContributor.generalAccess.customizedAccess, onChangeAccessType: function (func) { return setnewContributor(function (prev) { return updateAuto(prev, { generalAccess: { customizedAccess: { $apply: func } } }); }); } }),
            React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                React.createElement(Button, { type: 'blue', size: 'medium', onClick: addNewContributor, disabled: addingNewContributor }, addingNewContributor ? React.createElement(LoadingDots, null) : 'Save'),
                React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: function () { setnewContributor(defaultNewContributor); setShowNewContributorModal(false); }, disabled: addingNewContributor }, "Cancel")))));
};
var ContributorMenu = function (props) {
    var _a = useState(false), showMenu = _a[0], setShowMenu = _a[1];
    var addToast = useToast();
    var deleteContributor = function () {
        var _a;
        ApiRequests.Contributors.delele(props.contributor.projectPk, (_a = props.contributor) === null || _a === void 0 ? void 0 : _a.pk, function (response) {
            if (response.status == '200') {
                addToast('Success', 'success');
                props.onAfterDelete();
            }
            else {
                addToast(response.errors, 'danger');
            }
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ThreeDotsWithMenu, { options: [{ text: 'Delete', onClick: deleteContributor }] })));
};
export var AccessToLanguageEdit = function (props) {
    var _a, _b;
    var _c = useState(false), saving = _c[0], setSaving = _c[1];
    var _d = useState(false), editMode = _d[0], setEditMode = _d[1];
    var _e = useState(props.access), currentAccess = _e[0], setcurrentAccess = _e[1];
    var options = useMemo(function () {
        var _a;
        return (_a = props.avaiableLanguages) === null || _a === void 0 ? void 0 : _a.map(function (d) { return { value: d === null || d === void 0 ? void 0 : d.language_code, displayValue: d === null || d === void 0 ? void 0 : d.language_code }; });
    }, [props.avaiableLanguages]);
    // const options: Array<Option> = AllLanguages?.map(d => { return { value: d, displayValue: d } });
    useEffect(function () {
        setcurrentAccess(props.access);
    }, [props.access]);
    var cancel = function () {
        setEditMode(false);
        setcurrentAccess(props.access);
    };
    var save = function () {
        props.onChangeAndSave((currentAccess === null || currentAccess === void 0 ? void 0 : currentAccess[0]) == 'all' ? ['all'] : currentAccess);
        setEditMode(false);
    };
    return (React.createElement(FlexDiv, { style: { alignItems: 'center' } },
        ((_a = props.access) === null || _a === void 0 ? void 0 : _a[0]) == 'all' ? 'All' : React.createElement(FormatFlags, { flags: (_b = props.access) === null || _b === void 0 ? void 0 : _b.map(function (d) { return extractDefaultCountryCodeFromLanguageCode(d); }) }),
        React.createElement(Button, { onClick: function () { return setEditMode(true); }, type: 'white', size: 'small', style: { marginLeft: '10px' } }, "Edit"),
        React.createElement(Modal, { style: { maxWidth: '600px' }, show: editMode, onHide: function () { return setEditMode(false); } },
            React.createElement(LanguageEditSelect, { label: props.label, style: { width: '300px' }, access: currentAccess, onChangeAccess: setcurrentAccess, avaiableLanguages: props.avaiableLanguages }),
            React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                React.createElement(Button, { type: 'blue', size: 'medium', onClick: save, disabled: saving }, saving ? React.createElement(LoadingDots, null) : 'Save'),
                React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: cancel, disabled: saving }, "Cancel")))));
};
export var LanguageEditSelect = function (props) {
    var getSelectedValues = function () {
        return props.access;
    };
    var options = useMemo(function () {
        var _a;
        return (_a = props.avaiableLanguages) === null || _a === void 0 ? void 0 : _a.map(function (d) { return { value: d === null || d === void 0 ? void 0 : d.language_code, displayValue: languageCodeToName(d === null || d === void 0 ? void 0 : d.language_code), imgSrc: getFlagSrcFromLanguageCode(d === null || d === void 0 ? void 0 : d.language_code) }; });
    }, [props.avaiableLanguages]);
    return (React.createElement(Select, { label: props.label, style: props.style, allowMultipleValues: true, values: getSelectedValues(), onChanges: props.onChangeAccess, options: options.concat([{ value: 'all', displayValue: 'All', singleOption: true }]) }));
};
export var AccessTypeEdit = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var _l = useState(false), editMode = _l[0], setEditMode = _l[1];
    var _m = useState(false), saving = _m[0], setSaving = _m[1];
    var _o = useState(props.accessType), accessType = _o[0], setAccessType = _o[1];
    useEffect(function () {
        setAccessType(props.accessType);
    }, [props.accessType]);
    /* const accessTypeToDisplay = (accessType: Types.ContributorGeneralAccess) => {
       if (accessType?.accessType == 'admin')
         return 'Admin';
       if (accessType?.accessType == 'translator')
         return 'Translator';
       if (accessType?.accessType == 'custom')
         return 'Custom';
     }*/
    var save = function () {
        props.onChangeAndSave(accessType);
        setEditMode(false);
    };
    var cancel = function () {
        setEditMode(false);
        setAccessType(props.accessType);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FlexDiv, null,
            React.createElement(ShowIf, { style: { marginLeft: '10px' }, show: true },
                React.createElement(FlexDiv, null,
                    React.createElement(BlueCircleCustomAccess, { noHoverEffect: true, style: { marginLeft: '2px' }, filled: (_b = (_a = props.accessType) === null || _a === void 0 ? void 0 : _a.customizedAccess) === null || _b === void 0 ? void 0 : _b.canAddKeys, onClick: function () { } }, "K"),
                    React.createElement(BlueCircleCustomAccess, { noHoverEffect: true, style: { marginLeft: '2px' }, filled: (_d = (_c = props.accessType) === null || _c === void 0 ? void 0 : _c.customizedAccess) === null || _d === void 0 ? void 0 : _d.canAddLanguages, onClick: function () { } }, "L"),
                    React.createElement(BlueCircleCustomAccess, { noHoverEffect: true, style: { marginLeft: '2px' }, filled: (_f = (_e = props.accessType) === null || _e === void 0 ? void 0 : _e.customizedAccess) === null || _f === void 0 ? void 0 : _f.canAddUsers, onClick: function () { } }, "C"),
                    React.createElement(BlueCircleCustomAccess, { noHoverEffect: true, style: { marginLeft: '2px' }, filled: (_h = (_g = props.accessType) === null || _g === void 0 ? void 0 : _g.customizedAccess) === null || _h === void 0 ? void 0 : _h.canDeleteLanguages, onClick: function () { } }, "L"),
                    React.createElement(BlueCircleCustomAccess, { noHoverEffect: true, style: { marginLeft: '2px' }, filled: (_k = (_j = props.accessType) === null || _j === void 0 ? void 0 : _j.customizedAccess) === null || _k === void 0 ? void 0 : _k.canDeleteKeys, onClick: function () { } }, "D"))),
            React.createElement(Button, { type: 'white', size: 'small', style: { marginLeft: '10px' }, onClick: function () { return setEditMode(true); } }, " Edit")),
        React.createElement(Modal, { style: { maxWidth: '600px' }, show: editMode, onHide: function () { return setEditMode(false); } },
            React.createElement(ShowIf, { show: true },
                React.createElement(AllCustomAccessCheckboxes, { accessType: accessType.customizedAccess, onChangeAccessType: function (func) { return setAccessType(function (prev) {
                        console.log(updateAuto(prev, { customizedAccess: { $apply: func } }));
                        return updateAuto(prev, { customizedAccess: { $apply: func } });
                    }); } })),
            React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                React.createElement(Button, { type: 'blue', size: 'medium', onClick: save, disabled: saving }, saving ? React.createElement(LoadingDots, null) : 'Save'),
                React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'medium', onClick: cancel, disabled: saving }, "Cancel")))));
};
export var AllCustomAccessCheckboxes = function (props) {
    var _a, _b, _c, _d, _e;
    return (React.createElement("div", { style: props.style },
        React.createElement(CheckboxWithText, { style: { marginTop: '5px' }, checked: (_a = props.accessType) === null || _a === void 0 ? void 0 : _a.canAddKeys, onChange: function (v) { return props.onChangeAccessType(function (prev) { return updateAuto(prev, { canAddKeys: { $set: v } }); }); } }, "Can add new keys"),
        React.createElement(CheckboxWithText, { style: { marginTop: '5px' }, checked: (_b = props.accessType) === null || _b === void 0 ? void 0 : _b.canAddLanguages, onChange: function (v) { return props.onChangeAccessType(function (prev) { return updateAuto(prev, { canAddLanguages: { $set: v } }); }); } }, "Can add languages"),
        React.createElement(CheckboxWithText, { style: { marginTop: '5px' }, checked: (_c = props.accessType) === null || _c === void 0 ? void 0 : _c.canAddUsers, onChange: function (v) { return props.onChangeAccessType(function (prev) { return updateAuto(prev, { canAddUsers: { $set: v } }); }); } }, "Can add new contributors"),
        React.createElement(CheckboxWithText, { style: { marginTop: '5px' }, checked: (_d = props.accessType) === null || _d === void 0 ? void 0 : _d.canDeleteKeys, onChange: function (v) { return props.onChangeAccessType(function (prev) { return updateAuto(prev, { canDeleteKeys: { $set: v } }); }); } }, "Can delete keys"),
        React.createElement(CheckboxWithText, { style: { marginTop: '5px' }, checked: (_e = props.accessType) === null || _e === void 0 ? void 0 : _e.canDeleteLanguages, onChange: function (v) { return props.onChangeAccessType(function (prev) { return updateAuto(prev, { canDeleteLanguages: { $set: v } }); }); } }, "Can delete languages")));
};
export var BlueCircleCustomAccess = function (props) {
    return (React.createElement("div", { style: props.style, className: style.BlueCircleCustomAccess + ' ' + (props.filled ? style.BlueCircleCustomAccessFilled : '') + ' ' + (props.noHoverEffect ? style.BlueCircleCustomAccessNoHover : '') + ' ' + (props.noBorder ? style.BlueCircleCustomAccessNoBorder : ''), onClick: props.onClick }, props.children));
};
var FormatFlags = function (props) {
    var _a, _b;
    return (React.createElement(FlexDiv, null, (_b = (_a = props.flags) === null || _a === void 0 ? void 0 : _a.filter(function (d) { return notUndefinedOrEmpty(d); })) === null || _b === void 0 ? void 0 : _b.map(function (flag, i) {
        return (React.createElement(Flag, { style: { marginLeft: i > 0 ? '3px' : '0' }, languageFlag: flag }));
    })));
};
export var CircleWithNameAndEmail = function (props) {
    var getInititals = function () {
        if (!props.name)
            return '';
        var split = props.name.split(' ');
        if (split.length == 1)
            return split[0].charAt(0).toUpperCase();
        return split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase();
    };
    return (React.createElement(FlexDiv, { style: __assign({}, props.style), alignCenter: true },
        React.createElement(ShowIf, { show: !props.disableCircle },
            React.createElement(Circle, { type: 'white' }, getInititals())),
        React.createElement(FlexDiv, { style: { flexDirection: 'column', marginLeft: props.disableCircle ? '0px' : '10px', minWidth: '150px' } },
            React.createElement("div", { style: { fontWeight: 'bold', color: 'black' } }, props.name),
            React.createElement("div", { style: { color: 'gray' } }, props.email))));
};
