import React from 'react';
//@ts-ignore
import style from './website.module.scss';
import { NavLink } from 'react-router-dom';
import { FlexDiv, Line, P } from '../components/Components';
import { CenterPart, Header, Hero } from './WebsiteWrapper';
import { Card } from './WebsiteCompoents';
import { JavascriptSvg, NoCodeSvg, ReactSvg } from '../components/SvgItems';
export var Documentation = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Hero, null,
            React.createElement(CenterPart, { style: { alignItems: 'center' } },
                React.createElement(Header, null, "Documentation"),
                React.createElement(P, { style: { marginTop: '40px', maxWidth: '600px', textAlign: 'center' } }, "This guide explains how to install Lingui and configure your project, then shows you how to synchronize your keys and translations, and presents the syntaxes you can use in your code."))),
        React.createElement(CenterPart, null,
            React.createElement(FlexDiv, null,
                React.createElement(Line, { thick: true, color: 'blue', style: { height: '68px', width: '2px' }, vertical: true }),
                React.createElement(Header, { size: 'medium' }, "Step by step guides")),
            React.createElement(P, null, "We wrote detailed guides to help you throughout the localization process of your application. Choose your stack and... follow the guide!"),
            React.createElement(FlexDiv, { style: { marginLeft: '-10px' } },
                React.createElement(CardWithLogo, { style: { marginLeft: '10px' }, navlinkTo: '/web/reactguide', logo: React.createElement(ReactSvg, null), text: 'React' }),
                React.createElement(CardWithLogo, { style: { marginLeft: '10px' }, navlinkTo: '/web/javascript', logo: React.createElement(JavascriptSvg, null), text: 'Javascript' }),
                React.createElement(CardWithLogo, { style: { marginLeft: '10px' }, navlinkTo: '/web/nocode', logo: React.createElement(NoCodeSvg, null), text: 'No Code' })))));
};
var CardWithLogo = function (props) {
    return (React.createElement(NavLink, { to: props.navlinkTo },
        React.createElement(Card, { style: props.style, className: style.CardWithLogo },
            React.createElement(FlexDiv, { alignCenter: true, style: { justifyContent: 'center', flexDirection: 'column' } },
                React.createElement("div", { style: { fontSize: '120px' } }, props.logo),
                React.createElement(P, { style: { fontSize: '25px', color: 'black' } },
                    " ",
                    props.text)))));
};
