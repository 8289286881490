import React, { useMemo, useState } from 'react';
import { Button, CheckboxWithText, FlexDiv, Header3, LoadingDots, Modal, Select, ShowIf, Tooltip, useToast } from '@/src/components/Components';
import { notUndefinedOrEmpty } from '@/src/utils/Helpers';
import { languageCodeToName } from '@/src/utils/LanguageHelpers';
import { getFlagName } from '@/src/components/PageComponents';
import { InfoSvg } from '@/src/components/SvgItems';
import { AllLanguages } from '@/shared/Types';
import { useKeystrokeLogger } from '@/src/utils/CustomHooks';
export var AddLanguageModal = function (props) {
    var _a = useState(), selectedLanguages = _a[0], setSelectedLanguages = _a[1];
    var _b = useState(false), isBaseLanguage = _b[0], setIsBaseLanguage = _b[1];
    var _c = useState(false), addingLanguage = _c[0], setaddingLanguage = _c[1];
    var onEnter = function () {
        addLanguage();
    };
    var keylogger = useKeystrokeLogger(notUndefinedOrEmpty(selectedLanguages), { onEnter: onEnter });
    var addToast = useToast();
    var addLanguage = function () {
        setaddingLanguage(true);
        props.onAddLanguages({ code: selectedLanguages, isBase: isBaseLanguage }, function () {
            addToast('Success!', 'success');
            setSelectedLanguages(null);
            setaddingLanguage(false);
            props.onHide();
        }, function (error) {
            addToast(error, 'danger');
            props.onHide();
            setaddingLanguage(false);
        });
    };
    var cancel = function () {
        setSelectedLanguages(null);
        props.onHide();
    };
    var languageOptions = useMemo(function () {
        var tmp = AllLanguages === null || AllLanguages === void 0 ? void 0 : AllLanguages.map(function (d) { return { value: d, imgSrc: getFlagName(d), displayValue: languageCodeToName(d).language + ' (' + languageCodeToName(d).country + ')' }; });
        return tmp === null || tmp === void 0 ? void 0 : tmp.filter(function (d) { var _a; return !((_a = props.alreadyExistingLocale) === null || _a === void 0 ? void 0 : _a.includes(d === null || d === void 0 ? void 0 : d.value)); });
    }, [props.alreadyExistingLocale]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { show: props.show, onHide: props.onHide },
            React.createElement(Header3, { style: { marginBottom: '20px' }, black: true }, "Add language"),
            React.createElement(Select, { showDropdownOnMount: true, options: languageOptions, onChange: setSelectedLanguages, value: selectedLanguages }),
            React.createElement(ShowIf, { show: notUndefinedOrEmpty(selectedLanguages) && false },
                React.createElement(Header3, { style: { marginTop: '5px' }, black: true }, "Settings"),
                React.createElement(CheckboxWithText, { style: { marginTop: '10px' }, checked: isBaseLanguage, onChange: setIsBaseLanguage, label: React.createElement(FlexDiv, null,
                        React.createElement("div", null, "Is base language for project"),
                        React.createElement(Tooltip, { style: { marginLeft: '5px' }, overlay: 'Defines if the selected language should be the base language of the project' },
                            React.createElement(InfoSvg, null))) })),
            React.createElement(FlexDiv, { style: { marginTop: '20px' } },
                React.createElement(Button, { type: 'blue', size: 'small', onClick: addLanguage, disabled: addingLanguage }, addingLanguage ? React.createElement(LoadingDots, null) : 'Add language'),
                React.createElement(Button, { style: { marginLeft: '10px' }, type: 'white', size: 'small', onClick: cancel }, "Cancel")))));
};
